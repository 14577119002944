import React from 'react';
import { useIntl } from 'react-intl';
import {NavLink} from 'react-router-dom';
import {getEnvVar} from "../../env-vars-utils";

export const PageNotFound: React.FC = () => {
  const intl = useIntl();
  const emailTo = getEnvVar('react_app_contact_mail')
  return (
    <div className="page-404">
      <section className="section-1 pt-2">
        <div className="container-fluid d-block d-lg-none px-4">
          <div className="row">
            <div className="col-7 bg-white">
              <a href="#" className="d-inline-block">
                <img className="my-4" src="../../assets/img/socgen_logo_muted.svg" height="32" />
              </a>
            </div>
            <div className="col-4 bg-white"></div>
          </div>
        </div>
        <div className="container d-none d-lg-block">
          <div className="row">
            <div className="col-7 bg-white">
              <a href="#" className="d-inline-block">
                <img className="spacing-mt-3 spacing-mb-5" src="/socgen_logo_full.svg" height="32" />
              </a>
            </div>
            <div className="col-4 bg-white"></div>
          </div>
        </div>
      </section>
      <section className="section-2">
        <div className="container-fluid">
          <div className="container main-wrapper">
            <div className="row">
              <div className="col-lg-2 bg-white pt-5 pb-4 py-lg-0">
                <span className="d-block d-md-none text-outline-socgen text-primary-alt font-weight-medium"
                  style={{ fontSize: '56px', fontFamily: 'Inter' }}>
                  404
              </span>
                <span className="d-none d-md-block d-lg-none text-outline-socgen text-primary-alt font-weight-medium"
                  style={{ lineHeight: 1.2, fontSize: '56px', fontFamily: 'Inter' }}>
                  404
              </span>
                <span className="mt-n2 d-none d-lg-inline-block d-xl-none display-2 text-outline-socgen text-primary-alt font-weight-medium">
                  404
              </span>
                <span className="mt-n3 d-none d-xl-block display-1 text-outline-socgen text-primary-alt font-weight-medium"
                  style={{ fontSize: '96px', overflow: 'hidden' }}>
                  404
              </span>
              </div>
              <div className="col-lg-5  bg-white d-flex flex-column justify-content-between">
                <div>
                  <h1 className="d-none d-lg-block display-2 font-weight-medium line-height-1 pt-0 pb-3" style={{color: 'black'}}>
                    {intl.formatMessage({ id: 'page-404.title' })}
                  </h1>
                  <h1 className="d-lg-none display-3 font-weight-medium line-height-1 py-2">
                    {intl.formatMessage({ id: 'page-404.title' })}
                  </h1>

                  {/* Mobile */}
                  <p className="d-lg-none mb-5 h3 font-weight-medium text-secondary line-height-1">
                    {intl.formatMessage({ id: 'page-404.description' })}
                  </p>
                  {/* Desktop */}
                  <p className="d-none d-lg-block d-xxl-none display-3 font-weight-medium text-secondary w-75 line-height-1">
                    {intl.formatMessage({ id: 'page-404.description' })}
                  </p>

                  {/* XXL */}
                  <p className="d-none d-xxl-block display-3 font-weight-medium text-secondary w-75 line-height-1">
                    {intl.formatMessage({ id: 'page-404.description' })}
                  </p>
                </div>
                <div className="mb-5 d-none d-md-flex">
                  <NavLink className="btn btn-xl btn-primary" to="/">
                      {intl.formatMessage({id: 'page-404.go-home'})}
                  </NavLink>
                    <a className="btn btn-xl btn-flat-secondary ms-1" href={`mailto:${emailTo}`}>
                        {intl.formatMessage({id: 'page-404.contact-us'})}
                    </a>
                </div>
                <div className="d-block d-md-none">
                    <NavLink className="btn btn-xl btn-primary btn-block mb-1" to="/">
                        {intl.formatMessage({id: 'page-404.go-home'})}
                    </NavLink>
                    <a className="btn btn-xl btn-flat-secondary ms-1 btn-block" href={`mailto:${emailTo}`}>
                        {intl.formatMessage({id: 'page-404.contact-us'})}
                    </a>
                </div>
              </div>
              <div className="col-4 px-0 position-relative">
                <div className="d-none d-lg-block"
                  style={{ paddingTop: '100%', background: '#fff url(/404.svg) center no-repeat', backgroundSize: '130%' }} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
