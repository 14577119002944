import {ajaxPost} from "rxjs/internal/observable/dom/AjaxObservable";
import {catchError, map} from "rxjs/operators";
import {handleError} from "../utils/UtilsService";
import {getEnvVar} from "../../env-vars-utils";
import {getAuthorizationHeader} from "../../helpers";

const API_URL = getEnvVar('react_app_api_endpoint');

export const postDeleteDailyData = (deleteRequest: any) => {
    const header = {
        Authorization: getAuthorizationHeader(),
        'Content-Type': 'application/json;charset=UTF-8'
    };
    return ajaxPost(
      API_URL + '/admin/deletedailydata',
      deleteRequest,
      header
    ).pipe(
        map(response => {
          return response;
        }),
        catchError(error => handleError(error))
    );
  };

export const postDeleteData = (deleteRequest: any) => {
  const header = {
      Authorization: getAuthorizationHeader(),
      'Content-Type': 'application/json;charset=UTF-8'
  };
  return ajaxPost(
    API_URL + '/admin/deletedata',
    deleteRequest,
    header
  ).pipe(
    map(response => {
      return response;
    }),
    catchError(error => handleError(error))
  );
};