import {ajaxPost} from "rxjs/internal/observable/dom/AjaxObservable";
import {catchError, map} from 'rxjs/operators'
import {handleError} from "./utils/UtilsService";
import {getEnvVar} from "../env-vars-utils";
import {getAuthorizationHeader} from "../helpers";

const API_URL = getEnvVar('react_app_api_endpoint');
const getHttpHeader = () => ({
    Authorization: getAuthorizationHeader(),
    'Content-Type': 'application/json'
});

export const FetchPerformanceTrendData = (date: string, reportingUnitId: number, subReportingUnitId: number,
                                          dataNatureId: number, importType: string, regionId: number | undefined) => {
    const body = {
        date,
        reportingUnitId,
        subReportingUnitId,
        dataNatureId,
        importType,
        regionId
    };

    return ajaxPost(
      API_URL + "/graph/summary",
      body,
      getHttpHeader()
    ).pipe(
      map(response => response.response),
      catchError(error => handleError(error))
    );
};

export const FetchHeatMapData = (date: string, reportingUnitId: number, subReportingUnitId: number, dataNatureId: number,
                                 importType: string, regionId: number | undefined) => {
    const request = {
        date,
        reportingUnitId,
        subReportingUnitId,
        dataNatureId,
        regionId,
    };
    let body;
    if (importType !== 'none') {
        body = {...request, importType};
    } else {
        body = request;
    }
    return ajaxPost(
      API_URL + "/heatmap",
      body,
      getHttpHeader()
    ).pipe(
      map(response => response.response),
      catchError(error => handleError(error))
    );
};

export const FetchHeatMapNews = (date: string, reportingUnitId: number, subReportingUnitId: number) => {
    const body = {
        date,
        reportingUnitId,
        subReportingUnitId,
        'newsType': 'SUMMARY'
    };

    return ajaxPost(
      API_URL + "/news/list",
      body,
      getHttpHeader()
    ).pipe(
      map(response => response.response),
      catchError(error => handleError(error))
    );
};