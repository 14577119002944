export const ColumnDefs: any[] = [
    {
        headerName: '',
        field: 'action',
        width: '20px',
        cellRenderer: 'editModalCellRenderer',
    },
    {
        headerName: 'Email',
        field: 'email',
        width: '250px',
        autoHeight: true
    },
    {
        headerName: 'FirstName',
        field: 'firstName',
        width: '100px',
        autoHeight: true
    },
    {
        headerName: 'LastName',
        field: 'lastName',
        width: '100px',
        autoHeight: true
    },

    {
        headerName: 'Authorities',
        field: 'roles',
        cellRenderer: 'authoritiesCellRenderer',
        cellEditor: 'rolesCellEditor',
        autoHeight: true
    },

    {
        headerName: 'Scope',
        field: 'perimeterList',
        cellRenderer: 'buCellRenderer',
        autoHeight: true,
    },
    {
        headerName: 'Last Connection',
        field: 'lastConnected',
        autoHeight: true
    },
    {
        headerName: 'Enabled',
        field: 'enabled',
        resizable: false,
        autoHeight: true,
        cellRenderer: 'enableAccountCellRenderer',
        width: '60px'
    },
    {
        field: 'editing',
        hide: true
    }

];



export const DefaultColumnDef: any = {
    flex: 1,
    minWidth: 40,
    enableValue: true,
    suppressMenu: true,
    resizable: false
}


