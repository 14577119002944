import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useParams} from 'react-router-dom';
import {useRouteContext} from "../../../context/RouteContext/RouteContext";
import {FetchHeatMapData} from "../../../services/DetailService";
import {GetRegionTitleByValue, GetRegionValueByCode} from "../../../services/region/RegionService";
import UtilsService from "../../../services/utils/UtilsService";
import {Accordion} from "../accordion/Accordion";
import {ConnexionError} from "../ConnexionError";
import {Loader} from "../Loader";
import {NumberInput} from "../numberInput/NumberInput";
import {IRouteParams} from "../route/IRouteParams";
import {TreeSelect} from "../selectGroup/TreeSelect";
import {HeatmapModel, ResultModel} from "./HeatmapModels";
import HeatmapService from "./HeatmapService";
import {HeatmapSVG} from "./HeatmapSVG";
import {TitleService} from "../../../services/event/TitleService";


export const HeatMap: React.FC<any> = () => {
  const routeParams: IRouteParams = useParams<IRouteParams>();
  const [title,setTitle] = useState("");

  const date = routeParams.date;
  const year = UtilsService.getYearFromDate(date);
  const previousYear = parseInt(year) - 1;

  const periodOptions =
      [{value: HeatmapService.CONSTANT.HEATMAP.TYPE.DAILY, name: 'Daily'},
        {value: HeatmapService.CONSTANT.HEATMAP.TYPE.WEEKLY, name: 'Weekly'},
        {value: HeatmapService.CONSTANT.HEATMAP.TYPE.MONTHLY, name: 'Monthly'},
        {value: HeatmapService.CONSTANT.HEATMAP.TYPE.QUARTERLY, name: 'Quarterly'},
        {value: HeatmapService.CONSTANT.HEATMAP.TYPE.YEARLY, name: 'Yearly'}];

  const targetOptions = [...periodOptions];

  const methodOptions =
      [{value: HeatmapService.CONSTANT.HEATMAP.METHOD_VALUE, name: 'Var Me'},
        {value: HeatmapService.CONSTANT.HEATMAP.METHOD_PERCENT, name: 'Var %'},
        {value: HeatmapService.CONSTANT.HEATMAP.METHOD_EMPTY, name: 'NBI'}];

  const versionOptions =
      [{value: HeatmapService.CONSTANT.HEATMAP.LAST, name: 'Last'},
        {value: HeatmapService.CONSTANT.HEATMAP.OFFICIAL, name: 'Official'},
        {value: HeatmapService.CONSTANT.HEATMAP.PROFORMA, name: 'Proforma'}];

  const defaultComparisonOptions =
      [{value: HeatmapService.CONSTANT.HEATMAP.TARGET, name: 'B' + year},
        {value: HeatmapService.CONSTANT.HEATMAP.PREVIOUS_YEAR, name: 'AVG ' + previousYear},
        {value: HeatmapService.CONSTANT.HEATMAP.INTERVAL, name: 'Interval'}];

  const [isLoading, setIsLoading] = useState(false);
  const [isConnexionFailed, setIsConnexionFailed] = useState(false);
  const [heatmapData, setHeatmapData] = useState({} as HeatmapModel);
  const [result, setResult] = useState({} as ResultModel);
  const [periodDefaultValue, setPeriodDefaultValue] = useState(periodOptions[0].value);
  const [comparisonOptions, setComparisonOptions] = useState(defaultComparisonOptions);
  const [comparisonDefaultValue, setComparisonDefaultValue] = useState(comparisonOptions[0].value);
  const [targetDefaultValue, setTargetDefaultValue] = useState(targetOptions[0].value);
  const [methodDefaultValue, setMethodDefaultValue] = useState(methodOptions[0].value);
  const [versionDefaultValue, setVersionDefaultValue] = useState(HeatmapService.CONSTANT.HEATMAP.LAST);
  const [budget, setBudget] = useState(0);
  const [budgetFocus, setBudgetFocus] = useState(false);
  const [minCount, setMinCount] = useState(-3);
  const [minCountFocus, setMinCountFocus] = useState(false);
  const [maxCount, setMaxCount] = useState(3);
  const [maxCountFocus, setMaxCountFocus] = useState(false);
  const [minInterval, setMinInterval] = useState(0);
  const [minIntervalFocus, setMinIntervalFocus] = useState(false);
  const [maxInterval, setMaxInterval] = useState(0);
  const [maxIntervalFocus, setMaxIntervalFocus] = useState(false);
  const {detailPageScope} = useRouteContext();
  const {parentName} = useRouteContext();


  useEffect(() => {
    const reportingUnitId = parseInt(routeParams.scope);
    const subReportingUnitId = parseInt(routeParams.subScope as any);
    const dataNatureId = parseInt(routeParams.indicatorId as any);
    const regionId = GetRegionValueByCode(routeParams.region);
    setIsLoading(true);
    const subscription = FetchHeatMapData(date, reportingUnitId, subReportingUnitId, dataNatureId, versionDefaultValue, regionId)
        .subscribe(
            data => {
              if (data) {
                initDataForHeatmap(data.heatmapDayDataList, data.previousYearDayDataList, periodDefaultValue, comparisonDefaultValue);
                setHeatmapData(data);
              }
              setIsLoading(false);
            },
            () => {
              setIsLoading(false);
              setIsConnexionFailed(true);
            });
    return () => subscription.unsubscribe();
  }, [versionDefaultValue]);

  const getTitleFromOtherWidget = (title: string, regionId: number | undefined) => {
    const finalParentName =  detailPageScope===parentName? "":  parentName+" | ";
    //when detailPageScope is regionName, get title from other widget, remove the last 2 words to get ScopeName
    const scopeName =  title.toString().split(' ').slice(0, -1).join(' ');
    let finalTitle: string;
    if(GetRegionTitleByValue(regionId)===detailPageScope){
      finalTitle = scopeName + " " + detailPageScope;
    }else if(routeParams.region === "ww"){
      finalTitle = finalParentName + detailPageScope;
    }else{
      finalTitle = finalParentName + detailPageScope+ " " + GetRegionTitleByValue(regionId);
    }
    setTitle(finalTitle) ;
  }

  useEffect(()=>{
    const regionId = GetRegionValueByCode(routeParams.region);
    const subscription = TitleService.observable().subscribe(({title}) => {
      getTitleFromOtherWidget(title, regionId);
    });
    return () => subscription.unsubscribe();
  },[])

  const initDataForHeatmap = (data: any[], previousYearData: any[], period = periodOptions[0].value,
                              comparison = comparisonOptions[0].value, target = targetOptions[0].value, minIntervalValue: number = 0,
                              maxIntervalValue: number = 0, minCountValue: number = -3, maxCountValue: number = 3, budgetValue: number = 0) => {
    const res = {
      'total': 0,
      'aboveTarget': 0,
      'belowTarget': 0,
      'withinInterval': 0,
      'outsideInterval': 0,
      'minValue': Number.MAX_VALUE,
      'maxValue': Number.MIN_VALUE
    };

    if (budgetValue === 0) {
      budgetValue = HeatmapService.getBudget(data, date, target);
      setBudget(budgetValue);
    }

    HeatmapService.calculateColor(data, previousYearData, period, comparison, target, budgetValue,
        minIntervalValue, maxIntervalValue, minCountValue, maxCountValue, minCountValue, maxCountValue, res)
    setResult(res);

    if (comparison === HeatmapService.CONSTANT.HEATMAP.INTERVAL && minIntervalValue === 0 && maxIntervalValue === 0) {
      setMinInterval(res.minValue);
      setMaxInterval(res.maxValue);
    }
  };

  const handlePeriodChange = (period: string) => {
    resetNumberInputs();
    const options = [...defaultComparisonOptions];
    let newComparisonValue = comparisonDefaultValue;
    switch (period) {
      case HeatmapService.CONSTANT.HEATMAP.TYPE.DAILY:
        if (comparisonDefaultValue === HeatmapService.CONSTANT.HEATMAP.PREVIOUS_PERIOD) {
          newComparisonValue = options[0].value;
          setComparisonDefaultValue(newComparisonValue);
        }
        break;
      case HeatmapService.CONSTANT.HEATMAP.TYPE.YEARLY:
        options[1].name = '' + previousYear;
        if (comparisonDefaultValue === HeatmapService.CONSTANT.HEATMAP.PREVIOUS_PERIOD) {
          newComparisonValue = options[0].value;
          setComparisonDefaultValue(newComparisonValue);
        }
        break;
      case HeatmapService.CONSTANT.HEATMAP.TYPE.WEEKLY:
        options.splice(2, 0, { value: HeatmapService.CONSTANT.HEATMAP.PREVIOUS_PERIOD, name: year + ' (W-1)' });
        break;
      case HeatmapService.CONSTANT.HEATMAP.TYPE.MONTHLY:
        options[1].name = '' + previousYear;
        options.splice(2, 0, { value: HeatmapService.CONSTANT.HEATMAP.PREVIOUS_PERIOD, name: year + ' (M-1)' });
        break;
      case HeatmapService.CONSTANT.HEATMAP.TYPE.QUARTERLY:
        options[1].name = '' + previousYear;
        options.splice(2, 0, { value: HeatmapService.CONSTANT.HEATMAP.PREVIOUS_PERIOD, name: year + ' (Q-1)' });
        break;
    }
    setComparisonOptions(options);
    initDataForHeatmap(heatmapData.heatmapDayDataList, heatmapData.previousYearDayDataList, period, newComparisonValue, targetDefaultValue);
    setPeriodDefaultValue(period);
  };

  const handleComparisonChange = (comparison: string) => {
    resetNumberInputs();
    initDataForHeatmap(heatmapData.heatmapDayDataList, heatmapData.previousYearDayDataList,
        periodDefaultValue, comparison, targetDefaultValue);
    setComparisonDefaultValue(comparison);
  };

  const resetNumberInputs = () => {
    setMinInterval(0);
    setMaxInterval(0);
    setMinCount(-3);
    setMaxCount(3);
    setBudgetFocus(false);
    setMinCountFocus(false);
    setMaxCountFocus(false);
    setMinIntervalFocus(false);
    setMaxIntervalFocus(false);
  };

  const handleTargetChange = (target: string) => {
    initDataForHeatmap(heatmapData.heatmapDayDataList, heatmapData.previousYearDayDataList,
        periodDefaultValue, comparisonDefaultValue, target);
    setTargetDefaultValue(target);
  };

  const handleMethodChange = (method: string) => {
    setMethodDefaultValue(method);
  };

  const handleVersionChange = (version: string) => {
    setVersionDefaultValue(version);
  };

  const handleBudgetChange = (budgetValue: any, autoFocus: boolean = false) => {
    initDataForHeatmap(heatmapData.heatmapDayDataList, heatmapData.previousYearDayDataList, periodDefaultValue,
        comparisonDefaultValue, targetDefaultValue, undefined, undefined, undefined, undefined, budgetValue);
    setBudget(budgetValue);
    setBudgetFocus(autoFocus);
  };

  const handleMinCountChange = (newMinCount: any, autoFocus: boolean = false) => {
    initDataForHeatmap(heatmapData.heatmapDayDataList, heatmapData.previousYearDayDataList, periodDefaultValue,
        comparisonDefaultValue, targetDefaultValue, undefined, undefined, newMinCount, maxCount);
    setMinCountFocus(autoFocus);
    setMaxCountFocus(false);
    setMinCount(newMinCount);

  };

  const handleMaxCountChange = (newMaxCount: any, autoFocus: boolean = false) => {
    initDataForHeatmap(heatmapData.heatmapDayDataList, heatmapData.previousYearDayDataList, periodDefaultValue,
        comparisonDefaultValue, targetDefaultValue, undefined, undefined, minCount, newMaxCount);
    setMaxCountFocus(autoFocus);
    setMinCountFocus(false);
    setMaxCount(newMaxCount);
  };

  const handleMinIntervalChange = (newMinInterval: any, autoFocus: boolean = false) => {
    initDataForHeatmap(heatmapData.heatmapDayDataList, heatmapData.previousYearDayDataList, periodDefaultValue,
        comparisonDefaultValue, targetDefaultValue, newMinInterval, maxInterval);
    setMinInterval(newMinInterval);
    setMinIntervalFocus(autoFocus);
    setMaxIntervalFocus(false);
  };

  const handleMaxIntervalChange = (newMaxInterval: any, autoFocus: boolean = false) => {
    initDataForHeatmap(heatmapData.heatmapDayDataList, heatmapData.previousYearDayDataList, periodDefaultValue,
        comparisonDefaultValue, targetDefaultValue, minInterval, newMaxInterval);
    setMaxInterval(newMaxInterval);
    setMaxIntervalFocus(autoFocus);
    setMinIntervalFocus( false);
  };

  const Display = () => {
    if (isLoading) {
      return <Loader/>;
    } else if (isConnexionFailed) {
      return <ConnexionError/>;
    } else {
      return (
          <div>
            <div className="row">
              <div className="filter col-sm-12 col-md-6 col-lg-1 d-flex flex-column flex-lg-row
                                    align-items-lg-center align-items-md-start mb-3">
                <label className="d-inline-block text-secondary fw-bolder mb-sm-1 mb-lg-0 me-2">
                  <FormattedMessage id="heatmap.period"/>
                </label>
                <TreeSelect optionList={periodOptions} defaultValue={periodDefaultValue}
                            inputId={'heatmap-period'}
                            onOptionChange={handlePeriodChange}
                            hideIcon={true}/>
              </div>
              <div className="filter-long col-sm-12 col-md-6 col-lg-1 d-flex flex-column flex-lg-row
                                    align-items-lg-center align-items-md-start mb-3">
                <label className="d-inline-block text-secondary font-weight-bold mb-sm-1 mb-lg-0 me-2">
                  <FormattedMessage id="heatmap.comparison"/>
                </label>
                <TreeSelect optionList={comparisonOptions} defaultValue={comparisonDefaultValue}
                            inputId={'heatmap-comparison'}
                            onOptionChange={handleComparisonChange}
                            hideIcon={true}/>
              </div>
              {comparisonDefaultValue === HeatmapService.CONSTANT.HEATMAP.PREVIOUS_YEAR
                  && (periodDefaultValue === HeatmapService.CONSTANT.HEATMAP.TYPE.DAILY
                      || periodDefaultValue === HeatmapService.CONSTANT.HEATMAP.TYPE.WEEKLY)
                  &&
                  <div className="filter-short col-sm-8 col-md-4 col-lg-1 d-flex flex-column flex-lg-row
                                    align-items-lg-center align-items-md-start mb-3">
                    <label className="d-inline-block text-secondary fw-bolder mb-sm-1 mb-lg-0 me-2">
                      <FormattedMessage id="heatmap.min"/>
                    </label>
                    <NumberInput value={minCount} onNumberChange={handleMinCountChange} autoFocus={minCountFocus}/>
                  </div>
              }
              {comparisonDefaultValue === HeatmapService.CONSTANT.HEATMAP.PREVIOUS_YEAR
                  && (periodDefaultValue === HeatmapService.CONSTANT.HEATMAP.TYPE.DAILY
                      || periodDefaultValue === HeatmapService.CONSTANT.HEATMAP.TYPE.WEEKLY)
                  &&
                  <div className="filter-short col-sm-8 col-md-4 col-lg-1 d-flex flex-column flex-lg-row
                                    align-items-lg-center align-items-md-start mb-3">
                    <label className="d-inline-block text-secondary fw-bolder mb-sm-1 mb-lg-0 me-2">
                      <FormattedMessage id="heatmap.max"/>
                    </label>
                    <NumberInput value={maxCount} onNumberChange={handleMaxCountChange} autoFocus={maxCountFocus}/>
                  </div>
              }
              {comparisonDefaultValue === HeatmapService.CONSTANT.HEATMAP.INTERVAL
                  &&
                  <div className="filter-short col-sm-8 col-md-4 col-lg-1 d-flex flex-column flex-lg-row
                                    align-items-lg-center align-items-md-start mb-3">
                    <label className="d-inline-block text-secondary fw-bolder mb-sm-1 mb-lg-0 me-2">
                      <FormattedMessage id="heatmap.min"/>
                    </label>
                    <NumberInput value={minInterval} onNumberChange={handleMinIntervalChange}
                                 autoFocus={minIntervalFocus}/>
                  </div>
              }
              {comparisonDefaultValue === HeatmapService.CONSTANT.HEATMAP.INTERVAL
                  &&
                  <div className="filter-short col-sm-8 col-md-4 col-lg-1 d-flex flex-column flex-lg-row
                                    align-items-lg-center align-items-md-start mb-3">
                    <label className="d-inline-block text-secondary fw-bolder mb-sm-1 mb-lg-0 me-2">
                      <FormattedMessage id="heatmap.max"/>
                    </label>
                    <NumberInput value={maxInterval} onNumberChange={handleMaxIntervalChange}
                                 autoFocus={maxIntervalFocus}/>
                  </div>
              }
              {comparisonDefaultValue === HeatmapService.CONSTANT.HEATMAP.TARGET
                  &&
                  <div className="filter col-sm-12 col-md-6 col-lg-1 d-flex flex-column flex-lg-row
                                    align-items-lg-center align-items-md-start mb-3 pr-0">
                    <label className="d-inline-block text-secondary fw-bolder mb-sm-1 mb-lg-0 me-2">
                      <FormattedMessage id="heatmap.target"/>
                    </label>
                    <TreeSelect optionList={targetOptions} defaultValue={targetDefaultValue}
                                onOptionChange={handleTargetChange}
                                hideIcon={true}/>
                  </div>
              }
              {comparisonDefaultValue === HeatmapService.CONSTANT.HEATMAP.TARGET
                  &&
                  <div className="filter-short col-sm-8 col-md-4 col-lg-1 d-flex flex-column flex-lg-row
                                    align-items-lg-center align-items-md-start mb-3 pl-0 ms-2">
                    <NumberInput value={budget} onNumberChange={handleBudgetChange} autoFocus={budgetFocus}/>
                  </div>
              }
              {comparisonDefaultValue !== HeatmapService.CONSTANT.HEATMAP.INTERVAL
                  &&
                  <div className="filter col-sm-12 col-md-6 col-lg-1 d-flex flex-column flex-lg-row
                                    align-items-lg-center align-items-md-start mb-3">
                    <label className="d-inline-block text-secondary fw-bolder mb-sm-1 mb-lg-0 me-2">
                      <FormattedMessage id="heatmap.method"/>
                    </label>
                    <TreeSelect inputId={'heatmap-method'} optionList={methodOptions} defaultValue={methodDefaultValue}
                                onOptionChange={handleMethodChange}
                                hideIcon={true}/>
                  </div>
              }
              <div className="filter col-sm-12 col-md-6 col-lg-1 d-flex flex-column flex-lg-row
                                    align-items-lg-center align-items-md-start mb-3">
                <label className="d-inline-block text-secondary fw-bolder mb-sm-1 mb-lg-0 me-2">
                  <FormattedMessage id="heatmap.version"/>
                </label>
                <TreeSelect optionList={versionOptions} defaultValue={versionDefaultValue}
                            onOptionChange={handleVersionChange}
                            hideIcon={true}/>
              </div>
            </div>
            <HeatmapSVG params={routeParams} title={title} date={date} data={heatmapData} result={result}
                        periodDefaultValue={periodDefaultValue} comparisonDefaultValue={comparisonDefaultValue}
                        targetDefaultValue={targetDefaultValue} methodDefaultValue={methodDefaultValue}
                        budget={budget}
                        min={minCount} max={maxCount}/>
          </div>
      );
    }
  };

  return (
      <Accordion title={<FormattedMessage id="heatmap.title" values={{title: `${title}`}}/>}>
        <div className="container-fluid heatmap-svg">
          <Display/>
        </div>
      </Accordion>
  );
};
