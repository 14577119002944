import HttpOptions from './HttpOptions';
import {getEnvVar} from '../../env-vars-utils';

export class LocalStorageSaveOptions {
    key = '';
    data: any = '';
    expirationMins?: number;
}

const defaultCacheDuration = getEnvVar('react_app_api_http_call_cache_duration');

const remove = (key: string) => {
    localStorage.removeItem(key)
};

export const load = (key: string) => {
    const item = localStorage.getItem(key);
    if (item) {
        const record = JSON.parse(item);
        const now = new Date().getTime();
        // Expired data will return null
        if (!record || (record.hasExpiration && record.expiration <= now)) {
            remove(key);
            return null;
        } else {
            if (Object.keys(record.value).length === 0)
                return null;
            else
                return JSON.parse(record.value);
        }
    }
    return null;
};


const save = (options: LocalStorageSaveOptions) => {
    try {
        options.expirationMins = options.expirationMins || 0;

        // set expiration date in milliseconds
        const expirationMS = options.expirationMins !== 0 ? options.expirationMins * 60 * 1000 : 0;
        const record = {
            value: typeof options.data === 'string' ? options.data : JSON.stringify(options.data),
            expiration: expirationMS !== 0 ? new Date().getTime() + expirationMS : null,
            hasExpiration: expirationMS !== 0 ? true : false
        };
        localStorage.setItem(options.key, JSON.stringify(record));
    }
    catch (e) {}
};


export const fetchWithCache = async (url: string, options: HttpOptions) => {
    // Setup default values
    options.cacheMins = options.cacheMins || parseInt(defaultCacheDuration as string, 10);

    if (options.cacheMins > 0) {
        // Get data from cache
        const cacheObject = load(options.key);
        // Return data from cache
        if (cacheObject !== null) {
            return cacheObject;
        }
    }

    const response = await fetch(url , {
        method : options.verb ,
        headers : options.headers
    });
    if (!response.ok) {
        return Promise.reject('An error occurred when fetching with cache. Http status code ' + response.status);
    }
    const data =  await response.json();

    save({
        key: options.key,
        data,
        expirationMins: options.cacheMins
    });

    return Promise.resolve(data);
};