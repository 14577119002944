import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Form, FormGroup, Input, Label} from "reactstrap";
import {PERIOD_TYPE} from "../Constants";
import {useRouteContext} from "../../../context/RouteContext/RouteContext";
import {IRouteParams} from "../route/IRouteParams";
import {useParams} from "react-router-dom";
import {YtdEvolutionChart} from "./YtdEvolutionChart";
import {GetRegionValueByCode} from "../../../services/region/RegionService";
import {FetchNbiYtdData} from "../../../services/SynthesisService";
import {Loader} from "../Loader";
import UtilsService from "../../../services/utils/UtilsService";
import {last} from 'lodash';

/**
 * TODO
 * Need to improve this function and pass dynamic data to chart
 * Currently data is hard constructed for top 3 scopes + others.
 * Ideally it can be done with a list of object with scope name and data then chart component takes this list to
 * render graphic.
 */
interface INBIYtdEvolutionProps {
    params: IRouteParams;
    data : any
}
export const NBIYtdEvolution: React.FC<INBIYtdEvolutionProps> = ({params,data}) => {
    const routeParams: IRouteParams = useParams<IRouteParams>();
    const intl = useIntl();
    const [period, changePeriod] = useState(PERIOD_TYPE.WTD);
    const [ytdEvolutionData, setYtdEvolutionData] = useState(data);
    const [isLoading, setIsLoading] = useState(true);
    const [finalXAxeValues, setFinalXAxeValues] = useState(['']);
    const [dataSbu1, setDataSbu1] = useState([]);
    const [dataSbu2, setDataSbu2] = useState([]);
    const [dataSbu3, setDataSbu3] = useState([]);
    const [dataSbu4, setDataSbu4]= useState( []);
    const [nbiYtdData, setNbiYtdData]= useState( []);
    const [nbiYtdDataForPreviousYear, setNbiYtdDataForPreviousYear]= useState( []);
    const [budgetData, setBudgetData]= useState( []);
    const [sbuName1, setSbuName1] = useState("");
    const [sbuName2, setSbuName2] = useState("");
    const [sbuName3, setSbuName3] = useState("");
    const [sbuName4, setSbuName4] = useState("");
    const {isProformaAvailable} = useRouteContext();

    const periodOptions: Array<any> = [
        {
            value: PERIOD_TYPE.WTD,
            label: intl.formatMessage({id: 'mini-heatmap.wtd'})
        },
        {
            value: PERIOD_TYPE.MTD,
            label: intl.formatMessage({id: 'mini-heatmap.mtd'})
        },
        {
            value: PERIOD_TYPE.QTD,
            label: intl.formatMessage({id: 'mini-heatmap.qtd'})
        }
    ];

    const handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const targetPeriod = e.target.value;
        changePeriod(targetPeriod);
    };

    const init = () => {
        setSbuName1('');
        setSbuName2('');
        setSbuName3('');
        setSbuName4('');
        setFinalXAxeValues(['']);
        setDataSbu1([]);
        setDataSbu2([]);
        setDataSbu3([]);
        setDataSbu4([]);
        setNbiYtdData([]);
        setBudgetData([]);
        setNbiYtdDataForPreviousYear([]);
    };

    const constructData = (data: any) => {
        init();
        if (!data || !data.length) {
            return;
        }

        const ytdData: any = [];
        const budgetData: any = [];
        const previousYearValue: any = [];
        const xAxisTitle: any = [];
        const firstLevelData: any = [];
        const secondLevelData: any = [];
        const thirdLevelData: any = [];
        const othersData: any = [];

        const lastDateData: any = last(data);

        const subUnitListSizeOfLastDate: number = lastDateData?.simpleFpvReportData?.length ?? 0;

        const firstLevelScopeName =  lastDateData?.simpleFpvReportData[subUnitListSizeOfLastDate - 1]?.unitName ?? "";
        const secondLevelScopeName =  lastDateData?.simpleFpvReportData[subUnitListSizeOfLastDate - 2]?.unitName ?? "";
        const thirdLevelScopeName =  lastDateData?.simpleFpvReportData[subUnitListSizeOfLastDate - 3]?.unitName ?? "";

        setSbuName1(firstLevelScopeName);
        setSbuName2(secondLevelScopeName);
        setSbuName3(thirdLevelScopeName);

        let isOtherScopeExist: boolean = false;
        data.forEach((d: any) => {
            ytdData.push(UtilsService.formatNumberWithOneDigit(d.ytd.value));
            budgetData.push(UtilsService.formatNumberWithOneDigit(d.cummulBudget));
            previousYearValue.push(UtilsService.formatNumberWithOneDigit(d.ytd.value - d.ytd.valueBasedYearOnYearVariation));

            let firstLevelValue: number | undefined = undefined;
            let secondLevelValue: number | undefined = undefined;
            let thirdLevelValue: number | undefined = undefined;
            let othersValue: number | undefined = undefined;
            d.simpleFpvReportData?.forEach((reportData: any) => {
                if (reportData?.unitName === firstLevelScopeName) {
                    firstLevelValue = reportData.ytd;
                }
                else if (reportData?.unitName === secondLevelScopeName) {
                    secondLevelValue = reportData.ytd;
                }
                else if (reportData?.unitName === thirdLevelScopeName) {
                    thirdLevelValue = reportData.ytd;
                }
                else {
                    othersValue = othersValue ?? 0;
                    othersValue += reportData.ytd;
                    isOtherScopeExist = true;
                }
            });
            firstLevelData.push(getFormattedNumber(firstLevelValue));
            secondLevelData.push(getFormattedNumber(secondLevelValue));
            thirdLevelData.push(getFormattedNumber(thirdLevelValue));
            othersData.push(getFormattedNumber(othersValue));
            xAxisTitle.push(d.wtdPeriod);
        });

        isOtherScopeExist && setSbuName4("OTHERS");
        setFinalXAxeValues(xAxisTitle);
        setDataSbu1(firstLevelData);
        setDataSbu2(secondLevelData);
        setDataSbu3(thirdLevelData);
        setDataSbu4(othersData);
        setNbiYtdData(ytdData);
        setBudgetData(budgetData);
        setNbiYtdDataForPreviousYear(previousYearValue);
    };

    const getFormattedNumber = (value: number | undefined) => {
        return value !== undefined ? UtilsService.formatNumberWithOneDigit(value) : null
    };


    useEffect(() => {
            const date = params.date;
            const reportingUnitId = params.scope;
            const subReportingUnitId = params.subScope ? params.subScope : reportingUnitId;
            const regionCode: string | undefined = params.region;
            const regionValue = GetRegionValueByCode(regionCode);
            const importType: string = isProformaAvailable ? "STABILIZED" : "PUBLISHED";
            setIsLoading(true);
            const subscription = FetchNbiYtdData(date, parseInt(reportingUnitId), regionValue, importType, period, parseInt(subReportingUnitId))
                .subscribe(data => {
                        if (data) {
                            setYtdEvolutionData(data);
                        }
                        setIsLoading(false);
                    },
                    () => {
                        setIsLoading(false);
                    });
            return () => subscription.unsubscribe();
    }, [params, period, isProformaAvailable]);

    useEffect(() => {
        setIsLoading(true);
        if (ytdEvolutionData.length > 0) {
            constructData(ytdEvolutionData)
            setIsLoading(false);
        }
    }, [ytdEvolutionData]);

    return (
        <div>
            <Form className="d-flex mb-lg-4 justify-content-end">
                <FormGroup className="d-flex align-items-center mb-0">
                    <Label for="periodOptions" className="m-auto text-secondary fw-bolder me-2">
                        <FormattedMessage id="period"/>
                    </Label>
                    <Col>
                        <Input type="select" className="form-select" name="select" id="periodOptions" value={period}
                               onChange={e => handlePeriodChange(e)}>
                            {periodOptions.map(e => <option key={e.value} value={e.value}>{e.label}</option>)}
                        </Input>
                    </Col>
                </FormGroup>
            </Form>
            {(()=>{
                return (
                    isLoading ? <Loader /> :
                      <div>
                          <div className="container-fluid ">
                              <div className="">
                                  <YtdEvolutionChart
                                    period={period}
                                    params={routeParams}
                                    data={ytdEvolutionData}
                                    axeXYear={finalXAxeValues}
                                    dataSbu1={dataSbu1}
                                    dataSbu2={dataSbu2}
                                    dataSbu3={dataSbu3}
                                    dataSbu4={dataSbu4}
                                    nbiYtdData={nbiYtdData}
                                    budgetData={budgetData}
                                    nbiYtdDataForPreviousYear={nbiYtdDataForPreviousYear}
                                    sbuName1={sbuName1}
                                    sbuName2={sbuName2}
                                    sbuName3={sbuName3}
                                    sbuName4={sbuName4}
                                  />
                              </div>
                          </div>
                      </div>
                    );
            })()}
        </div>
    );
};
