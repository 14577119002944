import React from 'react';
import { FormattedMessage } from 'react-intl';

interface IValidationErrorProps {
    errors: string[];
}


export const FormValidationErrors: React.FC<IValidationErrorProps> = (props) => {

    if (props.errors.length) {
        return (
            < div className="alert alert-outline-danger" role="alert" >

                {props.errors.map(e => { return <div><FormattedMessage id = {e}  /><br/></div> })}

            </div >
        );
    }
    else
        return (<div></div>);
}