import React, {useEffect, useState} from 'react';
import {RouteComponentProps, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {DataStatus} from '../../../models/DataStatusModel';
import {
    confirmAllDataStatus,
    confirmDataStatus,
    unvalidateDataStatus
} from '../../../services/admin/DataStatusServices';
import {getDataStatus} from "../../../services/CommonService";
import {DataNotConfirmed} from "../../common/DataNotConfirmed";
import {SynthesisFilter} from "../../common/filter/SynthesisFilter";
import {Loader} from "../../common/Loader";
import {NBIReportingGroup} from "../../common/reportingGroup/NBIReportingGroup";
import {IRouteParams} from "../../common/route/IRouteParams";
import {DataAvailabilityService} from "../../../services/event/DataAvailabilityService";
import {News} from "../../common/news/News";
import {useUser} from "../../../context/UserContext/UserContext";
import './Synthesis.scss';
import {PnlAndRiskWidget} from "../../common/nbiRiskOverview/RiskAndPnlNbiWidget";
import {Charts} from "../../common/Charts/Charts";

export const Synthesis: React.FC<RouteComponentProps> = () => {
  const routeParams: IRouteParams = useParams<IRouteParams>();
  const date = routeParams.date;
  const reportingUnitId = parseInt(routeParams.scope);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  const {isAdmin} = useUser();

  useEffect(() => {
    const subscription = DataAvailabilityService.observable().subscribe((isAvailable: boolean) => {
      setIsDataAvailable(isAvailable);
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const subscription = getDataStatus(date, reportingUnitId).subscribe(
        status => {
            setIsConfirmed(status.SYNTHESIS === DataStatus.CONFIRMED);
            setIsLoading(false);
        },
        () => {
            setIsConfirmed(false);
            setIsLoading(false);
        }
    );
      return () => subscription.unsubscribe();
  }, [routeParams.date, routeParams.scope]);

    const handleUnvalidate = () => {
        unvalidateDataStatus(routeParams.date, parseInt(routeParams.scope)).then(
            status => {
                setIsConfirmed(status.SYNTHESIS === DataStatus.CONFIRMED);
                toast.success('Data is all set to not validated for current day!');
            }
        ).catch(
            () => {
                toast.error('Fail to set all data to not validated for current day!');
            }
        )
    };

    const handleConfirm = () => {
        confirmDataStatus(routeParams.date, parseInt(routeParams.scope)).then(
            status => {
                setIsConfirmed(status.SYNTHESIS === DataStatus.CONFIRMED);
                toast.success('Data is all set to confirmed for current day!');
            }
        ).catch(
            () => {
                toast.error('Fail to set all data to confirmed for current day!');
            }
        )
    };

    const handleConfirmAll = () => {
        confirmAllDataStatus(routeParams.date, parseInt(routeParams.scope)).then(
            status => {
                setIsConfirmed(status.SYNTHESIS === DataStatus.CONFIRMED);
                toast.success('Data is all set to confirmed in Emars!');
            }
        ).catch(
            () => {
                toast.error('Fail to set all data to confirmed in Emars!');
            }
        )
    };

    return (
        <div className="synthesis">
            <SynthesisFilter params={routeParams} isLoading={isLoading} isAdmin={isAdmin} isConfirmed={isConfirmed}
                             confirm={handleConfirm} unvalidate={handleUnvalidate} confirmAll={handleConfirmAll}/>
            <div className="main-content ps-0 pe-0 ps-md-4 pe-md-4">
                {(
                    () => {
                        if (isLoading || isAdmin === undefined) {
                            return <div className="py-4 px-2"><Loader/></div>;
                        } else if (isDataAvailable && (isAdmin || isConfirmed)) {
                            return (
                                <>
                                    {<PnlAndRiskWidget params={routeParams}/>}

                                    {/*Reporting Module */}
                                    <NBIReportingGroup params={routeParams}/>

                                    {/*NBI news*/}
                                    <News params={routeParams}></News>

                                    {/*NBI comparison && NBI YTD evolution && Risk in time*/}
                                    <Charts params={routeParams}/>


                                </>
                            );
                        } else {
                            return <DataNotConfirmed/>;
                        }
                    }
                )()}
            </div>
        </div>
  );
};
