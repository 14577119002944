import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useParams} from 'react-router-dom';
import {useRouteContext} from "../../../context/RouteContext/RouteContext";
import {Accordion} from "../accordion/Accordion";
import {IRouteParams} from "../route/IRouteParams";
import {PerformanceTrendChart} from "./PerformanceTrendChart";
import {GetRegionTitleByValue, GetRegionValueByCode} from "../../../services/region/RegionService";
import {TitleService} from "../../../services/event/TitleService";

export const PerformanceTrend: React.FC<any> = () => {
    const routeParams: IRouteParams = useParams<IRouteParams>();
    const {detailPageScope} = useRouteContext();
    const {parentName} = useRouteContext();
    const [title, setTitle] = useState("");

    const getTitleFromOtherWidget = (title: String, regionId: number | undefined) => {
        let finalParentName =  detailPageScope===parentName? "":  parentName+" | ";
        //when detailPageScope is regionName, get title from other widget, remove the last 2 words to get ScopeName
        const scopeName =  title.toString().split(' ').slice(0, -1).join(' ');
        let finalTitle = "";
        if(GetRegionTitleByValue(regionId)===detailPageScope){
            finalTitle = scopeName + " " + detailPageScope;
        }else if(routeParams.region === "ww"){
            finalTitle = finalParentName + detailPageScope;
        }else{
            finalTitle = finalParentName + detailPageScope+ " " + GetRegionTitleByValue(regionId);
        }
        setTitle(finalTitle) ;
    }
    useEffect(() => {
        const regionId = GetRegionValueByCode(routeParams.region);
        const subscription = TitleService.observable().subscribe(({title}) => {
            getTitleFromOtherWidget(title, regionId);
        });
        return () => subscription.unsubscribe();
    }, [])

    return (
        <Accordion title={<FormattedMessage id="perfomance.trend.title" values={{title: `${title}`}}/>}>
            <div className="container-fluid">
                <PerformanceTrendChart params={routeParams}/>
            </div>
        </Accordion>
    );
};