import {ajaxGetJSON} from "rxjs/internal/observable/dom/AjaxObservable";
import {catchError, map} from "rxjs/operators";
import {handleError} from '../utils/UtilsService';
import {getEnvVar} from "../../env-vars-utils";
import {getAuthorizationHeader} from "../../helpers";

const API_URL = getEnvVar('react_app_api_endpoint');
const getHttpHeader = () => ({
    Authorization: getAuthorizationHeader(),
    'Content-Type': 'application/json'
});

export const ClearCache = () => {
    const endpoint = API_URL + '/admin/clearcache';

    return ajaxGetJSON(endpoint, getHttpHeader())
        .pipe(
            map(response => response),
            catchError(error => handleError(error))
        );
};

export const RefreshAsh = () => {
   const endpoint = API_URL + '/admin/refreshstructure';

  return ajaxGetJSON(endpoint, getHttpHeader())
      .pipe(
          map(response => response),
          catchError(error => handleError(error))
      );
};