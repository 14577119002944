export enum RequestStatus {
    INITIALIZED,
    TO_CONFIRM,
    IN_PROGRESS,
    IS_FINISHED_LOAD,
    IS_SUCCESS,
    IS_FAILURE
}

export enum Target {
    FPV = 'FPV',
    COMFI = 'COMFI',
    REGIONS = 'REGIONS',

}