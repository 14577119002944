import {IRouteParams} from "../route/IRouteParams";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, {useEffect, useState} from "react";
import {Loader} from "../Loader";
import {FormattedMessage} from 'react-intl';


require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data.js')(Highcharts);
require('highcharts/highcharts-more.js')(Highcharts);

interface INBIPerfProps {
    data: any,
    params: IRouteParams,
}
export const NBIPerformance: React.FC<INBIPerfProps> =({data,params})=>{

    const [series, setSeries] = useState([] as any);
    const seriesList : any[] = [];
    const [isLoading,setIsLoading] = useState(true);
    const [isDataAvailable, setIsDataAvailable] = useState(false);

    const constructData = (data:any) =>{
        data.forEach((d: any)=>{
            let unit: any[] = [];
            unit.push(d);
            seriesList.push({
                data: unit,
                name: d.name,
                minSize:30,
                maxSize:150,
                sizeByAbsoluteValue: true,
            })
        });
        setSeries(seriesList);
    }

    useEffect(()=>{
        if(data.length>0){
            setIsDataAvailable(true);
            constructData(data);
            setIsLoading(false);
        }else{
            setIsLoading(false);
            setIsDataAvailable(false);
        }

    },[data,params])

    const options ={
        chart: {
            type: 'bubble',
            plotBorderWidth: 1,
            plotBorderColor :'#949c9e',
            zoomType: 'xy',
            backgroundColor: 'rgba(0,0,0,0)',
            height:'800px',
        },
        credits: {
            enabled: false
        },

        legend: {
            enabled: true,
            verticalAlign:'top',
            itemStyle:{
                color:'#FFFFFF',
                fontWeight: 'bold'
            },
            itemHoverStyle:{
                color:'#FF0000'
            }
        },

        colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4','#E74C3C'],

        xAxis: {
            gridLineWidth: 0,
            title: {
                text: 'NBI Variation vs Budget',
                style:{
                    color: '#FFFFFF',
                    fontSize: 18
                },
            },
            labels: {
                format: '{value} %',
                style:{
                    color: '#949c9e',
                    fontSize: 15,
                    fontWeight: 600,
                    fontFamily: 'Inter'
                },
            },
            plotLines: [
                {
                    color: '#949c9e',
                    dashStyle: 'Solid',
                    width: 2,
                    value: 0,
                    zIndex: 3
                }],
            max: 150,
            min: -150,
            tickInterval: 25,
            tickLength: 10,
            tickWidth: 1,
        },

        yAxis: {
            tickLength: 10,
            tickWidth: 1,
            gridLineWidth: 0,
            title: {
                text: 'NBI Variation vs Y-1',
                style:{
                    color: '#FFFFFF',
                    fontSize: 18,
                },
            },
            labels: {
                format: '{value} %',
                style:{
                    color: '#949c9e',
                    fontSize: 15,
                    fontWeight: 600,
                    fontFamily: 'Inter'
                },
            },
            plotLines: [
                {
                    color: '#949c9e',
                    dashStyle: 'Solid',
                    width: 2,
                    value: 0,
                    zIndex: 3
                },
                {
                    value: 140,
                    width:0,
                    label:{
                        text:'ABOVE NBI Y-1',
                        align: 'right',
                        x: 0,
                        style:{
                            color:'#68EFAD',
                            fontSize: 15,
                            fontWeight: 'bold',

                        },
                    },
                },
                {
                    value: 130,
                    width:0,
                    label:{
                        text:'ABOVE BUDGET',
                        align: 'right',
                        x: 0,
                        style:{
                            color:'#68EFAD',
                            fontSize: 15,
                            fontWeight: 'bold',

                        },
                    },
                },
                {
                    value: 140,
                    width:0,
                    label:{
                        text:'ABOVE NBI Y-1',
                        align: 'left',
                        x: 0,
                        style:{
                            color:'#529aff',
                            fontSize: 15,
                            fontWeight: 'bold',

                        },
                    },
                },
                {
                    value: 130,
                    width:0,
                    label:{
                        text:'BELOW BUDGET',
                        align: 'left',
                        x: 0,
                        style:{
                            color:'#529aff',
                            fontSize: 15,
                            fontWeight: 'bold',

                        },
                    },
                },
                {
                    value: -150,
                    width:0,
                    label:{
                        text:'BELOW NBI Y-1',
                        align: 'right',
                        x: 0,
                        style:{
                            color:'#529aff',
                            fontSize: 15,
                            fontWeight: 'bold',

                        },
                    },
                },
                {
                    value: -140,
                    width:0,
                    label:{
                        text:'ABOVE BUDGET',
                        align: 'right',
                        x: 0,
                        style:{
                            color:'#529aff',
                            fontSize: 15,
                            fontWeight: 'bold',

                        },
                    },
                },
                {
                    value: -150,
                    width:0,
                    label:{
                        text:'BELOW NBI Y-1',
                        align: 'LEFT',
                        x: 0,
                        style:{
                            color:'#D53229',
                            fontSize: 15,
                            fontWeight: 'bold',

                        },
                    },
                },
                {
                    value: -140,
                    width:0,
                    label:{
                        text:'BELOW BUDGET',
                        align: 'LEFT',
                        x: 0,
                        style:{
                            color:'#D53229',
                            fontSize: 15,
                            fontWeight: 'bold',

                        },
                    },
                },


            ],
            max: 150,
            min: -150,
            tickInterval: 25,
        },


        tooltip: {
            useHTML: true,
            headerFormat: '<table style="width:250px">',
            pointFormat:
                '<table style="width:100%">'+
                '<tr><th colspan="2">{point.name}</th colspan="2"></tr>'+
                '<tr><td>% var vs Budget:</td><td>{point.x} %</td></tr>' +
                '<tr><td>% var vs Y-1:</td><td>{point.y} %</td></tr>' +
                '<tr><td>MTD PNL:</td><td>{point.z} M€</td></tr>',
            footerFormat: '</table>',
            followPointer: true,
            borderRadius: 10,
            borderWidth: 5,
        },

        plotOptions: {
            series: {
                stickyTracking: false,
                dataLabels: {
                    enabled: true,
                    color:'white',
                    format: '{point.name}'+ ': '+'{point.z}'+'M€'
                },
                events: {
                    legendItemClick: function() { return false; }
                }
            }
        },

        series: series,

        exporting: {
            enabled: true,
            align: 'right',
            y: -10,
            buttons: {
                contextButton: {
                    menuItems: [
                        "viewFullscreen"],
                    symbolStroke: '#dddddd',
                    symbol: 'menu',
                    theme: {
                        fill: '#505053'
                    },
                },
            }
        },
    }

    return(
        isLoading? <Loader/> :
            isDataAvailable? <HighchartsReact highcharts={Highcharts} options={options}/>
                : <div className="d-flex align-items-center justify-content-center">
                    <FormattedMessage id='home.charts.performance'></FormattedMessage>
                </div>
    )

}