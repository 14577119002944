const widgetSGWTConnect = document.querySelector('sgwt-connect') as any;
if (widgetSGWTConnect.sgwtConnect) {
    // The function is available, we can call it.
    console.log("widgetSGWTConnect.sgwtConnect is available...")
} else {
    // The function is not available, we wait for the widget to be ready.
    console.log("widgetSGWTConnect.sgwtConnect is not available...")
    widgetSGWTConnect.addEventListener('sgwt-connect--ready', widgetSGWTConnect.sgwtConnect);
}
widgetSGWTConnect.removeEventListener('sgwt-connect--ready', widgetSGWTConnect.sgwtConnect);

export const sgwtConnect = widgetSGWTConnect.sgwtConnect;