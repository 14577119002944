import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {IRouteParams} from '../route/IRouteParams';
import {Accordion} from '../accordion/Accordion';
import {TitleService} from "../../../services/event/TitleService";
import {ChartsWidgetType} from "../ChartsWidgetType";
import {NBIComparison} from "../nbiComparison/NBIComparison";
import {NBIYtdEvolution} from "../ytdEvolotion/NbiYtdEvolution";
import {NBIPerformance} from "../nbiPerformance/NBIPerformance";
import {GetRegionValueByCode} from "../../../services/region/RegionService";
import {FetchMiniHeatMapData, FetchNbiYtdData, FetchPerformanceData} from "../../../services/SynthesisService";
import {useRouteContext} from "../../../context/RouteContext/RouteContext";
import {PERIOD_TYPE} from "../Constants";
import {NBIRisk} from "../nbiRiskChart/NBIRisk";
import {GetRiskGraphData} from "../../../services/RiskService";
import {useViewport} from "../../../context/ViewportContext";
import UtilsService from "../../../services/utils/UtilsService";
import {MuiThemeProvider} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {MuiTooltipTheme} from '../MuiTooltipTheme';
import {ConnexionError} from "../ConnexionError";

interface ICompareAndEvolWidgetProps {
  params: IRouteParams;
}

export const Charts: React.FC<ICompareAndEvolWidgetProps> = ({ params }) => {
  const [showCompareWidget, setShowCompareWidget] = useState(true);
  const [showEvolWidget, setShowEvolWidget] = useState(false);
  const [showRiskWidget, setShowRiskWidget] = useState(false);
  const [showPerformanceWidget, setShowPerformanceWidget] = useState(false);
  const [title, setTitle] = useState("");
  const [activeTab, setActiveTab] = useState(ChartsWidgetType.COMPARISON);
  const {isProformaAvailable} = useRouteContext();
  const [miniHeatmapData, setMiniHeatmapData] = useState([]);
  const [riskData, setRiskData] = useState([]);
  const [isLoading, setIsLoading ] = useState(false);
  const { isExtraSmallDevice } = useViewport();
  const [ytdEvolutionData, setYtdEvolutionData] = useState([] as any);
  const [currentYear, setCurrentYear] = useState({});
  const [previousYear, setPreviousYear] = useState({});
  const [showToolTip, setShowToolTip] = useState(true);
  const [performanceData, setPerformanceData] = useState([] as any);
  const [isConnexionFailed, setIsConnexionFailed] = useState(false);

  const toggle = (tab: ChartsWidgetType) => {
    if(activeTab !== tab) {
      setActiveTab(tab);
      if(tab === ChartsWidgetType.EVOLUTION){
          setShowCompareWidget(false);
          setShowEvolWidget(true);
          setShowRiskWidget(false);
          setShowPerformanceWidget(false);
      } else if (tab === ChartsWidgetType.COMPARISON){
          setShowCompareWidget(true);
          setShowEvolWidget(false);
          setShowRiskWidget(false);
          setShowPerformanceWidget(false);
      } else if (tab === ChartsWidgetType.RISK){
          setShowRiskWidget(true);
          setShowCompareWidget(false);
          setShowEvolWidget(false);
          setShowPerformanceWidget(false);
      }else if (tab === ChartsWidgetType.PERFORMANCE){
          setShowPerformanceWidget(true);
          setShowRiskWidget(false);
          setShowCompareWidget(false);
          setShowEvolWidget(false);

      }
    }
  };

    const canDisplayRiskWidget = () => {
        return (UtilsService.isGbisOrMark(parseInt(params.scope))
            && params.subScope === undefined
            && params.region === 'ww'
            && UtilsService.isWorldWideByCode(params.userLocation));
    }

    useEffect(() => {
        const date = params.date;
        const reportingUnitId = params.scope;
        const subReportingUnitId = params.subScope ? params.subScope : reportingUnitId;
        const regionCode: string | undefined = params.region;
        const regionValue = GetRegionValueByCode(regionCode);
        const importType: string = isProformaAvailable ? "STABILIZED" : "PUBLISHED";
        setIsLoading(true);
        const currentYear = new Date(date).getFullYear();
        const previousYear = currentYear - 1;
        setCurrentYear(currentYear);
        setPreviousYear(previousYear);
        const subscription = FetchMiniHeatMapData(date, parseInt(reportingUnitId), regionValue, importType, parseInt(subReportingUnitId))
            .subscribe(
                data => {
                    if (data) {
                        setMiniHeatmapData(data);
                    }
                },
                () => {
                    setMiniHeatmapData([]);
                });
        setIsLoading(false);
        return () => subscription.unsubscribe();
    }, [params, isProformaAvailable]);

    useEffect(() => {
        const date = params.date;
        const reportingUnitId = params.scope;
        const subReportingUnitId = params.subScope ? params.subScope : reportingUnitId;
        const regionCode: string | undefined = params.region;
        const regionValue = GetRegionValueByCode(regionCode);
        const importType: string = isProformaAvailable ? "STABILIZED" : "PUBLISHED";
        setIsLoading(true);
        //take WTD as period by default
        const period = PERIOD_TYPE.WTD;
        const subscription = FetchNbiYtdData(date, parseInt(reportingUnitId), regionValue, importType, period, parseInt(subReportingUnitId))
            .subscribe(data => {
                    if (data) {
                        setYtdEvolutionData(data);
                    }
                    setIsLoading(false);
                },
                () => {
                    setIsLoading(false);
                });
        return () => subscription.unsubscribe();
    }, [params, isProformaAvailable]);

    useEffect(()=>{
        setActiveTab(ChartsWidgetType.COMPARISON);
        setShowCompareWidget(true);
        setShowEvolWidget(false);
        setShowPerformanceWidget(false);
        if (!canDisplayRiskWidget()) {
            setShowRiskWidget(canDisplayRiskWidget);
        }
        if (!isExtraSmallDevice && canDisplayRiskWidget()) {
            const date = params.date;
            const reportingUnitId = parseInt(params.scope);
            const subscription = GetRiskGraphData(date, reportingUnitId)
                .subscribe(data => {
                    if (data) {
                        setRiskData(data);
                    }
                });
            return () => subscription.unsubscribe();
        }
    },[params]);

  useEffect(() => {
      const subscription = TitleService.observable().subscribe(({title}) => {
      setTitle(title);
    });
    return () => subscription.unsubscribe();
  }, [params]);




  const setFullPerformanceData = (data: any) =>{
      const performanceDataList : any[]=[];
      data.forEach(( data : any )=> {
          performanceDataList.push({
              z: UtilsService.formatNumberWithOneDigit(data.nbiMonthToDate.pnl),
              x: UtilsService.applySpecificFormattingToNumber(data.nbiMonthToDate.percentageVsBudget,1,100),
              y: UtilsService.applySpecificFormattingToNumber(data.nbiMonthToDate.percentageVsPreviousYear,1,100),
              name: data.nbiMonthToDate.scope
         } )

     });
      setPerformanceData(performanceDataList);

  }

    useEffect(() => {
        setPerformanceData([]);
        const date = params.date;
        const reportingUnitId = parseInt(params.scope);
        const subReportingUnitId: number | undefined = params.subScope ? parseInt(params.subScope) : reportingUnitId;
        const importType: string = isProformaAvailable ? "STABILIZED" : "PUBLISHED";
        const regionCode: string | undefined = params.region;
        const regionId: number | undefined =  GetRegionValueByCode(regionCode);
        const subscription = FetchPerformanceData(date, reportingUnitId,importType,subReportingUnitId,regionId)
            .subscribe(data => {
                    if (data) {
                       setFullPerformanceData(data);
                    }
                },
                () => {
                    setFullPerformanceData([]);
                    setIsConnexionFailed(true);
                });
        return () => subscription.unsubscribe();
    }, [params,isProformaAvailable]);

    const Error = () => {
        return < ConnexionError/>;
    };

    return (
        <div className='overview'>
            {(()=>{
                if (!isExtraSmallDevice) {
                    return ( <Accordion title={<FormattedMessage id='home.charts' values={{title}}/>} openByDefault={true}
                        filter = {
                            showToolTip ?
                                <div className="d-flex flex-column flex-sm-row">
                                    <MuiThemeProvider theme={MuiTooltipTheme}>
                                        <Tooltip placement="left" title={<span>
                                            <div className="container">
                                                <h6 className="under pb-1"> NBI HISTORICAL </h6>
                                                <div className="customFont form-inline pb-2">
                                                    <FormattedMessage id='home.charts.wtd' values={{
                                                        currentYear: `${currentYear}`,
                                                        previousYear: `${previousYear}`
                                                    }}/>
                                                </div>
                                                <h6 className="under pb-1"> NBI PERFORMANCE </h6>
                                                <div className="customFont form-inline pb-2">
                                                    <FormattedMessage
                                                        id='home.charts.performance.first'></FormattedMessage>
                                                </div>
                                                <div className="customFont form-inline"><FormattedMessage
                                                    id='home.charts.performance.second'></FormattedMessage></div>
                                                <div className="customFont form-inline"><FormattedMessage
                                                    id='home.charts.performance.third'></FormattedMessage></div>
                                                <div className="customFont form-inline"><FormattedMessage
                                                    id='home.charts.performance.forth'></FormattedMessage></div>
                                                <div className="customFont form-inline"><FormattedMessage
                                                    id='home.charts.performance.fifth'></FormattedMessage></div>
                                                <div className="customFont form-inline pb-2"><FormattedMessage
                                                    id='home.charts.performance.sixth'></FormattedMessage></div>
                                                <div className="customFont form-inline"><FormattedMessage
                                                    id='home.charts.performance.seventh'></FormattedMessage></div>
                                            </div>
                                        </span>
                                        } >
                                            <IconButton>
                                                <InfoOutlinedIcon style={{fill: '#ffff00'}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </MuiThemeProvider>
                                </div> : ''
                        }>
                            {
                                <ul className="nav nav-tabs mb-4">
                                    <li className="nav-item" style={{cursor: "pointer"}}>
                                        <a className={`${activeTab === ChartsWidgetType.COMPARISON ? 'nav-link active' : 'nav-link'}`}
                                           onClick={() => {
                                               toggle(ChartsWidgetType.COMPARISON);
                                           }}
                                        >NBI HISTORICAL</a>
                                    </li>
                                    <li className="nav-item" style={{cursor: "pointer"}}>
                                        <a className={`${activeTab === ChartsWidgetType.PERFORMANCE ? 'nav-link active' : 'nav-link'}`}
                                           onClick={() => {
                                               toggle(ChartsWidgetType.PERFORMANCE);
                                           }}
                                        >NBI PERFORMANCE</a>
                                    </li>
                                    <li className="nav-item" style={{cursor: "pointer"}}>
                                        <a className={`${activeTab === ChartsWidgetType.EVOLUTION ? 'nav-link active' : 'nav-link'}`}
                                           onClick={() => {
                                               toggle(ChartsWidgetType.EVOLUTION);
                                           }}
                                        >NBI YTD EVOLUTION</a>
                                    </li>
                                    {
                                        canDisplayRiskWidget() && (
                                            <li className="nav-item" style={{cursor: "pointer"}}>
                                                <a className={`${activeTab === ChartsWidgetType.RISK ? 'nav-link active' : 'nav-link'}`}
                                                   onClick={() => {
                                                       toggle(ChartsWidgetType.RISK);
                                                   }}
                                                >RISK VaR/SVaR</a>
                                            </li>
                                        )
                                    }
                                </ul>
                            }
                            {
                                showCompareWidget && (
                                    <NBIComparison data={miniHeatmapData} params={params} isLoading={isLoading}/>
                                )
                            }
                            {
                                showPerformanceWidget && (
                                    <NBIPerformance data={performanceData} params={params}></NBIPerformance>
                                )
                            }
                            {
                                showEvolWidget && (
                                    <NBIYtdEvolution params={params}  data={ytdEvolutionData}/>
                                )
                            }
                            {
                                showRiskWidget && (
                                    <NBIRisk data={riskData} params={params}/>
                                )
                            }

                        </Accordion>
                    )
                } else {
                    return ( <Accordion title={<FormattedMessage id='home.comparison' values={{title}}/>} openByDefault={true}>
                        <NBIComparison data={miniHeatmapData} params={params} isLoading={isLoading}/>
                    </Accordion>)
                }

        })()}
       </div>
  );
}
