import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useRouteContext} from '../../../../context/RouteContext/RouteContext';
import {GetRegionCodeByValue} from '../../../../services/region/RegionService';
import {IRouteParams} from '../../route/IRouteParams';

export const ViewDetailsRenderer: React.FC = (props: any) => {
    const routeParams: IRouteParams = useParams<IRouteParams>();
    const history = useHistory();
    const {detailPageParamsChanged} = useRouteContext();

    const viewDetails = () => {
        let regionCode = "ww";
        if (props.data.regionParent !== 0) {
            regionCode = GetRegionCodeByValue(props.data.regionParent)
        }
    const path = `/detail/${regionCode}/${routeParams.scope}/${props.data.reportingUnitId}/${props.data.indicatorId}/${routeParams.date}`;
    detailPageParamsChanged(props.data.title, props.data.indicatorCode,props.data.parentName);
    history.push(path);
  };

  return (
      <span>
      <a role="button"
         style={{ height: 20, lineHeight: 0.5, cursor: "pointer" }}
         onClick={viewDetails}>
        <i className="icon icon-md">open_in_new</i>
      </a>
    </span>
  );
};