import React, {useEffect, useRef, useState} from "react";
import {Card} from "../card/Card";
import {CARD_TYPE} from "../Constants";
import {TitleService} from "../../../services/event/TitleService";
import './NBIOverview.scss'

interface INBIOverviewData {
  dtd?: any,
  wtd?: any,
  mtd?: any,
  qtd?: any,
  ytd?: any
}

interface INBIOverviewProps {
  nbiData: INBIOverviewData;
}

export const NBIOverview: React.FC<INBIOverviewProps> = ({ nbiData }) => {
  const nbiOverViewDivRef = useRef<HTMLDivElement>(null);
  const [overflowActive, setOverflowActive] = useState(false);
  const [scrollToEndRight, setScrollToEndRight] = useState(false);
  const [title, setTitle] = useState("");
  const [data, setData] = useState({} as INBIOverviewData);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
      setData(nbiData);
  }, [nbiData]);

  const handleResize = () => {
    if (nbiOverViewDivRef) {
      const overflowActive = hasOverflowingChildren(nbiOverViewDivRef.current);
      setOverflowActive(overflowActive);
      handleScroll();
    }
  };

  const handleScroll = () => {
    const current = nbiOverViewDivRef.current;
    if (current && (current.offsetWidth + current.scrollLeft === current.scrollWidth)) {
      setScrollToEndRight(true);
    } else {
      setScrollToEndRight(false);
    }
  };

  const hasOverflowingChildren = (element: HTMLDivElement | null): boolean => {
    return element != null && element.offsetWidth < element.scrollWidth;
  };

  const shouldDisplayGradient = () => {
    return overflowActive && !scrollToEndRight;
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    nbiOverViewDivRef.current && nbiOverViewDivRef.current.addEventListener('scroll', handleScroll);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
      nbiOverViewDivRef.current && nbiOverViewDivRef.current.removeEventListener('scroll', handleScroll);
    }
  }, []);


  useEffect(() => {
    const subscription = TitleService.observable().subscribe(({title}) => {
      setTitle(title);
    });
    return () => subscription.unsubscribe();
  }, []);

  return (

        <div className={`flex-row mt-0 position-relative ${shouldDisplayGradient() ? "gradient" : ""}`}>
          <div className="position-relative overflow-auto" ref={nbiOverViewDivRef}>
            <div className="cards-holder">
              <div className="cards row">
                <div className="col-sm-6 col-md-2_4 padding-left-md-0 mb-md-0 mb-3">
                  <Card type={CARD_TYPE.DTD} data={nbiData.dtd} isLoading={isLoading}/>
                </div>
                <div className="col-sm-6 col-md-2_4 mb-md-0 mb-3">
                  <Card type={CARD_TYPE.WTD} data={nbiData.wtd} isLoading={isLoading}/>
                </div>
                <div className="col-sm-6 col-md-2_4  mb-md-0 mb-3">
                  <Card type={CARD_TYPE.MTD} data={nbiData.mtd} isLoading={isLoading}/>
                </div>
                <div className="col-sm-6 col-md-2_4  mb-md-0 mb-3">
                  <Card type={CARD_TYPE.QTD} data={nbiData.qtd} isLoading={isLoading}/>
                </div>
                <div className="col-sm-6 col-md-2_4 mb-md-0 mb-3">
                  <Card type={CARD_TYPE.YTD} data={nbiData.ytd} isLoading={isLoading}/>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
};