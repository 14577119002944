import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Form, FormGroup, Input, Label} from "reactstrap";
import {MINI_HEATMAP_COMPARISON_TYPE, PERIOD_TYPE} from "../Constants";
import {ColumnChart} from "../columnChart/ColumnChart";
import {IRouteParams} from "../route/IRouteParams";
import DateService from "../../../services/DateService";
import {MiniHeatmapSVG} from "../miniHeatmap/MiniHeatmapSVG";
import {Loader} from "../Loader";


interface INBIComparisonProps {
    params: IRouteParams;
    data: any,
    isLoading: boolean
}

export const NBIComparison: React.FC<INBIComparisonProps> = ({data, params, isLoading}) => {
    const intl = useIntl();
    const [miniHeatmapData, setMiniHeatmapData] = useState([] as any);
    const [period, changePeriod] = useState(PERIOD_TYPE.WTD);
    const [comparisonType, changeComparisonType] = useState(MINI_HEATMAP_COMPARISON_TYPE.BUDGET);
    const currentYear = new Date().getFullYear();
    const [yearSelected, setYearSelected] = useState(currentYear);
    const [previousYear, setPreviousYear] = useState(currentYear - 1);
    const monthSelected: any = new Date(params.date).getMonth();

    const periodOptions: Array<any> = [
        {
            value: PERIOD_TYPE.WTD,
            label: intl.formatMessage({id: 'mini-heatmap.wtd'})
        },
        {
            value: PERIOD_TYPE.MTD,
            label: intl.formatMessage({id: 'mini-heatmap.mtd'})
        },
        {
            value: PERIOD_TYPE.QTD,
            label: intl.formatMessage({id: 'mini-heatmap.qtd'})
        },
        {
            value: PERIOD_TYPE.YTD,
            label: intl.formatMessage({id: 'mini-heatmap.ytd'})
        }
    ];
    const constructComparisonTypeOptions = (year: number, previousYear: number) => {
        return [
            {
                value: MINI_HEATMAP_COMPARISON_TYPE.SAME_PERIOD,
                label: previousYear
            },
            {
                value: MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD,
                label: year + ` (${periodOptions.find(e => period === e.value).label} -1)`
            },
            {
                value: MINI_HEATMAP_COMPARISON_TYPE.BUDGET,
                label: `Budget ${year}`
            }
        ];
    };

    const [comparisonTypeOptions, setComparisonTypeOptions] = useState(constructComparisonTypeOptions(yearSelected, previousYear));

    const handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const targetPeriod = e.target.value;
        changePeriod(targetPeriod);
        if (targetPeriod === PERIOD_TYPE.YTD) {
            const index = comparisonTypeOptions.findIndex(e => e.value === MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD);
            if (index > -1) {
                comparisonTypeOptions.splice(index, 1);
                if (comparisonType === MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD) {
                    changeComparisonType(MINI_HEATMAP_COMPARISON_TYPE.BUDGET);
                }
            }
        } else {
            setComparisonTypeOptions([
                {
                    value: MINI_HEATMAP_COMPARISON_TYPE.SAME_PERIOD,
                    label: previousYear
                },
                {
                    value: MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD,
                    label: yearSelected + ` (${periodOptions.find(e => targetPeriod === e.value).label} - 1)`
                },
                {
                    value: MINI_HEATMAP_COMPARISON_TYPE.BUDGET,
                    label: `B${yearSelected}`
                }
            ]);
        }
    };

    const initDate = (date: string) => {
        const year = DateService.getFullYearFromDate(date);
        const previousYear = year - 1;
        setYearSelected(year);
        setPreviousYear(previousYear);
        setComparisonTypeOptions(constructComparisonTypeOptions(year, previousYear));
    };

    useEffect(() => {
        if (data) {
            initDate(params.date);
            setMiniHeatmapData(data);
        }
    }, [data, params]);


    return (
        <div>
            <Form className="d-flex flex-column flex-sm-row mb-lg-4 justify-content-end">
                <FormGroup className="d-flex align-items-center mb-2 mb-sm-0">
                    <Label for="typeOptoins" className="m-auto text-secondary fw-bold mx-2">
                        <FormattedMessage id="comparison-with"/>
                    </Label>
                    <Col>
                    <Input className="form-select" type="select" name="select" id="typeOptoins" value={comparisonType}
                           onChange={e => changeComparisonType(e.target.value)}>
                        {comparisonTypeOptions.map(e => <option key={e.value} value={e.value}>{e.label}</option>)}
                    </Input>
                </Col>
            </FormGroup>
            <FormGroup className="d-flex align-items-center mb-0">
                <Label for="periodOptions" className="m-auto text-secondary fw-bold mx-2">
                    <FormattedMessage id="period"/>
                </Label>
                <Col>
                    <Input className="form-select" type="select" name="select" id="periodOptions" value={period}
                           onChange={e => handlePeriodChange(e)}>
                        {periodOptions.map(e => <option key={e.value} value={e.value}>{e.label}</option>)}
                    </Input>
                </Col>
            </FormGroup>
          </Form>

          <div className="container-fluid ps-0 pe-0">
              <div className="row">
                  <div className="col-sm-12 col-lg-4">
                      <MiniHeatmapSVG data={miniHeatmapData} period={period} comparisonType={comparisonType}
                                      year={yearSelected}/>
                  </div>
                  {(() => {
                      return (
                          isLoading ? <div className="col-sm-12 col-lg-8 mt-4 mt-lg-0"><Loader/>
                              </div> :
                              <div className="col-sm-12 col-lg-8 mt-4 mt-lg-0" key={period}>
                                  <ColumnChart data={miniHeatmapData} period={period} comparisonType={comparisonType}
                                               date={monthSelected}/>
                              </div>
                      );
            })()}
          </div>
        </div>
      </div>
  );
};
