import React from 'react';

export const MiniFooter: React.FC = () => {
  return (
    <sgwt-mini-footer
      type="compact"
      design="light"
      mode="sg-markets"
      accessibility-compliance="none"
      contact-us-by-help-center="sgwt-help-center" />
  );
};
