import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {combineLatest} from 'rxjs';
import {first} from 'rxjs/operators';
import {Role} from '../../../../models/UserInfoModel';
import {postDeleteDailyData, postDeleteData} from '../../../../services/admin/DeleteDataService';
import {getCurrentUser, getLastDate} from '../../../../services/CommonService';
import DatePicker from '../../../common/dateTimePicker/DatePicker';
import {Loader} from '../../../common/Loader';
import {TreeSelect} from '../../../common/selectGroup/TreeSelect';
import './DeleteDailyData.scss';
import {RequestStatus, Target} from './DeleteDailyDataModels';

export const DeleteDailyData: React.FC = () => {
  const DAILY = 'Daily';
  const ADVANCED = 'Advanced';
  const [mode, setMode] = useState(DAILY);
  const [singleDate, setSingleDate] = useState('');
  const [dateRangeStart, setDateRangeStart] = useState('');
  const [dateRangeEnd, setDateRangeEnd] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [targets, setTargets] = useState([Target.FPV.valueOf(), Target.COMFI.valueOf(), Target.REGIONS.valueOf()]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDataType, setShowDataType] = useState(true);
  const [showCheckAll, setShowCheckAll] = useState(true);
  const ALL = 'ALL';
  const MARK_INSIDE_GBIS = {
    value: '3',
    name: 'MARK (inside GBIS, only PnL)'
  };
  const MARK_OUTSIDE_GBIS = {
    value: '13',
    name: 'MARK (outside GBIS)'
  };
  const [scopeOptions, setScopeOptions] = useState([{ value: ALL, name: ALL }]);
  const [selectedScope, setSelectedScope] = useState(ALL);
  const dataTypeOptions = [
    { value: ALL, name: ALL },
    { value: 'STABILIZED', name: 'PROFORMA' },
    { value: 'PUBLISHED', name: 'OFFICIAL' }];
  const [selectedDataType, setSelectedDataType] = useState(ALL);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.INITIALIZED);

  useEffect(() => {
    setIsLoading(true);
    const subscription = combineLatest([
      getCurrentUser(),
      getLastDate()
    ]).subscribe(
      ([current, date]) => {
        setSingleDate(date);
        setDateRangeStart(date);
        setDateRangeEnd(date);
        setSelectedDate(date);
        initializeScopeOptions(current);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    }
  }, []);

  const initializeScopeOptions = (current: any) => {
    const roles = current.roles;
    let regionScope: string;
    let isAdminWW = false;
    if (roles.includes(Role.ROLE_ADMIN)) {
      regionScope = 'WW';
      isAdminWW = true;
    } else if (roles.includes(Role.ROLE_ADMIN_ASIA)) {
      regionScope = 'ASIA';
    }

    const newScopeOptions = [...scopeOptions];
    current.scopeList
      .filter((p: any) => p.location.code === regionScope)
      .map((p: any) => newScopeOptions.push({ value: p.id + '', name: p.code }));

    if (isAdminWW) {
      transformMarkScope(current, newScopeOptions);
    }

    setScopeOptions(newScopeOptions);
  };

  const transformMarkScope = (current: any, newScopeOptions: any[]) => {
    const markOutsideGBISIndex = current.perimeters.findIndex((p: any) => p.id == MARK_OUTSIDE_GBIS.value);
    if (markOutsideGBISIndex > -1) {
      // first replace mark with mark outside gbis
      newScopeOptions.splice(markOutsideGBISIndex, 1, MARK_OUTSIDE_GBIS);
      // then insert mark inside gbis before it
      newScopeOptions.splice(markOutsideGBISIndex + 1, 0, MARK_INSIDE_GBIS);
    }
  };

  const handleModeChange = (event: any) => {
    const newMode = event.target.value;
    setMode(newMode);
    if (newMode === DAILY) {
      setSelectedDate(singleDate);
    } else {
      setSelectedDate(getDateRange(dateRangeStart, dateRangeEnd));
    }
  };

  const handleSingleDateChange = (date: string) => {
    setSingleDate(date);
    setSelectedDate(date);
  };

  const handleDateRangeStartChange = (date: string) => {
    setDateRangeStart(date);
    setSelectedDate(getDateRange(date, dateRangeEnd));
  };

  const handleDateRangeEndChange = (date: string) => {
    setDateRangeEnd(date);
    setSelectedDate(getDateRange(dateRangeStart, date));
  };

  const getDateRange = (start: string, end: string) => {
    return start + ' to ' + end;
  };

  const handleScopeChange = (scope: any) => {
    setSelectedScope(scope);
  };

  const changeTarget = (value: string) => {
    const newTargets = [...targets];
    const index = newTargets.indexOf(value);
    if (index > -1) {
      newTargets.splice(index, 1);
    } else {
      newTargets.push(value);
    }
    setTargets(newTargets);
    setShowDataType(
      newTargets.includes(Target.FPV.valueOf())
      || newTargets.includes(Target.COMFI.valueOf())
      || newTargets.includes(Target.REGIONS.valueOf()))
  };

  const changeAllTarget = (isCheck: boolean) => {
    setShowCheckAll(!isCheck);
    if (isCheck) {
      const newTargets: string[] = [];
      Object.values(Target).forEach(
        value => {
          newTargets.push(value);
          setTargets(newTargets);
        });
    } else {
      setTargets([]);
    }
    setShowDataType(isCheck);
  };

  const handleDataTypeChange = (dataType: any) => {
    setSelectedDataType(dataType);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
  };

  const handleDelete = (): void => {
    if (requestStatus === RequestStatus.TO_CONFIRM) {

      const request = buildRequest();
      setRequestStatus(RequestStatus.IS_DELETING);
      if (mode === DAILY) {
        deleteDailyData(request);
      } else {
        deleteData(request);
      }
    } else {
      setRequestStatus(RequestStatus.TO_CONFIRM);
    }
  };

  const deleteDailyData = (request: any) => {
    postDeleteDailyData(request)
      .pipe(first())
      .subscribe(
        () => setRequestStatus(RequestStatus.IS_DELETED),
        () => setRequestStatus(RequestStatus.FAILED_TO_DELETE)
      );
  };

  const deleteData = (request: any) => {
    postDeleteData(request)
      .pipe(first())
      .subscribe(
        () => setRequestStatus(RequestStatus.IS_DELETED),
        () => setRequestStatus(RequestStatus.FAILED_TO_DELETE)
      );
  };

  const buildRequest = () => {
    let request;
    if (mode === DAILY) {
      request = { date: singleDate }
    } else {
      request = {
        dateFrom: dateRangeStart,
        dateTo: dateRangeEnd,
        deleteDataOptions: targets,
        importType: getImportType(),
        reportingUnitId: getReportingUnitId()
      }
    }
    return request;
  };

  const getImportType = () => {
    return selectedDataType === ALL ? null : selectedDataType;
  };

  const getReportingUnitId = () => {
    return selectedScope === ALL ? null : parseInt(selectedScope, 10);
  };

  const handleCancel = () => {
    setRequestStatus(RequestStatus.INITIALIZED);
  };

  const handleReset = () => {
    setRequestStatus(RequestStatus.INITIALIZED);
    setSelectedDataType(ALL);
    setSelectedScope(ALL);
  };

  return (
    <div className="container-fluid px-5 wrapper">
      { isLoading ?
        <div style={{ height: '400px' }}><Loader /></div>
        :
        <div className="pt-5">
          <h1><FormattedMessage id="admin.delete.head" /></h1>
          <form onSubmit={handleSubmit}>
            <div className="mt-5">
              <div><h4><FormattedMessage id="admin.delete.mode.title" /></h4></div>
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label className={"btn btn-toggle-info me-1" + (mode === DAILY ? ' active' : '')}>
                    <input className="me-2 position-absolute opacity-0" type="checkbox" value={DAILY}
                           checked={mode === DAILY}
                           onChange={handleModeChange}/>
                  <FormattedMessage id="admin.delete.mode.daily"/>
                </label>
                  <label className={"btn btn-toggle-info me-1" + (mode === ADVANCED ? ' active' : '')}>
                    <input className="me-2 position-absolute opacity-0" type="checkbox" value={ADVANCED}
                           checked={mode === ADVANCED}
                           onChange={handleModeChange}/>
                    <FormattedMessage id="admin.delete.mode.advanced"/>
                  </label>
              </div>
            </div>
            {mode === 'Daily' ?
              <div className="mt-4">
                <h4><FormattedMessage id="admin.delete.singleDate" /></h4>
                <div className="mb-0 w-100">
                  <DatePicker onDateChanged={handleSingleDateChange} initDate={singleDate} />
                </div>
              </div> :
              <>
                <div className="mt-4">
                  <h4><FormattedMessage id="admin.delete.dateRange.title"/></h4>
                  <div className="d-flex mx-0">
                    <div className="d-flex flex-row align-items-center me-4">
                      <label className="me-2"><FormattedMessage id="admin.delete.dateRange.from"/></label>
                      <div className="mb-0 w-100">
                        <DatePicker onDateChanged={handleDateRangeStartChange} initDate={dateRangeStart}/>
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                      <label className="me-2"><FormattedMessage id="admin.delete.dateRange.to"/></label>
                      <div className="mb-0 w-100">
                        <DatePicker onDateChanged={handleDateRangeEndChange} initDate={dateRangeEnd}/>
                            </div>
                        </div>
                  </div>
                </div>
                <div className="mt-4">
                  <h4><FormattedMessage id="admin.delete.scope.title"/></h4>
                  <div className="d-flex mx-0" style={{width: '300px'}}>
                    <TreeSelect optionList={scopeOptions} defaultValue={selectedScope}
                                onOptionChange={handleScopeChange}/>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="d-flex flex-row justify-content-start align-items-center">
                    <h4><FormattedMessage id="admin.target.title"/></h4>
                    {showCheckAll ?
                        <button type="button" className="btn btn-sm btn-danger ms-2"
                                onClick={() => changeAllTarget(true)}>
                          <FormattedMessage id="admin.target.checkAll"/>
                        </button>
                        :
                        <button type="button" className="btn btn-sm btn-danger ms-2"
                                onClick={() => changeAllTarget(false)}>
                          <FormattedMessage id="admin.target.uncheckAll"/>
                        </button>
                    }
                  </div>
                  <div className="d-flex my-2">
                    {Object.values(Target).map(
                        value =>
                            <div className="form-check me-4" key={value}>
                              <input className={'me-2' + (targets.includes(value) ? ' is-valid' : '')}
                                     type="checkbox" value="fpv"
                                     checked={targets.includes(value)} onChange={() => changeTarget(value)}/>
                              <label className=""><FormattedMessage
                                  id={"admin.delete.target." + value}/></label>
                            </div>
                    )}
                  </div>
                </div>
                {showDataType &&
                  <div className="mt-4">
                    <h4><FormattedMessage id="admin.delete.dataType.title" /></h4>
                    <div className="mx-0" style={{ width: '300px' }}>
                      <TreeSelect optionList={dataTypeOptions} defaultValue={selectedDataType} onOptionChange={handleDataTypeChange} />
                    </div>
                  </div>
                }
              </>
            }
            <div className="mt-5">
              {requestStatus === RequestStatus.TO_CONFIRM ?
                <>
                    <button className="btn btn-danger me-2" onClick={handleDelete}>
                        <span><FormattedMessage id="admin.button.confirm"/></span>
                    </button>
                    <button className="btn btn-secondary me-2" onClick={handleCancel}>
                        <span><FormattedMessage id="admin.button.cancel"/></span>
                    </button>
                </>
                :
                <>
                    <button className="btn btn-primary me-2" onClick={handleDelete}>
                        <span><FormattedMessage id="admin.button.delete"/></span>
                    </button>
                    <button className="btn btn-secondary me-2" onClick={handleReset}>
                        <span><FormattedMessage id="admin.button.reset"/></span>
                    </button>
                </>
              }
            </div>
          </form>
          {requestStatus === RequestStatus.TO_CONFIRM &&
            <div className="alert alert-outline-warning col-4 mt-5" role="alert">
              <FormattedMessage id="admin.delete.request.toConfirm" values={{ date: `${selectedDate}`}} />
            </div>
          }
          {requestStatus === RequestStatus.IS_DELETING &&
            <div className="alert alert-outline-info col-4 mt-5" role="alert">
                <FormattedMessage id="admin.delete.request.deleting"/>
            </div>
          }
          {requestStatus === RequestStatus.IS_DELETED &&
            <div className="alert alert-outline-success alert-dismissible fade show col-4 mt-5" role="alert">
              <FormattedMessage id="admin.delete.response.success" values={{ date: `${selectedDate}`}} />
            </div>
          }
          {requestStatus === RequestStatus.FAILED_TO_DELETE &&
            <div className="alert alert-outline-danger alert-dismissible fade show col-4 mt-5" role="alert">
              <FormattedMessage id="admin.delete.response.failure" values={{ date: `${selectedDate}`}} />
            </div>
          }
        </div>
      }
    </div>
  );
}

