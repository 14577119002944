export enum RequestStatus {
    INITIALIZED,
    TO_CONFIRM,
    IS_DELETING,
    IS_DELETED,
    FAILED_TO_DELETE
}

export enum Target {
    FPV = 'FPV',
    COMFI = 'COMFI',
    REGIONS = 'REGIONS',
    NEWS = 'NEWS',
    RISK = 'RISK'
}