import React, {useState} from "react";
import {addNews, DeleteNews, UpdateNewsDetails} from "../../../services/SynthesisService";
import {IRouteParams} from "../route/IRouteParams";
import {useParams} from "react-router-dom";
import {useRouteContext} from "../../../context/RouteContext/RouteContext";
import CKEditor from '@ckeditor/ckeditor5-react';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import './NewsDetails.css';


export const NewsDetails: React.FC<any> = (props:any) =>  {
    const [newNewsDetails, setNewNews] = useState(props.data.newsDetails);
    const [isEditingCurrentNews, setIsEditingCurrentNews] = useState(true);
    const routeParams: IRouteParams = useParams<IRouteParams>();
    const {isProformaAvailable} = useRouteContext();


    function onEditorChange( data:any ) {
        setNewNews(data);
    }
    const handleChange = async(event:any)=>{
        const val = btoa(unescape(encodeURIComponent(newNewsDetails)))
        const newsObject = {
            id : props.data.id,
            newsType: isProformaAvailable ? "SUMMARY_PROFORMA" : "SUMMARY",
            reportingUnitId: props.data.reportingUnitId,
            subReportingUnitId: props.data.subReportingUnitId,
            text: val,
            date: routeParams.date,
            title: props.data.reportingUnitName
        };
        if (props.data.newsDetails === '') {
            await addNews(newsObject).then((response: any) => {
                    if (response) {
                        newsObject.id = response.id;
                    }
                }
            )
        }
        else if(newNewsDetails === ''){
            DeleteNews(newsObject);
        }
        else {
            UpdateNewsDetails(newsObject);
        }
        props.onChange(newNewsDetails, newsObject.id, newsObject);
        debugger
        setIsEditingCurrentNews(false);
    }

    return (
        <div>
            {isEditingCurrentNews &&
            <div>
                <CKEditor
                    editor={ ClassicEditor }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        onEditorChange(data);
                    } }

                    data={props.data.newsDetails}

                />
                <br/>
                <div className="container-fluid">
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-default" type="button" onClick={props.onClose}> Cancel</button>
                        <button className="btn btn-danger" type="button" onClick={handleChange}>Save</button>
                    </div>
                </div>
            </div>
            }
        </div>

    );
};
