import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official'

import './NbiRiskChart.scss';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data.js')(Highcharts);

interface RiskState {
    xAxisPeriod: any;
    varData: any,
    sVarData: any,
    varAvg60Data: any,
    sVarAvg60Data: any,
    params: any;
}

export const NbiRiskChart: React.FC<any> = (props: RiskState) =>  {

        Highcharts.setOptions({
            lang: {
                thousandsSep: ','
            }
        });
        Highcharts.SVGRenderer.prototype.symbols.download = function (x: any, y: any, w: any, h: any) {
            var path = [
                // Arrow stem
                'M', x + w * 0.5, y,
                'L', x + w * 0.5, y + h * 0.7,
                // Arrow head
                'M', x + w * 0.3, y + h * 0.5,
                'L', x + w * 0.5, y + h * 0.7,
                'L', x + w * 0.7, y + h * 0.5,
                // Box
                'M', x, y + h * 0.9,
                'L', x, y + h,
                'L', x + w, y + h,
                'L', x + w, y + h * 0.9
            ];
            return path;
        };
            const options = {
                chart: {
                    type: 'spline',
                    height: 620,
                    renderTo: 'container',
                    backgroundColor: {
                        linearGradient: [0, 0, 500, 500],
                        stops: [
                            [0, 'rgb(48,51,51)'],
                        ]
                    },
                    commonSeriesSettings: {
                        barPadding: 5,
                        columnPadding: 5,
                    },
                    panning: true,
                    itemPadding: 0,
                    zoomType: 'xy',
                },
                title: {
                    text: null
                },
                legend: {
                    itemStyle: {
                        color: '#949c9e',//text-secondary
                        fontWeight: 'bold'
                    },
                    itemHoverStyle: {
                        color: 'white'
                    }
                },
                tooltip: {
                    width:'600px',
                    enabled: true,
                    shared: true,
                    backgroundColor: 'rgba(0,0,0,0.7)',
                    valueSuffix: ' Mln€',
                    useHTML: true,
                    borderColor: 'rgba(0,0,0,0.7)',
                    padding:15,
                    headerFormat: '<table style="font-size: 14px; width: max-content" >' +
                        '<tr><th style="color: white" colspan="2">{point.key}</th></tr>',
                    pointFormat: '<tr><td style="color: {series.color}">{series.name} </td>' +
                        '<td style="text-align: right ; color: white"><b>{point.y}</b></td></tr>',
                    footerFormat: '</table>',
                    valueDecimals: 1
                },

                xAxis: [{
                    categories: props.xAxisPeriod,
                    labels: {
                        style: {
                            color: '#949c9e',//text-secondary
                            fontWeight: '600',//fw-semibold
                            fontFamily: 'Inter', //body font (inter)
                            fontSize: 15
                        }
                    },
                    crosshair: false,
                    min : props.xAxisPeriod?.length- 41 >= 0 ? props.xAxisPeriod?.length- 41 : 0,
                    max : props.xAxisPeriod?.length -1,

                }],
                scrollbar: {
                    enabled: true,
                    height: 10,
                    rifleColor: 'none',
                    barBackgroundColor: '#c4c8ca',
                    barBorderWidth: 0,
                    buttonBackgroundColor: '#c4c8ca',
                    buttonBorderWidth: 0,
                    trackBackgroundColor: '#303333',
                    trackBorderWidth: 0
                },
                yAxis: [{
                    startOnTick: false,
                    gridLineColor: '#464b4b',
                    gridLineWidth: 1,
                    title: null,
                    labels: {
                        style: {
                            color: '#949c9e',//text-secondary
                            fontWeight: '600',//fw-semibold
                            fontFamily: 'Inter', //body font (inter)
                            fontSize: 15
                        }
                    },

                },
                ],
                series: [
                    {
                        name: 'VaR',
                        type: 'spline',
                        dashStyle: 'shortdot',
                        color: '#155de1',
                        data: props.varData,
                        lineWidth: 3,
                        dataLabels: {
                            lang: {
                                decimalPoint: '',
                                thousandsSep: ''
                            },
                            padding: 5,
                            style: {
                                distance: '-50%',
                                color: 'white',
                                textOutline: 0,
                                fontSize: '15px',
                                fontWeight: '600',//fw-semibold
                                fontFamily: 'Inter', //body font (inter)
                            },
                        },
                        showInLegend: true,
                        marker: {
                        },
                    },
                    {
                        name: 'VaR 60D-MA',
                        type: 'spline',
                        dashStyle: 'shortdot',
                        color: '#54d3de',
                        data: props.varAvg60Data,
                        lineWidth: 3,
                        dataLabels: {
                            lang: {
                                decimalPoint: '',
                                thousandsSep: ''
                            },
                            padding: 5,
                            style: {
                                distance: '-50%',
                                color: 'white',
                                textOutline: 0,
                                fontSize: '15px',
                                fontWeight: '600',//fw-semibold
                                fontFamily: 'Inter', //body font (inter)
                            },
                        },
                        showInLegend: true,
                        marker: {
                        },
                    },
                    {
                        name: 'SVaR',
                        type: 'spline',
                        dashStyle: 'shortdot',
                        color: '#ff0c00',
                        data: props.sVarData,
                        lineWidth: 3,
                        dataLabels: {
                            lang: {
                                decimalPoint: '',
                                thousandsSep: ''
                            },
                            padding: 5,
                            style: {
                                distance: '-50%',
                                color: 'white',
                                textOutline: 0,
                                fontSize: '15px',
                                fontWeight: '600',//fw-semibold
                                fontFamily: 'Inter', //body font (inter)
                            },
                        },
                        showInLegend: true,
                        marker: {
                        },
                    },

                    {
                        name: 'SVaR 60D-MA',
                        type: 'spline',
                        dashStyle: 'shortdot',
                        color: '#F6A416',
                        data: props.sVarAvg60Data,
                        lineWidth: 3,
                        dataLabels: {
                            lang: {
                                decimalPoint: '',
                                thousandsSep: ''
                            },
                            padding: 5,
                            style: {
                                distance: '-50%',
                                color: 'white',
                                textOutline: 0,
                                fontSize: '15px',
                                fontWeight: '600',//fw-semibold
                                fontFamily: 'Inter', //body font (inter)
                            },
                        },
                        showInLegend: true,
                        marker: {
                        },
                    },
                ],
                credits: {
                    enabled: false
                },

                exporting: {
                    enabled: true,
                    buttons: {
                        contextButton: {
                            menuItems: [
                                "downloadPNG",
                                "downloadJPEG",
                                "downloadPDF",
                                "downloadSVG",
                                "separator",
                                "downloadCSV",
                                "downloadXLS",
                                "openInCloud"],
                            symbolStroke: '#dddddd',
                            symbol: 'download' ,
                            theme: {
                                fill: '#505053'
                            },
                        },
                    }
                },

            };
        return (
            <div className="risk-chart">
                <HighchartsReact
                    allowChartUpdate={true}
                    highcharts={Highcharts}
                    options={options} />
            </div>
        );
}