import React, {Component} from 'react';
import Select from "react-select";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import {GetAuthorities, RegisterUser, UpdateUser} from '../../../../services/admin/AccountsManagementService';
import {FormValidationErrors} from './formComponents/FormValidationErrors';
import {FormSuccessMessage} from './formComponents/FormSuccessMessage';
import DropdownTreeSelect, {TreeNode} from 'react-dropdown-tree-select';
import {RegionOptions} from "../../../../services/region/RegionService";
import {TagsInput} from "react-tag-input-component";

import './UserManager.scss';
import UtilsService from "../../../../services/utils/UtilsService";

interface UserManagerProps {
    reload: any;
    data: any;
    businessUnitList: any;
}

interface UserManagerButtonState {
    modal: boolean,
    email: string,
    firstName: string,
    lastName: string,
    authorities: string[],
    perimeters: string[],
    errors: string[],
    successMessage: string,
    authoritiesList: any[],
    buList: any[],
    multiSelectBU: any[],
    selectedPerimeter: string[],
    userRoles: any[]
    enabled: boolean,
    showWWRegion: boolean
}

/**
 * Class to manage User ( create and update data )
 */
export class UserManager extends Component<UserManagerProps, UserManagerButtonState> {

    email: string = '';
    lastName: string = '';
    firstName: string = '';
    authoritiesList: any[] = [];
    userRoles: any[] = [];
    buList: any[] = [];
    sbuList: any[] = [];
    regionList: any[] = [];
    wwRegionList: any[] = [];
    selectedScopeList: any[] = [];
    selectedRegionList: any[] = [];
    perimeterList: any[] = [];
    isUpdateUser: boolean = false;
    addUserPerimeter: string[] = [];
    updateUserPerimeter: string[] = [];

    emptyState = {
        modal: false,
        email: '',
        firstName: '',
        lastName: '',
        authorities: [],
        perimeters: [],
        errors: [],
        successMessage: '',
        authoritiesList: [],
        buList: [],
        allRegion: [],
        multiSelectBU: [],
        selectedPerimeter: [],
        userRoles: [],
        enabled: this.props.data.enabled,
        showWWRegion: false
    };

    emptyUserInput = {
        email: '',
        firstName: '',
        lastName: '',
        authorities: [],
        perimeters: [],
        errors: [],
        successMessage: '',
        multiSelectBU: [],
        enabled: this.props.data.enabled,
    };

    constructor(props: any) {
        super(props);
        this.state = this.emptyState;
        if (this.props.data.email) {
            this.isUpdateUser = true
            this.email = this.props.data.email
            this.firstName = this.props.data.firstName
            this.lastName = this.props.data.lastName
            this.updateUserPerimeter = this.props.data.perimeterList
            let adminScopeList = this.props.data.adminScopeList
            if (adminScopeList != undefined) {
                this.formatBuList(adminScopeList);
            }
            this.setState({
                email: this.props.data.email,
                firstName: this.props.data.firstName,
                lastName: this.props.data.lastName,
                enabled: this.props.data.enabled,
            });
        }
        GetAuthorities().then((result: any) => {
            result.map((au: any) => this.authoritiesList.push({value: au.name, label: au.name}));
            this.setState({
                authoritiesList: result
            })
            if (this.props.data.email) {
                this.userRoles = this.authoritiesList.filter(r => this.props.data.roles?.includes(r.value))
            }
        });
        if (this.props.businessUnitList) {
            this.formatAllBuAndSbu(this.props.businessUnitList);
        }

        RegionOptions.forEach(region => {
            this.regionList.push({value: region.value, label: region.name.toUpperCase()})
        });
        this.wwRegionList.push(this.regionList[0])

    }

    formatBuList = (adminScopeList: any) => {
        this.formatAllBuAndSbu(adminScopeList);
    }

    callLoadGridRowsFunction = () => {
        // Trigger LoadGridRows from parent
        const event = new Event('callLoadGridRowsEvent');
        document.dispatchEvent(event);
    };

    formatAllBuAndSbu = (result: any[]) => {
        result.forEach(b => {
            const formattedSbuList: any[] = [];
            b.children.forEach((child: any) => {
                if (child.code != null) {
                    formattedSbuList.push({value: child.id, label: b.name + ' | ' + child.name});
                }
                this.sbuList.push({value: child.id, label: b.name});

            });
            this.buList.push({value: b.id, label: b.name, children: formattedSbuList});
        });
    };

    toggle = () => {
        this.addUserPerimeter = []
        this.selectedScopeList = []
        this.setState({
            ...this.emptyUserInput,
            modal: !this.state.modal
        });
    };

    handleInputChange = (event: any) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name: string = target.name;
        this.mapUserInfo(name, value);
        const newState = {[name]: value} as Pick<UserManagerButtonState, keyof UserManagerButtonState>
        this.setState(newState);
    };

    private mapUserInfo(name: string, value: string) {
        switch (name) {
            case 'email':
                this.email = value;
                break
            case 'lastName':
                this.lastName = value;
                break
            case 'firstName':
                this.firstName = value;
        }
    }

    /**
     * Generates user data and creates a payload for creating or updating a user
     */
    generateUserData = () => {
        const perimeters = this.getPerimeters();
        if (this.isUpdateUser) {
            return {
                id: this.props.data.id,
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                username: this.email,
                perimeters: perimeters,
                roles: this.userRoles.map(value => value.value),
                enabled: this.state.enabled,
                lastConnected: "",

            };
        } else {
            return {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                perimeters: perimeters,
                roles: this.state.authorities,
                enabled: true
            };
        }
    };

    /**
     * Creates a list of perimeter object (regionId, scopeId) from list of Region X Scope input
     */
    getPerimeters = () => {
        this.perimeterList = []
        const listSelectedPerimeter = this.isUpdateUser ? this.updateUserPerimeter : this.addUserPerimeter;
        listSelectedPerimeter.forEach(value => {
            const inputEl = value.split(' X ');
            const bu = inputEl[0].split(',');
            bu.forEach(label => {
                const sbu = label.split(' | ');
                let reportingUnitList: any[]
                if (sbu.length > 1) {
                    const selectedBu = this.buList.find(value4 => value4.label == sbu[0])
                    reportingUnitList = selectedBu.children.filter((child: { label: string; }) => child.label.split(' | ')[1] == sbu[1])
                } else {
                    const buAndSbu = this.buList.concat(this.buList);
                    reportingUnitList = buAndSbu.filter(el => label == el.label);
                }
                const finalRegion = RegionOptions.filter((region) => region.name.toUpperCase() == inputEl[inputEl.length - 1].toUpperCase())
                this.perimeterList.push({
                    reportingUnitId: reportingUnitList[0]?.value,
                    regionId: finalRegion[0]?.value
                });
            })
        })
        return this.perimeterList;
    };


    /**
     * If call is success, to reload grid data,
     * create function calls this.props.reload and update
     * function calls callLoadGridRowsFunction to dispatch reload event
     * @param event
     */
    handleSubmit = (event: any) => {
        event.preventDefault();
        const userData = this.generateUserData();
        if (this.isUpdateUser) {
            UpdateUser(userData).then(result => {
                this.setState({...this.emptyUserInput, successMessage: 'user.update.success'});
                this.callLoadGridRowsFunction();
            }, error => {
                this.setState({errors: ['user.update.failure']});
            });
        } else {
            if (this.validateForm()) { // only when create user
                RegisterUser(userData).then(result => {
                    this.setState({...this.emptyUserInput, successMessage: 'user.add.success'});
                    this.props.reload();
                }, error => {
                    this.setState({errors: ['user.add.failure']});
                });
            }
        }
        this.perimeterList = [];
        this.addUserPerimeter = [];
    };


    handleSelectAuthoritiesChange = (items: any) => {
        this.userRoles = items
        const newAuthorities: string[] = [];
        if (Array.isArray(items)) {
            items.forEach(el => newAuthorities.push(el.value));
            this.setState({authorities: newAuthorities});
        }
    };

    handleSelectBuChange = (currentNode: TreeNode, selectedNodes: TreeNode[]) => {
        selectedNodes.forEach(node => {
            if (!this.selectedScopeList.includes(node.label)) {
                this.selectedScopeList.push(node.label)
            }
        });
        this.setState({showWWRegion: !this.state.showWWRegion});
        if (this.selectedScopeList.find(value => UtilsService.isMarkUKOrChild(value))) {
            this.setState({showWWRegion: true});
        } else {
            this.setState({showWWRegion: false});
        }
    }

    handleSelectRegionChange = (value: string) => {
        this.selectedRegionList.push(value);
    }

    handleAddOrUpdatePerimeter = () => {
        const newPerimeter = this.selectedScopeList + " X " + this.selectedRegionList[0]?.label.toUpperCase();
        if (!this.addUserPerimeter.includes(newPerimeter)) {
            this.addUserPerimeter.push(newPerimeter);
        }
        if (!this.updateUserPerimeter.includes(newPerimeter) && this.isUpdateUser) {
            this.updateUserPerimeter.push(newPerimeter);
        }
        this.selectedScopeList = []
        this.selectedRegionList = []
        this.setState({selectedPerimeter: this.isUpdateUser ? this.updateUserPerimeter : this.addUserPerimeter});
    }

    clearPerimeter = () => {
        this.selectedScopeList.length = 0
        if (this.isUpdateUser) {
            this.updateUserPerimeter = []
        } else {
            this.addUserPerimeter = []
        }
        this.setState({selectedPerimeter: this.isUpdateUser ? this.updateUserPerimeter : this.addUserPerimeter});
    }

    handleSuppressPerimeter = (el: any) => {
        this.addUserPerimeter = this.addUserPerimeter.filter((item) => item != el);
        this.updateUserPerimeter = this.updateUserPerimeter.filter((item) => item != el);
        this.setState({selectedPerimeter: this.isUpdateUser ? this.updateUserPerimeter : this.addUserPerimeter});
    }
    handleSuppressScope = (el: any) => {
        this.selectedScopeList = this.selectedScopeList.filter((item) => item != el);
    }

    getPerimeterValue = (el: any) => {
        this.addUserPerimeter = el;
        this.updateUserPerimeter = el
        this.setState({selectedPerimeter: this.isUpdateUser ? this.updateUserPerimeter : this.addUserPerimeter});
    }

    handleModeChange = () => {
        this.setState({enabled: !this.state.enabled});
    };

    validateForm = (): boolean => {
        let isValid = true;
        const errors: string[] = [];
        if (this.state.email === '') {
            isValid = false;
            errors.push('admin.create.user.required.mail');
        }
        if (this.state.firstName === '') {
            isValid = false;
            errors.push('admin.create.user.required.firstname');
        }
        if (this.state.lastName === '') {
            isValid = false;
            errors.push('admin.create.user.required.lastname');
        }
        if (this.state.email !== '' && !this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            isValid = false;
            errors.push('admin.create.user.invalid.mail.format');
        }
        this.setState({errors, successMessage: ''});
        return isValid;

    };

    render() {
        return (
            <div className='user-manager'>
                {this.isUpdateUser ?
                    <button className='btn ' onClick={this.toggle}><i
                        className='icon icon-prepend'>edit</i></button>
                    : <button className='btn btn-danger create-btn mt-2' onClick={this.toggle}><i
                        className='icon icon-prepend'>add</i> <FormattedMessage id='admin.add.user'/></button>}
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader
                        toggle={this.toggle}> {`${this.isUpdateUser ? 'UPDATE ' : 'ADD '}`} User</ModalHeader>
                    <ModalBody>
                        <FormSuccessMessage messageId={this.state.successMessage}/>
                        {!this.isUpdateUser && <FormValidationErrors errors={this.state.errors}/>}
                        <form className='create-account-form'>
                            <div className='form-group reduced-margins'>
                                <label htmlFor='Email'>Email</label>
                                <input type='email' className='form-control popup-form-control' id='Email'
                                       placeholder='Email'
                                       name='email' onChange={this.handleInputChange}
                                       value={this.email}/>
                            </div>
                            <div className='form-group reduced-margins'>
                                <label htmlFor='firstName'>First Name</label>
                                <input type='text' className='form-control popup-form-control' id='firstName'
                                       placeholder='First Name' name='firstName' onChange={this.handleInputChange}
                                       value={this.firstName}/>
                            </div>
                            <div className='form-group reduced-margins'>
                                <label htmlFor='lastName'>Last Name</label>
                                <input type='text' className='form-control popup-form-control' id='lastName'
                                       placeholder='Last Name' name='lastName' onChange={this.handleInputChange}
                                       value={this.lastName}/>
                            </div>
                            <div className='form-group reduced-margins'>
                                <label htmlFor='authorities'>Authorities</label>
                                <Select options={this.authoritiesList} isMulti
                                        value={this.userRoles}
                                        onChange={this.handleSelectAuthoritiesChange} classNamePrefix='react-select'/>
                            </div>
                            <div className='form-group pt-2 border-top border-bottom pb-2 border-light'>
                                <div className='form-group reduced-margins py-2'>
                                    <label htmlFor='scope'>Scope X Region</label>
                                    <TagsInput
                                        value={this.isUpdateUser ? this.updateUserPerimeter : this.addUserPerimeter}
                                        onChange={this.getPerimeterValue}
                                        onRemoved={this.handleSuppressPerimeter}
                                        isEditOnRemove={true}/>
                                </div>
                                <div className='form-group reduced-margins'>
                                    <label htmlFor='scope'>Scope</label>
                                    <div className='react-dropdown-tree-select-create'>
                                        <TagsInput
                                            value={this.selectedScopeList}
                                            onRemoved={this.handleSuppressScope}/>
                                        <DropdownTreeSelect data={this.buList}
                                                            onChange={this.handleSelectBuChange}
                                                            texts={{placeholder: 'Select...'}}/>
                                    </div>
                                </div>
                                <div className='form-group reduced-margins'>
                                    <label htmlFor='region'>Region</label>
                                    <Select classNamePrefix='react-select' placeholder={'Select ...'}
                                            options={this.state.showWWRegion ? this.wwRegionList : this.regionList}
                                            value={this.selectedRegionList}
                                            onChange={this.handleSelectRegionChange}
                                            isDisabled={this.selectedScopeList.length == 0}/>
                                </div>
                                <Button className='btn add-user-button btn-danger'
                                        onClick={this.handleAddOrUpdatePerimeter}>Add</Button>
                                <Button className='btn secondary'
                                        onClick={this.clearPerimeter}>Clear</Button>
                            </div>
                            {this.isUpdateUser &&
                                <div className='form-group reduced-margins'>
                                    <label htmlFor='enable'>Enabled</label>
                                    <input className='ms-2' type="checkbox" checked={this.state.enabled}
                                           onChange={this.handleModeChange}/>
                                </div>}
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='secondary' onClick={this.toggle}>Cancel</Button>{' '}
                        <Button className='btn add-user-button btn-danger'
                                onClick={this.handleSubmit}>  {`${this.isUpdateUser ? "Update" : "Add"}`}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}