import {DataViewType} from "../../components/common/DataViewType";

export interface IRegion {
  value: number,
  code: string,
  name: string,
  title: string,
  dataView: DataViewType
}

export const RegionOptions: IRegion[] = [
  {
    value: 4,
    code: 'ww',
    name: 'Worldwide',
    title: '',
    dataView: DataViewType.FPV
  },
  {
    value: 1,
    code: 'euro',
    name: 'Europe',
    title: 'EUROPE',
    dataView: DataViewType.REGION
  },
  {
    value: 2,
    code: 'amer',
    name: 'America',
    title: 'AMERICA',
    dataView: DataViewType.REGION
  },
  {
    value: 3,
    code: 'asia',
    name: 'Asia',
    title: 'ASIA',
    dataView: DataViewType.REGION
  }
];

export const RegionOptionsWithMarkUk: IRegion[] = [
  ...RegionOptions,
  {
    value: 315,
    code: 'sglb',
    name: 'Sglb',
    title: 'SGLB',
    dataView: DataViewType.REGION
  },
  {
    value: 316,
    code: 'sgil',
    name: 'Sgil',
    title: 'SGIL',
    dataView: DataViewType.REGION
  }
];

export const GetRegionValueByCode = (code: string | undefined) => {
  const region: IRegion | undefined = code ? RegionOptionsWithMarkUk.find(e => e.code === code) : undefined;
  return region ? region.value : undefined;
};

export const GetRegionNameByCode = (code: string | undefined) => {
  const region: IRegion | undefined = code ? RegionOptions.find(e => e.code === code) : undefined;
  return region ? region.name : "";
};

export const GetRegionCodeByValue = (value: number | undefined) => {
  const region: IRegion | undefined = value ? RegionOptionsWithMarkUk.find(e => e.value === value) : undefined;
  return region ? region.code : "";
};

export const GetRegionTitleByValue = (value: number | undefined) => {
  const region: IRegion | undefined = value ? RegionOptionsWithMarkUk.find(e => e.value === value) : undefined;
  return region ? region.title : "";
};

export const DefaultRegionOption: IRegion= RegionOptions[0];