import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from 'reactstrap';
import {Accordion} from "../accordion/Accordion";
import {NBIReporting} from "../reporting/NBIReporting";
import {DataViewType} from "../DataViewType";
import {IRouteParams} from "../route/IRouteParams";
import UtilsService from "../../../services/utils/UtilsService";
import {TitleService} from "../../../services/event/TitleService";

import './NBIReportingGroup.scss'
import {GetDefaultPreference,} from "../../../services/SynthesisService";
import {first} from "rxjs/operators";
import {flatMap} from "rxjs/internal/operators";
import {of} from "rxjs";
import {useRouteContext} from "../../../context/RouteContext/RouteContext";
import {PreferenceType} from "../PreferenceType";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {MuiThemeProvider} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import {useViewport} from "../../../context/ViewportContext";
import {MuiTooltipTheme} from '../MuiTooltipTheme';
import {useUser} from "../../../context/UserContext/UserContext";
import {getReportingRangeDate} from "../../../services/CommonService";
import DateService from "../../../services/DateService";
import {rangeDateProperty} from "../reporting/CustomViewDefs";


interface INBIReportingGroupProps {
    params: IRouteParams;
}

interface INBIReportingGroupBindingParams {
    rangeStartDate?: string;
    disableRegionView: boolean
    fpvCallbackData?: any[];
    activePreference: PreferenceType,
    title: string
}

export const bindingDefaultValue: INBIReportingGroupBindingParams = {
    rangeStartDate: undefined,
    disableRegionView: false,
    fpvCallbackData: [],
    activePreference: PreferenceType.NONE,
    title: ''
}

export const NBIReportingGroup: React.FC<INBIReportingGroupProps> = ({params}) => {
    const [activeTab, setActiveTab] = useState(DataViewType.FPV);
    const [nbiReportingParams, setNbiReportingParams] = useState({} as any);
    const [nbiBindingParams] = useState(bindingDefaultValue);
    const [reportingUnitId, setReportingUnitId] = useState(NaN);
    const [title, setTitle] = useState("");
    const [columnDefs, setColumnDefs] = useState(undefined);
    const [columnDefsRegions, setColumnDefsRegions] = useState(undefined);
    const {isProformaAvailable} = useRouteContext();
    const {isSmallDevice} = useViewport();
    const [showMessage, setShowMessage] = useState(false);
    const [disableRegionMomentary, setDisableRegionMomentary] = useState(true);
    const date = params.date;
    const [firstYearDay, setFirstYearDay] = useState('');
    const [rangeStartDate, setRangeStartDate] = useState(undefined);
    const currentYear = new Date(date).getFullYear();
    const previousYear = new Date(date).getFullYear() - 1;
    const yearMinusTwo = new Date(date).getFullYear() - 2;
    const yearMinusThree = new Date(date).getFullYear() - 3;
    const {isEstimatesUser} = useUser();

    const toggle = (tab: DataViewType) => {
        const rangePicker = document.querySelector('.reporting-range-date');
        rangePicker?.setAttribute("style", `display : none !important`)
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const displayFPVTab = (): boolean => {
        return !isRegionView() && columnDefs !== undefined;
    };
    const displayRegionTab = (): boolean => {
        return !(isSubScope() && !params.region) && !displayRegionFpvTab() && columnDefs!==undefined;
    };

    const displayRegionFpvTab  = (): boolean => {
        return !!(params.subScope && params.region === 'ww') && columnDefs!==undefined;
    }

    const displayComfiTab = (): boolean => {
        // Only Worldwide GBIS and MARK can view axe COMFI and only for top level scope
        return UtilsService.isGbisOrMarkForWW(reportingUnitId) && !isRegionView() && !isSubScope() && columnDefs !== undefined;
    };

    const isRegionView = () => {
        return params.region && params.region !== 'ww';
    };

    const isSubScope = () => {
        return params.subScope;
    };
    /**
     * True only if is MARK or MARK UK
     */
    const hideEstimatesMessage = (): boolean => {
        return !((title.includes("MARK") && !title.includes('MARK SALES')) && isEstimatesUser)
    };

    /**
     * To Retrieve fpv x region data
     * region view is disabled until fpv data is loaded
     * @param val
     */
    function disableRegionView(val: boolean) {
        setDisableRegionMomentary(val);
    }

    useEffect(() => {
        const scope = params.scope;
        setReportingUnitId(parseInt(scope));
        setNbiReportingParams(params);
        nbiBindingParams.fpvCallbackData = []
    }, [params]);

    /**
     * Hide estimates family for MARK UK X Regions
     */
    function setColumnsForMarkUkRegions(pref: any) {
        if (UtilsService.isMarkUKOrChild(title) && pref != undefined) {
            pref = pref.filter((pref: { groupId: string; }) => pref.groupId != "estimates");
            setColumnDefsRegions(pref);
        }
    }

    useEffect(() => {
        if (rangeStartDate) {
            const localStartDate = DateService.formatDateWithPattern(Number(rangeStartDate), "YYYY-MM-DD")
            nbiBindingParams.rangeStartDate = localStartDate
            const rangePeriod = rangeDateProperty(date, localStartDate);
            if (rangePeriod) {
                const subscription = GetDefaultPreference(rangePeriod, parseInt(params.scope))
                    .pipe(
                        first(),
                        flatMap((result: any) => {
                            const preferenceJson = result ?? {};
                            return of(preferenceJson);
                        })
                    )
                    .subscribe((pref: any) => {
                        if (pref) {
                            setColumnDefs(pref);
                            setColumnsForMarkUkRegions(pref);
                        }
                    });
                return () => subscription.unsubscribe();
            }
        }
    }, [rangeStartDate]);

    useEffect(() => {
        const wrapper = document.querySelector('sg-date-picker');
        wrapper?.addEventListener('dateSelected', (e: Event) => handleSelectDate(e));
        return () => {
        };
    });
    const handleSelectDate = (event: any) => {
        setRangeStartDate(event?.detail?.date);
    }

    useEffect(() => {
        const subscription = TitleService.observable().subscribe(({title}) => {
            setTitle(title);
            bindingDefaultValue.title = title
        });
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        params.region !== 'ww' && UtilsService.isWorldWideByCode(params.userLocation ? params.userLocation : '')
            ? setActiveTab(DataViewType.REGION) : setActiveTab(DataViewType.FPV);

    }, [params.region, params.subScope]);

    /**
     * To retrieve sbu x ww x proforma available data
     * show and load FPV first
     */
    useEffect(() => {
        if (params.region === 'ww' && params.subScope) {
            setActiveTab(DataViewType.FPV)
        }
    }, [isProformaAvailable]);


    const FpvLoader = () => {
        return <div style={{height: '400px'}}>
            <div className="spinner" role="status">
            </div>
            <FormattedMessage id="loading.fpv.report"/>

        </div>;
    };

    useEffect(() => {
        const subscription = getReportingRangeDate(params.date, 10).subscribe(
            (rangeDate: any) => {
                if (rangeDate) {
                    nbiBindingParams.rangeStartDate = rangeDate?.startBDay
                    setRangeStartDate(DateService.convertDatePickerToString(rangeDate?.startBDay))
                    setFirstYearDay(DateService.convertDatePickerToString(rangeDate?.firstYearBDay))
                }
            }
        );
        return () => {
            subscription.unsubscribe();
        }
    }, [params.date]);
    return (
        <div className="reporting-group">
            <Accordion title={<FormattedMessage id="home.reporting" values={{title: `${title}`}}/>}
                       filter={
                           isSmallDevice ? '' :
                               <div className="d-flex flex-column flex-sm-row">
                                   <MuiThemeProvider theme={MuiTooltipTheme}>
                                       <Tooltip placement="left" title={<span>
                                                        <div className="container" hidden={!showMessage}>
                                                         <h6 className="under pb-1"> Family NBI Y ({currentYear}) and NBI Y-1 ({previousYear}) </h6>
                                                            <div className="customFont form-inline pb-2">
                                                                {<FormattedMessage id='reporting.periodData'
                                                                                   values={{currentYear: `${currentYear}`}}/>}
                                                            </div>
                                                            <h6 className="under pb-1"> Family NBI Y-2 ({yearMinusTwo}) and NBI Y-3 ({yearMinusThree}) </h6>
                                                            <div className="customFont form-inline pb-2">
                                                                {<FormattedMessage id='reporting.lastData' values={{
                                                                    yearMinusTwo: `${yearMinusTwo}`,
                                                                    yearMinusThree: `${yearMinusThree}`
                                                                }}/>}
                                                            </div>
                                                            <h6 className="under pb-1"> Family NBI Y ({currentYear}) </h6>
                                                            <div className="customFont form-inline pb-2">
                                                                {<FormattedMessage id='reporting.rangePeriod'/>}
                                                            </div>
                                                            <div hidden={hideEstimatesMessage()}>
                                                                   <h6 className="under pb-1">Family ESTIMATES</h6>
                                                                    <span style={{color: 'red'}}
                                                                          className="customFont form-inline pb-2">{
                                                                        <FormattedMessage
                                                                            id='reporting.estimates'/>}</span>
                                                                <div className="customFont form-inline pb-1">
                                                                    {<FormattedMessage id='reporting.estimates_ask'
                                                                                       values={{
                                                                                           currentYear: `${currentYear}`,
                                                                                           currentYearDigit: `${currentYear.toString().slice(-2)}`
                                                                                       }}/>}
                                                                </div>
                                                                <div className="customFont form-inline pb-2">
                                                                        {<FormattedMessage id='reporting.estimates_bid'
                                                                                           values={{currentYearDigit: `${currentYear.toString().slice(-2)}`}}/>}
                                                                </div>
                                                                <div className="customFont form-inline pb-2">
                                                                        {<FormattedMessage id='reporting.estimates_2'
                                                                                           values={{currentYearDigit: `${currentYear.toString().slice(-2)}`}}/>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>}>
                                           <IconButton className="btn-message"
                                                       onMouseEnter={() => setShowMessage(true)}
                                                       onMouseLeave={() => setShowMessage(false)}>
                                               <InfoOutlinedIcon style={{fill: '#ffff00'}}/>
                                           </IconButton>

                                       </Tooltip>
                                   </MuiThemeProvider>
                               </div>

                       }>
                {rangeStartDate && firstYearDay &&
                    <div className="align-items-center gap-2 reporting-range-date">
                        <label className="m-auto text-secondary text-nowrap mx-2">Start Date</label>
                        <sg-date-picker placeholder={rangeStartDate} date-format="dd-mmmm-yyyy"
                                        show-clear-button="false"
                                        display-date-format="DD MMMM YYYY"
                                        default-value={rangeStartDate}
                                        size="md"
                                        disable-presets={`weekends,after:${DateService.convertDatePickerToString(date)},before:${firstYearDay},exact:${DateService.convertDatePickerToString(date)}`}>
                        </sg-date-picker>
                    </div>
                }

                <div className="position-relative container-fluid pt-5 p-0">

                    <div className="nav position-absolute top-0 start-0">
                        <Nav tabs className="ms-2 nav-underline nav-hover gap-4">
                            {
                                displayFPVTab() && (
                                    <NavItem>
                                        <NavLink
                                            className={`${activeTab === DataViewType.FPV ? ' active' : ''}`}
                                            onClick={() => {
                                                toggle(DataViewType.FPV);
                                            }}>
                                            <FormattedMessage id="reporting.fpv"/>
                                        </NavLink>
                                    </NavItem>
                                )}
                            {
                                displayRegionTab() && (
                                    <NavItem>
                                        <NavLink
                                            className={`${activeTab === DataViewType.REGION ? 'active' : ''}`}
                                            onClick={() => {
                                                toggle(DataViewType.REGION);
                                            }}>
                                            <FormattedMessage id="reporting.region"/>
                                        </NavLink>
                                    </NavItem>

                                )}
                            {
                                displayRegionFpvTab() && (
                                    <NavItem>
                                         <NavLink
                                             className={`${activeTab === DataViewType.REGION ? 'active' : ''}`}
                                             onClick={() => {
                                                 toggle(DataViewType.REGION);
                                             }}>
                                             <FormattedMessage id="reporting.region"/>
                                         </NavLink>
                                    </NavItem>
                                )}
                            {
                                displayComfiTab() && (
                                    <NavItem>
                                        <NavLink
                                            className={`${activeTab === DataViewType.COMFI ? 'active' : ''}`}
                                            onClick={() => {
                                                toggle(DataViewType.COMFI);
                                            }}>
                                            <FormattedMessage id="reporting.comfi"/>
                                        </NavLink>
                                    </NavItem>
                                )}
                        </Nav>
                    </div>

                    <TabContent className="mt-3" activeTab={activeTab}>
                        {
                            displayFPVTab() && (
                                <TabPane tabId="FPV">
                                    <Row>
                                        <Col sm="12">
                                            <NBIReporting type={DataViewType.FPV} columnDefsDefault={columnDefs}
                                                          params={nbiReportingParams} bindingParams={nbiBindingParams}
                                                          activeTab={activeTab} disableRegionView={disableRegionView}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane >
                            )}
                        {
                            displayRegionTab() && (
                                <TabPane tabId="REGIONS">
                                    <Row>
                                        <Col sm="12">
                                            <NBIReporting type={DataViewType.REGION}
                                                          columnDefsDefault={columnDefsRegions ?? columnDefs}
                                                          params={nbiReportingParams} bindingParams={nbiBindingParams}
                                                          activeTab={activeTab} disableRegionView={disableRegionView}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                            )}
                        {displayRegionFpvTab() && (
                            <TabPane tabId="REGIONS">
                                <Row>
                                    <Col sm="12">
                                        {disableRegionMomentary && <FpvLoader/>}
                                        {!disableRegionMomentary &&
                                            <NBIReporting type={DataViewType.RegionsFPV}
                                                          columnDefsDefault={columnDefsRegions ?? columnDefs}
                                                          params={nbiReportingParams} bindingParams={nbiBindingParams}
                                                          activeTab={activeTab} disableRegionView={disableRegionView}

                                            />
                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                        )}
                        {
                            displayComfiTab() && (
                                <TabPane tabId="COMFI">
                                    <Row>
                                        <Col sm="12">
                                            <NBIReporting type={DataViewType.COMFI} columnDefsDefault={columnDefs}
                                                          params={nbiReportingParams} bindingParams={nbiBindingParams}
                                                          activeTab={activeTab} disableRegionView={disableRegionView}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                            )}
                    </TabContent>
                </div>
            </Accordion>
        </div>
    );
};

