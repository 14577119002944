import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { UploadFiles } from "../../../services/admin/FileUploadService";

export const UploadModal: React.FC<any> = ({ isOpen, toggle }) => {
    const [files, setFiles] = useState([]);
    const [results, setResults] = useState([]);

    useEffect(
        () => {
            setFiles([]);
            setResults([]);
        }, [isOpen]);


    const browseFiles = (event: any) => {
        event.preventDefault();
        setFiles(event.target.files);
    }

    const uploadFiles = () => {
        UploadFiles(files)
            .then(response => {
                setResults(response);
            })
            .catch(error => console.error(error));
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={toggle}><FormattedMessage id="admin.upload.modal.head" /></ModalHeader>
            <ModalBody>
                {results.length > 0 ?
                      results.map((result: any, index) => (
                        <div className={'alert ' + (result.error === true ? 'alert-danger' : 'alert-success')} key={index}>{
                            result.fileName} : {result.message}
                        </div>
                      ))
                    :
                    <div>
                        <input type="file"
                            name="files"
                            onChange={browseFiles}
                            multiple />
                    </div>
                }
            </ModalBody>
            <ModalFooter>
                <Button className="btn btn-white" onClick={uploadFiles}><FormattedMessage id="admin.upload.modal.send" /></Button>{' '}
                <Button className="btn btn-secondary" onClick={toggle}><FormattedMessage id="admin.upload.modal.close" /></Button>
            </ModalFooter>
        </Modal>
    );
}