import React, {CSSProperties, PropsWithChildren, useState} from "react";
import {FormattedMessage} from "react-intl";

import './YtdChartLegend.scss'

interface IYtdChartLegend extends PropsWithChildren<any> {
  scopeName: string;
  style?: CSSProperties;
  value?: number;
  legendSymbolClass?: string;
  legendSymbolStyle?: CSSProperties;
  scopeNameStyle?: CSSProperties;
  onClick?: Function;
}

export const YtdChartLegend: React.FC<IYtdChartLegend> = (props: IYtdChartLegend) => {
  const {children, scopeName, style, value, legendSymbolClass, legendSymbolStyle, scopeNameStyle, onClick} = props;
  const [status, setStatus] = useState(false);

  const handleClick = () => {
    setStatus(!status);
    onClick && onClick();
  };

  const isValidatedValue = (value: any) => {
    return !Number.isNaN(parseInt(value));
  };

  return (
    <div className={`ytd-chart-legend d-flex flex-column p-2 m-2 align-items-stretch ${status ? "activated" : ""}`}
         style={style}
         onClick={handleClick}>
      {
        status && (<div className="status">
          <i className="icon icon-sm">check_circle_outline</i>
        </div>)
      }

      <div className="legend-symbol">
        <div className={`symbol ${legendSymbolClass}`} style={legendSymbolStyle} />
      </div>

      <div className="header mt-1 text-secondary fw-semibold flex-grow-1">
          <span style={scopeNameStyle}>{scopeName}</span>
      </div>

      <div className="body mt-1">
        {
          isValidatedValue(value) && (
            <>
                <span className="display-4">{value}</span>
                <span className="text-light fw-semibold"> <FormattedMessage id='million-euro'/></span>
            </>
          )
        }
      </div>

        <div className="footer mt-1 text-light fw-semibold">
            {children}
        </div>
    </div>
  );
};