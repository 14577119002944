import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {AccountCenter} from './AccountCenter';
import {SGMLogo} from './SGMLogo';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavItem,
    NavLink,
    UncontrolledDropdown
} from 'reactstrap';
import {UploadModal} from './upload/UploadModal';
import {useHistory} from 'react-router-dom';
import {ClearCache, RefreshAsh} from "../../services/admin/AdminService";
import {first} from "rxjs/operators";
import {toast} from "react-toastify";
import {useUser} from "../../context/UserContext/UserContext";

import './Header.scss';

export const Header: React.FC = () => {
  const history = useHistory();
    const {isAdmin, isAdminWorldWide} = useUser();
    const logoMini = require('../../assets/images/favicon.ico');
  const [collapsed, setCollapsed] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    const toggleUploadModal = () => {
        setUploadModal(!uploadModal);
    };

    const handleAccountsManagementClick = () => {
        history.push('/admin/accountsManagement');
    };

    const handleDeleteClick = () => {
        history.push('/admin/deleteDailyData');
    };

    const handleCacheManagement = () => {
        history.push('/admin/cacheManagement');
    };

    const clearCacheClick = () => {
        const toastId = toast.info('Clear cache in progress', {autoClose: false});
        ClearCache()
            .pipe(first())
            .subscribe(
                () => toast.update(toastId, {
                    render: 'Cache cleared successfully',
                    type: toast.TYPE.SUCCESS, autoClose: 3000
                }),
                () => toast.update(toastId, {
                        render: 'Failed to clear cache',
                        type: toast.TYPE.ERROR, autoClose: 3000
                    }
                )
            );
    };

    const clearNavigatorCacheClick = () => {
      if('caches' in window){
          caches.keys().then((names) => {
              names.forEach(async (name) => {
                  await caches.delete(name);
              })
              window.location.reload();
          })
      }
  };

  const reloadWindow = () => {
      window.location.reload();
  };


  const refreshAsClick = () => {
      const toastId = toast.info('Refresh AS in progress', {autoClose: false});
      RefreshAsh()
          .pipe(first())
          .subscribe(
              () => toast.update(toastId, {
                  render: 'AS Refreshed successfully',
                  type: toast.TYPE.SUCCESS, autoClose: 3000
              }),
              () => toast.update(toastId, {
                      render: 'Failed to refresh AS',
                      type: toast.TYPE.ERROR, autoClose: 3000
                  }
              )
          );
  };

  return (
    <header className="navbar navbar-expand-xl ps-md-4 pe-md-4">
        <div className="d-flex">
            <a href="/" className="navbar-brand d-flex align-items-center">
                <img src={logoMini} alt={`<FormattedMessage id="title" />`}/>
            </a>
        </div>

        <div className="d-flex align-items-center order-xl-1">
            <AccountCenter/>
            <SGMLogo/>
        </div>

      <div className="collapse navbar-collapse justify-content-end"
        id="navbar-default">
        <ul className="navbar-nav me-xl-auto">
            <li className="nav-item dropdown">
                <Navbar className="p-0" expand="md">
                    <Nav className="me-auto" navbar>
                        <NavItem>
                            <NavLink active href="/"><FormattedMessage id="menu.home"/></NavLink>
                        </NavItem>
                        {isAdmin &&
                            <UncontrolledDropdown setActiveFromChild nav inNavbar
                                                  className="d-none d-md-block dropdown nav-item">
                                <DropdownToggle className="nav-link justify-content-start fw-bolder" nav caret>
                                    <FormattedMessage id="menu.admin"/>
                                </DropdownToggle>
                                <UploadModal isOpen={uploadModal} toggle={toggleUploadModal}/>
                                <DropdownMenu>
                                    <DropdownItem onClick={toggleUploadModal}>
                                        <em className="icon icon-sm me-1">file_upload</em>
                                        <FormattedMessage id="admin.upload"/>
                                    </DropdownItem>
                                    <DropdownItem onClick={handleDeleteClick}>
                                        <em className="icon icon-sm me-1">delete</em>
                                        <FormattedMessage id="admin.delete.head"/>
                                    </DropdownItem>
                                    <DropdownItem divider/>
                                    <DropdownItem onClick={handleAccountsManagementClick}>
                            <em className="icon icon-sm me-1">supervisor_account</em>
                            <FormattedMessage id="admin.accounts.management"/>
                        </DropdownItem>
                        <DropdownItem hidden={!isAdminWorldWide} onClick={handleCacheManagement}>
                            <em className="icon icon-sm me-1">upload</em>
                            <FormattedMessage id="admin.cache.head"/>
                        </DropdownItem>
                        <DropdownItem onClick={clearCacheClick}>
                            <em className="icon icon-sm me-1">cached</em>
                            <FormattedMessage id="admin.clear.cache"/>
                        </DropdownItem>
                        <DropdownItem onClick={refreshAsClick}>
                            <em className="icon icon-sm me-1">cached</em>
                            <FormattedMessage id="admin.refresh.as"/>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>}
              </Nav>
            </Navbar>
          </li>
        </ul>
      </div>
    </header>
  );
};
