import moment from "moment";

export default class DateService {
  static getFullYearFromDate(dateString: string) {
    return new Date(dateString).getFullYear();
  }

  public static formatDateWithPattern(dateString: string | number, format: string = "DD MMM YYYY") {
    if (dateString) {
      const date = new Date(dateString);
      return moment(date).format(format)
    }
    return '';
  }

  public static convertDatePickerToString(dateString: any) {
    if (dateString) {
      return new Date(dateString).getTime().toString()
    }
    return dateString;
  }
}
