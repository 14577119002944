import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {IRouteParams} from '../route/IRouteParams';
import {Accordion} from '../accordion/Accordion';
import {TitleService} from "../../../services/event/TitleService";
import {OverviewWidgetType} from "../OverviewWidgetType";
import UtilsService from "../../../services/utils/UtilsService";
import {NBIOverview} from "../overview/NBIOverview";
import {RiskWidget} from "../riskWidget/RiskWidget";
import {GetRiskData} from "../../../services/RiskService";
import {GetRegionValueByCode} from "../../../services/region/RegionService";
import {FetchNBIData} from "../../../services/SynthesisService";
import {useRouteContext} from "../../../context/RouteContext/RouteContext";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import './RiskAndPnlNbiWidget.css';

import {MuiThemeProvider} from "@material-ui/core/styles";
import {useViewport} from "../../../context/ViewportContext";
import {MuiTooltipTheme} from '../MuiTooltipTheme';


interface IRiskWidgetProps {
    params: IRouteParams;
}


export const PnlAndRiskWidget: React.FC<IRiskWidgetProps> = ({params}) => {
    const [showRiskWidget, setShowRiskWidget] = useState(false);
    const [showPnlNbiWidget, setShowPnlNbiWidget] = useState(true);
    const [title, setTitle] = useState("");
    const [activeTab, setActiveTab] = useState(OverviewWidgetType.NBI);
    const [showRiskLegend, setShowRiskLegend] = useState(false);
    const [currentYear, setCurrentYear] = useState({});
    const [previousYear, setPreviousYear] = useState({});
    const [riskData, setRiskData] = useState({});
    const [nbiData, setNbiData] = useState({});
    const [indicatorName, setIndicatorName] = useState("");
    const {isProformaAvailable} = useRouteContext();
    const {isSmallDevice, isLargeDevice} = useViewport();

    useEffect(() => {
        const date = params.date;
        const reportingUnitId = parseInt(params.scope);
        const subReportingUnitId: number | undefined = params.subScope ? parseInt(params.subScope) : reportingUnitId;
        const subscription = GetRiskData(date, reportingUnitId, subReportingUnitId)
            .subscribe(response => {
                if (response) {
                    setRiskData(response);
                }
            });
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        const date = params.date;
        const currentYear = new Date(date).getFullYear();
        const previousYear = currentYear - 1;
        setCurrentYear(currentYear);
        setPreviousYear(previousYear);
        const reportingUnitId = parseInt(params.scope);
        const subReportingUnitId: number | undefined = params.subScope ? parseInt(params.subScope) : reportingUnitId;
        const regionCode: string | undefined = params.region;
        const regionId: number | undefined = GetRegionValueByCode(regionCode);
        const importType: string = isProformaAvailable ? "STABILIZED" : "PUBLISHED";
        const subscription = FetchNBIData(date, reportingUnitId, subReportingUnitId, regionId, importType, indicatorName)
            .subscribe(response => {
                    if (response) {
                        setNbiData(response);
                        setIndicatorName(response.indicatorName);
                    }
                },
                () => setNbiData(false));
        return () => subscription.unsubscribe();
    }, [params, isProformaAvailable, currentYear]);


    const toggle = (tab: OverviewWidgetType) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            if (tab === OverviewWidgetType.RISK) {
                setShowPnlNbiWidget(false);
                setShowRiskLegend(true);
            } else if (tab === OverviewWidgetType.NBI) {
                setShowPnlNbiWidget(true);
                setShowRiskLegend(false);
            }
        }
    };

    const canDisplayRiskWidget = () => {
        return (UtilsService.isGbisOrMark(parseInt(params.scope))
            && params.subScope === undefined
            && params.region === 'ww'
            && UtilsService.isWorldWideByCode(params.userLocation));
    }

    const canDisplayRiskLegend = () => {
        return (UtilsService.isGbisOrMark(parseInt(params.scope))
            && activeTab === OverviewWidgetType.RISK
            && params.subScope === undefined
            && params.region === 'ww'
            && UtilsService.isWorldWideByCode(params.userLocation));
    }

    useEffect(() => {
        const subscription = TitleService.observable().subscribe(({title}) => {
            setTitle(title);
        });
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        setShowRiskWidget(canDisplayRiskWidget());
        setShowRiskLegend(canDisplayRiskLegend());
        if (activeTab === OverviewWidgetType.RISK && params.region !== 'ww') {
            setActiveTab(OverviewWidgetType.NBI);
            setShowPnlNbiWidget(true);
            setShowRiskWidget(false);
        }
    }, [params]);

    return (
        <div className='overview'>
            <Accordion title={<FormattedMessage id='risk.home' values={{title}}/>} openByDefault={true}
                       filter={
                           isSmallDevice ? '' :
                               showRiskLegend ?
                                   <div className="d-flex flex-column flex-sm-row">
                                       <MuiThemeProvider theme={MuiTooltipTheme}>
                                           <Tooltip  placement="left-start" title={ <span>
                                       <div className="d-inline-flex customFont">
                                       <div>Ratio between metrics move and the limit value :</div>
                                       <ul className="legend mb-0 ">
                                           <li className=""><span
                                               className="superawesome"></span> &le; 2%</li>
                                           <li className=""><span
                                               className="awesome"></span> 2%&le; x &le; 10%</li>
                                           <li className=""><span
                                               className="notawesome"></span> &gt; 10%</li>
                                       </ul>
                                       </div></span>}>
                                               <IconButton><InfoOutlinedIcon style={{fill: '#ffff00'}}/> </IconButton>
                                           </Tooltip>
                                       </MuiThemeProvider>
                                   </div>

                                   :
                                   <div className="d-flex flex-column flex-sm-row">
                                       <MuiThemeProvider theme={MuiTooltipTheme}>
                                           <Tooltip placement="left" title={ <span>
                                       <div className="container">
                                       <h6 className="under"> Budget comparison </h6>
                                           <div className="customFont pb-2">Ratio between NBI and the budget :
                                           <ul className="legend  mb-1">
                                           <li className="d-flex align-items-center"><span
                                               className="superawesome mb-0"></span> <div
                                               className=" mb-0 pt-1">&gt; Budget</div>  </li>
                                           <li className="d-flex align-items-center"><span
                                               className="notawesome mb-0"></span> <div
                                               className=" mb-0 pt-1"> 0&le; x &le; Budget </div></li>
                                           <li className="d-flex align-items-center"><span
                                               className="notawesomeAtAll mb-0"></span> <div
                                               className=" mb-0 pt-1">&lt; 0</div> </li>
                                           </ul>
                                           </div>
                                       <h6 className="under pb-1"> Y-1 comparison </h6>
                                           <div className="customFont pb-2">
                                               <div className="customFont">
                                                   Values Y-1 observed at the same YTD opened day of {currentYear}.
                                               </div>
                                               <div className="form-inline">
                                                    Ratio between NBI {currentYear} and NBI {previousYear} :
                                                   <ul className="legend mb-1 ">
                                                   <li className=" mb-0 d-flex align-items-center"><span
                                                       className="superawesome mb-0"></span> <div
                                                       className=" mb-0 pt-1">&gt; Y-1</div></li>
                                                   <li className=" mb-0 d-flex align-items-center"><span
                                                       className="notawesome mb-0"></span> <div
                                                       className=" mb-0 pt-1">&lt; Y-1</div></li>
                                                   </ul>
                                               </div>
                                           </div>
                                           <h6 className="under pb-1"> DAY Y-1 comparison </h6>
                                           <div
                                               className="customFont d-flex align-items-center pb-2">Comparison of the {currentYear} Daily PnL vs an average of {previousYear} published Daily PnL in the same quarter for the same opened days.
                                           </div>
                                           <h6 className="under pb-1"> WTD Y-1 comparison </h6>
                                           <div className="customFont d-flex align-items-center pb-2">
                                               <FormattedMessage id='home.charts.wtd' values={{
                                                   currentYear: `${currentYear}`,
                                                   previousYear: `${previousYear}`
                                               }}/>
                                           </div>
                                       </div>
                               </span>}>
                                               <IconButton><InfoOutlinedIcon style={{fill: '#ffff00'}}/> </IconButton>
                                           </Tooltip>
                                       </MuiThemeProvider>
                                   </div>
                       }>
                {
                    showRiskWidget ?
                        <ul className="nav nav-tabs mb-4">
                            <li className="nav-item" style={{cursor: "pointer"}}>
                                <a className={`${activeTab === OverviewWidgetType.NBI ? 'nav-link active' : 'nav-link'}`}
                                   onClick={() => {
                                       toggle(OverviewWidgetType.NBI);
                                   }}
                                >NBI - {indicatorName}</a>
                            </li>
                            <li className="nav-item" style={{cursor: "pointer"}}>
                                <a className={`${activeTab === OverviewWidgetType.RISK ? 'nav-link active' : 'nav-link'}`}
                                   onClick={() => {
                                       toggle(OverviewWidgetType.RISK);
                                   }}
                                >RISK</a>
                            </li>
                        </ul>
                        :
                        <ul className="nav nav-tabs mb-4">
                            <li className="nav-item" style={{cursor: "pointer"}}>
                                <a className={`${activeTab === OverviewWidgetType.NBI ? 'nav-link active' : 'nav-link'}`}
                                   onClick={() => {
                                       toggle(OverviewWidgetType.NBI);
                                   }}
                                >NBI - {indicatorName}</a>
                            </li>
                        </ul>
                }
                {
                    showPnlNbiWidget ? <NBIOverview nbiData={nbiData}/> : <RiskWidget data={riskData} params={params}/>
                }
            </Accordion>
        </div>
    );
}