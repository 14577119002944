import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Form, FormGroup, Input, Label} from "reactstrap";
import {Accordion} from "../accordion/Accordion";
import {FetchMiniHeatMapData} from "../../../services/SynthesisService";
import {MINI_HEATMAP_COMPARISON_TYPE, PERIOD_TYPE} from "../Constants";
import {ColumnChart} from "../columnChart/ColumnChart";
import {IRouteParams} from "../route/IRouteParams";
import DateService from "../../../services/DateService";
import {useRouteContext} from "../../../context/RouteContext/RouteContext";
import {GetRegionTitleByValue, GetRegionValueByCode} from "../../../services/region/RegionService";
import {useParams} from "react-router-dom";
import {Loader} from "../Loader";
import {ConnexionError} from "../ConnexionError";
import {TitleService} from "../../../services/event/TitleService";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {MuiTooltipTheme} from "../MuiTooltipTheme";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";


export const NbiComparisonInTime: React.FC<any> = () => {
  const intl = useIntl();
  const [miniHeatmapData, setMiniHeatmapData] = useState([] as any);
  const [period, changePeriod] = useState(PERIOD_TYPE.WTD);
  const [comparisonType, changeComparisonType] = useState(MINI_HEATMAP_COMPARISON_TYPE.BUDGET);
  const currentYear = new Date().getFullYear();
  const [yearSelected, setYearSelected] = useState(currentYear);
  const [previousYear, setPreviousYear] = useState(currentYear - 1);
  const [isLoading, setIsLoading] = useState(false);
  const [isConnexionFailed, setIsConnexionFailed] = useState(false);
  const routeParams: IRouteParams = useParams<IRouteParams>();
  const {isProformaAvailable} = useRouteContext();
  const [title,setTitle] = useState("");


  const monthSelected:any = new Date(routeParams.date).getMonth();
  const {detailPageScope} = useRouteContext();
  const {parentName} = useRouteContext();

  const periodOptions: Array<any> = [
    {
      value: PERIOD_TYPE.WTD,
      label: intl.formatMessage({id: 'mini-heatmap.wtd'})
    },
    {
      value: PERIOD_TYPE.MTD,
      label: intl.formatMessage({id: 'mini-heatmap.mtd'})
    },
    {
      value: PERIOD_TYPE.QTD,
      label: intl.formatMessage({id: 'mini-heatmap.qtd'})
    },
    {
      value: PERIOD_TYPE.YTD,
      label: intl.formatMessage({id: 'mini-heatmap.ytd'})
    }
  ];
  const constructComparisonTypeOptions = (year: number, previousYear: number) => {
    return [
      {
        value: MINI_HEATMAP_COMPARISON_TYPE.SAME_PERIOD,
        label: previousYear
      },
      {
        value: MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD,
        label: year + ` (${periodOptions.find(e => period === e.value).label} -1)`
      },
      {
        value: MINI_HEATMAP_COMPARISON_TYPE.BUDGET,
        label: `${intl.formatMessage({id: 'budget'})}${year}`
      }
    ];
  };

  const [comparisonTypeOptions, setComparisonTypeOptions] = useState(constructComparisonTypeOptions(yearSelected, previousYear));

  const handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const targetPeriod = e.target.value;
    changePeriod(targetPeriod);
    if (targetPeriod === PERIOD_TYPE.YTD) {
      const index = comparisonTypeOptions.findIndex(e => e.value === MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD);
      if (index > -1) {
        comparisonTypeOptions.splice(index, 1);
        if (comparisonType === MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD) {
          changeComparisonType(MINI_HEATMAP_COMPARISON_TYPE.BUDGET);
        }
      }
    }
    else {
      setComparisonTypeOptions([
        {
          value: MINI_HEATMAP_COMPARISON_TYPE.SAME_PERIOD,
          label: previousYear
        },
        {
          value: MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD,
          label: yearSelected + ` (${periodOptions.find(e => targetPeriod === e.value).label} - 1)`
        },
        {
          value: MINI_HEATMAP_COMPARISON_TYPE.BUDGET,
          label: `${intl.formatMessage({id: 'budget'})} ${yearSelected}`
        }
      ]);
    }
  };

  const initDate = (date: string) => {
    const year = DateService.getFullYearFromDate(date);
    const previousYear = year - 1;
    setYearSelected(year);
    setPreviousYear(previousYear);
    setComparisonTypeOptions(constructComparisonTypeOptions(year, previousYear));
  };

  useEffect(() => {
    const date = routeParams.date;
    const reportingUnitId = routeParams.scope;
    const subReportingUnitId = routeParams.subScope ? routeParams.subScope : reportingUnitId;
    const indicatorId = routeParams.indicatorId ? parseInt(routeParams.indicatorId) : undefined;
    initDate(date);
    setIsLoading(true);
    const importType: string = isProformaAvailable ? "STABILIZED" : "PUBLISHED";
    const regionId = GetRegionValueByCode(routeParams.region);
    const subscription = FetchMiniHeatMapData(date, parseInt(reportingUnitId), regionId,
        importType, parseInt(subReportingUnitId), indicatorId).subscribe(
      data => {
        if (data) {
          setMiniHeatmapData(data)
        }
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
        setIsConnexionFailed(true);
      }
    );
    return () => subscription.unsubscribe();
  }, [routeParams, period, isProformaAvailable]);

  const getTitleFromOtherWidget = (title: String, regionId: number | undefined) => {
    let finalParentName =  detailPageScope===parentName? "":  parentName+" | ";
    //when detailPageScope is regionName, get title from other widget, remove the last 2 words to get ScopeName
    const scopeName =  title.toString().split(' ').slice(0, -1).join(' ');
    let finalTitle = "";
    if(GetRegionTitleByValue(regionId)===detailPageScope){
      finalTitle = scopeName + " " + detailPageScope;
    }else if(routeParams.region === "ww"){
      finalTitle = finalParentName + detailPageScope;
    }else{
      finalTitle = finalParentName + detailPageScope+ " " + GetRegionTitleByValue(regionId);
    }
    setTitle(finalTitle) ;
  }

  useEffect(()=>{
    const regionId = GetRegionValueByCode(routeParams.region);
    const subscription = TitleService.observable().subscribe(({title}) => {
      getTitleFromOtherWidget(title, regionId);
    });
    return () => subscription.unsubscribe();
  },[])

  const LocalLoader = () => {
    return <Loader/>;
  };

  const Error = () => {
    return <ConnexionError />;
  };

  return (
    <Accordion title={<FormattedMessage id="detail.comparison" values={{title: `${title}`}}/>} filter={
      <Form className="d-flex">
        <FormGroup className="d-flex align-items-center mb-0" >
          <Label for="typeOptions" className="m-auto text-secondary fw-bolder me-2">
            <FormattedMessage id="comparison-with"/>
          </Label>
          <Col>
            <Input className="form-select" type="select" name="select" id="typeOptions" value={comparisonType}
                   onChange={e => changeComparisonType(e.target.value)}>
              {comparisonTypeOptions.map(e => <option key={e.value} value={e.value}>{e.label}</option>)}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup className="d-flex align-items-center mb-0" >
          <Label for="periodOptions" className="m-auto text-secondary fw-bolder mx-2">
            <FormattedMessage id="period"/>
          </Label>
          <Col>
            <Input className="form-select" type="select" name="select" id="periodOptions" value={period}
                   onChange={e => handlePeriodChange(e)}>
              {periodOptions.map(e => <option key={e.value} value={e.value}>{e.label}</option>)}
            </Input>
          </Col>
        </FormGroup>
        <div className="d-flex flex-column flex-sm-row">
          <MuiThemeProvider theme={MuiTooltipTheme}>
            <Tooltip placement="top" title={<span>
                                            <div className="container">
                                                <h6 className="under pb-1"> WTD Y-1 comparison </h6>
                                                <div className="customFont form-inline pb-2">
                                                    <FormattedMessage id='charts.comparison.wtd' values={{
                                                        currentYear: `${yearSelected}`,
                                                        previousYear: `${previousYear}`
                                                    }}/>
                                                </div>
                                            </div>
                                        </span>
            } >
              <IconButton>
                <InfoOutlinedIcon style={{fill: '#ffff00'}}/>
              </IconButton>
            </Tooltip>
          </MuiThemeProvider>
        </div>

      </Form>
    }>
      {(() => {
        if (isLoading) {
          return <LocalLoader />;
        } else if (isConnexionFailed) {
          return <Error />;
        } else {
          return (
            <div className="container-fluid ">
              <div className="row">
                {
                  miniHeatmapData.length > 0 &&
                  <ColumnChart data={miniHeatmapData} period={period} comparisonType={comparisonType} date={monthSelected}></ColumnChart>
                }
              </div>
            </div>
          );
        }
      })()}
    </Accordion>
  );
};
