import React, {useEffect, useState} from "react";
import './HeatmapSVG.scss';
import * as XLSX from 'xlsx';
import {GetRegionNameByCode} from "../../../services/region/RegionService";

interface DatePnlVariation {
    date: any;
    pnl: number;
    variation: number;
}

export const HeatmapExtractModule: React.FC<any> = (props) => {
    const [data, setData] = useState<DatePnlVariation[]>([]);
    const [comparisonValue, setComparisonValue] = useState<string>(props.comparisonDefaultValue);
    const [periodValue, setPeriodValue] = useState<string>(props.periodDefaultValue);

    useEffect(() => {
        createData()
    }, [props.datePnl, props.dateVariation, props.periodDefaultValue, props.comparisonDefaultValue])

    const createData = () => {

        const dataList: DatePnlVariation[] = [];
        const datePnl = props.datePnl
        for (const date in datePnl) {
            if (datePnl.hasOwnProperty(date)) {
                const pnl = datePnl[date];
                const variation = props.dateVariation[date];
                dataList.push({date, pnl, variation});
            }
        }
        const comparisonValue = (document.getElementById('heatmap-comparison') as HTMLInputElement)?.value
        const methodValue = (document.getElementById('heatmap-method') as HTMLInputElement)?.value
        setComparisonValue(("Comparison (".concat(comparisonValue.concat(")")).concat(" - ").concat(methodValue)))
        const periodValue = (document.getElementById('heatmap-period') as HTMLInputElement)?.value
        setPeriodValue("NBI (".concat(periodValue).concat(")"))
        setData(dataList);
    };

    const exportDataAsExcel = (): void => {
        if (data) {
            const region = GetRegionNameByCode(props.region).toUpperCase();
            const parts = props.title.split('|');
            const title = parts[1] ? parts[1].trim().split(' ').slice(0, 2).join(' ') : props.title;
            const fileTitle = ('NBI'.concat('_').concat(title).concat('_').concat(region)).substring(0, 30);
            const sheetTitle = (title.concat('_').concat(region)).substring(0, 30);
            const updatedData = updateColumnsName();
            const worksheet = XLSX.utils.json_to_sheet(updatedData);
            const workbook = XLSX.utils.book_new();
            const dateColumn = "A";
            for (let i = 2; i <= data.length + 1; i++) {
                //date format (jj/mm/aaaa)
                worksheet[`${dateColumn}${i}`].z = XLSX.SSF._table[14]
            }
            worksheet['!cols'] = [{width: 20}, {width: 20}, {width: 20}];
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetTitle);
            XLSX.writeFile(workbook, fileTitle + '.xlsx');
        }
    };

    const updateColumnsName = (): any => {
        return data.map(row => {
            return {
                'Date': new Date(row.date),
                [periodValue]: row.pnl,
                [comparisonValue]: row.variation
            };
        });
    }

    return (
        <div className="extract-heatmap">
            <button className="btn btn-flat" onClick={exportDataAsExcel}>
                <i className="icon icon-small">save_alt</i>
            </button>
        </div>
    );
}