import React, {FunctionComponent} from 'react';
import './DatePicker.scss'

interface DateSelectorProps {
  onDateChanged: Function;
  initDate: String;
}

const DatePicker: FunctionComponent<DateSelectorProps> = ({onDateChanged, initDate}) => {
  const datetimePicker = React.useRef<any>(null);
  const getDateFromDatePicker = React.useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      const value = event.detail.value;
      onDateChanged(value);
    },
    [onDateChanged],
  );

  React.useLayoutEffect(() => {
    const currentPicker = datetimePicker.current;
    if (currentPicker) {
      currentPicker.addEventListener('sgwt-datetime-picker--datetime-updated', getDateFromDatePicker, false);
    }
    return () => {
      if (currentPicker) {
        currentPicker.removeEventListener('sgwt-datetime-picker--datetime-updated', getDateFromDatePicker, false);
      }
    };
  }, [getDateFromDatePicker]);
  return (
    <div className="align">
      <sgwt-datetime-picker
        id="date-picker"
        ref={datetimePicker}
        date-format="YYYY-MM-DD"
        display-date-format="DD MMMM YYYY"
        type="date"
        value={initDate}
      />
    </div>
  );
};
export default DatePicker;