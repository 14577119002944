export interface EnvVars extends HTMLElement {
    REACT_APP_API_ENDPOINT: any;
    REACT_APP_AG_GRID_LICENSE_KEY: string;
    REACT_APP_CONTACT_MAIL: any;
    REACT_APP_MATOMO_SERVER: any;
    REACT_APP_SG_CONNECT_REDIRECT_URI: any;
    REACT_APP_API_HTTP_CALL_CACHE_DURATION: any;
}

export const getEnvVar = (key: string) => {
    const keys = document.querySelector<EnvVars>('env-vars');
    return process.env[key.toUpperCase()] ?? varFromXlDDictionary(keys, key);
}
const varFromXlDDictionary = (keys: EnvVars | null, key: string) => {
    if (keys != null) {
        const keyValue = keys.getAttribute(key);
        if (keyValue != null) {
            return keyValue;
        }
    }
}