import React from "react";

export const PaginationComponent: React.FC<any> = ({ pageCount, currentPage, onPageChanged }) => {
 
  const handleMoveLeft = (evt: any) => {
    evt.preventDefault();
    const newPage = Math.max(0, Math.min(currentPage - 1, pageCount - 1))
    onPageChanged(newPage);
  };

  const handleMoveRight = (evt: any) => {
    evt.preventDefault();
    const newPage = Math.max(0, Math.min(currentPage + 1, pageCount - 1));
    onPageChanged(newPage);
  };
  
  const handleClick = (page: any) => {
    const newPage = Math.max(0, Math.min(page, pageCount - 1));
    onPageChanged(newPage);
  };

  return (
    <header aria-label="Page navigation">
      <ul className="pagination">
        {currentPage !== 0 &&
          <li className="page-item">
            <a className="page-link" onClick={handleMoveLeft}>
              <em className="icon">keyboard_arrow_left</em>
            </a>
          </li>
        }
        {pageCount > 0 && Array.from(Array(pageCount).keys()).map((page: number) => {
          return (
            <li
              key={page}
              className={
                currentPage === page
                    ? "custom-disabled active page-item ms-2"
                    : "page-item ms-2"
              }
            >
              <a
                className="page-link"
                href="#!"
                onClick={(evt) => {
                  handleClick(page);
                  evt.preventDefault();

                }}
              >
                {page + 1}
              </a>
            </li>
          );
        })}
        {currentPage !== pageCount - 1 &&
            <li className="page-item ms-2">
                <a className="page-link" onClick={handleMoveRight}><em className="icon">keyboard_arrow_right</em>
                </a>
            </li>
        }
      </ul>
    </header>
  );
}