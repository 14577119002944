import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './components/app/App';
import {SgwtConnectHTMLElement} from './sgwt-widgets';
import {getEnvVar} from './env-vars-utils';
// Load SG Bootstrap v5 CSS
import '@sg-bootstrap/icons/dist/index.css';
import '@sg-bootstrap/core/scss/sg-bootstrap-dark.scss';

// Auto-login with SG Connect, using the <sgwt-connect> widget.0

const widget = document.querySelector<SgwtConnectHTMLElement>('sgwt-connect');

if (widget === null) {
    document.body.innerHTML = `
      <div class="alert alert-danger" role="alert">
        No widget found to handle the authentication on this application!
      </div>`;
} else if (widget.sgwtConnect) {
    // Widget is already initialized, so can make the SG Connect login...
    login();
} else {
    // Widget is not yet initialized, we need to wait for the "ready" event.
    widget.addEventListener('sgwt-connect--ready', login);
}

function login() {
    if (!widget) {
        document.body.innerHTML = `
      <div class="alert alert-danger" role="alert">
        No widget found to handle the authentication on this application!
      </div>`;
        return;
    }
    const sgwtConnect = widget.sgwtConnect;
    widget.removeEventListener('sgwt-connect--ready', login);
    if (sgwtConnect.getAuthorizationError()) {
        // Case #1: an error occurred during the retrieval of the SG Connect token...
        document.body.innerHTML = `
      <div class="alert alert-danger" role="alert">
        Authorization error: ${sgwtConnect.getAuthorizationError()}.
      </div>`;
    } else if (sgwtConnect.isAuthorized()) {
        // Case #2: the user is authenticated, we load the application
        getUserInfo(sgwtConnect.getAuthorizationHeader());
    } else {
        // Case #3: the user is not authenticated, we request an authorization...
        sgwtConnect.requestAuthorization();
    }
}

function getUserInfo(token: any) {
    const http = new XMLHttpRequest();
    http.open('GET', getEnvVar('react_app_api_endpoint') + '/user/current');
    http.setRequestHeader('Content-Type', 'application/json');
    http.setRequestHeader('Authorization', token);
    http.send();
    http.onreadystatechange = () => {
        if (http.status === 401) {
            document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            window.location.href = getEnvVar('react_app_unauthorized_url') as any;
        } else if (http.readyState === XMLHttpRequest.DONE && http.status === 200) {
            ReactDOM.render(<App/>, document.getElementById('root'));
        }
    };
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
