import React, {useEffect, useRef, useState} from 'react';
import {AgGridReact} from 'ag-grid-react';
import {ColumnDefs, DefaultColumnDef} from '../../../common/accountsManagement/AccountsGridDefinition';
import {FormattedMessage} from 'react-intl';
import {GetBusinessUnit, GetUsersAccounts, UpdateUser} from '../../../../services/admin/AccountsManagementService';
import {BusinessUnitsCellRenderer} from '../../../common/accountsManagement/cellRenderers/BusinessUnitsCellRenderer';
import {EnableAccountCellRenderer} from '../../../common/accountsManagement/cellRenderers/EnableAccountCellRenderer';
import {RolesCellEditor} from '../../../common/accountsManagement/cellRenderers/RolesCellEditor';
import {PerimetersCellEditor} from '../../../common/accountsManagement/cellRenderers/PerimetersCellEditor';
import {AuthoritiesCellRenderer} from '../../../common/accountsManagement/cellRenderers/AuthoritiesCellRenderer';
import {GridReadyEvent} from 'ag-grid-community/dist/lib/events';
import {UserManager} from '../../../common/accountsManagement/createUser/UserManager';
import {ColumnApi, GridApi} from 'ag-grid-community';
import './AccountsManagement.scss';

export const AccountsManagementGrid: React.FC = () => {


    const gridApi = useRef<GridApi>();
    const gridColumnApi = useRef<ColumnApi>();
    const [rowData, setRowData] = useState(undefined);
    const [columnDef, setColumnDefData] = useState(undefined);
    const [buList, setBuList] = useState(undefined);

    const gridOptions = {
        headerHeight: 32,
        rowHeight: 40,
        pagination: true,
        paginationPageSize : 10,
        domLayout: 'autoHeight',
        suppressHorizontalScroll: true
    };


    const onRowEditingStarted = (params: any) => {
        params.api.refreshCells({
            columns: ['action'],
            rowNodes: [params.node],
            force: true

        });
    };

    const onRowEditingStopped = (params: any) => {
        params.api.refreshCells({
            columns: ['action'],
            rowNodes: [params.node],
            force: true
        });
    };

    useEffect(() => {
        document.addEventListener('callLoadGridRowsEvent', loadGridRows);
        return () => {
            window.removeEventListener('callLoadGridRowsEvent', loadGridRows);
        }
    }, []);

    const onGridReady = (params: GridReadyEvent) => {
        gridApi.current = params.api;
        gridColumnApi.current = params.columnApi;
        gridApi.current.sizeColumnsToFit();
    };

    const onGridSizeChanged = (params: any) => {
        if (gridApi.current) {
            const currentRowsIndexes: number[] = [];
            const editingCells = params.api.getEditingCells();
            editingCells.map((cell: any) => {
                 if (! currentRowsIndexes.includes(cell.rowIndex)) { currentRowsIndexes.push(cell.rowIndex); }
            });
            currentRowsIndexes.forEach(index => {
                setRowToEditingMode(index, false, gridApi.current);
            });
            gridApi.current.stopEditing(true);
            gridApi.current.resetRowHeights();
            gridApi.current.sizeColumnsToFit();
        }

    };


    const onFilterTextBoxChanged = (filterValue: string) => {
        if (gridApi && gridApi.current)
            gridApi.current.setQuickFilter(filterValue);
    };

    const exportDataToExcel = () => {
        const params = {
            columnWidth: 100,
            sheetName: 'Emars Uers list',
            allColumns: true,
            columnGroups: true,
            exportMode: undefined,
            suppressTextAsCDATA: false,
            rowHeight: undefined,
            skipGroups: true,
            headerRowHeight: undefined
        };
        if (gridApi && gridApi.current)
            gridApi.current.exportDataAsExcel(params);
    };

    const loadGridRows = () => {
        GetBusinessUnit().subscribe((buList: any) => {
            setBuList(buList);
        })
    };


    const onCellClicked = (params: any) => {
        const gridApi = params.api;
        const rowIndex =  params.rowIndex;

        if (params.column.colId === 'action' && params.event.target.dataset) {
            const action = params.event.target.dataset.action;
            if (action === 'edit') {
                gridApi.resetRowHeights();
                const rowNode = gridApi.getDisplayedRowAtIndex(rowIndex);
                setRowToEditingMode(rowIndex, true, gridApi);
                rowNode.setRowHeight(160);
                gridApi.onRowHeightChanged();
                gridApi.startEditingCell({
                    rowIndex: params.node.rowIndex,
                    colKey: params.columnApi.getDisplayedCenterColumns()[1].colId
                });
            }
            if (action === 'cancel') {
                setRowToEditingMode(rowIndex, false, gridApi);
                params.api.stopEditing(true);
                gridApi.resetRowHeights();
            }
            if (action === 'update') {
                const rowNode = gridApi.getDisplayedRowAtIndex(rowIndex);
                setRowToEditingMode(rowIndex, false, gridApi);
                params.api.stopEditing(false);
                UpdateUser(rowNode.data).then(result => {
                    // TODO : If API will return code correctly then add the message returned to this section
                });
                gridApi.resetRowHeights();
            }
        }
    };

    const setRowToEditingMode = (index: number, isEditing: boolean, gridApi: any) => {
        const rowNode = gridApi.getDisplayedRowAtIndex(index);
        rowNode.data.editing = isEditing;
        gridApi.redrawRows({rowNodes: [rowNode]});
    };

    useEffect(() => {
        setColumnDefData(ColumnDefs as any);
        loadGridRows();
    }, []);

    useEffect(() => {
        if (buList != undefined) {
            GetUsersAccounts()
                .subscribe((userList: any) => {
                    userList.forEach((val: any) => {
                        if (buList != undefined) {
                            val.adminScopeList = buList
                        }
                    })
                    setRowData(userList);
                });
        }
    }, [buList]);


    return (
        <div className='account-management mt-4 bg-lvl1' >
            <div className='main-content pe-2'>
                <div className='pt-4 m-2 p-2'>
                    <h1><FormattedMessage id='admin.accounts.management'/></h1>
                    {(buList != undefined) &&
                        <UserManager reload={loadGridRows} data={{}} businessUnitList={buList}/>}
                </div>
                <div className='tree-select-container col-md-3 col-sm-6 ms-2 ps-2'>
                    <div className='selected-text'>
                        <div className='dropdown'>
                                <div className='input-icon-start'>
                                    <i className='icon text-large'>search</i></div>
                            <input type='text' placeholder='Quick Search...'
                                   className='form-control form-control-undefined'
                                   onChange={e => {
                                       onFilterTextBoxChanged(e.target.value)
                                   }}/>
                        </div>

                    </div>
                </div>

                <div className='my-2 float-end'>
                    <button className='btn btn-lg btn-flat-secondary btn-icon-text' onClick={exportDataToExcel}>
                        <i className='icon'>get_app</i> Export table
                    </button>
                </div>
                <br/> <br/>
                <div className='w-100 flex-grow-1 mt-4 m-2 p-2' style={{width: '70% !important'}}>
                    <div className={'ag-theme-sg-bootstrap ag-theme-sg-bootstrap-condensed'}
                         style={{height: '800px'}}>
                        <AgGridReact
                            gridOptions={gridOptions}
                            columnDefs={columnDef}
                            onCellClicked={onCellClicked}
                            editType='fullRow'
                            suppressContextMenu={true}
                            suppressClickEdit={true}
                            rowData={rowData}
                            enableRangeSelection={true}
                            onRowEditingStarted={onRowEditingStarted}
                            onRowEditingStopped={onRowEditingStopped}
                            onGridSizeChanged={onGridSizeChanged}
                            onGridReady={onGridReady}
                            pagination={true}
                            defaultColDef={DefaultColumnDef}
                            frameworkComponents={{
                                buCellRenderer: BusinessUnitsCellRenderer,
                                enableAccountCellRenderer: EnableAccountCellRenderer,
                                rolesCellEditor: RolesCellEditor,
                                perimetersCellEditor: PerimetersCellEditor,
                                authoritiesCellRenderer: AuthoritiesCellRenderer,
                                editModalCellRenderer: UserManager
                            }}
                        >
                        </AgGridReact>
                    </div>
                </div>
            </div>
        </div >
    );
};