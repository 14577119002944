import {sgwtConnect} from '../utils/types';
import {fetchWithCache} from '../utils/CacheService';
import {ajaxGetJSON} from "rxjs/internal/observable/dom/AjaxObservable";
import {catchError, map, shareReplay} from "rxjs/operators";
import {handleError} from '../utils/UtilsService';
import {getEnvVar} from "../../env-vars-utils";
import {getAuthorizationHeader} from "../../helpers";
import {ajax} from "rxjs/ajax";

const API_URL = getEnvVar('react_app_api_endpoint');
const getHttpHeader = () => ({
    Authorization: getAuthorizationHeader() ?? sgwtConnect.getAuthorizationHeader(),
    'Content-Type': 'application/json'
});

export const GetUsersAccounts = () => {
    const endpoint = API_URL + '/user/list?searchCriteria=';
    return ajaxGetJSON(endpoint, getHttpHeader())
        .pipe(
            map(response => response),
            catchError(error => handleError(error))
        );
};

export const GetAuthorities = async () => {
    const endpoint = API_URL + '/authority/list';
    return fetchWithCache(endpoint, {
        key: 'authorities',
        verb: 'GET',
        headers: new Headers(getHttpHeader())
    });

};


export const GetBusinessUnit = () => {
    const endpoint = API_URL + '/user/toplevelreportunit/list';
    return ajax.getJSON(endpoint, getHttpHeader())
        .pipe(
            catchError(error => handleError(error)));
};


export const RegisterUser = async (userdata: any) => {
    const endpoint = API_URL + '/user/create';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: new Headers(getHttpHeader()),
        body: JSON.stringify(userdata)
    });
    if (!response.ok) {
        return Promise.reject('An error occurred when fetching user accounts data. Http status code ' + response.status);
    }

    return response.json();
};

export const UpdateUser = async (userData: any) => {
    const endpoint = API_URL + '/user/update';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: new Headers(getHttpHeader()),
        body: JSON.stringify(userData)
    });
    if (!response.ok) {
        return Promise.reject('An error occurred when fetching user accounts data. Http status code ' + response.status);
    }
    return response.json();
};