import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useRouteContext } from "../../../context/RouteContext/RouteContext";
import { Loader } from "../Loader";
import { NewsNotFound } from "../NewsNotFound";
import { PaginationComponent } from "../pagination/PaginationComponent";
import './HeatmapSVG.scss';

export const HeatmapModal: React.FC<any> = ({ handleClose, show, news, newsDay, isLoading }) => {
    const showHideClassName = show ? "modal shadow-xxl display-block" : "modal display-none";
    const {detailPageScope} = useRouteContext();
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        setCurrentPage(0);
    }, [newsDay])

    const LocalLoader = () => {
        return <Loader/>;
    }

    const onPageChanged = (newPage: any) => {
        setCurrentPage(newPage);
    }

    const ModalContent = () => {
        return (
            <div>
                <div className="modal-header">
                    <div className="row-cols-auto">
                        <h3 className="modal-title" id="title">
                            <FormattedMessage id="heatmap.modal.title" values={{scope: `${detailPageScope}`}}/>
                        </h3>
                        <span className="badge badge-discreet-primary badge-prepend-square align-self-center">
                            {newsDay}
                        </span>
                    </div>
                    <button type="button" className="close icon" style={{color: 'white'}} data-dismiss="modal"
                            aria-label="Close" onClick={handleClose}>
                        close
                    </button>
                </div>
                <div className="modal-body">
                    {news && news.length > 0 &&
                        <div className="d-flex flex-row-reverse">
                            <PaginationComponent pageCount={news ? news.length : 0} currentPage={currentPage} onPageChanged={onPageChanged} />
                        </div>
                    }
                    {news && news.length > 0 ? (
                        <div className="news-scroll"><div dangerouslySetInnerHTML={{__html: news[currentPage].text}}></div></div>
                    ) : (
                        <NewsNotFound />
                    )}
                </div>
            </div>
        );
    }

    const Display = () => {
        if (isLoading) {
            return <LocalLoader />;
        } else {
            return <ModalContent />;
        }
    };

    return (
        <div id="newsModal" aria-labelledby="title" className={showHideClassName} aria-modal="true">
            <Display />
        </div>
    );
}