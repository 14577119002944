export const CARD_TYPE = {
  DTD: 'DAY',
  WTD: 'WTD',
  MTD: 'MTD',
  QTD: 'QTD',
  YTD: 'YTD'
};

export const RISK_CARD_TYPE = {
  STT: 'STT',
  VAR: 'VaR',
  SVAR: 'SVaR',
  CRDST: 'CrdST'
};

export const PERIOD_TYPE = {
  WTD: 'wtd',
  MTD: 'mtd',
  QTD: 'qtd',
  YTD: 'ytd'
};

export const MINI_HEATMAP_COMPARISON_TYPE = {
  SAME_PERIOD: 'samePeriod',
  PREVIOUS_PERIOD: 'previousPeriod',
  BUDGET: 'budget'
};

export const LOCATION = {
  WW: '1',
  ASIA: '2'
};

export const SCOPE = {
  GBIS_ID: '1',
  MARK_ID: '13',
  GBIS_ASIA_ID: '15',
  MARK_ASIA_ID: '16'
};
