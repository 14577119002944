import {ajaxPost} from "rxjs/internal/observable/dom/AjaxObservable";
import {catchError, map} from 'rxjs/operators'
import {handleError} from "./utils/UtilsService";
import {getEnvVar} from "../env-vars-utils";
import {getAuthorizationHeader} from "../helpers";

const API_URL = getEnvVar('react_app_api_endpoint');
const getHttpHeader = () => ({
    Authorization: getAuthorizationHeader(),
    'Content-Type': 'application/json'
});

export const GetRiskData = (date: string, reportingUnitId: number, subReportingUnitId: number) => {
    const body = {
        date,
        reportingUnitId,
        subReportingUnitId
    };

    return ajaxPost(
        API_URL + '/synthesis/risk',
        body,
        getHttpHeader()
    ).pipe(
        map(response => response.response),
        catchError(error => handleError(error))
    );
}

export const GetRiskGraphData = (date: string, reportingUnitId: number) => {
    const body = {
        date,
        reportingUnitId,
    };

    return ajaxPost(
        API_URL + '/synthesis/risk-graph',
        body,
        getHttpHeader()
    ).pipe(
        map(response => response.response),
        catchError(error => handleError(error))
    );
}
