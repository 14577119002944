import React from "react";

export const NumberInput: React.FC<any> = ({ autoFocus = false, value, onNumberChange }) => {

    const minusOne = () => {
        onNumberChange(Math.floor(value) - 1);
    };

    const onChange = (event: any) => {
        let newValue = event.target.value;
        if(isFinite(newValue)) {
            newValue = Math.floor(newValue);
        }
        onNumberChange(newValue, true);
    };

    const plusOne = () => {
        onNumberChange(Math.floor(value) + 1);
    };

    return (
        <div className="d-flex">
            <button className="btn btn-default" onClick={minusOne}>-</button>
            <input className="form-control text-center" type="text" autoFocus={autoFocus} 
            defaultValue={value} onChange={onChange} />
            <button className="btn btn-default" onClick={plusOne}>+</button>
        </div>
    );
}
