import {DataViewType} from "../components/common/DataViewType";
import {sgwtConnect} from "./utils/types";
import {ajax} from 'rxjs/ajax';
import {ajaxGet, ajaxPost} from "rxjs/internal/observable/dom/AjaxObservable";
import {catchError, map} from 'rxjs/operators'
import {handleError} from "./utils/UtilsService";
import {PreferenceType} from "../components/common/PreferenceType";
import {getEnvVar} from "../env-vars-utils";
import {getAuthorizationHeader} from "../helpers";

const API_URL = getEnvVar('react_app_api_endpoint');
const getHttpHeader = () => ({
    Authorization: getAuthorizationHeader(),
    'Content-Type': 'application/json'
});

export const FetchNBIData = (date: string, reportingUnitId: number, subReportingUnitId: number, regionId: number | undefined,
                             importType: string, indicatorName: string) => {
    const body = {
        reportingUnitId,
        date,
        regionId,
        subReportingUnitId,
        importType,
        indicatorName,
    };

    return ajaxPost(
      API_URL + "/synthesis/overview",
      body,
      getHttpHeader()
    ).pipe(
      map(response => response.response),
      catchError(error => handleError(error))
    );
};

export const FetchPerformanceData = (date: string, reportingUnitId: number,importType: string, subReportingUnitId: number,regionId: number | undefined) =>{
    const body = {
        date,
        reportingUnitId,
        importType,
        subReportingUnitId,
        regionId
    };
    return ajaxPost(
        API_URL + "/synthesis/performance",
        body,
        getHttpHeader()
    ).pipe(
        map(response => response.response),
        catchError(error => handleError(error))
    );

}

export const FetchLastAvailableDate = () => {
    return ajax({
        url: API_URL + "/date",
        method: "GET",
        responseType: 'text',
        headers: {Authorization: getAuthorizationHeader()},
    })
      .pipe(
        map(response => response.response),
        catchError(error => handleError(error))
      );
};

export const FetchMiniHeatMapData = (date: string, reportingUnitId: number, regionId : number | undefined,
                                     importType: string, subReportingUnitId: number, dataNatureId?: number) => {
    const body = {
        date,
        reportingUnitId,
        subReportingUnitId,
        importType,
        regionId,
        dataNatureId
    };

    return ajaxPost(
      API_URL + "/sub-scope-heatmap",
      body,
      getHttpHeader()
    ).pipe(
      map(response => response.response),
      catchError(error => handleError(error))
    );
};

export const FetchNbiReportingData = (rangePeriod: any, importType: string, reportingUnitId: string | number, dataView: DataViewType, regionId: number | undefined) => {
    const body = {
        dataView,
        rangePeriod,
        reportingUnitId,
        importType,
        regionId,
    };

    const url = API_URL + "/report-data/list-reporting";

    return ajaxPost(
        url,
        body,
        getHttpHeader()
    ).pipe(
        map(response => response.response),
        catchError(error => handleError(error))
    );
};

export const FetchNewsDetails = (date: string, importType: string, reportingUnitId: string | number, regionId : number | undefined, subReportingUnitId?: number) => {
    const body = {
        date,
        reportingUnitId,
        importType,
        regionId,
        subReportingUnitId,
    };

    return ajaxPost(
      API_URL + "/news/synthesis-news",
      body,
      getHttpHeader()
    ).pipe(
      map(response => response.response),
      catchError(error => handleError(error))
    );
};

export const GetUserReportingPreference = () => {
    return ajaxGet(API_URL + '/user/reporting-preference', getHttpHeader())
        .pipe(
            map(response => response.response),
            catchError(error => handleError(error))
        );
};

export const GetAllAdminReportingPreference = () => {

    return ajaxGet(API_URL + '/user/reporting-all-admin-preference', getHttpHeader())
        .pipe(
            map(response => response.response),
            catchError(error => handleError(error))
        );
};

export const GetDefaultPreference = (rangePeriod: any, reportingUnitId: number) => {
    const body = {
        rangePeriod,
        reportingUnitId
    };

    return ajaxPost(
        API_URL + "/report-data/default-preference",
        body,
        getHttpHeader()
    ).pipe(
        map(response => response.response),
        catchError(error => handleError(error))
    );
};

export const SaveUserReportingPreference = (preferences: string) => {
    const body = {
        preferences
    };

    return ajaxPost(
        API_URL + "/user/reporting-savePreferences",
        body,
        getHttpHeader()
    ).pipe(
        map(response => response.response),
        catchError(error => handleError(error))
    );
};

export const SaveAdminReportingPreference = (preferences: string, adminPreference: PreferenceType | undefined) => {
    const body = {
        adminPreference,
        preferences,

    };

    return ajaxPost(
        API_URL + "/user/reporting-save-admin-preferences",
        body,
        getHttpHeader()
    ).pipe(
        map(response => response.response),
        catchError(error => handleError(error))
    );
};

export const FetchNbiYtdData = (date: string, reportingUnitId: number, regionId : number | undefined, importType: string, period: string, subReportingUnitId?: number) => {
    const body = {
        date,
        reportingUnitId,
        subReportingUnitId,
        importType,
        regionId,
        period
    };

    return ajaxPost(
        API_URL + "/synthesis/ytd-evolution/"+ reportingUnitId,
        body,
        getHttpHeader()
    ).pipe(
        map(response => response.response),
        catchError(error => handleError(error))
    );
};

export const UpdateNewsDetails = async (criteria:any) => {

    const body = {
        id : criteria.id,
        locationId: criteria.locationId,
        newsType: criteria.newsType,
        reportingUnitId: criteria.reportingUnitId,
        subReportingUnitId: criteria.subReportingUnitId,
        text: criteria.text
    };

    return fetch(API_URL + "/news/update", {
        method: 'POST',
        headers: new Headers({
            Authorization: getAuthorizationHeader() ?? sgwtConnect.getAuthorizationHeader(),
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(body)

    })
};

export const addNews = async (newsObject: any) => {
    const body = {
        date : newsObject.date,
        newsType : newsObject.newsType,
        reportingUnitId : newsObject.reportingUnitId,
        subReportingUnitId : newsObject.subReportingUnitId,
        text : newsObject.text,
        title : newsObject.title,
    };
    const response = await fetch(API_URL + "/news/save", {
        method: 'POST',
        headers: new Headers({
            Authorization: getAuthorizationHeader() ?? sgwtConnect.getAuthorizationHeader(),
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(body)
    });

    if (!response.ok) {
        return Promise.reject("An error occurred when saving news. Http status code " + response.status);
    }

    return await response.json();
};

export const DeleteNews = async (newsObject: any) => {
    const body = {
        id : newsObject.id,
        reportingUnitId: newsObject.reportingUnitId,
    };
    const response = await fetch(API_URL + "/news/delete", {
        method: 'POST',
        headers: new Headers({
            Authorization: getAuthorizationHeader() ?? sgwtConnect.getAuthorizationHeader(),
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(body)
    });

    if (!response.ok) {
        return Promise.reject("An error occurred when deleting news. Http status code " + response.status);
    }

};
