import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {RouteComponentProps, useHistory, useParams} from 'react-router-dom';
import {useRouteContext} from "../../../context/RouteContext/RouteContext";
import {DataStatus} from "../../../models/DataStatusModel";
import {getDataStatus} from "../../../services/CommonService";
import DateService from "../../../services/DateService";
import {DataNotConfirmed} from "../../common/DataNotConfirmed";
import {HeatMap} from "../../common/heatmap/Heatmap";
import {Loader} from "../../common/Loader";
import {NbiComparisonInTime} from "../../common/nbiComparaisonInTime/NbiComparisonInTime";
import {PerformanceTrend} from "../../common/performanceTrend/PerformanceTrend";
import {IRouteParams} from "../../common/route/IRouteParams";
import {useUser} from "../../../context/UserContext/UserContext";

export const Detail: React.FC<RouteComponentProps> = () => {
    const routeParams: IRouteParams = useParams<IRouteParams>();
    const date = routeParams.date;
    const formattedDate = DateService.formatDateWithPattern(date, "DD MMM YYYY");
    const reportingUnitId = parseInt(routeParams.scope);
    const {detailPageIndicator} = useRouteContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const {isAdmin} = useUser();
    const history = useHistory();

    useEffect(() => {
        setIsLoading(true);
        const subscription = getDataStatus(date, reportingUnitId).subscribe(
            status => {
                setIsConfirmed(status.SYNTHESIS === DataStatus.CONFIRMED);
                setIsLoading(false);
            },
          () => {
              setIsConfirmed(false);
              setIsLoading(false);
          }
        );
        return () => subscription.unsubscribe();
    }, [routeParams.date, routeParams.scope]);

    const handleClick = () => {
        history.goBack();
    };

    const DetailWidgets = () => {
        return <>
            <HeatMap />
            <NbiComparisonInTime />
            <PerformanceTrend />
        </>;
    };

    const DetailMainPage = () => {
        if (isLoading) {
            return <div className="py-4 px-2"><Loader /></div>;
        } else if (isAdmin || isConfirmed) {
            return <DetailWidgets />;
        } else {
            return <DataNotConfirmed />;
        }
    };

    return (
        <div className="main-content ps-md-4 pe-md-4">
            <div className="d-inline-block pb-1" style={{cursor: 'pointer'}} onClick={handleClick}>
                <i className="icon me-1">arrow_back</i>
                <FormattedMessage id="detail.page.back"/>
            </div>
            <div className="d-flex mt-2">
                <h1>NBI detailed analysis</h1>
                <span
                    className="badge badge-secondary badge-prepend-square ms-2 align-self-center">{formattedDate}</span>
                <span
                    className="badge badge-secondary badge-prepend-square ms-2 align-self-center">{detailPageIndicator}</span>
            </div>
            <DetailMainPage/>
        </div>
    );
};