import React, {ReactNode, useEffect, useState} from 'react';
import {CardTemplate} from '../card/CardTemplate';
import IRiskCardDTO from './IRiskCardDTO';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import '../card/Card.scss';
import './RiskCard.scss'


interface RiskCardProperty {
    isLoading: boolean;
    indicator: string;
    hint?: string;
    data: IRiskCardDTO;
}

export const RiskCard: React.FC<RiskCardProperty> = ({ isLoading, indicator, data, hint }) => {

    const [headerColor, setHeaderColor] = useState('bg-green');
    const [bodyColor, setBodyColor] = useState('bg-green');
    const [footerColor, setFooterColor] = useState('bg-green');
    const [sevenFooterColor, setsevenFooterColor] = useState('bg-green');
    const [monthFooterColor, setmonthFooterColor] = useState('bg-green');
    let cardHeader;
    let cardBody;
    let cardFooter;
    let footerCardBody;
    const numberOfDigits = 1;

    const hasData = (): boolean => {
        return data && !isNaN(data.value);
    };

    useEffect(() => {
        const valueLimitDMinusOnePercentage = calculatePercentageBetweenTwoValues(data.dayMinusOneVariation, data.limit);
        const dayMinusSevenLimitPercentage =
        calculatePercentageBetweenTwoValues(data.dayMinusSevenVariation, data.limit);
        const dayMinusThirtyLimitPercentage =
        calculatePercentageBetweenTwoValues(data.dayMinus30Variation, data.limit);
        changeColorBasedonPercentage(valueLimitDMinusOnePercentage, setHeaderColor);
        changeColorBasedonPercentage(valueLimitDMinusOnePercentage, setBodyColor);
        changeColorBasedonPercentage(dayMinusSevenLimitPercentage, setsevenFooterColor);
        changeColorBasedonPercentage(dayMinusThirtyLimitPercentage, setmonthFooterColor);
        if (hasData() && !isLoading) {
            setFooterColor('none');
        }
    });


    const calculatePercentageBetweenTwoValues = (firstValue: number, secondValue: number): number => {
        if ((typeof (firstValue) !== 'number') || (typeof (secondValue) !== 'number')) {
            return NaN;
        } else {
            return (firstValue / secondValue) * 100;
        }
    }

    const changeColorBasedonPercentage = (percentage: number, updateStateFunc: any) => {
        if (!Number.isFinite(percentage) && !hasData()) {
            updateStateFunc('bg-green');
            return;
        }
        else if(!Number.isFinite(percentage) && hasData()) {
            updateStateFunc('bg-red');
            return;
        }

        percentage = Math.abs(percentage);
        if (percentage <= 2) {
            updateStateFunc('bg-green');
        }
        else if (percentage > 2 && percentage <= 10) {
            updateStateFunc('custom-warning');
        }
        else if (percentage > 10) {
            updateStateFunc('bg-red');
        }
    }

    const formattedNumber = (num: number, digits?: number | 1): ReactNode => {
        if ((Number.isFinite(num))) {
            return <FormattedNumber value={num} maximumFractionDigits={digits} />;
        }
        return '-';
    };

    const formattedLimit = formattedNumber(data.limit, numberOfDigits);
    const formattedPercentage = formattedNumber(data.percentage, numberOfDigits);
    const formattedValue = formattedNumber(data.value, numberOfDigits);
    const formattedDayMinusOneVariationPercentage = formattedNumber(data.dayMinusOneVariationPercentage, numberOfDigits);
    const formattedDayMinusOneVariation = formattedNumber(data.dayMinusOneVariation, numberOfDigits);
    const formattedDayMinusSevenVarPercentage = formattedNumber(data.dayMinusSevenVariationPercentage, numberOfDigits);
    const formattedDayMinusSevenVaration = formattedNumber(data.dayMinusSevenVariation, numberOfDigits);
    const formattedDayMinusThirtyVPercentage = formattedNumber(data.dayMinus30VariationPercentage, numberOfDigits);
    const formattedDayMinusThirtyVariation = formattedNumber(data.dayMinus30Variation, numberOfDigits);

    if (hasData()) {
        cardHeader =
            <div className='w-100' id='header-card-container'>
                <p className='card-title line-height-1 tabular-figure mb-0 text-regular ms-2 me-2 pull-right variation-text'>
                    Limit : {formattedLimit}&nbsp;Mln€ (Usage : {formattedPercentage}%) {(hint) ? `(${hint})` : ''}
                </p>
            </div>
        ;

        cardBody =
            <div className='d-flex align-items-left pt-2 '>
                <div className='flex-grow-0 me-3 align-items-left restricted-body-height'>
                    <h2> {indicator} </h2>
                </div>
                <div className='flex-grow-1'>
                    <div className='d-flex align-items-end'>
                        <h2 className='indicator-amount flex-grow-0'>
                            {formattedValue}
                        </h2>
                        <span className='d-minus-mln-label flex-grow-1'>&nbsp;Mln&#8364;</span>
                    </div>
                </div>
                <br />
            </div>
        ;
        footerCardBody =
            (
                <p className='text-regular mb-0 align-items-left variation-text '>
                    &Delta; D-1: {formattedDayMinusOneVariationPercentage}%
                    ({formattedDayMinusOneVariation} Mln&#8364;)
                </p>
            )

        cardFooter = (
            !isNaN(data.percentage) && <div className='mt-2 w-100' id='footer-card-container' >
                <div className={`footer-align-items-left footer-variation ps-3 ${sevenFooterColor}`}>
                    <p className='variation-text mt-1 mb-1'>&Delta; D-7: {formattedDayMinusSevenVarPercentage}%
                        ({formattedDayMinusSevenVaration} Mln&#8364;)</p>
                </div>
                <div className={`footer-align-items-left footer-variation ps-3 ${monthFooterColor} `}>
                    <p className='variation-text mt-1 mb-1'>
                        &Delta; D-30: {formattedDayMinusThirtyVPercentage}%
                        ({formattedDayMinusThirtyVariation} Mln&#8364;)
                    </p>
                </div>
            </div>
        )
    }
    else {
        cardBody =
            <div className='text-center no-data'>
                <h3 className='mb-0'>
                    <FormattedMessage id='no-data' />
                </h3>
            </div>
    }



    return (
        <div>
            <CardTemplate isLoading={isLoading} cardHeader={cardHeader} headerColor={headerColor}
                bodyColor={bodyColor} footerColor={footerColor} cardBody={cardBody} cardFooter={cardFooter} footerCardBody={footerCardBody} />
        </div>
    );
};