import React, { useEffect, useState , useRef } from 'react';
import { IRouteParams } from '../route/IRouteParams';
import { RiskCard } from './RiskCard';
import RiskCardDTO from './IRiskCardDTO';
import {TitleService} from "../../../services/event/TitleService";
import UtilsService from '../../../services/utils/UtilsService';

import './RiskWidget.scss';
import {OverviewWidgetType} from "../OverviewWidgetType";

interface IRiskWidgetProps {
  data: any;
  params: IRouteParams;
}

export const RiskWidget: React.FC<IRiskWidgetProps> = ({ data, params }) => {
  const riskDivRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [svarCardDTO, setSVarDTo] = useState({} as RiskCardDTO);
  const [varCardDTO, setVarDTo] = useState({} as RiskCardDTO);
  const [sttCardDTO, setSttDTo] = useState({} as RiskCardDTO);
  const [eSttCardDTO, seteSttDTo] = useState({} as RiskCardDTO);
  const [gSttCardDTO, setgSttDTo] = useState({} as RiskCardDTO);
  const [showRiskWidget, setShowRiskWidget] = useState(false);
  const [title, setTitle] = useState("");
  const [overflowActive, setOverflowActive] = useState(false);
  const [scrollToEndRight, setScrollToEndRight] = useState(false);
  const [activeTab, setActiveTab] = useState(OverviewWidgetType.NBI);


  useEffect(() => {
    setIsLoading(true);
    if (data){
      setSVarDTo(extractSvarFromResponse(data));
      setVarDTo(extractVarFromResponse(data));
      setSttDTo(extractSttFromResponse(data));
      seteSttDTo(extractEmSttFromResponse(data));
      setgSttDTo(extractGbSttFromResponse(data));
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    const subscription = TitleService.observable().subscribe(({title}) => {
      setTitle(title);
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    setShowRiskWidget(canDisplayRiskWidget());
  });


  useEffect(() => {
    window.addEventListener('resize', handleResize);
    riskDivRef.current && riskDivRef.current.addEventListener('scroll', handleScroll);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
      riskDivRef.current && riskDivRef.current.removeEventListener('scroll', handleScroll);
    }
  }, []);



  const handleResize = () => {
    if (riskDivRef) {
      const overflowActive = hasOverflowingChildren(riskDivRef.current);
      setOverflowActive(overflowActive);
      handleScroll();
    }
  };

  const handleScroll = () => {
    const current = riskDivRef.current;
    if (current && (current.offsetWidth + current.scrollLeft === current.scrollWidth)) {
      setScrollToEndRight(true);
    } else {
      setScrollToEndRight(false);
    }
  };

  const hasOverflowingChildren = (element: HTMLDivElement | null): boolean => {
    return element != null && element.offsetWidth < element.scrollWidth;
  };

  const extractSttFromResponse = (response: any): React.SetStateAction<RiskCardDTO> => {
    return {
      limit: response.riskKeyFigure?.streetTestLimit,
      percentage: response.riskKeyFigure?.streetTestLimitPercentage,
      value: response.riskKeyFigure?.streetTest,
      dayMinusOneVariation: response.riskKeyFigureDayMinusOne?.sttVariation,
      dayMinusOneVariationPercentage: response.riskKeyFigureDayMinusOne?.sttPercentageVariation,
      dayMinusSevenVariation: response.riskKeyFigureDayMinusSeven?.sttVariation,
      dayMinusSevenVariationPercentage: response.riskKeyFigureDayMinusSeven?.sttPercentageVariation,
      dayMinus30Variation: response.riskKeyFigureDayMinusTwenty?.sttVariation,
      dayMinus30VariationPercentage: response.riskKeyFigureDayMinusTwenty?.sttPercentageVariation
    };
  }

  const extractGbSttFromResponse = (response: any): React.SetStateAction<RiskCardDTO> => {
    return {
      limit: response.riskKeyFigure?.globalStreetTestLimit,
      percentage: response.riskKeyFigure?.globalStreetTestLimitPercentage,
      value: response.riskKeyFigure?.globalStreetTest,
      dayMinusOneVariation: response.riskKeyFigureDayMinusOne?.gbSttVariation,
      dayMinusOneVariationPercentage: response.riskKeyFigureDayMinusOne?.gbSttPercentageVariation,
      dayMinusSevenVariation: response.riskKeyFigureDayMinusSeven?.gbSttVariation,
      dayMinusSevenVariationPercentage: response.riskKeyFigureDayMinusSeven?.gbSttPercentageVariation,
      dayMinus30Variation: response.riskKeyFigureDayMinusTwenty?.gbSttVariation,
      dayMinus30VariationPercentage: response.riskKeyFigureDayMinusTwenty?.gbSttPercentageVariation
    };
  }

  const extractEmSttFromResponse = (response: any): React.SetStateAction<RiskCardDTO> => {
    return {
      limit: response.riskKeyFigure?.emergingStreetTestLimit,
      percentage: response.riskKeyFigure?.emergingStreetTestLimitPercentage,
      value: response.riskKeyFigure?.emergingStreetTest,
      dayMinusOneVariation: response.riskKeyFigureDayMinusOne?.emSttVariation,
      dayMinusOneVariationPercentage: response.riskKeyFigureDayMinusOne?.emSttPercentageVariation,
      dayMinusSevenVariation: response.riskKeyFigureDayMinusSeven?.emSttVariation,
      dayMinusSevenVariationPercentage: response.riskKeyFigureDayMinusSeven?.emSttPercentageVariation,
      dayMinus30Variation: response.riskKeyFigureDayMinusTwenty?.emSttVariation,
      dayMinus30VariationPercentage: response.riskKeyFigureDayMinusTwenty?.emSttPercentageVariation
    };
  }

  const extractSvarFromResponse = (response: any): React.SetStateAction<RiskCardDTO> => {
    return {
      limit: response.riskKeyFigure?.svaRLimit,
      percentage: response.riskKeyFigure?.svaRLimitPercentage,
      value: response.riskKeyFigure?.svaR,
      dayMinusOneVariation: response.riskKeyFigureDayMinusOne?.svarVariation,
      dayMinusOneVariationPercentage: response.riskKeyFigureDayMinusOne?.svarPercentageVariation,
      dayMinusSevenVariation: response.riskKeyFigureDayMinusSeven?.svarVariation,
      dayMinusSevenVariationPercentage: response.riskKeyFigureDayMinusSeven?.svarPercentageVariation,
      dayMinus30Variation: response.riskKeyFigureDayMinusTwenty?.svarVariation,
      dayMinus30VariationPercentage: response.riskKeyFigureDayMinusTwenty?.svarPercentageVariation
    };
  }

  const extractVarFromResponse = (response: any): React.SetStateAction<RiskCardDTO> => {
    return {
      limit: response.riskKeyFigure?.valueAtRiskLimit,
      percentage: response.riskKeyFigure?.valueAtRiskLimitPercentage,
      value: response.riskKeyFigure?.valueAtRisk,
      dayMinusOneVariation: response.riskKeyFigureDayMinusOne?.varVariation,
      dayMinusOneVariationPercentage: response.riskKeyFigureDayMinusOne?.varPercentageVariation,
      dayMinusSevenVariation: response.riskKeyFigureDayMinusSeven?.varVariation,
      dayMinusSevenVariationPercentage: response.riskKeyFigureDayMinusSeven?.varPercentageVariation,
      dayMinus30Variation: response.riskKeyFigureDayMinusTwenty?.varVariation,
      dayMinus30VariationPercentage: response.riskKeyFigureDayMinusTwenty?.varPercentageVariation
    };
  }

  const canDisplayRiskWidget = () => (UtilsService.isGbisOrMark(parseInt(params?.scope))
  && params.subScope === undefined && UtilsService.isWorldWideByCode(params.region ? params.region : '')
  && UtilsService.isWorldWideByCode(params.userLocation));

  const shouldDisplayGradient = () => {
    return overflowActive && !scrollToEndRight;
  };

    return (
        <div className='overview'>
        <div className={`flex-row mt-0 position-relative  ${shouldDisplayGradient() ? 'gradient' : ''} }`}>
          <div className='position-relative overflow-auto' ref={riskDivRef}>
            <div className='cards-holder'>
              <div className='cards row'>
                <div className='col-sm-6 col-md-2_4 padding-start-sm-0 padding-start-md-0 mb-md-0 mb-3'>
                    <RiskCard isLoading={isLoading} indicator={'VaR'} data={varCardDTO}/>
                </div>
                  <div className='col-sm-6 col-md-2_4 padding-end-sm-0 mb-md-0 mb-3'>
                      <RiskCard isLoading={isLoading} indicator={'SVaR'} data={svarCardDTO}/>
                  </div>
                  <div className='col-sm-6 col-md-2_4 padding-start-sm-0 mb-md-0 mb-3'>
                      <RiskCard isLoading={isLoading} indicator={'STT'} data={sttCardDTO}/>
                  </div>
                  <div className='col-sm-6 col-md-2_4 padding-end-sm-0 mb-md-0 mb-3'>
                      <RiskCard isLoading={isLoading} indicator={'EMSTT '} data={eSttCardDTO}/>
                  </div>
                  <div className='col-sm-6 col-md-2_4 padding-start-sm-0 padding-end-md-0 mb-md-0 mb-3'>
                      <RiskCard isLoading={isLoading} indicator={'GBSTT'} hint={'SG Level'} data={gSttCardDTO}/>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );

}