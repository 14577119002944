import React, {PropsWithRef, useEffect, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import {MINI_HEATMAP_COMPARISON_TYPE, PERIOD_TYPE} from "../Constants";
import * as d3 from "d3";
import {useViewport} from "../../../context/ViewportContext";
import {IRouteParams} from "../route/IRouteParams";
import {useParams} from "react-router-dom";
import UtilsService from "../../../services/utils/UtilsService";

import './ColumnChart.css';
import {useIntl} from "react-intl";

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data.js')(Highcharts);


interface IMiniHeatmapSVG extends PropsWithRef<any> {
    period: string;
    comparisonType: string;
    data: any | undefined;
    date: number;
}


export const ColumnChart: React.FC<any> = (props: IMiniHeatmapSVG) =>  {
    const routeParams: IRouteParams = useParams<IRouteParams>();
    const intl = useIntl();
    const date = routeParams.date;
    const year = parseInt(UtilsService.getYearFromDate(date));
    const [nbi, setNbi] = useState([]);
    const [xAxisNumber, setXAxisNumber] = useState(0);
    const [nbiColumnOne, setNbiColumnOne] = useState([]);
    const [enableScroll, setEnableleScroll] = useState(true);
    const [isQuarter, setIsQuarter] = useState(false);
    const axeXQuarter = ['Q1', 'Q2','Q3','Q4'];
    const [finalXAxeValues, setFinalXAxeValues]= useState(['']);
    const monthSelected= props.date;
    const [firstColumnTitle, setFirstColumnTitle]= useState(String(year-1));
    const axeXyear=['Jan '  , 'Feb ' , 'Mar ' , 'Apr ' , 'Mai ' ,'Jun ' ,'Jul ' ,'Aug ' ,'Sep ' ,'Oct ' ,'Nov ' ,'Dec ' ]
    const {isSmallDevice} = useViewport();


    Highcharts.setOptions({
        lang: {
            thousandsSep: ''
        }
    });
    Highcharts.SVGRenderer.prototype.symbols.download = function (x: any, y: any, w: any, h: any) {
        var path = [
            // Arrow stem
            'M', x + w * 0.5, y,
            'L', x + w * 0.5, y + h * 0.7,
            // Arrow head
            'M', x + w * 0.3, y + h * 0.5,
            'L', x + w * 0.5, y + h * 0.7,
            'L', x + w * 0.7, y + h * 0.5,
            // Box
            'M', x, y + h * 0.9,
            'L', x, y + h,
            'L', x + w, y + h,
            'L', x + w, y + h * 0.9
        ];
        return path;
    };
    const options = {
        chart: {
            renderTo: 'container',
            type: 'column',
            marginTop:80,
            height: 600,
            marginBottom:60,
            spacingTop: 0,
            spacingLeft: 0,
            spacingRight: 0,
            zoomType: 'x',
            panning: true,
            itemPadding:0,
            maxPadding:0,
            commonSeriesSettings:{
                barPadding:10,
                columnPadding:10,
            },
            backgroundColor: {
                linearGradient: [0, 0, 500, 500],
                stops: [
                    [0, 'rgb(48,51,51)'],
                ]
            },
        },
        title: {
            text: null
        },
        scrollbar: {
            enabled: enableScroll,
            height: 10,
            rifleColor: 'none',
            barBackgroundColor: '#c4c8ca',
            barBorderWidth: 0,
            buttonBackgroundColor: '#c4c8ca',
            buttonBorderWidth: 0,
            trackBackgroundColor: '#303333',
            trackBorderWidth: 0
        },
        exporting: {
            enabled: isSmallDevice ? false : true,
            buttons: {
                contextButton: {
                    menuItems: [
                        "downloadPNG",
                        "downloadJPEG",
                        "downloadPDF",
                        "downloadSVG",
                        "separator",
                        "downloadCSV",
                        "downloadXLS",
                        "openInCloud"],
                    symbolStroke: '#dddddd',
                    symbol: 'download' ,
                    theme: {
                        fill: '#505053'
                    },
                },
            }
        },

        plotOptions: {
            column: {
                pointPadding: 0,
                borderWidth: 0,
                shadow: false,
                dataLabels: {
                    lang: {
                        decimalPoint: '',
                        thousandsSep: ''
                    },
                    padding :0,
                    enabled: true,
                    style: {
                        distance: '-50%',
                        fontSize: '15px',
                        fontWeight: '600',//fw-semibold
                        fontFamily: 'Inter', //body font (inter)
                        color: 'white',
                        textOutline: '0px contrast'
                    },
                },
                style: {
                    fontWeight: 'bold',
                    color: 'red',
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }

            },
            series: {
                groupPadding:0.05,
                enableMouseTracking: false // disable all mouse events
            }
        },
        credits: {
            enabled: false
        },
      
        series: [

            {
                legendColor :'yellow',
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, new Highcharts.Color('#6488FF').setOpacity(0.7).get('rgba')],
                        [1, new Highcharts.Color('#324480').setOpacity(0.7).get('rgba')]
                    ]
                },
                name: firstColumnTitle,
                data: nbiColumnOne,
                showInNavigator: true,

            },
            {
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, new Highcharts.Color('#F6A416').setOpacity(0.7).get('rgba')],
                        [1, new Highcharts.Color('#6B480A').setOpacity(0.7).get('rgba')]

                    ]
                },
                name: year,
                data: nbi,
                showInNavigator: true,
            },
        ],
        xAxis: {
            categories: finalXAxeValues ,
            min:0,
            max: xAxisNumber,
            labels: {
                style: {
                    color: '#949c9e',//text-secondary
                    fontWeight: '600',//fw-semibold
                    fontFamily: 'Inter', //body font (inter)
                    fontSize: props.period === PERIOD_TYPE.WTD ? 14 : 15
                }
            },

        },

        yAxis: {
            resize: {
                enabled: true
            },
            gridLineColor: '#464b4b',
            stackLabels: {
                enabled: true,
            },
            title: {
                text: null
            },
            labels: {
                style: {
                    color: '#949c9e',//text-secondary
                    fontWeight: '600',//fw-semibold
                    fontFamily: 'Inter', //body font (inter)
                    fontSize: 15
                }
            }

        },
        legend: {
            enabled: true,
            align: 'left',
            verticalAlign: 'top',
            itemStyle: {
                fontSize: '15px',
                fontWeight: '600',//fw-semibold
                fontFamily: 'Inter', //body font (inter)
                color: 'white'
            },
        },
    };


    const getXAxisTitles = () => {
        const xAxis:any=[];
        const nbiDatas:any=[];
        const nbiColulnOneDatas:any=[];
        if (props.period === PERIOD_TYPE.WTD) {
            setIsQuarter(false);
            for (let i =0 ;i<props.data.length;i++){
                if (props.data[i].week.length > 3){
                    xAxis.push(props.data[i].week);

                if(props.data[i].wtd){
                    if(props.data[i].wtd.value){
                        const value = d3.format(".1f")(props.data[i].wtd.value / 1000);
                        nbiDatas.push(+value);
                    }else {
                        nbiDatas.push(0);
                    }
                }else {
                    nbiDatas.push(0);
                }
                if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.SAME_PERIOD) {
                    if(props.data[i].wtd){
                        if(props.data[i].wtd.previousYearValue){
                            const value = d3.format(".1f")(props.data[i].wtd.previousYearValue / 1000);
                            nbiColulnOneDatas.push(+value);
                        }else {
                            nbiColulnOneDatas.push(0);
                        }
                    }else {
                        nbiColulnOneDatas.push(0);
                    }
                }
                else if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.BUDGET){
                    if(props.data[i].wtd){
                        if(props.data[i].wtd.currentPeriodBudget){
                            const value = d3.format(".1f")(props.data[i].wtd.currentPeriodBudget / 1000);
                            nbiColulnOneDatas.push(+value);
                        }else {
                            nbiColulnOneDatas.push(0);
                        }
                    }else {
                        nbiColulnOneDatas.push(0);
                    }
                }
                else if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD){
                    if(props.data[i].wtd){
                        if(props.data[i].wtd.previousPeriodValue){
                            const value = d3.format(".1f")(props.data[i].wtd.previousPeriodValue / 1000);
                            nbiColulnOneDatas.push(+value);
                        }else {
                            nbiColulnOneDatas.push(0);
                        }
                    }else {
                        nbiColulnOneDatas.push(0);
                    }
                }
              }
            }
            setNbi(nbiDatas);
            setNbiColumnOne(nbiColulnOneDatas);
            setFinalXAxeValues(xAxis);
        }
        else if (props.period === PERIOD_TYPE.MTD) {
            const xAxis:any=[];
            setEnableleScroll(true);
            setIsQuarter(false);
            for (let i =0 ;i<=monthSelected;i++){
                xAxis.push(axeXyear[i]);
                if(props.data[i].mtd){
                    if(props.data[i].mtd.value){
                        const value = d3.format(".1f")(props.data[i].mtd.value / 1000);
                        nbiDatas.push(+value);
                    }else {
                        nbiDatas.push(0);
                    }
                }else {
                    nbiDatas.push(0);
                }
                if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.SAME_PERIOD) {
                    if(props.data[i].mtd){
                        if(props.data[i].mtd.previousYearValue){
                            nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[i].mtd.previousYearValue / 1000)));
                        }else {
                            nbiColulnOneDatas.push(0);
                        }
                    }else {
                        nbiColulnOneDatas.push(0);
                    }
                }
                else if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.BUDGET){
                    if(props.data[i].mtd){
                        if(props.data[i].mtd.currentPeriodBudget){
                            nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[i].mtd.currentPeriodBudget / 1000)));
                        }else {
                            nbiColulnOneDatas.push(0);
                        }
                    }else {
                        nbiColulnOneDatas.push(0);
                    }
                }
                else if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD){
                    if(props.data[i].mtd){
                        if(props.data[i].mtd.previousPeriodValue){
                            nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[i].mtd.previousPeriodValue / 1000)));
                        }else {
                            nbiColulnOneDatas.push(0);
                        }
                    }else {
                        nbiColulnOneDatas.push(0);
                    }
                }
            }
            setNbi(nbiDatas);
            setNbiColumnOne(nbiColulnOneDatas);
            setFinalXAxeValues(xAxis);

        }
        else if (props.period === PERIOD_TYPE.QTD) {
            isSmallDevice ? setEnableleScroll(true) : setEnableleScroll(false);
            setIsQuarter(true);
            if(monthSelected < 2){
                if( props.data[monthSelected].qtd ){
                    if(props.data[monthSelected].qtd.value){
                        nbiDatas.push(+(d3.format(".1f")(props.data[monthSelected].qtd.value / 1000)));
                    }
                    else {
                        nbiDatas.push(0);
                    }

                }else {
                    nbiDatas.push(0);
                }
                if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.SAME_PERIOD) {
                    if(props.data[monthSelected].qtd){
                        if(props.data[monthSelected].qtd.previousYearValue){

                            nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[monthSelected].qtd.previousYearValue / 1000)));
                        }else {
                            nbiColulnOneDatas.push(0);
                        }
                    }else {
                        nbiColulnOneDatas.push(0);
                    }
                }
                else if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.BUDGET){
                    if(props.data[monthSelected].qtd){
                        if(props.data[monthSelected].qtd.currentPeriodBudget){
                            nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[monthSelected].qtd.currentPeriodBudget / 1000)));
                        }else {
                            nbiColulnOneDatas.push(0);
                        }
                    }else {
                        nbiColulnOneDatas.push(0);
                    }
                }
                else if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD){
                    if(props.data[monthSelected].qtd){
                        if(props.data[monthSelected].qtd.previousPeriodValue){
                            nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[monthSelected].qtd.previousPeriodValue / 1000)));
                        }else {
                            nbiColulnOneDatas.push(0);
                        }
                    }else {
                        nbiColulnOneDatas.push(0);
                    }
                }
            }
            else if((monthSelected+1)%3 === 0 ){
                for (let i =2 ;i<= monthSelected  ;i = i+ 3){
                    if( props.data[i].qtd ){
                        if(props.data[i].qtd.value){
                            nbiDatas.push(+(d3.format(".1f")(props.data[i].qtd.value / 1000)));
                        }
                        else {
                            nbiDatas.push(0);
                        }

                    }else {
                        nbiDatas.push(0);
                    }
                    if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.SAME_PERIOD) {
                        if(props.data[i].qtd){
                            if(props.data[i].qtd.previousYearValue){

                                nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[i].qtd.previousYearValue / 1000)));
                            }else {
                                nbiColulnOneDatas.push(0);
                            }
                        }else {
                            nbiColulnOneDatas.push(0);
                        }
                    }
                    else if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.BUDGET){
                        if(props.data[i].qtd){
                            if(props.data[i].qtd.currentPeriodBudget){
                                nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[i].qtd.currentPeriodBudget / 1000)));
                            }else {
                                nbiColulnOneDatas.push(0);
                            }
                        }else {
                            nbiColulnOneDatas.push(0);
                        }
                    }
                    else if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD){
                        if(props.data[i].qtd){
                            if(props.data[i].qtd.previousPeriodValue){
                                nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[i].qtd.previousPeriodValue / 1000)));
                            }else {
                                nbiColulnOneDatas.push(0);
                            }
                        }else {
                            nbiColulnOneDatas.push(0);
                        }
                    }
                }
            }
            else{
                let j = 3;
                if( props.data[2].qtd ){
                    if(props.data[2].qtd.value){
                        nbiDatas.push(+(d3.format(".1f")(props.data[2].qtd.value / 1000)));
                    }
                    else {
                        nbiDatas.push(0);
                    }

                }else {
                    nbiDatas.push(0);
                }
                if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.SAME_PERIOD) {
                    if(props.data[2].qtd){
                        if(props.data[2].qtd.previousYearValue){

                            nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[2].qtd.previousYearValue / 1000)));
                        }else {
                            nbiColulnOneDatas.push(0);
                        }
                    }else {
                        nbiColulnOneDatas.push(0);
                    }
                }
                else if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.BUDGET){
                    if(props.data[2].qtd){
                        if(props.data[2].qtd.currentPeriodBudget){
                            nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[2].qtd.currentPeriodBudget / 1000)));
                        }else {
                            nbiColulnOneDatas.push(0);
                        }
                    }else {
                        nbiColulnOneDatas.push(0);
                    }
                }
                else if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD){
                    if(props.data[2].qtd){
                        if(props.data[2].qtd.previousPeriodValue){
                            nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[2].qtd.previousPeriodValue / 1000)));
                        }else {
                            nbiColulnOneDatas.push(0);
                        }
                    }else {
                        nbiColulnOneDatas.push(0);
                    }
                }
                for (let i = 3; i<=monthSelected ; i=i+j){
                    if(i+j <= monthSelected){
                        if( props.data[i+j-1].qtd ){
                            if(props.data[i+j-1].qtd.value){
                                nbiDatas.push(+(d3.format(".1f")(props.data[i+j-1].qtd.value / 1000)));
                            }
                            else {
                                nbiDatas.push(0);
                            }

                        }else {
                            nbiDatas.push(0);
                        }
                        if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.SAME_PERIOD) {
                            if(props.data[i+j-1].qtd){
                                if(props.data[i+j-1].qtd.previousYearValue){

                                    nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[i+j-1].qtd.previousYearValue / 1000)));
                                }else {
                                    nbiColulnOneDatas.push(0);
                                }
                            }else {
                                nbiColulnOneDatas.push(0);
                            }
                        }
                        else if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.BUDGET){
                            if(props.data[i+j-1].qtd){
                                if(props.data[i+j-1].qtd.currentPeriodBudget){
                                    nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[i+j-1].qtd.currentPeriodBudget / 1000)));
                                }else {
                                    nbiColulnOneDatas.push(0);
                                }
                            }else {
                                nbiColulnOneDatas.push(0);
                            }
                        }
                        else if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD){
                            if(props.data[i+j-1].qtd){
                                if(props.data[i+j-1].qtd.previousPeriodValue){
                                    nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[i+j-1].qtd.previousPeriodValue / 1000)));
                                }else {
                                    nbiColulnOneDatas.push(0);
                                }
                            }else {
                                nbiColulnOneDatas.push(0);
                            }
                        }
                    }
                    else {
                        if( props.data[monthSelected].qtd ){
                            if(props.data[monthSelected].qtd.value){
                                nbiDatas.push(+(d3.format(".1f")(props.data[monthSelected].qtd.value / 1000)));
                            }
                            else {
                                nbiDatas.push(0);
                            }

                        }else {
                            nbiDatas.push(0);
                        }
                        if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.SAME_PERIOD) {
                            if(props.data[monthSelected].qtd){
                                if(props.data[monthSelected].qtd.previousYearValue){

                                    nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[monthSelected].qtd.previousYearValue / 1000)));
                                }else {
                                    nbiColulnOneDatas.push(0);
                                }
                            }else {
                                nbiColulnOneDatas.push(0);
                            }
                        }
                        else if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.BUDGET){
                            if(props.data[monthSelected].qtd){
                                if(props.data[monthSelected].qtd.currentPeriodBudget){
                                    nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[monthSelected].qtd.currentPeriodBudget / 1000)));
                                }else {
                                    nbiColulnOneDatas.push(0);
                                }
                            }else {
                                nbiColulnOneDatas.push(0);
                            }
                        }
                        else if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD){
                            if(props.data[monthSelected].qtd){
                                if(props.data[monthSelected].qtd.previousPeriodValue){
                                    nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[monthSelected].qtd.previousPeriodValue / 1000)));
                                }else {
                                    nbiColulnOneDatas.push(0);
                                }
                            }else {
                                nbiColulnOneDatas.push(0);
                            }
                        }
                    }
                }
            }
            setNbi(nbiDatas);
            setNbiColumnOne(nbiColulnOneDatas);
            setFinalXAxeValues(axeXQuarter);
        }
        else if (props.period === PERIOD_TYPE.YTD) {
            const xAxis:any=[];
            setEnableleScroll(true);
            setIsQuarter(false);
            for (let i =0 ;i<=monthSelected;i++){
                xAxis.push(axeXyear[i]);
                if( props.data[i].ytd && props.data[i].ytd !== undefined){
                    if(props.data[i].ytd.value){
                        nbiDatas.push(+(d3.format(".1f")(props.data[i].ytd.value / 1000)));
                    }else {
                        nbiDatas.push(0);
                    }
                }else {
                    nbiDatas.push(0);
                }
                if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.SAME_PERIOD) {
                    if(props.data[i].ytd){
                        if(props.data[i].ytd.previousYearValue){
                            nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[i].ytd.previousYearValue / 1000)));
                        }else {
                            nbiColulnOneDatas.push(0);
                        }
                    }else {
                        nbiColulnOneDatas.push(0);
                    }
                }
                else if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.BUDGET){
                    if(props.data[i].ytd){
                        if(props.data[i].ytd.currentPeriodBudget){
                            nbiColulnOneDatas.push(+(d3.format(".1f")(props.data[i].ytd.currentPeriodBudget / 1000)));
                        }else {
                            nbiColulnOneDatas.push(0);
                        }
                    }else {
                        nbiColulnOneDatas.push(0);
                    }
                }
            }
            setNbi(nbiDatas);
            setNbiColumnOne(nbiColulnOneDatas);
            setFinalXAxeValues(xAxis);
        }
        if(isSmallDevice && nbiDatas.length > 3){
            setXAxisNumber(2);
            setEnableleScroll(true);
        }
        else if(nbiDatas.length > 5 ){
            setXAxisNumber(5);
            setEnableleScroll(true);
        } else{
            setXAxisNumber(nbiDatas.length);
            setEnableleScroll(false);
        }

    };

    useEffect(() => {
        if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.BUDGET){
            setFirstColumnTitle(intl.formatMessage({id: 'budget'})+ year);
        }else if(props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD){
            setFirstColumnTitle( year + " ("  + props.period.toUpperCase() + " - 1)");
        }
        else{
            setFirstColumnTitle(String(year-1));
        }
        getXAxisTitles();
        if(monthSelected < 3 && props.period !== PERIOD_TYPE.WTD){
            setEnableleScroll(false);
        }
    },[props.data,props.period,props.comparisonType,enableScroll,xAxisNumber,isQuarter,isSmallDevice]);


    return (
        <div className='chart'>
            <HighchartsReact highcharts={Highcharts} allowChartUpdate={true} options={options} />
        </div>
    )

}