import DateService from "../../../services/DateService";
import {DayData} from "./HeatmapModels";

export default class HeatmapService {
  private static DATE_FORMAT: string = "DD MMM YYYY";
  private static INCREASE_COLOR_LIGHT: string = 'green';
  private static INCREASE_COLOR_DARK: string = 'dark-green';
  private static DECREASE_COLOR_LIGHT: string = 'red';
  private static DECREASE_COLOR_DARK: string = 'dark-red';
  private static NO_DATA_COLOR: string = '';
  private static MAXIMUM_WEEKS_OF_YEAR: number = 54;

  public static CONSTANT = {
    HEATMAP: {
      TYPE: {
        DAILY: 'daily',
        WEEKLY: 'weekly',
        MONTHLY: 'monthly',
        QUARTERLY: 'quarterly',
        YEARLY: 'yearly'
      },
      PREVIOUS_YEAR: 'previousYear',
      PREVIOUS_PERIOD: 'previousPeriod',
      TARGET: 'target',
      INTERVAL: 'interval',
      METHOD_VALUE: 'value',
      METHOD_PERCENT: 'percent',
      METHOD_EMPTY: 'empty',
      LAST: 'none',
      OFFICIAL: 'PUBLISHED',
      PROFORMA: 'STABILIZED'
    }
  };

  /**
   * return nbi value in 'Million'
   * @param heatmapType
   * @param data
   * @returns {number}
   */
  public static getNbiValueInMillion(heatmapType: string, data: any) {
    const value = this.getNbiValueInThousand(heatmapType, data);
    return value != undefined ? this.convertToMillion(heatmapType, value) : undefined;
  }

  public static getVariation(heatmapType: string, heatmapTargetType: string, comparison: string, data: any, budget: number, previousYearDataList: any[],
    daysBeforeCurrentDate: number, daysAfterCurrentDate: number, weeksBeforeCurrentWeek: number, weeksAfterCurrentWeek: number) {
    let variation: number = 0;
    let nbi: any;
    let targetNbi: number;
    if (this.isCompareToPreviousYear(comparison) || this.isCompareToPreviousPeriod(comparison)) {
      nbi = this.getNbiValueInThousand(heatmapType, data);
      targetNbi = this.getPreviousTargetValue(heatmapType, comparison, data, previousYearDataList, daysBeforeCurrentDate,
        daysAfterCurrentDate, weeksBeforeCurrentWeek, weeksAfterCurrentWeek);
      variation = nbi != undefined && targetNbi ? this.convertToMillion(heatmapType, nbi - targetNbi) : NaN;
    }
    else if (this.isCompareToTarget(comparison)) {
      nbi = this.getNbiValueInMillion(heatmapType, data);
      targetNbi = this.getTargetValueForComparisonTypeTarget(heatmapType, heatmapTargetType, comparison, data, budget);
      variation = nbi != undefined && targetNbi ? nbi - targetNbi : NaN;
    }
    return this.formatNumberWithDecimals(variation);
  }

  /**
   * get the percentage value for nbi compare to target. (nbi - target) / target
   * Be careful, the number scale of the target value (budget) is 'Million', and all the other nbi value (day, mtd, qtd, ytd)
   * is 'Thousand'.
   * @param heatmapType - DAILY/WEEKLY/MONTHLY/YEARLY
   * @param heatmapTargetType - DAILY/WEEKLY/MONTHLY/YEARLY
   * @param comparison - PREVIOUS_YEAR/PREVIOUS_PERIOD/TARGET/INTERVAL
   * @param data - current day data
   * @param budget
   * @param previousYearDataList
   * @param daysBeforeCurrentDate
   * @param daysAfterCurrentDate
   * @param weeksBeforeCurrentWeek
   * @param weeksAfterCurrentWeek
   * @returns {number}
   */
  public static getPercentageValue(heatmapType: string, heatmapTargetType: string, comparison: string, data: any, budget: number,
    previousYearDataList: any[], daysBeforeCurrentDate: number, daysAfterCurrentDate: number,
    weeksBeforeCurrentWeek: number, weeksAfterCurrentWeek: number) {
    let percentage: number;
    let nbi: any = 0;
    let targetNbi: number = 0;
    if (this.isCompareToPreviousYear(comparison) || this.isCompareToPreviousPeriod(comparison)) {
      nbi = this.getNbiValueInThousand(heatmapType, data);
      targetNbi = this.getPreviousTargetValue(heatmapType, comparison, data, previousYearDataList, daysBeforeCurrentDate,
        daysAfterCurrentDate, weeksBeforeCurrentWeek, weeksAfterCurrentWeek);
    }
    else if (this.isCompareToTarget(comparison)) {
      nbi = this.getNbiValueInThousand(heatmapType, data);
      targetNbi = this.getTargetValueForComparisonTypeTarget(heatmapType, heatmapTargetType, comparison, data, budget * 1000);
    }
    percentage = nbi != 0 && targetNbi != 0 && targetNbi !== 0 ? (nbi - targetNbi) / Math.abs(targetNbi) : NaN;
    return this.formatNumberWithDecimals(percentage * 100);
  }


  /**
   * When the comparison type is 'PREVIOUS PERIOD', the target value's number scale is 'Thousand'.
   * @param heatmapType
   * @param comparison
   * @param data
   * @param previousYearDataList
   * @param daysBeforeCurrentDate
   * @param daysAfterCurrentDate
   * @param weeksBeforeCurrentWeek
   * @param weeksAfterCurrentWeek
   * @returns {number}
   */
  private static getPreviousTargetValue(heatmapType: string, comparison: string, data: any, previousYearDataList: any[], daysBeforeCurrentDate: number,
    daysAfterCurrentDate: number, weeksBeforeCurrentWeek: number, weeksAfterCurrentWeek: number): number {
    let target: number = NaN;
    switch (heatmapType) {
      case HeatmapService.CONSTANT.HEATMAP.TYPE.DAILY:
        if (this.isCompareToPreviousYear(comparison)) {
          target = this.calculateAverageValue(previousYearDataList, data.previousYearDate, daysBeforeCurrentDate, daysAfterCurrentDate);
        }
        break;
      case HeatmapService.CONSTANT.HEATMAP.TYPE.WEEKLY:
        if (this.isCompareToPreviousYear(comparison)) {
          target = this.calculateAverageWtdValue(previousYearDataList, data, weeksBeforeCurrentWeek, weeksAfterCurrentWeek);
        }
        else if (this.isCompareToPreviousPeriod(comparison)) {
          target = data.previousWtd;
        }
        break;
      case HeatmapService.CONSTANT.HEATMAP.TYPE.MONTHLY:
        if (this.isCompareToPreviousYear(comparison)) {
          target = data.previousYearMtd;
        }
        else if (this.isCompareToPreviousPeriod(comparison)) {
          target = data.previousMtd;
        }
        break;
      case HeatmapService.CONSTANT.HEATMAP.TYPE.QUARTERLY:
        if (this.isCompareToPreviousYear(comparison)) {
          target = data.previousYearQtd;
        }
        else if (this.isCompareToPreviousPeriod(comparison)) {
          target = data.previousQtd;
        }
        break;
      case HeatmapService.CONSTANT.HEATMAP.TYPE.YEARLY:
        if (this.isCompareToPreviousYear(comparison)) {
          target = data.previousYearYtd;
        }
        break;
      default:
        console.error('Unknown heatmap type: ' + heatmapType);
    }
    return target ? target : NaN;
  }

  /**
   * Calculate average daily value on the date range from previousYearDate + before (before < 0) to previousYearDate + after
   * ie: previousYearDate = 2017-10-30
   *     before = -5
   *     after = 5
   * So we need to calculate daily value from 5 days before 2017-10-30 to 5 days after 2017-10-30.
   * @param previousYearDataList
   * @param previousYearDate
   * @param before - number of days before current date
   * @param after - number of days after current date
   * @returns {number}
   */
  private static calculateAverageValue(previousYearDataList: any[], previousYearDate: string, before: number, after: number): number {
    let result: number = NaN;
    const currentData = previousYearDataList.find(e => e.date === previousYearDate);
    if (currentData) {
      const currentDataIndex = previousYearDataList.findIndex(e => e.date === previousYearDate);
      const daysNumberBeforeCurrentDate = isNaN(before) || before > 0 ? 0 : Math.abs(before);
      const daysNumberAfterCurrentDate = isNaN(after) || after < 0 ? 0 : after;
      const dataBeforeCurrentDate: any[] = [];
      const dataAfterCurrentDate: any[] = [];

      // List of data before current date
      let minIndex = currentDataIndex - daysNumberBeforeCurrentDate;
      minIndex = minIndex < 0 ? 0 : minIndex;
      for (let cursorBeforeCurrentDate = currentDataIndex - 1; cursorBeforeCurrentDate >= minIndex; cursorBeforeCurrentDate--) {
        const data = previousYearDataList[cursorBeforeCurrentDate];
        if (data && !isNaN(data.day)) {
          dataBeforeCurrentDate.push(data);
        }
      }

      // List of data after current date
      let maxIndex = currentDataIndex + daysNumberAfterCurrentDate;
      maxIndex = maxIndex > previousYearDataList.length - 1 ? previousYearDataList.length - 1 : maxIndex;
      for (let cursorAfterCurrentDate = currentDataIndex + 1; cursorAfterCurrentDate <= maxIndex; cursorAfterCurrentDate++) {
        const data = previousYearDataList[cursorAfterCurrentDate];
        if (data && !isNaN(data.day)) {
          dataAfterCurrentDate.push(data);
        }
      }
      // Merge two data list
      let dataToCalculate = [...dataBeforeCurrentDate, ...dataAfterCurrentDate];
      // If current date has data, we should add it in data list.
      if (!isNaN(currentData.day)) {
        dataToCalculate = [...dataToCalculate, currentData];
      }
      const dailyList = dataToCalculate.map(e => e.day);
      // Calculate average value
      result = dailyList.length > 0 ? dailyList.reduce((a, b) => a + b) / dailyList.length : NaN;
    }
    return result;
  }

  private static calculateAverageWtdValue(previousYearDataList: any[], data: any, weeksBeforeCurrentWeek: number, weeksAfterCurrentWeek: number): number {
    let result: number = NaN;
    const before = isNaN(weeksBeforeCurrentWeek) || weeksBeforeCurrentWeek > 0 ? 0 : Math.abs(weeksBeforeCurrentWeek);
    const after = isNaN(weeksAfterCurrentWeek) || weeksAfterCurrentWeek < 0 ? 0 : weeksAfterCurrentWeek;

    const currentWeekOfYear: number = data.weekOfYear;
    const currentDayOfWeek: number = data.dayOfWeek;
    let weekRangeFrom = currentWeekOfYear - before;
    let weekRangeTo = currentWeekOfYear + after;
    weekRangeFrom = weekRangeFrom < 1 ? 1 : weekRangeFrom;
    weekRangeTo = weekRangeTo > HeatmapService.MAXIMUM_WEEKS_OF_YEAR ? HeatmapService.MAXIMUM_WEEKS_OF_YEAR : weekRangeTo;

    // Get the same day of week for weeks in [weekRangeFrom, weekRangeTo] in previous year
    const weeksInPreviousYear = previousYearDataList.filter(e => e.dayOfWeek === currentDayOfWeek &&
      e.weekOfYear >= weekRangeFrom && e.weekOfYear <= weekRangeTo);

    if (weeksInPreviousYear) {
      const weeklyList = weeksInPreviousYear.filter(e => !isNaN(e.wtd)).map(e => e.wtd);
      result = weeklyList.length > 0 ? weeklyList.reduce((a, b) => a + b) / weeklyList.length : NaN;
    }

    return result;
  }

  private static isCompareToPreviousYear(comparison: string): boolean {
    return comparison === HeatmapService.CONSTANT.HEATMAP.PREVIOUS_YEAR;
  }

  private static isCompareToPreviousPeriod(comparison: string): boolean {
    return comparison === HeatmapService.CONSTANT.HEATMAP.PREVIOUS_PERIOD;
  }

  private static isCompareToTarget(comparison: string): boolean {
    return comparison === HeatmapService.CONSTANT.HEATMAP.TARGET;
  }

  private static isCompareToInterval(comparison: string): boolean {
    return comparison === HeatmapService.CONSTANT.HEATMAP.INTERVAL;
  }

  /**
   * When the comparison type is 'TARGET', the target value's number scale is 'Million'.
   * @param heatmapType
   * @param heatmapTargetType
   * @param comparison
   * @param data
   * @param budget
   * @returns {number}
   */
  private static getTargetValueForComparisonTypeTarget(heatmapType: string, heatmapTargetType: string, comparison: string, data: any, budget: number): number {
    let target: number = 0;
    if (this.isCompareToTarget(comparison)) {
      if (heatmapType === HeatmapService.CONSTANT.HEATMAP.TYPE.DAILY) {
        if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.DAILY) {
          target = budget;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.WEEKLY) {
          target = budget / 5;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.MONTHLY) {
          target = budget * 12 / data.totalBusinessDaysInYear;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.QUARTERLY) {
          target = budget * 4 / data.totalBusinessDaysInYear;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.YEARLY) {
          target = budget / data.totalBusinessDaysInYear;
        }
      }
      else if (heatmapType === HeatmapService.CONSTANT.HEATMAP.TYPE.WEEKLY) {
        if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.DAILY) {
          target = budget * data.businessDaysInWeek;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.WEEKLY) {
          target = (budget / 5) * data.businessDaysInWeek;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.MONTHLY) {
          const targetDaily = budget * 12 / data.totalBusinessDaysInYear;
          target = targetDaily * data.businessDaysInWeek;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.QUARTERLY) {
          const targetDaily = budget * 4 / data.totalBusinessDaysInYear;
          target = targetDaily * data.businessDaysInWeek;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.YEARLY) {
          const targetDaily = budget / data.totalBusinessDaysInYear;
          target = targetDaily * data.businessDaysInWeek;
        }
      }
      else if (heatmapType === HeatmapService.CONSTANT.HEATMAP.TYPE.MONTHLY) {
        if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.DAILY) {
          target = budget * data.businessDaysInMonth;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.WEEKLY) {
          target = (budget / 5) * data.businessDaysInMonth;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.MONTHLY) {
          const targetDaily = budget * 12 / data.totalBusinessDaysInYear;
          target = targetDaily * data.businessDaysInMonth;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.QUARTERLY) {
          const targetDaily = budget * 4 / data.totalBusinessDaysInYear;
          target = targetDaily * data.businessDaysInMonth;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.YEARLY) {
          const targetDaily = budget / data.totalBusinessDaysInYear;
          target = targetDaily * data.businessDaysInMonth;
        }
      }
      else if (heatmapType === HeatmapService.CONSTANT.HEATMAP.TYPE.QUARTERLY) {
        if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.DAILY) {
          target = budget * data.businessDaysInQuarter;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.WEEKLY) {
          target = (budget / 5) * data.businessDaysInQuarter;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.MONTHLY) {
          const targetDaily = budget * 12 / data.totalBusinessDaysInYear;
          target = targetDaily * data.businessDaysInQuarter;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.QUARTERLY) {
          const targetDaily = budget * 4 / data.totalBusinessDaysInYear;
          target = targetDaily * data.businessDaysInQuarter;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.YEARLY) {
          const targetDaily = budget / data.totalBusinessDaysInYear;
          target = targetDaily * data.businessDaysInQuarter;
        }
      }
      else if (heatmapType === HeatmapService.CONSTANT.HEATMAP.TYPE.YEARLY) {
        if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.DAILY) {
          target = budget * data.businessDaysInYear;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.WEEKLY) {
          target = (budget / 5) * data.businessDaysInYear;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.MONTHLY) {
          const targetDaily = budget * 12 / data.totalBusinessDaysInYear;
          target = targetDaily * data.businessDaysInYear;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.QUARTERLY) {
          const targetDaily = budget * 4 / data.totalBusinessDaysInYear;
          target = targetDaily * data.businessDaysInYear;
        }
        else if (heatmapTargetType === HeatmapService.CONSTANT.HEATMAP.TYPE.YEARLY) {
          const targetDaily = budget / data.totalBusinessDaysInYear;
          target = targetDaily * data.businessDaysInYear;
        }
      }
      else {
        console.error('Unknown heatmap type or heatmap target type: ' + heatmapType + ' : ' + heatmapTargetType);
      }
    }
    return target;
  }

  /**
   * There are three different rules to calculate color for each compare type.
   *
   * 1. When compare type is PREVIOUS_YEAR or PREVIOUS_PERIOD
   *    * If the NBI remains on +/- 10% around the NBI (previous year / previous period)
   *      => INCREASE_COLOR_LIGHT / DECREASE_COLOR_LIGHT
   *    * If the NBI greater than 10% / less than -10% of the NBI of previous year or previous period
   *      => INCREASE_COLOR_DARK / DECREASE_COLOR_DARK
   *
   * 2. When compare type is INTERVAL
   *    * Inside the interval
   *      => INCREASE_COLOR_LIGHT
   *    * Outside the interval
   *      => DECREASE_COLOR_LIGHT
   *
   * 3. When compare type is TARGET (BUDGET)
   *    * If the NBI greater than BUDGET + AVERAGE DELTA VALUE for all NBI greater than BUDGET
   *      [sum(All NBI which are greater than BUDGET - BUDGET) / Number of NBI which are greater than BUDGET]
   *      => INCREASE_COLOR_DARK
   *    * If the NBI greater than BUDGET but less than BUDGET + AVERAGE DELTA VALUE for all NBI greater than BUDGET
   *      => INCREASE_COLOR_LIGHT
   *    * If the NBI less than BUDGET + AVERAGE DELTA VALUE for all NBI less than BUDGET
   *      [sum(All NBI which are less than BUDGET - BUDGET) / Number of NBI which are less than BUDGET]
   *      => DECREASE_COLOR_DARK
   *    * If the NBI less than BUDGET but greater than BUDGET + AVERAGE DELTA VALUE for all NBI less than BUDGET
   *      => DECREASE_COLOR_LIGHT
   *
   *    | DECREASE_COLOR_DARK | DECREASE_COLOR_LIGHT | INCREASE_COLOR_LIGHT | INCREASE_COLOR_DARK |
   *    |_____________________|______________________|______________________|_____________________|
   *                                              BUDGET
   * @param dataList
   * @param previousYearDataList
   * @param heatmapType - Daily/Monthly/Quarterly/Yearly
   * @param comparison - Budget/Previous Year/Previous Period/Interval
   * @param heatmapTargetType - Daily/Monthly/Quarterly/Yearly
   * @param budget
   * @param intervalMinValue - used when compare type is 'Interval'
   * @param intervalMaxValue: number, - used when compare type is 'Interval'
   * @param daysBeforeCurrentDate - used when period is 'Daily' and compare type is 'Y - 1'
   * @param daysAfterCurrentDate - used when period is 'Daily' and compare type is 'Y - 1'
   * @param weeksBeforeCurrentWeek - used when period is 'Weekly' and compare type is 'Y - 1'
   * @param weeksAfterCurrentWeek - used when period is 'Weekly' and compare type is 'Y - 1'
   * @param result
   */
  public static calculateColor(dataList: any[], previousYearDataList: any[], heatmapType: string, comparison: string, heatmapTargetType: string, budget: number,
    intervalMinValue: number, intervalMaxValue: number, daysBeforeCurrentDate: number, daysAfterCurrentDate: number,
    weeksBeforeCurrentWeek: number, weeksAfterCurrentWeek: number, result: any) {
    dataList.forEach(d => d.color = '');
    if (this.isCompareToTarget(comparison)) {
      this.getColorForCompareTypeTarget(dataList, heatmapType, comparison, heatmapTargetType, budget, result);
    }
    else if (this.isCompareToPreviousYear(comparison) || this.isCompareToPreviousPeriod(comparison)) {
      this.getColorForCompareTypePreviousYearOrPreviousPeriod(dataList, heatmapType, comparison, heatmapTargetType, budget, result,
        previousYearDataList, daysBeforeCurrentDate, daysAfterCurrentDate,
        weeksBeforeCurrentWeek, weeksAfterCurrentWeek);
    }
    else if (this.isCompareToInterval(comparison)) {
      this.getMinAndMaxCompareTypeInterval(dataList, heatmapType, result);
      if (intervalMinValue === 0 && intervalMaxValue === 0) {
        intervalMinValue = result.minValue;
        intervalMaxValue = result.maxValue;
      }
      this.getColorForCompareTypeInterval(dataList, heatmapType, intervalMinValue, intervalMaxValue, result);
    }
  }

  private static getColorForCompareTypePreviousYearOrPreviousPeriod(dataList: any[], heatmapType: string, comparison: string, heatmapTargetType: string, budget: number, result: any,
    previousYearDataList: any[], daysBeforeCurrentDate: number, daysAfterCurrentDate: number,
    weeksBeforeCurrentWeek: number, weeksAfterCurrentWeek: number) {
    dataList.forEach(d => {
      const nbi = this.getNbiValueInMillion(heatmapType, d);
      const percent = this.getPercentageValue(heatmapType, heatmapTargetType, comparison, d, budget,
        previousYearDataList, daysBeforeCurrentDate, daysAfterCurrentDate,
        weeksBeforeCurrentWeek, weeksAfterCurrentWeek);
      if (nbi != undefined) {
        result.total++;
        if (percent >= 0 || Number.isNaN(percent)) {
          d.color = percent > 10 ? HeatmapService.INCREASE_COLOR_DARK : HeatmapService.INCREASE_COLOR_LIGHT;
          result.aboveTarget++;
        }
        else {
          d.color = percent < -10 ? HeatmapService.DECREASE_COLOR_DARK : HeatmapService.DECREASE_COLOR_LIGHT;
          result.belowTarget++;
        }
      }
    });
  }

  private static getColorForCompareTypeTarget(dataList: any[], heatmapType: string, comparison: string, heatmapTargetType: string, budget: number, result: any) {
    const nbiGreaterThanBudgetList = [] as any[];
    const nbiLessThanBudgetList = [] as any[];
    dataList.forEach(d => {
      const nbi = this.getNbiValueInMillion(heatmapType, d);
      const nbiBudget = this.getTargetValueForComparisonTypeTarget(heatmapType, heatmapTargetType, comparison, d, budget);
      if (nbi != undefined) {
        d.budget = nbiBudget;
        const delta = nbi - nbiBudget;
        delta >= 0 ? nbiGreaterThanBudgetList.push(delta) : nbiLessThanBudgetList.push(delta);
      }
    });
    const averageDeltaValueForNbiGreaterThanBudget = nbiGreaterThanBudgetList.length > 0 ?
      nbiGreaterThanBudgetList.reduce((a, b) => a + b) / nbiGreaterThanBudgetList.length : 0;
    const averageDeltaValueForNbiLessThanBudget = nbiLessThanBudgetList.length > 0 ?
      nbiLessThanBudgetList.reduce((a, b) => a + b) / nbiLessThanBudgetList.length : 0;

    dataList.forEach(d => {
      const nbi = this.getNbiValueInMillion(heatmapType, d);
      if (nbi != undefined) {
        result.total++;
        if (nbi < (d.budget + averageDeltaValueForNbiLessThanBudget)) {
          d.color = HeatmapService.DECREASE_COLOR_DARK;
          result.belowTarget++;
        }
        else if (nbi >= (d.budget + averageDeltaValueForNbiLessThanBudget) && nbi <= d.budget) {
          d.color = HeatmapService.DECREASE_COLOR_LIGHT;
          result.belowTarget++;
        }
        else if (nbi >= d.budget && nbi <= (d.budget + averageDeltaValueForNbiGreaterThanBudget)) {
          d.color = HeatmapService.INCREASE_COLOR_LIGHT;
          result.aboveTarget++;
        }
        else if (nbi > (d.budget + averageDeltaValueForNbiGreaterThanBudget)) {
          d.color = HeatmapService.INCREASE_COLOR_DARK;
          result.aboveTarget++;
        }
      }
    });
  }

  private static getMinAndMaxCompareTypeInterval(dataList: any[], heatmapType: string, result: any) {
    dataList.forEach(d => {
      const nbi = this.getNbiValueInMillion(heatmapType, d);
      if (nbi != undefined) {
        result.minValue = Math.min(result.minValue, nbi);
        result.maxValue = Math.max(result.maxValue, nbi);
      }
      result.minValue = Math.floor(result.minValue);
      result.maxValue = Math.ceil(result.maxValue);
    });
  }

  private static getColorForCompareTypeInterval(dataList: any[], heatmapType: string, intervalMinValue: number, intervalMaxValue: number, result: any) {
    dataList.forEach(d => {
      const nbi = this.getNbiValueInMillion(heatmapType, d);
      if (nbi != undefined) {
        result.total++;
        if (nbi >= intervalMinValue && nbi <= intervalMaxValue) {
          d.color = nbi >= 0 ? HeatmapService.INCREASE_COLOR_DARK : HeatmapService.DECREASE_COLOR_DARK;
          result.withinInterval++;
        }
        else {
          d.color = HeatmapService.NO_DATA_COLOR;
          result.outsideInterval++;
        }
      }
    });
  }

  private static getNbiValueInThousand(heatmapType: string, data: DayData) {
    let value: any;
    switch (heatmapType) {
      case HeatmapService.CONSTANT.HEATMAP.TYPE.DAILY:
        value = data.day;
        break;
      case HeatmapService.CONSTANT.HEATMAP.TYPE.WEEKLY:
        value = data.wtd;
        break;
      case HeatmapService.CONSTANT.HEATMAP.TYPE.MONTHLY:
        value = data.mtd;
        break;
      case HeatmapService.CONSTANT.HEATMAP.TYPE.QUARTERLY:
        value = data.qtd;
        break;
      case HeatmapService.CONSTANT.HEATMAP.TYPE.YEARLY:
        value = data.ytd;
        break;
      default:
        console.error('Unknown heatmap type: ' + heatmapType);
    }
    return value;
  }

  /**
   * convert number to 'million'
   * @param heatmapType
   * @param valueInThousand
   * @returns {number}
   */
  private static convertToMillion(heatmapType: string, valueInThousand: number): number {
    let result: number = 0;
    switch (heatmapType) {
      case HeatmapService.CONSTANT.HEATMAP.TYPE.DAILY:
      case HeatmapService.CONSTANT.HEATMAP.TYPE.WEEKLY:
        result = Math.abs(valueInThousand) > 1000 ? Math.round(valueInThousand / 100) / 10 : Math.round(valueInThousand / 10) / 100;
        break;
      case HeatmapService.CONSTANT.HEATMAP.TYPE.MONTHLY:
      case HeatmapService.CONSTANT.HEATMAP.TYPE.QUARTERLY:
      case HeatmapService.CONSTANT.HEATMAP.TYPE.YEARLY:
        result = Math.round(valueInThousand / 100)/10;
        break;
      default:
        console.error('Unknown heatmap type: ' + heatmapType);
    }
    return result;
  }

  /**
   * format a number and keep one decimal by default
   * @param num
   * @param decimals
   * @returns {number}
   */
  private static formatNumberWithDecimals(num: number, decimals?: number): number {
    decimals = decimals && Number.isInteger(decimals) ? decimals : 1;
    if (Number.isInteger(num)) {
      return num;
    }
    else if (Number.isFinite(num)) {
      return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
    }
    return NaN;
  }

  public getMaxNumberWeeksOfYear(): number {
    return HeatmapService.MAXIMUM_WEEKS_OF_YEAR;
  }

  public static getBudget(dataList: any[], date: string, heatmapTargetType: string): number {
    const dayDatas = dataList.filter(data => data.date === date);
    if (dayDatas.length === 0) return 0;
    const dayData = dayDatas[0];
    const budgetYear = dayData.budgetYear || 0;
    const totalBusinessDaysInYear = dayData.totalBusinessDaysInYear;
    const budgetDaily = budgetYear / totalBusinessDaysInYear;
    let budget = 0;
    switch (heatmapTargetType) {
      case HeatmapService.CONSTANT.HEATMAP.TYPE.DAILY:
        budget = Math.round(budgetDaily) / 1000;
        break;
      case HeatmapService.CONSTANT.HEATMAP.TYPE.WEEKLY:
        budget = Math.round(budgetDaily / 2) / 100;
        break;
      case HeatmapService.CONSTANT.HEATMAP.TYPE.MONTHLY:
        budget = Math.round(budgetYear / 120) / 100;
        break;
      case HeatmapService.CONSTANT.HEATMAP.TYPE.QUARTERLY:
        budget = Math.round(budgetYear / 40) / 100;
        break;
      case HeatmapService.CONSTANT.HEATMAP.TYPE.YEARLY:
        budget = Math.round(budgetYear / 100) / 10;
        break;
    }

    return budget;
  }

  public static getDayOfWeek(dateString: string) {
    const dayOfWeek = new Date(dateString).getDay();
    return isNaN(dayOfWeek) ? null : 
      ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];
  }

  public static getComparedDay(d: any, previousYearData: any[], heatmapType: string, comparison: string,
    weeksBeforeCurrentWeek: number, weeksAfterCurrentWeek: number,
    daysBeforeCurrentDate: number, daysAfterCurrentDate: number): string {
    let formatDate = '';
    let dateCompared = '';
    if (d.date) {
      if (heatmapType === HeatmapService.CONSTANT.HEATMAP.TYPE.DAILY && comparison === HeatmapService.CONSTANT.HEATMAP.PREVIOUS_YEAR) {
        const previousYearDate = d.previousYearDate;
        const daysBeforeDate = isNaN(daysBeforeCurrentDate) || daysBeforeCurrentDate > 0 ? 0 : Math.abs(daysBeforeCurrentDate);
        const daysAfterDate = isNaN(daysAfterCurrentDate) || daysAfterCurrentDate < 0 ? 0 : daysAfterCurrentDate;
        const previousDataIndex = previousYearData.findIndex(e => e.date === previousYearDate);

        let minIndex = previousDataIndex - daysBeforeDate;
        minIndex = minIndex < 0 ? 0 : minIndex;

        let maxIndex = previousDataIndex + daysAfterDate;
        maxIndex = maxIndex > previousYearData.length - 1 ? previousYearData.length - 1 : maxIndex;

        const beginningDate = previousYearData[minIndex] ? previousYearData[minIndex].date : '';
        const endDate = previousYearData[maxIndex] ? previousYearData[maxIndex].date : '';

        return `(vs AVG of DAYs. Period: ${DateService.formatDateWithPattern(beginningDate, HeatmapService.DATE_FORMAT)} 
                to ${DateService.formatDateWithPattern(endDate, HeatmapService.DATE_FORMAT)})`;
      }
      else if (heatmapType === HeatmapService.CONSTANT.HEATMAP.TYPE.WEEKLY && comparison === HeatmapService.CONSTANT.HEATMAP.PREVIOUS_YEAR) {
        const currentDayOfWeek = d.dayOfWeek;
        const currentWeekOfYear = d.weekOfYear;
        const before = isNaN(weeksBeforeCurrentWeek) || weeksBeforeCurrentWeek > 0 ? 0 : Math.abs(weeksBeforeCurrentWeek);
        const after = isNaN(weeksAfterCurrentWeek) || weeksAfterCurrentWeek < 0 ? 0 : weeksAfterCurrentWeek;

        const maximumWeeksOfYear: number = HeatmapService.MAXIMUM_WEEKS_OF_YEAR;
        let weekRangeFrom = currentWeekOfYear - before;
        let weekRangeTo = currentWeekOfYear + after;
        weekRangeFrom = weekRangeFrom < 1 ? 1 : weekRangeFrom;
        weekRangeTo = weekRangeTo > maximumWeeksOfYear ? maximumWeeksOfYear : weekRangeTo;

        const weeksInpreviousYear = previousYearData.filter(e => e.dayOfWeek === currentDayOfWeek &&
            e.weekOfYear >= weekRangeFrom && e.weekOfYear <= weekRangeTo);
        if (weeksInpreviousYear) {
          weeksInpreviousYear.sort((a, b) => a.weekOfYear - b.weekOfYear);
        }
        const beginningDate = weeksInpreviousYear && weeksInpreviousYear.length > 0 ? weeksInpreviousYear[0].date : '';
        const endDate = weeksInpreviousYear && weeksInpreviousYear.length > 0 ? weeksInpreviousYear[weeksInpreviousYear.length - 1].date : '';
        const day = HeatmapService.getDayOfWeek(d.date);

        return `(vs AVG of ${day}s. Period: ${DateService.formatDateWithPattern(beginningDate, HeatmapService.DATE_FORMAT)} 
                to ${DateService.formatDateWithPattern(endDate, HeatmapService.DATE_FORMAT)})`;
      }
      else if (comparison === HeatmapService.CONSTANT.HEATMAP.PREVIOUS_YEAR) {
        dateCompared = d.previousYearDate;
      }
      else if (heatmapType === HeatmapService.CONSTANT.HEATMAP.TYPE.WEEKLY && comparison === HeatmapService.CONSTANT.HEATMAP.PREVIOUS_PERIOD) {
        dateCompared = d.previousWeekDate;
      }
      else if (heatmapType === HeatmapService.CONSTANT.HEATMAP.TYPE.MONTHLY && comparison === HeatmapService.CONSTANT.HEATMAP.PREVIOUS_PERIOD) {
        dateCompared = d.previousMonthDate;
      }
      else if (heatmapType === HeatmapService.CONSTANT.HEATMAP.TYPE.QUARTERLY && comparison === HeatmapService.CONSTANT.HEATMAP.PREVIOUS_PERIOD) {
        dateCompared = d.previousQuarterDate;
      }
    }
    if (dateCompared) {
      formatDate = `(vs ${DateService.formatDateWithPattern(dateCompared, HeatmapService.DATE_FORMAT)})`;
    }
    return formatDate;
  }
}

