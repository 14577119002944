import React, {useEffect, useRef, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useHistory} from 'react-router-dom';
import {TreeSelect} from "../selectGroup/TreeSelect";
import {IRouteParams} from "../route/IRouteParams";
import {useRouteContext} from "../../../context/RouteContext/RouteContext";
import DatePicker from "../dateTimePicker/DatePicker";
import {Switch} from "../switch/Switch";
import {FetchAvailableDataType, FetchScopeList, getCurrentUser} from "../../../services/CommonService";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {DefaultRegionOption, IRegion, RegionOptions} from "../../../services/region/RegionService";
import {DataAvailabilityService} from "../../../services/event/DataAvailabilityService";
import {TitleService} from "../../../services/event/TitleService";
import {first} from 'rxjs/operators';
import cloneDeep from "lodash/cloneDeep";
import UtilsService from "../../../services/utils/UtilsService";
import {DataViewType} from "../DataViewType";
import {first as _first, last as _last} from 'lodash';
import {useViewport} from "../../../context/ViewportContext";
import DateService from "../../../services/DateService";

import './SynthesisFilter.scss';

const URL_SEPARATOR = "/";

interface IHomeFilterProps {
  params: IRouteParams;
  isLoading: boolean;
  isAdmin: boolean | undefined;
  isConfirmed: boolean;
  confirm: () => void;
  unvalidate: () => void;
  confirmAll: () => void;
}

interface IScope {
  id: number;
  value: string;
  name: string;
  order: number;
  location: string;
  dataView: string | undefined;
  isChild: boolean;
  parentId?: number;
  parentName?: string
  children?: IScope[];
}

export const SynthesisFilter: React.FC<IHomeFilterProps> = ({
                                                              params, isLoading, isAdmin, isConfirmed,
                                                              confirm, unvalidate, confirmAll
                                                            }) => {
  const history = useHistory();
  const [regionOptions, setRegionOptions] = useState(RegionOptions);
  const [scopeOptions, setScopeOptions] = useState([] as any);
  const [defaultRegionsOption, setDefaultRegionOption] = useState("");
  const [defaultScopeOption, setDefaultScopeOption] = useState("");
  const [lastDate, setLastDate] = useState("");
  const {scopes, setScopes, importTypeChanged} = useRouteContext();
  const [showProformaSwitch, setShowProformaSwitch] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedScope, setSelectedScope] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const {isSmallDevice} = useViewport();

  // A map to keep filtered scope list with dataView: {"FPV": IScope[], "REGIONS": IScope[]}
  const dataViewToWWScopes = useRef(new Map<DataViewType, Array<IScope>>());
  const wwScopeList = useRef([] as IScope[]);
  const asiaScopeList = useRef([] as IScope[]);
  const fullScopeOptions = useRef([] as IScope[]);
  const scopeRef = useRef<string>(params.scope);
  const subScopeRef = useRef<string | undefined>(params.subScope);
  const wwScopeListFpv = useRef([] as IScope[]);
  const wwScopeListRegion = useRef([] as IScope[]);
  const perimeterList: any[] = [];

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const subscription = FetchAvailableDataType(params.date, parseInt(params.scope))
        .subscribe(response => {
          if (response) {
            fillDataTypeByDataViewInfos(response);
          }
        });
    return () => subscription.unsubscribe();
  }, [params.date, params.scope]);

  const init = () => {
    const lastAvailableDate = params.date;
    const userLocation = params.userLocation;
    const regionCode = params.region;
    const scopeValue = params.scope;
    const subScopeValue = params.subScope;
    setLastDate(lastAvailableDate);
    setSelectedDate("");
    setSelectedScope("");
    setSelectedRegion("");

    const scopeListFromGlobalContext = scopes;
    if (!Array.isArray(scopeListFromGlobalContext) || !scopeListFromGlobalContext.length) {
      // Data doesn't exist in Global Context then retrieve it from API
      FetchScopeList(lastAvailableDate)
          .pipe(first())
          .subscribe(scopeList => {
            setScopes(scopeList);
            prepareData(scopeList as any, lastAvailableDate, userLocation, regionCode, scopeValue, subScopeValue, false)
          });
    } else {
      // Data already exist in Global Context
      prepareData(scopeListFromGlobalContext, lastAvailableDate, userLocation, regionCode, scopeValue, subScopeValue, false)
    }
  };
  /**
   * remove from scopeList items for regions views
   * MARK UK is only WW region
   * @param scopeList
   */
  const removeRegionBUForMarkUk = (scopeList: IScope[]) => {
    let result: IScope[] = []
    if (Array.isArray(scopeList) && scopeList.length) {
      result = cloneDeep(scopeList);
      result.forEach(scope => {
        if (scope.children && scope.children.length && UtilsService.isMarkUK(scope.name)) {
          scope.children = scope.children.filter((e: any) => e.dataView === DataViewType.FPV);
        }
      })
    }
    return result;
  }

  /**
   * filter scope list with user access perimeter
   * @param perimeters
   */
  const removeScopeUserDontHaveAccess = (perimeters: any) => {
    let result = [];
    if (Array.isArray(perimeters) && perimeters.length) {
      result = cloneDeep(perimeters);
      result.forEach(scope => {
        let regionUserList = []
        if (perimeterList.find(value => value.reportingUnitId == scope.id)) {
          regionUserList = perimeterList.filter(value => value.reportingUnitId == scope.id)

          if (scope.children && scope.children.length) {
            if (regionUserList.length == 1) {
              regionUserList.forEach(value => {
                const regionOption: IRegion | undefined = regionOptions.find(e => e.value === value.regionId);
                const isRegionView = regionOption ? regionOption.dataView === DataViewType.REGION : false;
                if (isRegionView) {
                  scope.children = scope.children.filter((e: any) => e.dataView === DataViewType.REGION);
                }
              })
            }
          }
        }
      })
    }
    return result;
  }

  const setDataViewsScopeList = (scopeOptionList: IScope[]) => {
    let regionList = scopeOptionList.filter(value => value.dataView == DataViewType.REGION || value.dataView == undefined)
    let fpvList = scopeOptionList.filter(value => value.dataView == DataViewType.FPV || value.dataView == undefined)

    fpvList?.forEach(value => {
      if (!regionList.find(value1 => (value1.id == value.id || value1.name == value.name))) {
        regionList.push(value)
      }
    })
    regionList?.forEach(value => {
      if (!fpvList.find(value1 => (value1.id == value.id || value1.name == value.name))) {
        fpvList.push(value)
      }
    })
    wwScopeListFpv.current = fpvList;
    wwScopeListRegion.current = regionList;
  }

  const prepareData = (scopeList: Array<any>, lastAvailableDate: string, userLocation: string, regionCode: string | undefined,
                       scopeValue: string, subScopeValue: string | undefined, useDefaultOption: boolean = false) => {

    const subscription = getCurrentUser()
        .pipe(first())
        .subscribe((user: any) => {
          user.perimeters.forEach((p: any) => perimeterList.push(p));
          const scopeOptionList: IScope[] = scopeList.length ? constructScopeList(scopeList) : [];
          fullScopeOptions.current.length = 0;
          wwScopeList.current.length = 0;
          wwScopeListFpv.current.length = 0;
          wwScopeListRegion.current.length = 0;
          asiaScopeList.current.length = 0;
          dataViewToWWScopes.current.clear();

          fullScopeOptions.current = cloneDeep(scopeOptionList);
          setSelectedDate(DateService.formatDateWithPattern(lastAvailableDate, "DD MMM YYYY"));

          // Separate scope list for different user location (ww and asia)
          scopeOptionList.forEach(scope => {
            if (UtilsService.isWorldWideByCode(scope.location)) {
              wwScopeList.current.push(scope);
            } else if (UtilsService.isAsiaByCode(scope.location)) {
              asiaScopeList.current.push(scope);
            }
          });
          setDataViewsScopeList(scopeOptionList);
          const wwScopeFilteredWithFPV = filterSubScopeWithDataView(wwScopeListFpv.current, DataViewType.FPV);
          const wwScopeFilteredWithRegions = filterSubScopeWithDataView(wwScopeListRegion.current, DataViewType.REGION);
          dataViewToWWScopes.current.set(DataViewType.FPV, wwScopeFilteredWithFPV);
          dataViewToWWScopes.current.set(DataViewType.REGION, wwScopeFilteredWithRegions);

          const finalScopeValue = subScopeValue ? subScopeValue : scopeValue;
          const finalScope: IScope | undefined = findScopeWithValue(fullScopeOptions.current, scopeValue);
          const finalScopeName = finalScope ? finalScope.name : "";
          const regionOptionList: IRegion[] = getRegionListForScope(scopeValue, finalScopeName);
          initGlobalFilters(lastAvailableDate, regionOptionList, userLocation, regionCode, finalScopeValue, useDefaultOption);
        });
    return () => subscription.unsubscribe();
  };

  const getRegionListForScope = (scopeValue: string, finalScopeName: string) => {
    const regionIdList: number[] = [];
    // get regionId list for selected scope
    perimeterList.forEach((p: any) => {
      if (p.reportingUnitId == scopeValue) {
        regionIdList.push(p.regionId)
      }
    });
    //MARK UK only has option ww, other BU/SBU ww should use RegionOptions
    //For BU level, regionIdList includes 4 means worldwide.
    //Because each SBU has 2 different scope FPV and REGIONS,for SBU regionIdList is [1,2,3] means worldwide
    const worldWideValue = RegionOptions[0].value;
    return ((regionIdList.includes(worldWideValue) || regionIdList.length == 3)
        && (!UtilsService.isMarkUKOrChild(finalScopeName) && !UtilsService.isMarkUK(scopeValue))) ? RegionOptions
        : RegionOptions.filter(region => regionIdList.includes(region.value));
  }

  const initGlobalFilters = (lastAvailableDate: string, regionList: Array<IRegion>, userLocation: string,
                             regionCode: string | undefined, scopeValue: string, useDefaultOption: boolean = false) => {
    // Retrieve region value with given code for WW
    const regionOption: IRegion = regionList.find(e => e.code === regionCode) || DefaultRegionOption;
    const regionNameInTitle = UtilsService.isWorldWideByCode(userLocation) ? regionOption.name : undefined;
    let regionOptionsList: IRegion[] = [];
    let defaultRegionOption: string = "";
    let scopeList: IScope[] | undefined = [];

    if (UtilsService.isAsiaByCode(userLocation)) {
      scopeList = asiaScopeList.current;
    } else if (UtilsService.isWorldWideByCode(userLocation)) {
      // review
      regionOptionsList = regionList;
      defaultRegionOption = regionOption.value.toString();
      scopeList = dataViewToWWScopes.current.get(regionOption.dataView) || [];
      setSelectedRegion(regionOption.name);
    }

    DataAvailabilityService.send(Array.isArray(scopeList) && (scopeList.length > 0));
    setRegionOptions(regionOptionsList);
    setScopeOptions(scopeList);
    setDefaultRegionOption(defaultRegionOption);

    // Check if user location in params correspond to location in scope entity
    const scope: IScope | undefined = findScopeWithValue(scopeList || [], scopeValue);
    if (scope && scope.location === userLocation) {
      setDefaultScopeOption(scopeValue);
      setSelectedScope(scope.name);
      const scopeNames: string[] = findScopeNames(scope, scopeList || []);
      constructTitleAndBroadcast(_first(scopeNames), _last(scopeNames), regionNameInTitle);
    } else if (useDefaultOption) {
      // 1. Scope doesn't exist in the list according to scope value passed in url
      // 2. User location in url doesn't correspond to location in scope selected
      // Select first option in scope list
      const firstScope = _first(scopeList);
      if (firstScope) {
        setDefaultScopeOption(firstScope.value);
        scopeRef.current = firstScope.value;
        subScopeRef.current = undefined;
        const scopeNames: string[] = findScopeNames(firstScope, scopeList || []);
        constructTitleAndBroadcast(_first(scopeNames), _last(scopeNames), regionNameInTitle);
        goTo(lastAvailableDate, userLocation, regionCode, scopeRef.current, subScopeRef.current);
      } else {
        goToHomePage();
      }
    }
  };

  /**
   * Return an array with two elements
   * first is sub scope name if it exists otherwise bu scope name
   * second is bu scope name
   * @param scope
   * @param scopeList
   */
  const findScopeNames = (scope: IScope, scopeList: Array<IScope>) => {
    let scopeName: string = scope.name;
    let buScopeName: string = scope.name;
    if (scope.isChild) {
      // In case scope is SBU level, then we need to find his parent and get parent name
      const parentScope = findScopeWithValue(scopeList, String(scope.parentId));
      buScopeName = parentScope ? parentScope.name : "";
    }
    return [scopeName, buScopeName];
  };

  const findScopeWithValue = (scopeList: Array<IScope>, value: string) => {
    let result;
    if (Array.isArray(scopeList) && scopeList.length) {
      result = scopeList.find(scope => scope.value === value);
    }
    return result ? result : scopeList.flatMap(scope => scope.children || []).find(scope => scope.value === value);
  };

  /**
   * Find scope with given name and dataView
   * Method is called when region changed from one to another to find the same scope (name)
   * !! scope id is different between FPV and REGIONS for same scope name !!
   */
  const findScopeWithName = (scopeList: Array<IScope> | undefined, name: string, parentName: string | undefined, dataView: string) => {
    let result;
    if (Array.isArray(scopeList) && scopeList.length) {
      result = scopeList.find(scope => scope.name === name);
    }
    return result ? result :
        scopeList?.flatMap(scope => scope.children || [])
            .filter(scope => scope.dataView === dataView)
            .find(scope => scope.name === name && scope.parentName === parentName);
  };

  const constructScopeList = (perimeters: Array<any>, isChild: boolean = false, parentId?: number, parentName?: string) => {
    const scopeList: Array<any> = perimeters.map((perimeter: any) => {
      let scope: IScope = {
        id: perimeter.id,
        value: String(perimeter.id),
        name: perimeter.code,
        order: perimeter.order,
        location: perimeter.location.code.toLowerCase(), // relation between scope (perimeter) and region (location)
        dataView: perimeter.dataView, // undefined for top level units
        parentId, // parent scope id
        parentName, // parent scope name
        isChild
      };

      if (perimeter.children && perimeter.children.length) {
        scope = {
          ...scope,
          children: constructScopeList(perimeter.children, true, scope.id, scope.name)
        };
      }
      return scope;
    });
    const scopeListWithoutMarkUkRegionBU = removeRegionBUForMarkUk(scopeList)
    const userScopeList = removeScopeUserDontHaveAccess(scopeListWithoutMarkUkRegionBU)
    const uniqueScopeList = removeDuplicateItems(userScopeList);
    return uniqueScopeList.sort((e1: any, e2: any) => e1.order - e2.order);
  };

  const removeDuplicateItems = (list: Array<any>) => {
    // Remove duplicate items
    const set = new Set();
    return list.reduce((acc, item) => {
      if (!set.has(item.id)) {
        set.add(item.id);
        acc.push(item);
      }
      return acc;
    }, []);
  };

  const filterSubScopeWithDataView = (scopeList: Array<any>, dataView: string) => {
    let result = [];
    if (Array.isArray(scopeList) && scopeList.length) {
      result = cloneDeep(scopeList);
      result.forEach(scope => {
        let filteredChildrenList = []
        if (scope.children && scope.children.length &&
            !(((UtilsService.isMarkUK(scope.name) || UtilsService.isMarkUK(scope.parentName))) &&
                dataView == DataViewType.REGION)) {
          filteredChildrenList = scope.children.filter((e: any) => e.dataView === dataView);
          if (filteredChildrenList.length == 0) {
            filteredChildrenList = scope.children.filter((e: any) => e.dataView !== dataView);
          }
          scope.children = filteredChildrenList
        }
      })
    }
    return result;
  };

  const goTo = (date: string, user: string, region: string | undefined, scope: string, subScope: string | undefined) => {
    const urlParams = [];
    const baseUrl = "/synthesis";
    urlParams.push(baseUrl, user);
    if (region) {
      urlParams.push(region);
    }
    urlParams.push(scope);
    if (subScope) {
      urlParams.push(subScope);
    }
    urlParams.push(date);
    const path = urlParams.join(URL_SEPARATOR);
    history.push(path);
  };

  const handleDateChange = (date: string) => {
    date = date !== '' ? date : params.date
    if (params.date !== date) {
      const formattedDate = DateService.formatDateWithPattern(date, "DD MMM YYYY");
      setSelectedDate(formattedDate);
      // Retrieve scope list when date changed
      FetchScopeList(date)
          .pipe(first())
          .subscribe(scopeList => {
            DataAvailabilityService.send(Array.isArray(scopeList) && (scopeList.length > 0));
            setScopes(scopeList);
            const userLocation = params.userLocation;
            let region = params.region;

            let scope = params.scope;
            let subScope = params.subScope; // check
            if (Array.isArray(scopeList) && (scopeList.length > 0) && !scopeList?.find((e: { id: any; }) => e.id == params.scope)) {
              scope = _first(scopeList).id
            }
            if (UtilsService.isMarkUKOrChild(scope)) {
              region = UtilsService.getMarkUK().id
            }
            scopeRef.current = scope;
            subScopeRef.current = subScope;
            // scopeRef and subScopeRef can be reassigned in case current scope doesn't exist in the new scope list
            prepareData(scopeList as any, date, userLocation, region, scope, subScope, true);
            goTo(date, userLocation, region, scopeRef.current, subScopeRef.current);
          });
    }
  };


  const handleRegionsChangeForMARKUK = (value: number, name: string) => {
    const scopeList = dataViewToWWScopes.current.get(DataViewType.FPV)
    setScopeOptions(scopeList);
    setSelectedRegion(name);
    DataAvailabilityService.send(Array.isArray(scopeList) && (scopeList.length > 0));
  };

  const handleRegionsChange = (value: number, name: string) => {
    const regionOption: IRegion = RegionOptions.find(e => e.value === value) || DefaultRegionOption;
    const scopeList: IScope[] = dataViewToWWScopes.current.get(regionOption.dataView) || [];
    const currentScopeValue = params.subScope ? params.subScope : params.scope;
    const currentScope: IScope | undefined = findScopeWithValue(fullScopeOptions.current, currentScopeValue);
    const currentScopeName = currentScope ? currentScope.name : "";
    const parentScopeName = currentScope && currentScope.isChild ? currentScope.parentName : undefined;
    let newScope: IScope | undefined = findScopeWithName(scopeList, currentScopeName, parentScopeName, regionOption.dataView);
    setScopeOptions(scopeList);
    setSelectedRegion(name);
    DataAvailabilityService.send(Array.isArray(scopeList) && (scopeList.length > 0));

    if (!newScope) {
      // No scope found for new region option, then use the first element in scope list
      newScope = _first(scopeList);
    }

    if (newScope) {
      setDefaultScopeOption(newScope.value);
      setSelectedScope(newScope.name);
      let scopeValue: string;
      let subScopeValue: string | undefined;
      if (newScope.isChild) {
        // SBU level
        scopeValue = String(newScope.parentId);
        subScopeValue = String(newScope.id);
      } else {
        // BU level
        scopeValue = String(newScope.id);
      }
      const scopeNames: string[] = findScopeNames(newScope, scopeList);
      constructTitleAndBroadcast(_first(scopeNames), _last(scopeNames), regionOption.name);

      goTo(params.date, params.userLocation, regionOption.code, scopeValue, subScopeValue);
    } else {
      goToHomePage();
    }
  };


  const handleScopeChange = (value: string, name: string) => {
    const subscription = getCurrentUser()
        .pipe(first())
        .subscribe((user: any) => {
          user.perimeters.forEach((p: any) => perimeterList.push(p));
          getRegionOptionForScope(value, name);
        });
    return () => subscription.unsubscribe();
  };

  const displayRegionList = () => {
    // In case of "Asia" page, regionOptions is empty
    return Array.isArray(regionOptions) && regionOptions.length > 0;
  };

  const goToHomePage = () => {
    history.push(URL_SEPARATOR);
  };

  const constructTitleAndBroadcast = (scopeName?: string, buScopeName?: string, regionName?: string) => {
    const finalScopeName: string | undefined = scopeName == buScopeName ? scopeName : buScopeName + " | " + scopeName;
    const title: string = (finalScopeName ?? "") + (regionName ? " " + (regionName === 'Worldwide' ? "" : regionName) : "");
    TitleService.broadcast(title);
  };

  //set data version type to route context to pass it to other widgets
  const handleDataVersionType = (isStab: boolean) => {
    importTypeChanged(isStab);
  };

  const fillDataTypeByDataViewInfos = (data: any) => {
    //if dataView is FPV or REGION && proforma data is available we show switch component
    const isProformaAvailable = data.FPV?.includes('STABILIZED');
    const isProformaAvailableForRegion = data.REGIONS?.includes('STABILIZED');
    const regionOption: IRegion | undefined = regionOptions.find(e => e.code === params.region);
    const isRegionSelected = regionOption ? regionOption.dataView === DataViewType.REGION : false;
    const isShow = isRegionSelected ? isProformaAvailableForRegion : isProformaAvailable;
    if (!isProformaAvailable && !isProformaAvailableForRegion) {
      importTypeChanged(false);
    }
    setShowProformaSwitch(isShow);
  };

  const displayFilterMenu = () => {
    return isSmallDevice && showFilter || !isSmallDevice;
  };

  const onClickFilterIcon = () => {
    setShowFilter(!showFilter);
  };

  const getRegionOptionForScope = (value: string, name: string) => {
    const scope: IScope | undefined = findScopeWithValue(scopeOptions, value);
    if (scope) {
      setSelectedScope(name ?? "");
      const scopeValue = scope.isChild ? String(scope.parentId) : String(scope.id);
      const subScopeValue = scope.isChild ? String(scope.id) : "";
      const finalRegionOptions: IRegion[] = getRegionListForScope(scopeValue, name);
      //Default regionOption is the first element of regionOptions
      const regionOption: IRegion = finalRegionOptions.find(e => e.code === params.region) || finalRegionOptions[0];
      const defaultRegion = regionOption.value.toString();
      const regionPath = regionOption.code;
      const scopeNames: string[] = findScopeNames(scope, scopeOptions || []);
      if (UtilsService.isMarkUKOrChild(name)) {
        handleRegionsChangeForMARKUK(DefaultRegionOption.value, DefaultRegionOption.name)
      } else {
        handleRegionsChange(regionOption.value, regionOption.name)
      }
      setDefaultScopeOption(value);
      const regionNameInTitle = UtilsService.isWorldWideByCode(params.userLocation) ? regionOption.name : undefined;
      constructTitleAndBroadcast(_first(scopeNames), _last(scopeNames), regionNameInTitle);

      setRegionOptions(finalRegionOptions);
      setDefaultRegionOption(defaultRegion);
      goTo(params.date, params.userLocation, regionPath, scopeValue, subScopeValue);
    } else {
      goToHomePage();
    }
  }

  return (
      <div className="global-filter sticky bg-lvl1 pt-2 pt-sm-4">
        <div className="border-bottom">
            <div className="ps-md-4 pe-md-4">
                <div className="mb-4">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                        <div className="display-3 me-3">
                            <h1>
                                <FormattedMessage id="title"/>
                            </h1>
                        </div>
                        {!isLoading && isAdmin &&
                            <UncontrolledDropdown className="d-none d-md-block">
                                {isConfirmed ?
                                    <DropdownToggle className="btn btn-success text-white ms-3">
                                        <FormattedMessage id="data.status.confirmed"/>
                                        <em className="icon icon-md ms-1">arrow_drop_down</em>
                                    </DropdownToggle> :
                                    <DropdownToggle className="btn btn-danger text-white ms-3">
                                        <FormattedMessage id="data.status.not.validated"/>
                                        <em className="icon icon-md ms-1">arrow_drop_down</em>
                                    </DropdownToggle>
                                }
                                <DropdownMenu>
                                    {!isConfirmed ?
                                        <DropdownItem>
                                            <div className="d-flex green">
                          <span>
                            <span><FormattedMessage id="admin.data.set"/></span>
                            <a className="btn btn-sm btn-success text-white mx-1" onClick={confirm}>
                              <FormattedMessage id="data.status.confirmed"/>
                            </a>
                            <span><FormattedMessage id="admin.data.scope.current"/></span>
                          </span>
                              </div>
                            </DropdownItem> :
                            <DropdownItem>
                              <div className="d-flex red">
                          <span>
                            <span><FormattedMessage id="admin.data.set"/></span>
                            <span className="btn btn-sm btn-danger text-white mx-1" onClick={unvalidate}>
                              <FormattedMessage id="data.status.not.validated"/>
                            </span>
                            <span><FormattedMessage id="admin.data.scope.current"/></span>
                          </span>
                              </div>
                            </DropdownItem>}
                        <DropdownItem>
                          <div className="d-flex green">
                        <span>
                          <span><FormattedMessage id="admin.data.set"/></span>
                          <span className="btn btn-sm btn-success text-white mx-1" onClick={confirmAll}>
                            <FormattedMessage id="data.status.confirmed"/>
                          </span>
                          <span><FormattedMessage id="admin.data.scope.all"/></span>
                        </span>
                          </div>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>}
              </div>
            </div>
            {
                isSmallDevice &&
                <div className="d-flex mb-3 flex-wrap align-items-center">
                    <a className="icon d-flex mb-2 me-2" onClick={onClickFilterIcon}>
                        <em className="icon icon-md border">filter_list</em>
                    </a>
                    {selectedDate &&
                        <span
                            className="badge badge-secondary badge-prepend-square me-2 mb-2 d-flex">{selectedDate}</span>
                    }
                    {selectedScope &&
                        <span
                            className="badge badge-secondary badge-prepend-square me-2 mb-2 d-flex">{selectedScope}</span>
                    }
                    {selectedRegion &&
                        <span
                          className="badge badge-secondary badge-prepend-square mb-2 d-flex">{selectedRegion}</span>
                  }
                </div>
            }
            <div className={`row ${displayFilterMenu() ? "" : "d-none"}`}>
                <div className="date-filter col-sm-12 col-md-6 col-lg-1 d-flex flex-column flex-lg-row
                              align-items-lg-center align-items-md-start mb-3">
                    <label className="text-secondary fw-bolder mb-sm-1 mb-lg-0 me-2">Date</label>
                    <div className="mb-0 w-100">
                        <DatePicker onDateChanged={handleDateChange} initDate={lastDate}/>
                    </div>
                </div>

                <div className="scope-filter col-sm-12 col-md-6 col-lg-1 d-flex flex-column flex-lg-row
                              align-items-lg-center align-items-md-start mb-3">
                    <label className="d-inline-block text-secondary fw-bolder mb-sm-1 mb-lg-0 me-2">
                        <FormattedMessage id="scope"/>
                    </label>
                    <TreeSelect optionList={scopeOptions} defaultValue={defaultScopeOption}
                                onOptionChange={handleScopeChange}/>
                </div>

              {displayRegionList() ?
                  <div className="region-filter col-sm-12 col-md-6 col-lg-1 d-flex flex-column flex-lg-row
                                    align-items-lg-center align-items-md-start mb-3">
                      <label className="d-inline-block text-secondary fw-bolder mb-sm-1 mb-lg-0 me-2">
                          <FormattedMessage id="region"/>
                      </label>
                      <TreeSelect optionList={regionOptions} defaultValue={defaultRegionsOption}
                                  onOptionChange={handleRegionsChange}/>
                  </div>
                  : ""}
              {
                showProformaSwitch ?
                    <div className="switch-filter col-sm-12 col-md-6 col-lg-1 d-flex flex-column flex-lg-row
                                    align-items-lg-center align-items-md-start mb-3">
                        <label className="text-secondary fw-bolder mb-sm-1 mb-lg-0 me-2">
                            <FormattedMessage id="data.type.version"/>
                        </label>
                        <Switch onOptionChange={handleDataVersionType}/>
                    </div>
                    : ""
              }
            </div>
          </div>
        </div>
      </div>
  );
};
