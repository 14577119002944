import {Column, ExcelExportParams} from "ag-grid-community";

export const excelStylesValue = () => {
    const styleCell = [1, 2, 3, 4, 5, 6]

    const colorCell = [
        {
            id: `cellLevel1`,
            interior:
                {
                    color: "#fddedd",
                    pattern: "Solid",
                },
        },
        {
            id: `cellLevel2`,
            interior:
                {
                    color: "#ddfddd",
                    pattern: "Solid",
                },
        },
        {
            id: `cellLevel3`,
            interior:
                {
                    color: "#dddefd",
                    pattern: "Solid",
                }
        },
        {
            id: `cellLevel4`,
            interior:
                {
                    color: "#fdfddd",
                    pattern: "Solid",
                }
        },
        {
            id: `cellLevel5`,
            interior:
                {
                    color: "#fddefd",
                    pattern: "Solid",
                }
        },
        {
            id: `cellLevel6`,
            interior:
                {
                    color: "#e1fdfd",
                    pattern: "Solid",
                }
        },
    ]
    styleCell.forEach(unit => {
        colorCell.push({
                id: `${unit}unit`,
                alignment: {
                    indent: unit -1,
                }
            } as any)
        }
    )
    return colorCell;
};

export const defaultExcelExportParams: any = (columns: Column[], intl: any, scope: string, date: string) => {

    const sheetName = scope.concat('_').concat(date)
    const fileTitle = intl.formatMessage({id: 'home.reporting'}, {title: scope}).concat('_').concat(date)
    const columnsExport = columns?.filter((col: Column) => col.getColDef().field !== 'viewDetailsLink') // zoom column
        .map((col: Column) => col);

    const params: ExcelExportParams = {
        sheetName: sheetName,
        fileName: fileTitle,
        columnGroups: true,
        columnKeys: columnsExport,
    };
    return params;
}