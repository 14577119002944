import React, {PropsWithRef} from "react";
import {FormattedMessage} from "react-intl";
import {useRouteContext} from "../../../context/RouteContext/RouteContext";

interface ISelectDataTypeProps extends PropsWithRef<any> {
    onOptionChange?: Function;
}

export const Switch: React.FC<ISelectDataTypeProps> = (props) => {
    const {isProformaAvailable} = useRouteContext();
    let stab :boolean = false;

    let changeSwitchValue = (event : any) => {
        let selectedDataVersion = event.target.value;
        selectedDataVersion === "Proforma" ? stab = true : stab = false;
        props.onOptionChange && props.onOptionChange(stab)
    }


    return (
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label className={`btn btn-toggle-primary scope ${isProformaAvailable ? "" :"active"}`}>
                    <input className="me-2 position-absolute opacity-0" type="checkbox" value="Official"
                           onClick={(e) => changeSwitchValue(e)}/>
                <FormattedMessage id="data.type.official"/>
            </label>
            <label className={`btn btn-toggle-primary scope ${isProformaAvailable ? "active" :""}`}>
                <input className="me-2 position-absolute opacity-0" type="checkbox" value="Proforma"
                       onClick={(e) => changeSwitchValue(e)}/>
                <FormattedMessage id="data.type.proforma"/>
            </label>
        </div>
    );
};