import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {IntlProvider} from '../../context/IntlContext';
import {GlobalViewportProvider as ViewportProvider} from '../../context/ViewportContext';
import {GlobalContext} from '../../context/GlobalContext';
import {RouteParamsProvider} from "../../context/RouteContext/RouteContext";
import {UserProvider} from "../../context/UserContext/UserContext";
import {Role} from "../../models/UserInfoModel";
import {Header, MiniFooter, PageNotFound} from '../common';
import {Home} from '../routes/Home/Home';
import {AccountsManagementGrid} from '../routes/Admin/AccountsManagement/AccountsManagement';
import {DeleteDailyData} from '../routes/Admin/DeleteDailyData/DeleteDailyData';
import {CacheManagement} from "../routes/Admin/CacheManagement/CacheManagement";
import {Synthesis} from "../routes/Synthesis/Synthesis";
import {HelpCenter} from '../common/HelpCenter';
import {Detail} from '../routes/Detail/Detail';
import {ToastContainer} from 'react-toastify';
import {LicenseManager} from "ag-grid-enterprise";
import {getCurrentUser} from "../../services/CommonService";

import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import {first} from "rxjs/operators";
import packageJson from "../../../package.json";
import moment from "moment";
import {getEnvVar} from "../../env-vars-utils";

const App: React.FC = () => {
    const license_KEY = getEnvVar('REACT_APP_AG_GRID_LICENSE_KEY') as string;
    LicenseManager.setLicenseKey(license_KEY);

    const [isLoaded, setIsLoaded] = useState<any>();
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);
    const [isAdminWorldWide, setIsAdminWorldWide] = useState(false);

    const getUserRoles = (currentUserInfo: any): any => {
        const roles = currentUserInfo?.roles;
        if (roles) {
            setIsAdminWorldWide(roles.includes(Role.ROLE_ADMIN));
        }
    };


    const buildDateGreaterThan = (latestDate: any, currentDate: any) => {
        const momLatestDateTime = moment(latestDate);
        const momCurrentDateTime = moment(currentDate);

        if (momLatestDateTime.isAfter(momCurrentDateTime)) {
            return true;
        } else {
            return false;
        }
    };

    const refreshCacheAndReload = () => {
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then((names) => {
                for (const name of names) {
                    caches.delete(name);
                }
            });
        }
        // delete browser cache and hard reload
        window.location.reload();
    };

    useEffect(() => {
        let myHeaders = new Headers();
        myHeaders.set('Cache-Control', 'no-cache');
        myHeaders.set('Pragma', 'no-cache');
        myHeaders.set('Expires', '0');

        fetch("/meta.json", {
            headers: myHeaders
        })
            .then((response) => response.json())
            .then((meta) => {
                const latestVersionDate = meta.buildDate;
                const currentVersionDate = packageJson.buildDate;

                const shouldForceRefresh = buildDateGreaterThan(
                    latestVersionDate,
                    currentVersionDate
                );
                if (shouldForceRefresh) {
                    setIsLatestBuildDate(false);
                    refreshCacheAndReload();
                } else {
                    setIsLatestBuildDate(true);
                }
            });
    }, []);

    useEffect(() => {
        const subscription = getCurrentUser()
            .pipe(first())
            .subscribe((user: any) => {
                setIsLoaded(true);
                getUserRoles(user);
            });
        return () => subscription.unsubscribe();
    }, []);

    const context = {};

    if (isLatestBuildDate){
        return (
            <IntlProvider>
                <GlobalContext.Provider value={context}>
                    { isLoaded && (
                            <ViewportProvider>
                                <RouteParamsProvider>
                                    <UserProvider>
                                        <Router>
                                                <div className="container-fluid flex-column customeStyle">
                                                    <Header />
                                                    <div className="flex-grow-1">
                                                        <Switch>
                                                            <Route exact path="/" component={Home}/>
                                                            <Route exact
                                                                   path="/synthesis/:userLocation(ww|asia)/:region(ww|asia|amer|euro)?/:scope(\d+)/:subScope(\d+)?/:date"
                                                                   component={Synthesis}/>
                                                            <Route path="/admin/accountsManagement"
                                                                   component={AccountsManagementGrid}/>
                                                            <Route path="/admin/deleteDailyData"
                                                                   component={DeleteDailyData}/>
                                                            {isAdminWorldWide && (
                                                                <Route path="/admin/cacheManagement"
                                                                       component={CacheManagement}/>
                                                            )}
                                                            <Route
                                                                path="/detail/:region(ww|asia|amer|euro|sglb|sgil)?/:scope/:subScope/:indicatorId/:date"
                                                                component={Detail}/>
                                                            <Route component={PageNotFound}/>
                                                        </Switch>
                                                    </div>
                                                    <HelpCenter />
                                                    <MiniFooter />
                                                </div>
                                                <ToastContainer
                                                    position="top-center"
                                                    autoClose={3000}
                                                    hideProgressBar
                                                    newestOnTop={false}
                                                    closeOnClick
                                                    rtl={false}
                                                    pauseOnFocusLoss
                                                    draggable
                                                    pauseOnHover/>
                                        </Router>
                                    </UserProvider>
                                </RouteParamsProvider>
                            </ViewportProvider>
                    )}
                </GlobalContext.Provider>
            </IntlProvider>
        );
    } else{
        console.log('*')
        return (<span></span>)
    }


}

export default App;
