import React from 'react';
import './Card.scss';
import {Loader} from "../Loader";

interface ICardTemplateProps {
    cardHeader : boolean | JSX.Element | undefined,
    cardBody : boolean | JSX.Element | undefined,
    footerCardBody? : boolean | JSX.Element | undefined,
    cardFooter : boolean | JSX.Element | undefined,
    headerColor : string ,
    bodyColor : string ,
    footerColor : string ,
    isLoading: boolean;
}

export const CardTemplate : React.FC<ICardTemplateProps> = (props) => {
 

    return (
        <div className='card text-white mb-1 '>
            <div
                className={`card-header py-2 px-3 d-flex align-items-center justify-content-center ${props.headerColor}`}>
                {props.isLoading ? '' : props.cardHeader}
            </div>
            <div className="row-cols-auto">
                <div className={`px-1 pt-1 d-flex justify-content-center ${props.bodyColor}`}>
                    {props.isLoading ? <Loader/> : props.cardBody}
                </div>
                <div className={`footer-align-items-left px-3 pb-1 pt-2 ${props.bodyColor}`}>
                    <div className={`${props.bodyColor}`}>
                        {props.isLoading ? <Loader/> : props.footerCardBody}
                    </div>
                </div>
            </div>

            <div className={`card-footer pt-2 px-3 pb-2 d-flex align-items-center ${props.footerColor}`}>
                {props.isLoading ? '' : props.cardFooter}
            </div>
      </div>
    );

};