import React from 'react';
import {FormattedMessage} from 'react-intl';

export const Loader: React.FC = () => {
  return (
      <div className="d-grid align-items-center">
          <FormattedMessage id="loading"/>
          <div className="blinker blinker-xl" role="status">
          </div>
      </div>
  );
};
