import React from 'react';

export const BusinessUnitsCellRenderer : React.FC = (params : any) => {

    const listBu : string[] = (params.data.perimeterList as string[]);
    const formattedListBu = listBu.join(',');

    if (!params.data.editing) {
        return (
            <div>
                 <p> {formattedListBu }</p>
            </div>
        );
    }
    else {
        return (<div></div>)
    }
}