import React, {Component, PropsWithRef} from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import UtilsService from "../../../services/utils/UtilsService";
import {YtdChartLegend} from "./ytdChartLegend/YtdChartLegend"

import './YtdEvolutionChart.scss';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data.js')(Highcharts);

interface YtdEvolutionState {
    hoverDate: any,
    axeXYear: any[];
    period: any,
    nbiYtdData: any,
    budgetData: any,
    nbiYtdDataForPreviousYear: any,
    dataSbu1: any,
    dataSbu2: any,
    dataSbu3: any,
    dataSbu4: any,
    selectedNbiYtdData: any,
    selectedBudgetData: any,
    selectedNbiYtdDataForPreviousYear: any,
    selectedDataSbu1: any,
    selectedDataSbu2: any,
    selectedDataSbu3: any,
    selectedDataSbu4: any,
    budgetVariation: any,
    previousYearVariation: any,
    sbuName1: any,
    sbuName2: any,
    sbuName3: any,
    sbuName4: any,
    dataLabelActiveForSbu1: boolean;
    dataLabelActiveForSbu2: boolean;
    dataLabelActiveForSbu3: boolean;
    dataLabelActiveForSbu4: boolean;
    dataLabelActiveForNbiYtdData: boolean;
    dataLabelActiveForPreviousYearData: boolean;
    dataLabelActiveForBudgetData: boolean;
    allowChartUpdate: boolean;

}

interface IMiniHeatmapSVG extends PropsWithRef<any> {
    period: string;
    params: any;
}

export class YtdEvolutionChart extends Component<IMiniHeatmapSVG, YtdEvolutionState> {
    constructor(props: any) {
        super(props);
        this.state = {
            hoverDate: null,
            axeXYear: this.props.axeXYear,
            period: this.props.period,
            nbiYtdData: this.props.nbiYtdData,
            budgetData: this.props.budgetData,
            nbiYtdDataForPreviousYear: this.props.nbiYtdDataForPreviousYear,
            dataSbu1: this.props.dataSbu1,
            dataSbu2: this.props.dataSbu2,
            dataSbu3: this.props.dataSbu3,
            dataSbu4: this.props.dataSbu4,
            budgetVariation: '',
            previousYearVariation: '',
            selectedNbiYtdData: null,
            selectedBudgetData: null,
            selectedNbiYtdDataForPreviousYear: null,
            selectedDataSbu1: null,
            selectedDataSbu2: null,
            selectedDataSbu3: null,
            selectedDataSbu4: null,
            sbuName1: this.props.sbuName1,
            sbuName2: this.props.sbuName2,
            sbuName3: this.props.sbuName3,
            sbuName4: this.props.sbuName4,
            dataLabelActiveForSbu1: false,
            dataLabelActiveForSbu2: false,
            dataLabelActiveForSbu3: false,
            dataLabelActiveForSbu4: false,
            dataLabelActiveForPreviousYearData: false,
            dataLabelActiveForNbiYtdData: false,
            dataLabelActiveForBudgetData: false,
            allowChartUpdate: false,
        };
    }

    componentDidMount() {
        this.setState({selectedNbiYtdData: this.props.nbiYtdData[this.props.nbiYtdData.length - 1]});
        this.setState({selectedBudgetData: this.props.budgetData[this.props.budgetData.length - 1]});
        this.setState({selectedNbiYtdDataForPreviousYear: this.props.nbiYtdDataForPreviousYear[this.props.nbiYtdDataForPreviousYear.length - 1]});
        this.setState({selectedDataSbu1: this.props.dataSbu1[this.props.dataSbu1.length - 1]});
        this.setState({selectedDataSbu2: this.props.dataSbu2[this.props.dataSbu2.length - 1]});
        this.setState({selectedDataSbu3: this.props.dataSbu3[this.props.dataSbu3.length - 1]});
        this.setState({selectedDataSbu4: this.props.dataSbu4[this.props.dataSbu4.length - 1]});
        this.setState({hoverDate: this.props.axeXYear[this.props.axeXYear.length - 1]});
    }

    calculateVariation = (num: number, text: string) => {
        if (Number.isFinite(num) && this.state.selectedNbiYtdData) {
            const variation = UtilsService.applySpecificFormattingToNumber(this.state.selectedNbiYtdData - num, 0,1);
            return (
              variation && (<span className="text-light fw-semibold">
                    {variation.toLocaleString()} {text}
                </span>
              )
            );
        }
        return '';
    };

    calculateSbuPercentage = (num: number) => {
        if (Number.isFinite(num)) {
            const totalSbuAbs = Math.abs(this.state.selectedDataSbu1) + Math.abs(this.state.selectedDataSbu2) + Math.abs(this.state.selectedDataSbu3) + Math.abs(this.state.selectedDataSbu4);
            const res = UtilsService.formatNumberWithOneDigit(((Math.abs(num) / totalSbuAbs) * 100), true);
            return (
              Number.isFinite(res) && (
                  <span className="text-light fw-semibold">
                    {res}% of YTD NBI
                </span>
              )
            );
        }
        return '';
    };

    setHoverData = (e: any) => {
        const selectedPeriod = (element: any) => element === e.target.category;
        const index = this.props.axeXYear.findIndex(selectedPeriod);
        this.setState({selectedNbiYtdData: this.props.nbiYtdData[index]});
        this.setState({selectedBudgetData: this.props.budgetData[index]});
        this.setState({selectedNbiYtdDataForPreviousYear: this.props.nbiYtdDataForPreviousYear[index]});
        this.setState({selectedDataSbu1: this.props.dataSbu1[index]});
        this.setState({selectedDataSbu2: this.props.dataSbu2[index]});
        this.setState({selectedDataSbu3: this.props.dataSbu3[index]});
        this.setState({selectedDataSbu4: this.props.dataSbu4[index]});
        this.setState({hoverDate: e.target.category});
        this.setState({allowChartUpdate: false})
    };

    handleLegendMouseClick = (selectedScope: any) => {
        switch (selectedScope) {
            case this.state.sbuName1:
                this.setState({dataLabelActiveForSbu1: !this.state.dataLabelActiveForSbu1});
                break;
            case this.state.sbuName2:
                this.setState({dataLabelActiveForSbu2: !this.state.dataLabelActiveForSbu2});
                break;
            case this.state.sbuName3:
                this.setState({dataLabelActiveForSbu3: !this.state.dataLabelActiveForSbu3});
                break;
            case this.state.sbuName4:
                this.setState({dataLabelActiveForSbu4: !this.state.dataLabelActiveForSbu4});
                break;
            case "YTD NBI":
                this.setState({dataLabelActiveForNbiYtdData: !this.state.dataLabelActiveForNbiYtdData});
                break;
            case "YTD-1 NBI":
                this.setState({dataLabelActiveForPreviousYearData: !this.state.dataLabelActiveForPreviousYearData});
                break;
            case "YTD Budget":
                this.setState({dataLabelActiveForBudgetData: !this.state.dataLabelActiveForBudgetData});
                break;
        }
        this.setState({allowChartUpdate: true});
    };

    numberSeparator = (number: any) => {
        return UtilsService.numberCommasSeparator(number);
    };

    render() {
        const {hoverDate} = this.state;
        Highcharts.setOptions({
            lang: {
                thousandsSep: ','
            }
        });
        Highcharts.SVGRenderer.prototype.symbols.download = function (x: any, y: any, w: any, h: any) {
            return [
                // Arrow stem
                'M', x + w * 0.5, y,
                'L', x + w * 0.5, y + h * 0.7,
                // Arrow head
                'M', x + w * 0.3, y + h * 0.5,
                'L', x + w * 0.5, y + h * 0.7,
                'L', x + w * 0.7, y + h * 0.5,
                // Box
                'M', x, y + h * 0.9,
                'L', x, y + h,
                'L', x + w, y + h,
                'L', x + w, y + h * 0.9
            ];
        };
        const options = {
            chart: {
                type: 'column',
                height: 620,
                backgroundColor: {
                    linearGradient: [0, 0, 500, 500],
                    stops: [
                        [0, 'rgb(48,51,51)'],
                    ]
                },
                commonSeriesSettings: {
                    barPadding: 5,
                    columnPadding: 5,
                },
                panning: true,
                itemPadding: 0,
                zoomType: 'xy',
            },
            title: {
                text: ''
            },
            tooltip: {
                enabled: false,
                shared: true,
                backgroundColor: 'black',
                style: {
                    color: 'white',
                    background: 'black',
                },
            },

            xAxis: [{
                categories: this.state.axeXYear,
                labels: {
                    style: {
                        color: '#949c9e',//text-secondary
                        fontWeight: '600',//fw-semibold
                        fontFamily: 'Inter', //body font (inter)
                        fontSize: 15
                    }
                },
                crosshair:{
                    dashStyle: 'Solid',
                    snap: true,
                    width: 1,
                    zIndex: 2
                }
            }],
            plotOptions: {
                column: {
                    stacking: 'normal',
                    pointPadding: 0,
                    borderWidth: 0,
                    groupPadding: 0.05,
                    shadow: false,
                },
                series: {
                    states: {
                        hover: {
                            enabled: false
                        },
                        inactive: {
                            opacity: 1,
                        },
                    },
                    point: {
                        events: {
                            mouseOver: this.setHoverData.bind(this),

                        }
                    }
                },
            },

            legend: {
                enabled: false,
                align: 'left',
                verticalAlign: 'top',
            },
            yAxis: [{
                startOnTick: false,
                gridLineColor: '#464b4b',
                gridLineWidth: 1,
                title: null,

                labels: {
                    style: {
                        color: '#949c9e',//text-secondary
                        fontWeight: '600',//fw-semibold
                        fontFamily: 'Inter', //body font (inter)
                        fontSize: 15
                    }
                },

            },
            ],
            series: [
                {

                    name: this.state.sbuName1,
                    data: this.state.dataSbu1,

                    stacking: 'normal',
                    dataLabels: {
                        enabled: this.state.dataLabelActiveForSbu1,
                        lang: {
                            decimalPoint: '',
                            thousandsSep: ''
                        },
                        padding: 0,
                        style: {
                            distance: '-50%',
                            color: 'white',
                            textOutline: '0px contrast',
                            fontSize: '15px',
                            fontWeight: '600',//fw-semibold
                            fontFamily: 'Inter', //body font (inter)
                        },
                    },

                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, new Highcharts.Color('#5D55FF').setOpacity(0.7).get('rgba')],
                            [1, new Highcharts.Color('#3C37A3').setOpacity(0.7).get('rgba')]
                        ]
                    },
                },
                {
                    name: this.state.sbuName2,
                    data: this.state.dataSbu2,
                    dataLabels: {
                        enabled: this.state.dataLabelActiveForSbu2,
                        lang: {
                            decimalPoint: '',
                            thousandsSep: ''
                        },
                        padding: 0,
                        style: {
                            distance: '-50%',
                            color: 'white',
                            textOutline: '0px contrast',
                            fontSize: '15px',
                            fontWeight: '600',//fw-semibold
                            fontFamily: 'Inter', //body font (inter)
                        },
                    },
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, new Highcharts.Color('#68EFAD').setOpacity(0.7).get('rgba')],
                            [1, new Highcharts.Color('#449C71').setOpacity(0.7).get('rgba')]
                        ]
                    },
                    showInLegend: true,
                    marker: {
                        enabled: false,
                        symbol: 'circle',
                        radius: 3,
                        states: {
                            hover: {
                                enabled: true,
                                lineWidth: 1
                            }
                        }
                    }
                },
                {
                    name: this.state.sbuName3,
                    data: this.state.dataSbu3,
                    dataLabels: {
                        enabled: this.state.dataLabelActiveForSbu3,
                        lang: {
                            decimalPoint: '',
                            thousandsSep: ''
                        },
                        padding: 0,
                        style: {
                            distance: '-50%',
                            color: 'white',
                            textOutline: '0px contrast',
                            fontSize: '15px',
                            fontWeight: '600',//fw-semibold
                            fontFamily: 'Inter', //body font (inter)
                        },
                    },
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, new Highcharts.Color('#F6A416').setOpacity(0.7).get('rgba')],
                            [1, new Highcharts.Color('#BF8011').setOpacity(0.7).get('rgba')]
                        ]
                    },
                    showInLegend: true

                },
                {
                    name: 'Others',
                    data: this.state.dataSbu4,
                    dataLabels: {
                        enabled: this.state.dataLabelActiveForSbu4,
                        lang: {
                            decimalPoint: '',
                            thousandsSep: ''
                        },
                        padding: 0,
                        style: {
                            distance: '-50%',
                            color: 'white',
                            textOutline: '0px contrast',
                            fontSize: '15px',
                            fontWeight: '600',//fw-semibold
                            fontFamily: 'Inter', //body font (inter)
                        },
                    },
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, new Highcharts.Color('#974A5A').setOpacity(0.7).get('rgba')],
                            [1, new Highcharts.Color('#5E2E38').setOpacity(0.7).get('rgba')]
                        ]
                    },
                    showInLegend: true

                },
                {
                    name: 'YTD BUDGET',
                    type: 'spline',

                    dataLabels: {
                        enabled: this.state.dataLabelActiveForBudgetData,
                        lang: {
                            decimalPoint: '',
                            thousandsSep: ''
                        },
                        padding: 5,
                        style: {
                            distance: '-50%',
                            color: 'white',
                            textOutline: 0,
                            fontSize: '15px',
                            fontWeight: '600',//fw-semibold
                            fontFamily: 'Inter', //body font (inter)
                        },
                    },
                    dashStyle: 'shortdot',
                    color: '#529aff',
                    data: this.state.budgetData,
                    marker: {
                        enabled: this.state.dataLabelActiveForBudgetData
                    },
                    showInLegend: true,
                    lineWidth: 3,

                },

                {
                    name: 'YTD NBI',
                    type: 'spline',
                    dashStyle: 'shortdot',
                    color: '#d53229',
                    data: this.state.nbiYtdData,
                    lineWidth: 3,
                    dataLabels: {
                        enabled: this.state.dataLabelActiveForNbiYtdData,
                        lang: {
                            decimalPoint: '',
                            thousandsSep: ''
                        },
                        padding: 5,
                        style: {
                            distance: '-50%',
                            color: 'white',
                            textOutline: 0,
                            fontSize: '15px',
                            fontWeight: '600',//fw-semibold
                            fontFamily: 'Inter', //body font (inter)
                        },
                    },
                    showInLegend: true,
                    marker: {
                        enabled: this.state.dataLabelActiveForNbiYtdData
                    },
                },
                {
                    name: 'YTD-1 NBI',
                    type: 'spline',
                    dataLabels: {
                        enabled: this.state.dataLabelActiveForPreviousYearData,
                        lang: {
                            decimalPoint: '',
                            thousandsSep: ''
                        },
                        padding: 5,
                        style: {
                            distance: '-50%',
                            color: 'white',
                            textOutline: 0,
                            fontSize: '15px',
                            fontWeight: '600',//fw-semibold
                            fontFamily: 'Inter', //body font (inter)
                        },
                    },
                    dashStyle: 'shortdot',
                    color: '#e8e8e8',
                    lineWidth: 3,
                    data: this.state.nbiYtdDataForPreviousYear,
                    marker: {
                        enabled: this.state.dataLabelActiveForPreviousYearData,
                    },

                }],

            credits: {
                enabled: false
            },

            exporting: {
                enabled: true,
                align: 'right',
                y: -10,
                buttons: {
                    contextButton: {
                        menuItems: [
                            "downloadPNG",
                            "downloadJPEG",
                            "downloadPDF",
                            "downloadSVG",
                            "separator",
                            "downloadCSV",
                            "downloadXLS"],
                        symbolStroke: '#dddddd',
                        symbol: 'download',
                        theme: {
                            fill: '#505053'
                        },
                    },
                }
            },

        };

        return (
            <div className="ytd-evolution">
                <div className="text-light text-large ms-2 fw-semibold ">{hoverDate}</div>
                <div className="d-flex flex-wrap mb-1">
                    {
                        this.state.sbuName1 && (
                            <YtdChartLegend scopeName={this.state.sbuName1}
                                            legendSymbolClass="bg-dodger-blue"
                                            value={this.numberSeparator(this.state.selectedDataSbu1)}
                                            onClick={() => this.handleLegendMouseClick(this.state.sbuName1)}>
                                {this.calculateSbuPercentage(this.state.selectedDataSbu1)}
                            </YtdChartLegend>
                        )}
                    {
                        this.state.sbuName2 && (
                        <YtdChartLegend scopeName={this.state.sbuName2}
                                        legendSymbolClass="bg-turquoise-blue"
                                        value={this.numberSeparator(this.state.selectedDataSbu2)}
                                        onClick={() => this.handleLegendMouseClick(this.state.sbuName2)}>
                            {this.calculateSbuPercentage(this.state.selectedDataSbu2)}
                        </YtdChartLegend>
                    )}
                    {
                        this.state.sbuName3 && (
                        <YtdChartLegend scopeName={this.state.sbuName3}
                                        legendSymbolClass="bg-buttercup"
                                        value={this.numberSeparator(this.state.selectedDataSbu3)}
                                        onClick={() => this.handleLegendMouseClick(this.state.sbuName3)}>
                            {this.calculateSbuPercentage(this.state.selectedDataSbu3)}
                        </YtdChartLegend>
                    )}
                    {
                        this.state.sbuName4 && (
                        <YtdChartLegend scopeName={this.state.sbuName4}
                                        legendSymbolClass="bg-copper-rust"
                                        value={this.numberSeparator(this.state.selectedDataSbu4)}
                                        onClick={() => this.handleLegendMouseClick(this.state.sbuName4)}>
                            {this.calculateSbuPercentage(this.state.selectedDataSbu4)}
                        </YtdChartLegend>
                    )}
                    <YtdChartLegend scopeName="YTD NBI"
                                    scopeNameStyle={{color: '#d53229'}}
                                    style={{flex: '0 0 170px'}}
                                    legendSymbolClass="dotted-gradient"
                                    legendSymbolStyle={{color: '#d53229'}}
                                    value={this.numberSeparator(this.state.selectedNbiYtdData)}
                                    onClick={() => this.handleLegendMouseClick("YTD NBI")}>
                        {this.calculateVariation(this.state.selectedNbiYtdDataForPreviousYear, 'vs YTD-1 NBI')}
                        <br />
                        {this.calculateVariation(this.state.selectedBudgetData, 'vs YTD Budget')}
                    </YtdChartLegend>

                    <YtdChartLegend scopeName="YTD-1 NBI"
                                    legendSymbolClass="dotted-gradient"
                                    legendSymbolStyle={{color: '#8b93a8'}}
                                    value={this.numberSeparator(this.state.selectedNbiYtdDataForPreviousYear)}
                                    onClick={() => this.handleLegendMouseClick("YTD-1 NBI")} />

                    <YtdChartLegend scopeName="YTD Budget"
                                    legendSymbolClass="dotted-gradient"
                                    legendSymbolStyle={{color: '#529aff'}}
                                    value={this.numberSeparator(this.state.selectedBudgetData)}
                                    onClick={() => this.handleLegendMouseClick("YTD Budget")} />
                </div>

                <HighchartsReact
                    highcharts={Highcharts}
                    allowChartUpdate={this.state.allowChartUpdate}
                    options={options} />
            </div>
        );
    }
}