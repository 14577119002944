import React, {Component} from 'react';
import {GetBusinessUnit} from '../../../../services/admin/AccountsManagementService';

import './PerimetersCellEditor.scss';
import '../DropdownTreeSelect.scss';
import DropdownTreeSelect, {TreeNode} from "react-dropdown-tree-select";

interface PerimetersCellEditorState {
    perimeters: any[],
}


export class PerimetersCellEditor extends Component<{}, PerimetersCellEditorState> {

    currentPerimeters: any[] = [];
    allBuSbuList: any[]=[];
    buList: any[] = [];
    completeBuList: any[] =[];
    completeSbuList: any[] = [];

    constructor(props: any) {
        super(props);
        this.state = {
            perimeters: []
        };
        props.data.perimeters.forEach((bu: { id: any; })=>this.currentPerimeters.push(bu.id));
        GetBusinessUnit().subscribe((result: any) => {
            this.formatPerimeters(result);
            const oldPerimeters = this.allBuSbuList.filter(r => this.currentPerimeters.includes(r.id));
            this.setState({perimeters: oldPerimeters});
        });
        this.handleSelectPerimetersChange = this.handleSelectPerimetersChange.bind(this);
    }

    formatPerimeters= (result: any[]) => {
        result.forEach(b => {
                const sbuList: any[] = [];
                if (b.children !== null) {
                    b.children.forEach((child: any) => {
                        if(this.currentPerimeters.includes(child.id)||this.currentPerimeters.includes(b.id)){
                            sbuList.push({value: child.id, label: b.name + ' | ' + child.name, checked: true});
                        }else{
                            sbuList.push({value: child.id, label: b.name + ' | ' + child.name, checked: false});
                        }
                        this.allBuSbuList.push({value: child.id, label: b.name + ' | ' + child.name});
                        this.completeSbuList.push(child);
                    });
                }
                if(this.currentPerimeters.includes(b.id)){
                    this.buList.push({value: b.id, label: b.name, children: sbuList, checked: true});
                }else{
                    this.buList.push({value: b.id, label: b.name, children: sbuList, checked: false});
                }
                this.allBuSbuList.push({value: b.id, label: b.name});
                this.completeBuList.push(b);
            }
        )
    };

    handleSelectPerimetersChange(currentNode: TreeNode, selectedNodes: TreeNode[]) {
        this.state = {
            perimeters: []
        };
        selectedNodes.forEach(node => this.state.perimeters.push(node.value))

    }

    render() {
        return (
            <div className='form-group reduced-margins'>
                <div className='react-dropdown-tree-select-edit'>
                    <DropdownTreeSelect data={this.buList} texts={{ placeholder: 'Search' }}
                                        onChange={this.handleSelectPerimetersChange}/>

                </div>
            </div>
        );
    }
}