import React from 'react';
import { FormattedMessage } from 'react-intl';

export const NewsNotFound: React.FC = () => {
  return (
<div className="container my-5 text-center text-secondary" >
      <em className="icon icon-xl d-inline-block pt-5 mb-4">account_balance</em>
      <h4><FormattedMessage id="news.not.found" /></h4>
      <p><FormattedMessage id="news.not.found.description" /></p>
    </div>
  );
};
