import {CellClassParams} from "ag-grid-community/dist/lib/entities/colDef";
import {DataViewType} from "../DataViewType";

export const CURRENT_YEAR_COLUMN_ID = 'currentYear';
export const PREVIOUS_YEAR_COLUMN_ID = 'previousYear';
export const YEAR_MINUS_TWO_COLUMN_ID = 'yearMinusTwo';
export const YEAR_MINUS_THREE_COLUMN_ID = 'yearMinusThree';
export const CURRENT_YEAR_BUDGET_COLUMN_ID = 'budget';
export const CURRENT_YEAR_VS_BUDGET_COLUMN_ID = 'currentVsB';
export const CURRENT_YEAR_VS_PREVIOUS_YEAR_COLUMN_ID = 'previousVsCurrentYear';
const EMPTY_NUMBER = '-';

export const columnDefsForSmallDevice: Array<any> = [
    {
        headerName: 'Organization unit',
        field: 'title',
        colId: 'title',
        rowGroup: true,
        enableRowGroup: true,
        rowGroupIndex: 0,
        hide: true,
        lockPosition: true,
    },
    {
        headerName: 'DAY',
        field: 'day',
        cellClass: colorCellClass,
        cellRenderer: (params: any) => formatNumberWithDecimalCellRenderer(params, 2),
    },
    {
        headerName: 'MTD',
        field: 'mtd',

        cellClass: cellClass,
        cellRenderer: numberWithOneDecimalCellRenderer,
    },
    {
        headerName: 'QTD',
        field: 'qtd',
        cellClass: cellClass,
        cellRenderer: numberWithOneDecimalCellRenderer,
    },
    {
        headerName: 'YTD',
        field: 'ytd',
        cellClass: cellClass,
        cellRenderer: numberWithOneDecimalCellRenderer,
    },
    {
        headerName: 'BFY',
        field: 'budgetYear',
        cellClass: budgetCellClass,
        cellRenderer: numberWithOneDecimalCellRenderer,
    },
    {
        headerName: '%QTD',
        field: 'qtdPercent',
        cellClass: percentageCellClass,
        cellRenderer: percentageCellRenderer

    },
    {
        headerName: '%YTD',
        field: 'ytdPercent',
        cellClass: percentageCellClass,
        cellRenderer: percentageCellRenderer

    }
];

function cellClass(params: any): Array<string> {
    return [`table-row-level${params.data?.level}`, 'text-center'];
}

function colorCellClass(params: any): Array<string> {
    const colorCellClass = cellClass(params);
    if (params.value > 0) {
        colorCellClass.push('increase');
        colorCellClass.push('fw-bold');
    } else if (params.value < 0) {
        colorCellClass.push('decrease');
        colorCellClass.push('fw-bold');
    }
    return colorCellClass;
}

function budgetCellClass(params: any): Array<string> {
    const budgetCellClass = cellClass(params);
    budgetCellClass.push('text-info');
    budgetCellClass.push('fw-bold');
    return budgetCellClass;
}

function percentageCellClass(params: any): Array<string> {
    const cellClass = [`table-row-level${params.data?.level}`, 'text-center', 'fw-bold'];
    cellClass.push(percentageCellColorClass(params));
    return cellClass;
}

function percentageCellColorClass(params: any): string {
    let color = '';
    if (Number.isFinite(params.value)) {
        if (params.value >= 0) {
            color = 'increase';
        } else {
            color = 'decrease';
        }
    }
    return color;
}

function displayRuleForBudgetRealization(percentage: number): boolean {
    return percentage !== null && percentage >= -10 && percentage < 10;
}

function numberWithOneDecimalCellRenderer(params: any): number | string {
    return formatNumberWithDecimalCellRenderer(params, 1);
}

function formatNumberWithDecimalCellRenderer(params: any, nbrDecimal: number): number | string {
    if (Number.isFinite(params.value)) {
        return Number(params.value / 1000).toLocaleString(undefined, {
            minimumFractionDigits: nbrDecimal,
            maximumFractionDigits: nbrDecimal
        });
    } else {
        return EMPTY_NUMBER;
    }
}

function formatPercentageCellRenderer(value: any): number | string {
    if (Number.isFinite(value)) {
        return Number(value * 100).toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    } else {
        return EMPTY_NUMBER;
    }
}

function percentageCellRenderer(params: any): string {
    if (Number.isFinite(params.value)) {
        return displayRuleForBudgetRealization(params.value) ? params.value > 0 ? '+' + formatPercentageCellRenderer(params.value) + '%' : '' + formatPercentageCellRenderer(params.value) + '%' : 'NS';
    } else {
        return EMPTY_NUMBER;
    }
}

function numberWithOneDecimalCellRendererWithSign(params: any): number | string {
    return formatNumberWithDecimalCellRendererWithSign(params, 1);
}

function formatNumberWithDecimalCellRendererWithSign(params: any, nbrDecimal: number): number | string {
    if (Number.isFinite(params.value)) {
        let result = Number(params.value / 1000).toLocaleString(undefined, {
            minimumFractionDigits: nbrDecimal,
            maximumFractionDigits: nbrDecimal
        });
        if (params.value > 0) {
            return '+' + result
        } else {
            return result;
        }
    } else {
        return EMPTY_NUMBER;
    }
}

function setHeaderName(column: any, year: number) {
    const previousYear = (year - 1).toString().substring(2);
    const currentYear = year.toString().substring(2);
    column.children?.forEach((child: any) => {
        if (!child.headerName.includes(currentYear) && !child.headerName.includes(previousYear)) {
            child.headerClass = ['header-hidden']
            if (child.children[0].field.includes('estimate')) {
                child.headerClass.push('header-estimates');
                if (child.children[0].field?.includes('Vs')) {
                    child.headerClass.push('header-versus-twice')
                }
                if (child.headerName.includes("(Y)")) {
                    child.headerName = child.headerName.replaceAll("(Y)", currentYear);
                }
                if (child.headerName.includes("(Y-1)")) {
                    child.headerName = child.headerName.replaceAll("(Y-1)", previousYear);
                }
                return;
            }
            if (child.children[0].field === 'day' || child.children[0].field.endsWith('td') || child.children[0].field.endsWith('nbiRangePeriod')) {
                child.headerName = child.headerName + '-' + currentYear;
                child.headerClass.push('period')
                return;

            } else if (child.children[0].field?.includes('ProratedBudget')) {
                child.headerName = child.headerName + '-B' + currentYear
                child.headerClass = ['header-versus']

            } else if (child.children[0].field?.includes('BudgetRealizationDifferential') || child.children[0].field?.includes('nbiRangePeriodVsBudget')) {
                child.headerName = child.headerName + ' vs B' + currentYear;

            } else if (child.children[0].field?.includes('PreviousVsCurrentYear')) {
                child.headerName = child.headerName + '-' + currentYear + ' ' + 'vs' + ' ' + previousYear;
                child.headerClass = ['header-versus-twice']

            } else if (child.children[0].field?.includes('quarter')) {
                child.headerName = child.headerName + '-' + currentYear;
                child.headerClass = ['quarter']

            } else if (child.children[0].field?.toLowerCase().includes('full')) {
                child.headerName = child.headerName + '-' + currentYear;
                child.headerClass = ['fy']

            } else if (child.children[0].field?.startsWith('budget')) {
                child.headerName = child.headerName + '-B' + currentYear;
                child.headerClass = ['header-versus']

            } else {
                child.headerName = child.headerName + '-' + currentYear;
            }
        }
    });
}

const constructNames = (column: any, year: number) => {
    if (column.groupId === PREVIOUS_YEAR_COLUMN_ID) {
        column.headerName = year - 1;
        column.headerName = 'NBI' + '\n' + column.headerName
        setHeaderName(column, year - 1);
    } else if (column.groupId === YEAR_MINUS_TWO_COLUMN_ID) {
        column.headerName = year - 2;
        column.headerName = 'NBI' + '\n' + column.headerName
        setHeaderName(column, year - 2);
    } else if (column.groupId === YEAR_MINUS_THREE_COLUMN_ID) {
        column.headerName = year - 3;
        column.headerName = 'NBI' + '\n' + column.headerName
        setHeaderName(column, year - 3);
    } else {
        if (column.groupId === CURRENT_YEAR_COLUMN_ID) {
            column.headerName = 'NBI' + '\n' + year;

        } else if (column.groupId === CURRENT_YEAR_BUDGET_COLUMN_ID) {
            column.headerName = 'B' + year;

        } else if (column.groupId === CURRENT_YEAR_VS_BUDGET_COLUMN_ID) {
            column.headerName = year + ' vs B' + year.toString();

        } else if (column.groupId === CURRENT_YEAR_VS_PREVIOUS_YEAR_COLUMN_ID) {
            column.headerName = year + ' vs ' + (year - 1);
        }
        setHeaderName(column, year);
    }
}

export function constructColumnNames(columnDefs: Array<any>, year: number) {

    columnDefs && columnDefs.forEach((column: any) => {
        constructNames(column, year);
        column.children?.forEach((child2nd: any) => {
            child2nd.children?.forEach((child: any) => {
                child.cellClass = colorCellClass
                if (child.field === 'day') {
                    child.cellClass = colorCellClass
                    child.cellRenderer = (params: any) => formatNumberWithDecimalCellRenderer(params, 2)
                } else if (child.field === 'dtdBudgetRealizationDifferential') {
                    child.cellRenderer = (params: any) => formatNumberWithDecimalCellRendererWithSign(params, 2)
                } else if (child.field?.includes('PreviousVsCurrentYear') || child.field?.includes('BudgetRealizationDifferential') || ((child.field?.includes('estimate') || child.field?.includes('RangePeriod')) && child.field?.includes('Vs'))) {
                    child.cellClass = colorCellClass
                    child.cellRenderer = numberWithOneDecimalCellRendererWithSign
                    if (child.field?.includes('Percent')) {
                        child.cellRenderer = percentageCellRenderer
                    }
                } else if (child.field?.toLowerCase().includes('budget')) {
                    child.cellClass = budgetCellClass
                    child.field?.includes('dtd') ? child.cellRenderer = (params: any) => formatNumberWithDecimalCellRenderer(params, 2) : child.cellRenderer = numberWithOneDecimalCellRenderer
                } else {
                    child.cellClass = cellClass
                    child.cellRenderer = numberWithOneDecimalCellRenderer
                }
            });
        });
    });
}

/**
 * Currently, when a favorite is saved on jan for example then on dec the columnOrders puts months between jan and dec at the end of the list
 * so we need to put them after current year family
 *
 * we also need to remove items included in columnOrders and not in columnDefsDefault to avoid synchronization issues
 * @param columnDefsDefault
 * @param columnOrders
 */
export function updateCurrentYearColsPref(columnDefsDefault: any, columnOrders: any[]) {
    let defaultColM: Array<any> = [];
    let defaultColQ: Array<any> = [];
    let monthField = 'monthCurrentYear'
    let quarterField = 'quarterCurrentYear'
    let currentYearFieldsPostCurrentDateList: Array<any> = [];

    columnDefsDefault[0].children?.forEach((child: { children: any; }) => {
        let col = child?.children[0].field;
        if (!columnOrders.includes(col) && (col.includes(monthField))) {
            defaultColM.push(col);
            return;
        }
        if (!columnOrders.includes(col) && (col.includes(quarterField))) {
            defaultColQ.push(col);
            return;
        }
        if (columnOrders.includes(col) && (col.includes(quarterField) || col.includes(monthField))) {
            currentYearFieldsPostCurrentDateList.push(col);
        }
    });
    let itemToDeleteList = columnOrders.filter(value => currentYearFieldsPostCurrentDateList.indexOf(value) === -1 && (value.includes(quarterField) || value.includes(monthField)))
    itemToDeleteList.forEach(value => {
        columnOrders.splice(columnOrders.indexOf(value), 1);
    })
    let colLength = columnOrders.length;
    let indexMonth = colLength - columnOrders.slice().reverse().findIndex(x => x.includes(monthField));
    columnOrders.splice(indexMonth, 0, ...defaultColM);
    let indexQuarter = colLength - columnOrders.slice().reverse().findIndex(x => x.includes(quarterField));
    columnOrders.splice(indexQuarter, 0, ...defaultColQ);
}

export const groupCellClass = (params: CellClassParams) => {
    const level = params.node.level.toString();
    return ['unit', level.concat('unit')];
};

export const getAutoGroupColumnDef: any = (dataView: DataViewType, isSmallDevice: boolean) => {
    return {
        headerName: 'Scope',
        field: 'title',
        pinned: 'left',
        headerClass: 'group-unit-header',
        cellClass: (params: CellClassParams) => groupCellClass(params),
        minWidth: isSmallDevice ? 150 : 210,
        maxWidth: 600,
        resizable: true,
        suppressMenu: isSmallDevice,
        filter: isSmallDevice ? '' : 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset']
        },
        menuTabs: ['filterMenuTab'],
        lockPosition: true,
        suppressNavigable: true,
        suppressSizeToFit: false,
        cellRendererParams: {
            suppressCount: true,
        }
    }
};

export const getGridOptions: any = (isSmallDevice: boolean) => {
    return {
        domLayout: 'autoHeight',
        headerHeight: 32,
        rowHeight: 28,
        suppressContextMenu: true,
        suppressCellSelection: true,
        excludeChildrenWhenTreeDataFiltering: isSmallDevice,

        rowClassRules: {
            'row-level1': (params: any) => params.data?.level === 1,
            'row-level2': (params: any) => params.data?.level === 2,
            'row-level3': (params: any) => params.data?.level === 3,
            'row-level4': (params: any) => params.data?.level === 4,
            'row-level5': (params: any) => params.data?.level === 5,
            'row-level6': (params: any) => params.data?.level === 6,
        },
        overlayNoRowsTemplate: 'No Data Available',
    };
}
export const indicatorColmun = {
    headerName: 'Indicator',
    field: 'indicatorCode',
    minWidth: 140,
    maxWidth: 150,
    pinned: 'left',
    headerClass: 'indicator-header',
    cellClass: 'indicator',
    resizable: true,
    lockPosition: true,
    suppressNavigable: true,
}
export const viewDetailsColumn = {
    headerName: 'Zoom',
    field: 'viewDetailsLink',
    minWidth: 75,
    maxWidth: 100,
    pinned: 'left',
    lockVisible: true,
    lockPosition: true,
    suppressNavigable: true,
    headerClass: 'details-link-header',
    cellClass: 'details-link',
    cellRenderer: 'viewDetailsRenderer',
};

export const getDefaultColDef: any = (isSmallDevice: boolean) => {
    return {
        flex: 1,
        minWidth: isSmallDevice ? 60 : 100,
        maxWidth: 170,
        enableValue: true,
        suppressMenu: true,
        resizable: true,

        cellClassRules: {
            'cellLevel1': (params: CellClassParams) => params.data?.level === 1,
            'cellLevel2': (params: CellClassParams) => params.data?.level === 2,
            'cellLevel3': (params: CellClassParams) => params.data?.level === 3,
            'cellLevel4': (params: CellClassParams) => params.data?.level === 4,
            'cellLevel5': (params: CellClassParams) => params.data?.level === 5,
            'cellLevel6': (params: CellClassParams) => params.data?.level === 6,
        },
    };
}