import React, {Component} from 'react';
import {GetAuthorities} from '../../../../services/admin/AccountsManagementService';
import Select from 'react-select';

import './RolesCellEditor.scss';
import {first} from "rxjs/operators";


interface RolesCellEditorState {
    roles: any[]
}


export class RolesCellEditor extends Component<{}, RolesCellEditorState> {

    roles: any[] = [];
    currentRoles: any[] = [];

    constructor(props: any) {
        super(props);
        this.state = {
            roles: []
        };

        this.currentRoles = props.value;

        GetAuthorities().then((result: any) => {
            result.map((b: any) => this.roles.push({value: b.name, label: b.name}));
            const oldRoles = this.roles.filter(r => this.currentRoles.includes(r.value));
            this.setState({roles: oldRoles});
        });

        this.handleSelectRoleChange = this.handleSelectRoleChange.bind(this);
    }

    handleSelectRoleChange(items: any) {
        this.setState({ roles: items });
    }

    getValue() {
        return this.state.roles.map(r => r.value);
    }

    isPopup() {
        return true;
    }
    render() {
        return (
            <div>
                <Select options={this.roles} isMulti
                    value={this.state.roles}
                    classNamePrefix='roles-multi-editor' onChange={this.handleSelectRoleChange} />

            </div>
        );
    }


}