import React, {CSSProperties, PropsWithChildren, useState} from "react";
import {FormattedMessage} from "react-intl";

import './RiskAVGQuarterData.scss'

interface IYtdChartLegend extends PropsWithChildren<any> {
    scopeName: string;
    style?: CSSProperties;
    value?: number;
    legendSymbolClass?: string;
    legendSymbolStyle?: CSSProperties;
    scopeNameStyle?: CSSProperties;
    onClick?: Function;
}

export const RiskAVGQuarterData: React.FC<IYtdChartLegend> = (props: IYtdChartLegend) => {
    const {scopeName, value, legendSymbolClass, legendSymbolStyle} = props;
    const [status, setStatus] = useState(false);

    const isValidatedValue = (value: any) => {
        return !Number.isNaN(parseInt(value));
    };

    return (
        <div className={`risk-chart-legend p-4 m-6`}
        >
            {
                status && (<div className="status">
                    <i className="icon icon-sm">check_circle_outline</i>
                </div>)
            }
            <div className="legend-symbol">
                <div className={`symbol ${legendSymbolClass}`} style={legendSymbolStyle}/>
            </div>
            <div className="header avg-font-size mt-1 text-secondary fw-semibold flex-grow-1">
                <span>{scopeName}</span>
            </div>
            <div className="body">
                {
                    isValidatedValue(value) && (
                        <>
                            <span className="display-4">{value}</span>
                            <span className="text-light fw-semibold"> <FormattedMessage id='million-euro'/></span>
                        </>
                    )
                }
            </div>
        </div>
    );
};