import {sgwtConnect} from "../utils/types";
import {getEnvVar} from "../../env-vars-utils";
import {getAuthorizationHeader} from "../../helpers";

const API_URL = getEnvVar('react_app_api_endpoint');

export const UploadFiles = async (files: any[]) => {
    const formData = new FormData();
    Array.from(files).forEach(file => {
        formData.append('uploadedFile[]', file);
    });
    const response = await fetch(API_URL + '/upload/file', {
        method: 'POST',
        headers: new Headers({
            Authorization: getAuthorizationHeader() ?? sgwtConnect.getAuthorizationHeader(),
        }),
        body: formData
    });

    if (!response.ok) {
        return Promise.reject('An error occurred while upload files by admin. Http status code ' + response.status);
    }

    return response.json();
};