import React from 'react';
import {getEnvVar} from "../../env-vars-utils";

const emailTo = getEnvVar('react_app_contact_mail');
const reactAppId = getEnvVar('react_app_id');

export const HelpCenter: React.FC = () => (
    <aside>
        <sgwt-help-center
            application-id={reactAppId}
            default-send-to={emailTo}
            mail-subject="User message"
            sg-connect-support="sg-connect-v2"
        />
    </aside>
);
