import React, {useEffect, useState} from "react";
import {ColGroupDef} from "ag-grid-community/dist/lib/entities/colDef";
import {Collapse} from "reactstrap";
import {ColumnState} from "ag-grid-community/dist/lib/columnController/columnController";
import {ColumnVisibleEvent} from "ag-grid-community";

import "./ColumnCustomizationToolPanel.scss";

interface Column {
    title: string,
    colId: string,
    visible: boolean | null | undefined
}

interface ColumnGroup {
    title: string,
    open: boolean,
    columns: Column[]
}

export const ColumnCustomizationToolPanel = (props: any) => {
    const [columnGroups, setColumnGroups] = useState([] as ColumnGroup[]);
    const [select, setSelect] = useState(new Array(7).fill(false));
    // open the collapse of quarter month period
    const [quarter, setQuarter] = useState(false);
    const [quarterPreviousYear, setQuarterPreviousYear] = useState(false);
    const [quarterY2, setQuarterY2] = useState(false);
    const [quarterY3, setQuarterY3] = useState(false);
    const [month, setMonth] = useState(false);
    const [monthPreviousYear, setMonthPreviousYear] = useState(false);
    const [monthY2, setMonthY2] = useState(false);
    const [monthY3, setMonthY3] = useState(false);
    const [period, setPeriod] = useState(false);
    const [customPeriod_day, setCustomPeriod_day] = useState(false);
    const [periodLastY2, setPeriodLastY2] = useState(false);
    const [periodLastY3, setPeriodLastY3] = useState(false);
    const [periodLastYear, setPeriodLastYear] = useState(false);
    const [estimateFy, setEstimateFy] = useState(false);
    const [estimateComparison, setEstimateComparison] = useState(false);
    const [estimateQuarter, setEstimateQuarter] = useState(false);
    const [renderView, setRenderView] = useState(true);
    const [dropDownVisible, setDropDownVisible] = useState(false);
    //open menu
    const [groupOpen, setGroupOpen] = useState(new Array(7).fill(true));


    //checkbox for month quarter and period
    const [quarterVisible, setQuarterVisible] = useState(true);
    const [quarterLastVisible, setQuarterLastVisible] = useState(true);
    const [quarterY2Visible, setQuarterY2Visible] = useState(true);
    const [quarterY3Visible, setQuarterY3Visible] = useState(true);
    const [monthVisible, setMonthVisible] = useState(true);
    const [monthLastVisible, setMonthLastVisible] = useState(true);
    const [monthY2Visible, setMonthY2Visible] = useState(true);
    const [monthY3Visible, setMonthY3Visible] = useState(true);
    const [periodVisible, setPeriodVisible] = useState(true);
    const [periodLastVisible, setPeriodLastVisible] = useState(true);
    const [periodLastY2Visible, setPeriodLastY2Visible] = useState(true);
    const [periodLastY3Visible, setPeriodLastY3Visible] = useState(true);
    const [estimateQuarterVisible, setEstimateQuarterVisible] = useState(true);
    const [estimateFyVisible, setEstimateFyVisible] = useState(true);
    const [estimateComparisonVisible, setEstimateComparisonVisible] = useState(true);

    //show quarters & months fields if included in ColumnGroup
    const [showMonth, setShowMonth] = useState(false);
    const [showQuarter, setShowQuarter] = useState(false);

    const openQuarter = () => {
        setQuarter(!quarter);
    }

    const openLastQuarter = () => {
        setQuarterPreviousYear(!quarterPreviousYear);
    }
    const openY2Quarter = () => {
        setQuarterY2(!quarterY2);
    }
    const openY3Quarter = () => {
        setQuarterY3(!quarterY3);
    }
    const openMonth = () => {
        setMonth(!month);
    }
    const openMonthLastYear = () => {
        setMonthPreviousYear(!monthPreviousYear);
    }
    const openY2Month = () => {
        setMonthY2(!monthY2);
    }
    const openY3Month = () => {
        setMonthY3(!monthY3);
    }
    const openPeriod = () => {
        setPeriod(!period);
    }

    const openCustomPeriod_day = () => {
        setCustomPeriod_day(!customPeriod_day);
    }
    const openCustomPeriod = () => {
        closeGroup();
        const rangePicker = document.querySelector('.reporting-range-date');
        rangePicker?.setAttribute("style", `display: flex !important`)
    }
    const openPeriodLastYear = () => {
        setPeriodLastYear(!periodLastYear);
    }
    const openPeriodLastY2 = () => {
        setPeriodLastY2(!periodLastY2);
    }
    const openPeriodLastY3 = () => {
        setPeriodLastY3(!periodLastY3);
    }
    const openQuarterEstimates = () => {
        setEstimateQuarter(!estimateQuarter);
    }

    const openFyEstimates = () => {
        setEstimateFy(!estimateFy);
    }
    const openEstimatesComparison = () => {
        setEstimateComparison(!estimateComparison);
    }

    const resetToDefault = () => {
        let selectCopies = [...select];
        setColumnGroups((prevState: ColumnGroup[]) => {
            prevState.forEach((columnGroup: ColumnGroup, index) => {
                columnGroup.columns.forEach(column => {
                    column.visible = true;
                });
                selectCopies[index] = columnGroup.columns.every(column => column.visible);
            });
            // if any column under quarter is invisible, quarter checkbox is unchecked
            setQuarterVisible(false);
            setMonthVisible(false);
            setQuarterLastVisible(false);
            setQuarterY2Visible(false)
            setQuarterY3Visible(false)
            setMonthLastVisible(false);
            setMonthY2Visible(false)
            setMonthY3Visible(false)
            setPeriodVisible(false);
            setPeriodLastVisible(false);
            setPeriodLastY2Visible(false);
            setPeriodLastY3Visible(false);
            setEstimateComparisonVisible(false);
            setEstimateQuarterVisible(false);
            setEstimateFyVisible(false);
            return [...prevState];
        });
        setSelect(selectCopies)
    }

    const handleCheckFamily = (position: any, group: any) => {
        const updateCheckFamily = select.map((group, index) =>
            index === position ? !group : group);
        setSelect(updateCheckFamily);
        setColumnGroups((prevState) => {
            prevState.map((columnGroup: ColumnGroup, index) => {
                    let title = group.title;
                    columnGroup.columns.forEach(column => {
                        if (columnGroup.title == title) {
                            column.visible = updateCheckFamily[index];
                            props.columnApi.setColumnVisible(column.colId, column.visible);
                        }
                    });
                }
            );
            return [...prevState];
        });
    };
//close sub menu when main menu is closed
    const closeSubMenu = () => {
        if (groupOpen[0]) {
            setMonth(false);
            setQuarter(false);
            setPeriod(false);
            setCustomPeriod_day(false);
        }
        if (groupOpen[4]) {
            setQuarterPreviousYear(false);
            setMonthPreviousYear(false);
            setPeriodLastYear(false);
        }
        if (groupOpen[5]) {
            setQuarterY2(false);
            setMonthY2(false);
            setPeriodLastY2(false);
        }
        if (groupOpen[6]) {
            setQuarterY3(false);
            setMonthY3(false);
            setPeriodLastY3(false);
        }
        if (groupOpen[7]) {
            setEstimateQuarter(false);
            setEstimateComparison(false);
            setEstimateFy(false);
        }
    }

    const closeGroup = () => {
        setColumnGroups((prevState: ColumnGroup[]) => {
            prevState.forEach((columnGroup) => {
                columnGroup.open = true;
            });
            return [...prevState];
        });
    }

    const handleClickOutside = (e: any) => {
        if (!e.target.classList.contains("list-family-common") &&
            !e.target.classList.contains("column") &&
            !e.target.classList.contains("tree-select-icon")) {
            setDropDownVisible(false);
            setGroupOpen(new Array(7).fill(true));
            closeGroup();
        } else {
            setDropDownVisible(true);
        }
    };

    const handleQuarter = (group: any) => {
        setQuarterVisible(!quarterVisible);
        setColumnGroups((prevState) => {
            prevState.map((columnGroup: ColumnGroup) => {
                    let title = group.title;
                    columnGroup.columns.forEach(column => {
                        if (columnGroup.title === title && column.colId.includes('quarterCurrentYear')) {
                            column.visible = quarterVisible;
                            props.columnApi.setColumnVisible(column.colId, column.visible);
                        }
                    });
                }
            );
            return [...prevState];
        });
    }

    const handleQuarterPreviousYear = (group: any) => {
        setQuarterLastVisible(!quarterLastVisible)
        setColumnGroups((prevState) => {
            prevState.map((columnGroup: ColumnGroup) => {
                    let title = group.title;
                    columnGroup.columns.forEach(column => {
                        if (columnGroup.title === title && column.colId.includes('quarterPreviousYear')) {
                            column.visible = quarterLastVisible;
                            props.columnApi.setColumnVisible(column.colId, column.visible);
                        }
                    });
                }
            );
            return [...prevState];
        });
    }
    const handleQuarterY2 = (group: any) => {
        setQuarterY2Visible(!quarterY2Visible)
        setColumnGroups((prevState) => {
            prevState.map((columnGroup: ColumnGroup) => {
                    let title = group.title;
                    columnGroup.columns.forEach(column => {
                        if (columnGroup.title === title && column.colId.includes('quarterYearMinusTwo')) {
                            column.visible = quarterY2Visible;
                            props.columnApi.setColumnVisible(column.colId, column.visible);
                        }
                    });
                }
            );
            return [...prevState];
        });
    }
    const handleQuarterY3 = (group: any) => {
        setQuarterY3Visible(!quarterY3Visible)
        setColumnGroups((prevState) => {
            prevState.map((columnGroup: ColumnGroup) => {
                    let title = group.title;
                    columnGroup.columns.forEach(column => {
                        if (columnGroup.title === title && column.colId.includes('quarterYearMinusThree')) {
                            column.visible = quarterY3Visible;
                            props.columnApi.setColumnVisible(column.colId, column.visible);
                        }
                    });
                }
            );
            return [...prevState];
        });
    }
    const handleMonth = (group: any) => {
        setMonthVisible(!monthVisible)
        setColumnGroups((prevState) => {
            prevState.map((columnGroup: ColumnGroup) => {
                    let title = group.title;
                    columnGroup.columns.forEach(column => {
                        if (columnGroup.title === title && column.colId.includes('monthCurrentYear')) {
                            column.visible = monthVisible;
                            props.columnApi.setColumnVisible(column.colId, column.visible);
                        }
                    });
                }
            );
            return [...prevState];
        });
    }

    const handleMonthLastYear = (group: any) => {
        setMonthLastVisible(!monthLastVisible);
        setColumnGroups((prevState) => {
            prevState.map((columnGroup: ColumnGroup) => {
                    let title = group.title;
                    columnGroup.columns.forEach(column => {
                        if (columnGroup.title === title && column.colId.includes('monthPreviousYear')) {
                            column.visible = monthLastVisible;
                            props.columnApi.setColumnVisible(column.colId, column.visible);
                        }
                    });
                }
            );
            return [...prevState];
        });
    }
    const handleMonthY2 = (group: any) => {
        setMonthY2Visible(!monthY2Visible);
        setColumnGroups((prevState) => {
            prevState.map((columnGroup: ColumnGroup) => {
                    let title = group.title;
                    columnGroup.columns.forEach(column => {
                        if (columnGroup.title === title && column.colId.includes('monthYearMinusTwo')) {
                            column.visible = monthY2Visible;
                            props.columnApi.setColumnVisible(column.colId, column.visible);
                        }
                    });
                }
            );
            return [...prevState];
        });
    }
    const handleMonthY3 = (group: any) => {
        setMonthY3Visible(!monthY3Visible);
        setColumnGroups((prevState) => {
            prevState.map((columnGroup: ColumnGroup) => {
                    let title = group.title;
                    columnGroup.columns.forEach(column => {
                        if (columnGroup.title === title && column.colId.includes('monthYearMinusThree')) {
                            column.visible = monthY3Visible;
                            props.columnApi.setColumnVisible(column.colId, column.visible);
                        }
                    });
                }
            );
            return [...prevState];
        });
    }

    const handlePeriod = (group: any) => {
        setPeriodVisible(!periodVisible);
        setColumnGroups((prevState) => {
            prevState.map((columnGroup: ColumnGroup) => {
                let title = group.title;
                columnGroup.columns.forEach(column => {
                    if (columnGroup.title === title && (column.colId.includes('d') && column.colId.length === 3) || (column.colId === 'nbiRangePeriod')) {
                        column.visible = periodVisible;
                        props.columnApi.setColumnVisible(column.colId, column.visible);
                    }
                });
            });
            return [...prevState];
        });

    }
    const handlePeriodLast = (group: any) => {
        setPeriodLastVisible(!periodLastVisible);
        setColumnGroups((prevState) => {
            prevState.map((columnGroup: ColumnGroup) => {
                let title = group.title;
                columnGroup.columns.forEach(column => {
                    if ((columnGroup.title === title && column.colId.includes('td') && column.colId.includes('previous')) || column.colId.includes('previousFullYear')) {
                        column.visible = periodLastVisible;
                        props.columnApi.setColumnVisible(column.colId, column.visible);
                    }
                });
            });
            return [...prevState];
        });

    }
    const handlePeriodLastY2 = () => {
        setPeriodLastY2Visible(!periodLastY2Visible);
        setColumnGroups((prevState) => {
            prevState.map((columnGroup: ColumnGroup) => {
                columnGroup.columns.forEach(column => {

                    if ((column.colId.includes('fullYearLastY2'))) {
                        column.visible = periodLastY2Visible;
                        props.columnApi.setColumnVisible(column.colId, column.visible);
                    }
                });
            });
            return [...prevState];
        });
    }

    const handlePeriodLastY3 = () => {
        setPeriodLastY3Visible(!periodLastY3Visible);
        setColumnGroups((prevState) => {
            prevState.map((columnGroup: ColumnGroup) => {
                columnGroup.columns.forEach(column => {
                    if ((column.colId.includes('fullYearLastY3'))) {
                        column.visible = periodLastY3Visible;
                        props.columnApi.setColumnVisible(column.colId, column.visible);
                    }
                });
            });
            return [...prevState];
        });

    }

    const handleEstimateQuarter = (group: any) => {
        setEstimateQuarterVisible(!estimateQuarterVisible);
        setColumnGroups((prevState) => {
            prevState.map((columnGroup: ColumnGroup) => {
                let title = group.title;
                columnGroup.columns.forEach(column => {
                    if (columnGroup.title === title && (column.colId === "estimateQuarter")) {
                        column.visible = estimateQuarterVisible;
                        props.columnApi.setColumnVisible(column.colId, column.visible);
                    }
                });
            });
            return [...prevState];
        });
    }
    const handleEstimateFy = (group: any) => {
        setEstimateFyVisible(!estimateFyVisible);
        setColumnGroups((prevState) => {
            prevState.map((columnGroup: ColumnGroup) => {
                let title = group.title;
                columnGroup.columns.forEach(column => {
                    if (columnGroup.title === title && column.colId.startsWith('estimateFy') && !column.colId.includes('estimateFyVs')) {
                        column.visible = estimateFyVisible;
                        props.columnApi.setColumnVisible(column.colId, column.visible);
                    }
                });
            });
            return [...prevState];
        });
    }
    const handleEstimateComparison = (group: any) => {
        setEstimateComparisonVisible(!estimateComparisonVisible);
        setColumnGroups((prevState) => {
            prevState.map((columnGroup: ColumnGroup) => {
                columnGroup.columns.forEach(column => {
                    let title = group.title;
                    if (columnGroup.title === title && (column.colId.includes('estimate') && column.colId.includes('Vs'))) {
                        column.visible = estimateComparisonVisible;
                        props.columnApi.setColumnVisible(column.colId, column.visible);
                    }
                });
            });
            return [...prevState];
        });
    }


    const handleCheckBoxOnChange = (column: any, index: number) => {
        column.visible = !column.visible;
        setColumnGroups([...columnGroups]);
        props.columnApi.setColumnVisible(column.colId, column.visible);
        if (!column.visible) {
            select[index] = false
        }
    };

    const toggle = (group: any, position: any) => {
        const groupStatus = groupOpen.map((group, index) =>
            index === position ? !group : group);

        setColumnGroups((prevState: ColumnGroup[]) => {
            prevState.forEach((columnGroup: ColumnGroup, groupIndex) => {
                //click other menu,close current menu
                groupStatus[groupIndex] = columnGroup.title !== group.title;
                columnGroup.open = groupStatus[groupIndex];
                closeSubMenu();
            });
            setGroupOpen(groupStatus);
            return [...prevState];
        });
    };

    const updateFamilyStatusAfterInit = (selectCopies: any[]) => {

        setColumnGroups((prevState: ColumnGroup[]) => {
            let checkList: string[] = [];
            prevState.forEach((columnGroup: ColumnGroup, index) => {
                //if all columns checked, the family checked
                selectCopies[index] = columnGroup.columns.every(column => column.visible);
                // the list of columns invisible
                columnGroup.columns.forEach(column => {
                    if (!column.visible) {
                        checkList.push(column.colId);
                    }
                })
            });
            // if any column under quarter is invisible, quarter checkbox is unchecked
            updateFamilyStatus(checkList);
            return [...prevState];
        });
    }


    const initColumnGroups = () => {
        const columnDefs: ColGroupDef[] = props.api.getColumnDefs();
        const columnState: ColumnState[] = props.columnApi.getColumnState();
        const columnGroups: ColumnGroup[] = [];
        let selectCopies = [...select];

        columnDefs?.forEach((columnGroup: ColGroupDef) => {
            if (columnGroup.children) {
                const group: ColumnGroup = {
                    title: columnGroup.headerName || '',
                    open: true,
                    columns: []
                };
                columnGroup.children?.forEach((columnChild: any) => {
                    let title = '';
                    columnChild.children?.forEach((column: any) => {
                        if (column.colId?.includes('Percent')) {
                            title = columnChild.headerName.concat(' (%)')
                        } else if (column.colId?.includes('Vs') || column.colId?.includes('Realization')) {
                            title = columnChild.headerName.concat(' (NBI) ');
                        } else {
                            title = columnChild.headerName;
                        }
                        if (column.colId?.includes('quarterCurrentYear')) {
                            setShowQuarter(true)
                        }
                        if (column.colId?.includes('monthCurrentYear')) {
                            setShowMonth(true)
                        }
                        const colId = column.colId;
                        const visible = !columnState?.find(e => e.colId === colId)?.hide;
                        group.columns?.push({title, colId, visible});
                    });
                });
                columnGroups.push(group);
            }
        });
        setColumnGroups(columnGroups);
        updateFamilyStatusAfterInit(selectCopies);
        setSelect(selectCopies);
    };

    const columnInput = (column: any, index: any) => {
        return (
            <dd key={index}>
                <div className={`btn-group d-flex justify-content-between`} role="group">
                    <label htmlFor="one" className="block " onClick={() => handleCheckBoxOnChange(column, index)}>
                        <input readOnly
                               type="checkbox"
                               className="column m-2 input-select-column"
                               checked={column.visible}/>
                        <span className="column m-0">{column.title}</span>
                    </label>
                    {column.colId.endsWith('nbiRangePeriod')
                        && <div className={`btn-group mt-2 me-3`} onClick={() => openCustomPeriod()}>
                            <i className="icon icon-sm tree-select-icon">edit</i>
                        </div>
                    }
                </div>
            </dd>
        );
    }
    
    const updateFamilyStatus = (checkList: String[]) => {
        setQuarterVisible(checkList.toString().includes('quarterCurrentYear'));
        setMonthVisible(checkList.toString().includes('monthCurrentYear'));
        setQuarterLastVisible(checkList.toString().includes('quarterPreviousYear'));
        setMonthLastVisible(checkList.toString().includes('monthPreviousYear'));
        setQuarterY2Visible(checkList.toString().includes('quarterYearMinusTwo'));
        setMonthY2Visible(checkList.toString().includes('monthYearMinusTwo'));
        setQuarterY3Visible(checkList.toString().includes('quarterYearMinusThree'));
        setMonthY3Visible(checkList.toString().includes('monthYearMinusThree'));
        setPeriodVisible(checkList.includes('wtd') || checkList.includes('mtd')
            || checkList.includes('qtd') || checkList.includes('ytd') || checkList.includes('day'));
        setPeriodLastVisible(checkList.toString().includes('Wtd') || checkList.toString().includes('Mtd')
            || checkList.toString().includes('Qtd') || checkList.toString().includes('Ytd') || checkList.toString().includes('previousFullYear'));
        setPeriodLastY2Visible(checkList.toString().includes('fullYearLastY2'));
        setPeriodLastY3Visible(checkList.toString().includes('fullYearLastY3'));
        setEstimateComparisonVisible(checkList.toString().includes('estimateFyVs') || checkList.toString().includes('estimateQuarterVs'));
        setEstimateFyVisible(checkList.includes('estimateFy') || checkList.includes('estimateFyBid') || checkList.includes('estimateFyAsk'));
        setEstimateQuarterVisible(checkList.includes('estimateQuarter'));
    }

    const columnVisibleChangedEvent = (e: ColumnVisibleEvent) => {

        if (!e.columns) {
            // case user clicks 'reset to default' button
            resetToDefault();
        } else {
            let selectCopies = [...select];
            setColumnGroups((prevState: ColumnGroup[]) => {
                let checkList: string[] = [];
                prevState.forEach((columnGroup: ColumnGroup, index) => {
                    e.columns?.forEach((changedColumns) => {
                        columnGroup.columns.forEach(column => {
                            if (column.colId === changedColumns.getColId()) {
                                column.visible = e.visible;
                            }
                        });
                    });

                    //if all columns checked, the family checked
                    selectCopies[index] = columnGroup.columns.every(column => column.visible);
                    // the list of columns invisible
                    columnGroup.columns.forEach(column => {
                        if (!column.visible) {
                            checkList.push(column.colId);
                        }

                    })
                });
                // if any column under quarter is invisible, quarter checkbox is unchecked
                updateFamilyStatus(checkList);
                return [...prevState];
            });
            setSelect(selectCopies)
        }
    };

    useEffect(() => {
        initColumnGroups();
        props.api.addEventListener('columnVisible', columnVisibleChangedEvent);
        return () => {
            props.api.removeEventListener('columnVisible', columnVisibleChangedEvent);
        }
    }, []);

    // re render column customization view
    useEffect(() => {
        setRenderView(false)
        setRenderView(true)
    }, [columnGroups, select]);

    useEffect(() => {
        // Add Event Listener to handle the click that happens outside
        // the Custom Select Container
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <div className="column-customization">
            <div className="select-families">
                {
                    renderView && columnGroups.map((group: any, index: number) => {
                        return (
                            <div className="column-children " key={index}>

                                <div className=" btn-group dropdown">
                                    <button className="btn btn-flat-primary dropdown-toggle column justify-content-between"
                                            type="button"
                                            onClick={() => toggle(group, index)}
                                    > {group.title}</button>
                                </div>
                                <Collapse className="list-family-common overflow-auto p-0"
                                          isOpen={!group.open && (index === 0) && dropDownVisible}>

                                    <div className={` column p-0 `}>
                                        <dd className="column" key={index}
                                            onClick={() => handleCheckFamily(index, group)}>
                                            <input readOnly
                                                   className="column m-2"
                                                   type="checkbox"
                                                   checked={select[index]}
                                            />
                                            <span className="column m-0">Select All</span>
                                        </dd>
                                        <div className={`column col-dropdown d-flex justify-content-between`}
                                             role="group">
                                            <div className=" btn-group column py-1" onClick={() => openPeriod()}>
                                                <i className=" icon icon-sm tree-select-icon">
                                                    {period ? 'expand_more' : 'chevron_right'}
                                                </i>
                                                <span className="column m-0">
                                               Periods
                                           </span>
                                            </div>
                                        </div>
                                        <Collapse className={`p-0 ${period ? "d-block" : "d-none"}`}
                                                  isOpen={period}>
                                            <dd className="column" key={"period"} onClick={() => handlePeriod(group)}>
                                                <label htmlFor="one" className="column block ">
                                                    <input readOnly
                                                           type="checkbox"
                                                           className="column m-2"
                                                           checked={!periodVisible}/>
                                                    <span className="column m-0">Select All</span>
                                                </label>
                                            </dd>
                                            {group.columns.map((column: any, index: number) => {
                                                if ((column.colId.includes('td') && !column.colId.includes('previous')) || column.colId === 'day' || column.colId === 'nbiRangePeriod') {
                                                    return columnInput(column, index)
                                                }
                                            })}
                                        </Collapse>
                                        <div className={`column btn-group col-dropdown`}
                                             role="group" onClick={() => openMonth()}>
                                            <div className="column py-1">
                                                <i className=" icon icon-sm tree-select-icon">
                                                    {month ? 'expand_more' : 'chevron_right'}
                                                </i>
                                                <span className="column m-0">
                                               Months
                                           </span>
                                            </div>
                                        </div>
                                        <Collapse className={`column p-0  ${month ? "d-block" : "d-none"}`}
                                                  isOpen={month}>
                                            <dd className="column" key={"month"} onClick={() => handleMonth(group)}>
                                                <label htmlFor="one" className="column block " hidden={!showMonth}>

                                                    <input readOnly
                                                           type="checkbox"
                                                           className="column m-2"
                                                           checked={!monthVisible}/>
                                                    <span className="column m-0">Select All</span>
                                                </label>
                                            </dd>
                                            {group.columns.map((column: any, index: number) => {
                                                if (column.colId.includes('monthCurrentYear')) {
                                                    return columnInput(column, index)
                                                }
                                            })}
                                        </Collapse>
                                        <div className={`column btn-group col-dropdown`}
                                             role="group" onClick={() => openQuarter()}>
                                            <div className="column py-1  justify-content-between ">
                                                <i className="icon icon-sm tree-select-icon">
                                                    {quarter ? 'expand_more' : 'chevron_right'}
                                                </i>
                                                <span className="column m-0">
                                               Quarters
                                           </span>
                                            </div>
                                        </div>

                                        <Collapse className={`column p-0  ${quarter ? "d-block" : "d-none"}`}
                                                  isOpen={quarter}>
                                            <dd key={"quarter"} onClick={() => handleQuarter(group)}>
                                                <label htmlFor="one" className="column block " hidden={!showQuarter}>
                                                    <input readOnly
                                                           type="checkbox"
                                                           className="column m-2"
                                                           checked={!quarterVisible}/>
                                                    <span className="column m-0">Select All</span>
                                                </label>
                                            </dd>
                                            {group.columns.map((column: any, index: number) => {
                                                if (column.colId.includes('quarterCurrentYear')) {
                                                    return columnInput(column, index)
                                                }
                                            })}
                                        </Collapse>
                                    </div>
                                </Collapse>
                                <Collapse className="list-family-common overflow-auto p-0 "
                                          isOpen={!group.open && (index === 4) && dropDownVisible}>

                                    <div className={`column p-0 `}>
                                        <dd className="column" key={index} onClick={() => handleCheckFamily(index, group)}>
                                            <input readOnly
                                                   className="column m-2"
                                                   type="checkbox"
                                                   checked={select[index]}
                                            />
                                            <span className="column m-0">Select All</span>
                                        </dd>
                                        <div className={`column btn-group col-dropdown`}
                                             role="group" onClick={() => openPeriodLastYear()}>
                                            <div className="column py-1">
                                                <i className=" icon icon-sm tree-select-icon">
                                                    {periodLastYear ? 'expand_more' : 'chevron_right'}
                                                </i>
                                                <span className="column m-0">
                                               Periods
                                           </span>
                                            </div>
                                        </div>
                                        <Collapse
                                            className={`column  p-0  ${periodLastYear ? "d-block" : "d-none"}`}
                                            isOpen={periodLastYear}>
                                            <dd className="column" key={"periodPreviousYear"}
                                                onClick={() => handlePeriodLast(group)}>
                                                <label htmlFor="one" className="column block ">
                                                    <input readOnly
                                                           type="checkbox"
                                                           className="column m-2"
                                                           checked={!periodLastVisible}/>
                                                    <span className="column m-0">Select All</span>
                                                </label>
                                            </dd>
                                            {group.columns.map((column: any, index: number) => {
                                                if ((column.colId.includes('td') && column.colId.includes('previous')) || column.colId.includes('previousFullYear')) {
                                                    return columnInput(column, index)
                                                }
                                            })}
                                        </Collapse>
                                        <div className={`column btn-group col-dropdown`}
                                             role="group" onClick={() => openMonthLastYear()}>
                                            <div className="column py-1">
                                                <i className=" icon icon-sm tree-select-icon">
                                                    {monthPreviousYear ? 'expand_more' : 'chevron_right'}
                                                </i>
                                                <span className="column m-0">
                                               Months
                                           </span>
                                            </div>
                                        </div>
                                        <Collapse className={`column p-0  ${monthPreviousYear ? "d-block" : "d-none"}`}
                                                  isOpen={monthPreviousYear}>
                                            <dd className="column" key={"monthPreviousYear"}
                                                onClick={() => handleMonthLastYear(group)}>
                                                <label htmlFor="one" className="column block ">

                                                    <input readOnly
                                                           type="checkbox"
                                                           className="column m-2"
                                                           checked={!monthLastVisible}/>
                                                    <span className="column m-0">Select All</span>
                                                </label>
                                            </dd>
                                            {group.columns.map((column: any, index: number) => {
                                                if (column.colId.includes('monthPreviousYear')) {
                                                    return columnInput(column, index)
                                                }
                                            })}
                                        </Collapse>
                                        <div className={`column btn-group col-dropdown `}
                                             role="group" onClick={() => openLastQuarter()}>

                                            <div className="column py-1 justify-content-between">
                                                <i className="icon icon-sm tree-select-icon">
                                                    {quarterPreviousYear ? 'expand_more' : 'chevron_right'}
                                                </i>
                                                <span className="column m-0">
                                               Quarters
                                           </span>
                                            </div>
                                        </div>

                                        <Collapse
                                            className={` column p-0  ${quarterPreviousYear ? "d-block" : "d-none"}`}
                                            isOpen={quarterPreviousYear}>
                                            <dd className="column" key={"quarterPreviousYear"}
                                                onClick={() => handleQuarterPreviousYear(group)}>
                                                <label htmlFor="one" className="column block ">
                                                    <input readOnly
                                                           type="checkbox"
                                                           className="column m-2"
                                                           checked={!quarterLastVisible}/>
                                                    <span className="column m-0">Select All</span>
                                                </label>
                                            </dd>
                                            {group.columns.map((column: any, index: number) => {
                                                if (column.colId.includes('quarterPreviousYear')) {
                                                    return columnInput(column, index)
                                                }
                                            })}
                                        </Collapse>
                                    </div>
                                </Collapse>
                                {/*// other families*/}
                                <Collapse className={`nowrap-family list-family-common p-0`}
                                          isOpen={!group.open && !(index === 0 || index == 4 || index === 5 || index === 6) && dropDownVisible}>
                                    <dd className="column" key={index} onClick={() => handleCheckFamily(index, group)}>
                                        <input
                                            className="column m-2"
                                            type="checkbox"
                                            checked={select[index]}
                                            onChange={() => handleCheckFamily(index, group)}
                                        />
                                        <span className="column m-0">Select All</span>
                                    </dd>
                                    {group.columns.map((column: any, index: number) => {
                                        return columnInput(column, index)
                                    })}
                                </Collapse>
                                <Collapse className=" list-family-common overflow-auto p-0 "
                                          isOpen={!group.open && (index === 5) && dropDownVisible}>
                                    <div className={`column p-0 `}>
                                        <dd className="column" key={index} onClick={() => handleCheckFamily(index, group)}>
                                            <input readOnly
                                                   className="column m-2"
                                                   type="checkbox"
                                                   checked={select[index]}
                                            />
                                            <span className="column m-0">Select All</span>
                                        </dd>

                                        <div className={`column btn-group col-dropdown`}
                                             role="group" onClick={() => openPeriodLastY2()}>
                                            <div className="column py-1">
                                                <i className=" icon icon-sm tree-select-icon">
                                                    {period ? 'expand_more' : 'chevron_right'}
                                                </i>
                                                <span className="column m-0">
                                               Period
                                           </span>
                                            </div>
                                        </div>
                                        <Collapse className={`  p-0  ${periodLastY2 ? "d-block" : "d-none"}`}
                                                  isOpen={periodLastY2}>
                                            <dd className="column" key={"periodLastY2"}
                                                onClick={() => handlePeriodLastY2()}>
                                                <label htmlFor="one" className="column block ">

                                                    <input readOnly
                                                           type="checkbox"
                                                           className="column m-2"
                                                           checked={!periodLastY2Visible}/>
                                                    <span className="column m-0">Select All</span>
                                                </label>
                                            </dd>
                                            {group.columns.map((column: any, index: number) => {
                                                if (column.colId.includes('fullYearLastY2')) {
                                                    return columnInput(column, index)
                                                }
                                            })}
                                        </Collapse>
                                        <div className={`column btn-group col-dropdown`}
                                             role="group" onClick={() => openY2Month()}>
                                            <div className="column py-1">
                                                <i className=" icon icon-sm tree-select-icon">
                                                    {monthY2 ? 'expand_more' : 'chevron_right'}
                                                </i>
                                                <span className="column m-0">
                                               Months
                                           </span>
                                            </div>
                                        </div>

                                        <Collapse className={`column p-0  ${monthY2 ? "d-block" : "d-none"}`}
                                                  isOpen={monthY2}>
                                            <dd className="column" key={"monthY2"} onClick={() => handleMonthY2(group)}>
                                                <label htmlFor="one" className="column block ">

                                                    <input readOnly
                                                           type="checkbox"
                                                           className="column m-2"
                                                           checked={!monthY2Visible}/>
                                                    <span className="column m-0">Select All</span>
                                                </label>
                                            </dd>
                                            {group.columns.map((column: any, index: number) => {
                                                if (column.colId.includes('monthYearMinusTwo')) {
                                                    return columnInput(column, index)
                                                }
                                            })}
                                        </Collapse>
                                        <div className={`column btn-group col-dropdown `}
                                             role="group" onClick={() => openY2Quarter()}>

                                            <div className="column py-1 justify-content-between">
                                                <i className="icon icon-sm tree-select-icon">
                                                    {quarterY2 ? 'expand_more' : 'chevron_right'}
                                                </i>
                                                <span className="column m-0">
                                               Quarters
                                           </span>
                                            </div>
                                        </div>
                                        <Collapse
                                            className={` column p-0  ${quarterY2 ? "d-block" : "d-none"}`}
                                            isOpen={quarterY2}>
                                            <dd className="column" key={"quarterY2"} onClick={() => handleQuarterY2(group)}>
                                                <label htmlFor="one" className="column block ">

                                                    <input readOnly
                                                           type="checkbox"
                                                           className="column m-2"
                                                           checked={!quarterY2Visible}/>
                                                    <span className="column m-0">Select All</span>
                                                </label>
                                            </dd>
                                            {group.columns.map((column: any, index: number) => {
                                                if (column.colId.includes('quarterYearMinusTwo')) {
                                                    return columnInput(column, index)
                                                }
                                            })}
                                        </Collapse>
                                    </div>
                                </Collapse>
                                <Collapse className="list-family-common overflow-auto p-0 "
                                          isOpen={!group.open && (index === 6) && dropDownVisible}>
                                    <div className={`column p-0 `}>
                                        <dd className="column" key={index} onClick={() => handleCheckFamily(index, group)}>
                                            <input readOnly
                                                   className="column m-2"
                                                   type="checkbox"
                                                   checked={select[index]}
                                            />
                                            <span className="column m-0">Select All</span>
                                        </dd>
                                        <div className={`column btn-group col-dropdown`}
                                             role="group" onClick={() => openPeriodLastY3()}>
                                            <div className="column py-1">
                                                <i className=" icon icon-sm tree-select-icon">
                                                    {period ? 'expand_more' : 'chevron_right'}
                                                </i>
                                                <span className="column m-0">
                                               Period
                                           </span>
                                            </div>
                                        </div>
                                        <Collapse className={`  p-0  ${periodLastY3 ? "d-block" : "d-none"}`}
                                                  isOpen={periodLastY3}>
                                            <dd className="column" key={"periodLastY3"}
                                                onClick={() => handlePeriodLastY3()}>
                                                <label htmlFor="one" className="column block ">

                                                    <input readOnly
                                                           type="checkbox"
                                                           className="column m-2"
                                                           checked={!periodLastY3Visible}/>
                                                    <span className="column m-0">Select All</span>
                                                </label>
                                            </dd>
                                            {group.columns.map((column: any, index: number) => {
                                                if (column.colId.includes('fullYearLastY3')) {
                                                    return columnInput(column, index)
                                                }
                                            })}
                                        </Collapse>
                                        <div className={`column btn-group col-dropdown`}
                                             role="group" onClick={() => openY3Month()}>
                                            <div className="column py-1">
                                                <i className=" icon icon-sm tree-select-icon">
                                                    {monthY3Visible ? 'expand_more' : 'chevron_right'}
                                                </i>
                                                <span className="column m-0">
                                               Months
                                           </span>
                                            </div>
                                        </div>

                                        <Collapse className={`column p-0  ${monthY3 ? "d-block" : "d-none"}`}
                                                  isOpen={monthY3}>
                                            <dd className="column" key={"monthY3"} onClick={() => handleMonthY3(group)}>
                                                <label htmlFor="one" className="column block ">

                                                    <input readOnly
                                                           type="checkbox"
                                                           className="column m-2"
                                                           checked={!monthY3Visible}/>
                                                    <span className="column m-0">Select All</span>
                                                </label>
                                            </dd>
                                            {group.columns.map((column: any, index: number) => {
                                                if (column.colId.includes('monthYearMinusThree')) {
                                                    return columnInput(column, index)
                                                }
                                            })}
                                        </Collapse>
                                        <div className={`column btn-group col-dropdown `}
                                             role="group" onClick={() => openY3Quarter()}>

                                            <div className="column py-1 justify-content-between">
                                                <i className="icon icon-sm tree-select-icon">
                                                    {quarterY3 ? 'expand_more' : 'chevron_right'}
                                                </i>
                                                <span className="column m-0">
                                               Quarters
                                           </span>
                                            </div>
                                        </div>

                                        <Collapse
                                            className={` column p-0  ${quarterY3 ? "d-block" : "d-none"}`}
                                            isOpen={quarterY3}>
                                            <dd className="column" key={"quarterY3"} onClick={() => handleQuarterY3(group)}>
                                                <label htmlFor="one" className="column block ">

                                                    <input readOnly
                                                           type="checkbox"
                                                           className="column m-2"
                                                           checked={!quarterY3Visible}/>
                                                    <span className="column m-0">Select All</span>
                                                </label>
                                            </dd>
                                            {group.columns.map((column: any, index: number) => {
                                                if (column.colId.includes('quarterYearMinusThree')) {
                                                    return columnInput(column, index)
                                                }
                                            })}
                                        </Collapse>
                                    </div>
                                </Collapse>

                                <Collapse className="list-family-common overflow-auto p-0 "
                                          isOpen={!group.open && group.title === 'Estimates' && dropDownVisible}>
                                    <div className={`column p-0 `}>
                                        <dd className="column" key={index} onClick={() => handleCheckFamily(index, group)}>
                                            <input readOnly
                                                   className="column m-2"
                                                   type="checkbox"
                                                   checked={select[index]}
                                            />
                                            <span className="column m-0">Select All</span>
                                        </dd>
                                        <div className={`column btn-group col-dropdown`}
                                             role="group" onClick={() => openQuarterEstimates()}>
                                            <div className="column py-1">
                                                <i className=" icon icon-sm tree-select-icon">
                                                    {estimateQuarter ? 'expand_more' : 'chevron_right'}
                                                </i>
                                                <span className="column m-0">
                                               Quarterly
                                           </span>
                                            </div>
                                        </div>
                                        <Collapse className={`p-0  ${estimateQuarter ? "d-block" : "d-none"}`}
                                                  isOpen={estimateQuarter}>
                                            <dd className="column" key={"estimateQuarter"}
                                                onClick={() => handleEstimateQuarter(group)}>
                                                <label htmlFor="one" className="column block ">

                                                    <input readOnly
                                                           type="checkbox"
                                                           className="column m-2"
                                                           checked={!estimateQuarterVisible}/>
                                                    <span className="column m-0">Select All</span>
                                                </label>
                                            </dd>
                                            {group.columns.map((column: any, index: number) => {
                                                if (column.colId.includes('estimateQuarter') && !column.colId.includes('Vs')) {
                                                    return columnInput(column, index)
                                                }
                                            })}
                                        </Collapse>
                                        <div className={`column btn-group col-dropdown`}
                                             role="group" onClick={() => openFyEstimates()}>
                                            <div className="column py-1">
                                                <i className=" icon icon-sm tree-select-icon">
                                                    {estimateFy ? 'expand_more' : 'chevron_right'}
                                                </i>
                                                <span className="column m-0">
                                               EFY
                                           </span>
                                            </div>
                                        </div>
                                        <Collapse className={`p-0  ${estimateFy ? "d-block" : "d-none"}`}
                                                  isOpen={estimateFy}>
                                            <dd className="column" key={"estimateFy"}
                                                onClick={() => handleEstimateFy(group)}>
                                                <label htmlFor="one" className="column block ">
                                                    <input readOnly
                                                           type="checkbox"
                                                           className="column m-2"
                                                           checked={!estimateFyVisible}/>
                                                    <span className="column m-0">Select All</span>
                                                </label>
                                            </dd>
                                            {group.columns.map((column: any, index: number) => {
                                                if (!column.colId.includes('estimateQuarter') && !column.colId.includes('Vs')) {
                                                    return columnInput(column, index)
                                                }
                                            })}
                                        </Collapse>
                                        <div className={`column btn-group col-dropdown`}
                                             role="group" onClick={() => openEstimatesComparison()}>
                                            <div className="column py-1">
                                                <i className=" icon icon-sm tree-select-icon">
                                                    {estimateComparison ? 'expand_more' : 'chevron_right'}
                                                </i>
                                                <span className="column m-0">
                                               Comparison
                                           </span>
                                            </div>
                                        </div>
                                        <Collapse className={`p-0  ${estimateComparison ? "d-block" : "d-none"}`}
                                                  isOpen={estimateComparison}>
                                            <dd className="column" key={"estimateComparison"}
                                                onClick={() => handleEstimateComparison(group)}>
                                                <label htmlFor="one" className="column block ">

                                                    <input readOnly
                                                           type="checkbox"
                                                           className="column m-2"
                                                           checked={!estimateComparisonVisible}/>
                                                    <span className="column m-0">Select All</span>
                                                </label>
                                            </dd>
                                            {group.columns.map((column: any, index: number) => {
                                                if (column.colId.includes('Vs')) { // or type
                                                    return columnInput(column, index)
                                                }
                                            })}
                                        </Collapse>
                                    </div>
                                </Collapse>
                            </div>
                        );
                    })}
            </div>
        </div>
    )
};
