import {ajax} from 'rxjs/ajax';
import {catchError, map, shareReplay} from 'rxjs/operators'
import {ajaxGetJSON, ajaxPost} from "rxjs/internal/observable/dom/AjaxObservable";
import {handleError} from './utils/UtilsService';
import {getEnvVar} from "../env-vars-utils";
import {getAuthorizationHeader} from "../helpers";

const API_URL = getEnvVar('react_app_api_endpoint')

const getHttpHeader = () => ({
  Authorization: getAuthorizationHeader(),
  'Content-Type': 'application/json'
});

export const getCurrentUser = () => {
  const header = {
    Authorization: getAuthorizationHeader(),
    'Content-Type': 'application/json'
  };
  return ajax.getJSON(API_URL + '/user/current', header)
    .pipe(
      catchError(error => handleError(error)));
};

export const FetchScopeList = (date: string) => {
  return ajax.getJSON(API_URL + '/user/current/units?date=' + date, getHttpHeader())
    .pipe(
      shareReplay(),
      map(response => response),
      catchError(error => handleError(error))
    )
};

export const getLastDate = () => {
  const header = {
    Authorization: getAuthorizationHeader(),
    'Content-Type': 'text/plain; charset=utf-8'
  };
  return ajax({
    url: API_URL + '/date',
    method: 'GET',
    headers: header,
    responseType: 'text'
  })
      .pipe(
          map(ajaxRsp => {
            return ajaxRsp.response;
          }),
          catchError(error => handleError(error))
      );
};
export const getReportingRangeDate = (date: string, value: number) => {
  return ajaxGetJSON(API_URL + '/range-date?date=' + date + '&value=' + value, getHttpHeader())
      .pipe(
          map(response => response),
          catchError(error => handleError(error))
      );
};

export const FetchAvailableDataType = (date: string, reportingUnitId: number) => {
  const body = {
    date,
    reportingUnitId
  };

  return ajaxPost(
    API_URL + '/synthesis/datatype',
    body,
    getHttpHeader()
  ).pipe(
    map(response => response.response),
    catchError(error => handleError(error))
  );
};

export const getDataStatus = (date: string, reportingUnitId: number) => {
  const body = {
    date,
    reportingUnitId
  };

  return ajaxPost(
    API_URL + '/synthesis/componentDataStatuses',
    body,
    getHttpHeader()
  ).pipe(
    map(response => response.response),
    catchError(error => handleError(error))
  );
};
