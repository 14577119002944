import React, {PropsWithRef, useEffect, useRef, useState} from "react";
import * as d3 from "d3";
import {MINI_HEATMAP_COMPARISON_TYPE, PERIOD_TYPE} from "../Constants";
import {FormattedMessage, useIntl} from "react-intl";
import {useViewport} from "../../../context/ViewportContext";

import './MiniHeatmapSVG.scss';

interface IMiniHeatmapSVG extends PropsWithRef<any> {
  period: string;
  comparisonType: string;
  data: any | undefined;
  year?: number;
}

const ColumnSize=3;

export const MiniHeatmapSVG: React.FC<IMiniHeatmapSVG> = (props) => {
  const [abovePercentage, updateAbovePercentage] = useState('');
  const [belowPercentage, updateBelowPercentage] = useState('');
  const [variation, setVariationPeriod] = useState("Weekly Variation");
  const [comparaison, setComparaison] = useState("");
  const intl = useIntl();
  const {width} = useViewport();
  const ref = useRef<HTMLDivElement>(null);
  const containerWidth: number = ref.current ? ref.current.offsetWidth : 0;
  const padding = 10;
  const gridHeight = 120;
  const gridWidthThreshold= 90;
  const svgHeight = 510;
  const year: number = props.year ? props.year : new Date().getFullYear();

  const draw = (containerWidth: number) => {
    const data: Array<any> = props.data;

    if (containerWidth > 0 && data) {
      d3.select('#content svg').remove();

      initDataForMiniHeatmap(data);

      const gridWidth: number = (containerWidth - padding * (ColumnSize - 1)) / ColumnSize;

      const svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any> = d3.select('#content')
        .append('svg')
        .attr('width', '100%')
        .attr('height', svgHeight);

      const cards: d3.Selection<d3.BaseType, any, SVGGElement, unknown> = svg.append('g')
        .attr('class', 'board')
        .selectAll('.square')
        .data(data);

      const rectGroup: d3.Selection<SVGGElement, any, SVGGElement, unknown> = cards.enter()
        .append('g')
        .attr('class', 'square');

      appendRect(rectGroup, gridWidth);

      // Title
      appendTitle(rectGroup, gridWidth);

      // NBI
      appendNBIValue(rectGroup, gridWidth);

      // Variation
      appendVariationValue(rectGroup, gridWidth);

      cards.exit().remove();
    }
  };

  const appendRect = (rectGroup: d3.Selection<SVGGElement, any, SVGGElement, unknown>, gridWidth: number) => {
    rectGroup.append('rect')
      .attr('x', (d: any) => (d.x - 1) * (gridWidth + padding))
      .attr('y', (d: any) => (d.y - 1) * (gridHeight + padding))
      .attr('width', gridWidth)
      .attr('height', gridHeight)
      .attr('class', (d: any) => d.color ? d.color : '');
  };

  const appendTitle = (rectGroup: d3.Selection<SVGGElement, any, SVGGElement, unknown>, gridWidth: number) => {
    rectGroup.append('text')
        .text(getTitle())
        .attr('class', (d: any) => `small fw-semibold ${getTextColor(d)}`)
      .attr("text-anchor", "middle")
      .attr('x', getTextPositionX(gridWidth))
      .attr('y', getTextPositionY(gridHeight, 30));
  };

  const appendNBIValue = (rectGroup: d3.Selection<SVGGElement, any, SVGGElement, unknown>, gridWidth: number) => {
    rectGroup.append('text')
      .text(nbiValue())
      .attr('class', (d: any) => `text-large font-weight-bolder ${getTextColor(d)}`)
      .attr("text-anchor", "middle")
      .attr('x', getTextPositionX(gridWidth))
      .attr('y', getTextPositionY(gridHeight, 65));
  };

  const appendVariationValue = (rectGroup: d3.Selection<SVGGElement, any, SVGGElement, unknown>, gridWidth: number) => {
    if (gridWidth >= gridWidthThreshold) {
        rectGroup.append('text')
            .text(d => `${getVariationPercentageValue(d)} ${getVariationValue(d)}`)
            .attr('class', (d: any) => `small fw-semibold ${getTextColor(d)}`)
        .attr("text-anchor", "middle")
        .attr('x', getTextPositionX(gridWidth))
        .attr('y', getTextPositionY(gridHeight, 95));
    }
    else {
        rectGroup.append('text')
            .text(d => getVariationPercentageValue(d))
            .attr('class', (d: any) => `small fw-semibold ${getTextColor(d)}`)
        .attr("text-anchor", "middle")
        .attr('x', getTextPositionX(gridWidth))
        .attr('y', getTextPositionY(gridHeight, 85));

        rectGroup.append('text')
            .text(d => getVariationValue(d))
            .attr('class', (d: any) => `small fw-semibold ${getTextColor(d)}`)
      .attr("text-anchor", "middle")
      .attr('x', getTextPositionX(gridWidth))
      .attr('y', getTextPositionY(gridHeight, 100));
    }
  };

  const getTitle = () => {
    return (d: any) => {
      if (props.period === PERIOD_TYPE.WTD) {
        return d.week;
      }
      else {
        return d.month + ' ' + year;
      }
    };
  };

  const getTextColor = (d: any) => {
    return d.nbi ? 'text-color-dark' : 'text-color-light';
  };

  const getTextPositionX = (gridWidth: number) => {
    return (d: any) => {
      return (d.x - 1/2) * (gridWidth + padding) - 4;
    };
  };

  const getTextPositionY = (gridHeight: number, offset: number) => {
    return (d: any) => {
      return (d.y - 1) * (gridHeight + padding) + offset;
    };
  };

  const nbiValue = () => {
    return (d: any) => {
      const nbiValue = d.nbi;
      return nbiValue ? d3.format(".1f")(nbiValue / 1000) : '';
    }
  };

  const getVariationPercentageValue = (d: any) => {
    const percentageVariation = d.percentageVariation;
    return percentageVariation ? percentageVariation > 0 ? '+' + d3.format(".1f")(percentageVariation * 100) + '%' : d3.format(".1f")(percentageVariation * 100) + '%' : '';
  };

  const getVariationValue = (d: any) => {
    const valueVariation = d.valueVariation;
    return valueVariation ? valueVariation > 0 ? '(+' + d3.format(".1f")(valueVariation / 1000) + ')' : '(' + d3.format(".1f")(valueVariation / 1000) + ')' : '';
  };

  const initTextsForHeatmap = (d: any) => {
    const periodValue = d[props.period];
    let valueVariation;
    let percentageVariation;
    d.nbi = undefined;
    d.percentageVariation = undefined;
    d.valueVariation = undefined;
    d.valueBudgetVariation = undefined;
    if (periodValue) {
      if (props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.BUDGET) {
        valueVariation = periodValue.valueBasedBudgetVariation;
        percentageVariation = periodValue.percentageBasedBudgetVariation;
      } else if (props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.SAME_PERIOD) {
        valueVariation = periodValue.valueBasedYearOnYearVariation;
        percentageVariation = periodValue.percentageBasedYearOnYearVariation;
      } else if (props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD) {
        valueVariation = periodValue.valueBasedPreviousPeriodVariation;
        percentageVariation = periodValue.percentageBasedPreviousPeriodVariation;
      }
      d.nbi = periodValue.value;
    }
    d.valueVariation = valueVariation;
    d.percentageVariation = percentageVariation;
  };

  const initColorsForHeatmap = (d: any) => {
    d.color = '';
    if (d.valueVariation >= 0) {
      d.color = 'green';
    }
    else if (d.valueVariation < 0) {
      d.color = 'red';
    }

    if (d.color === '' && d.nbi >= 0) {
      d.color = 'green';
    } else if (d.color === '' && d.nbi < 0) {
      d.color = 'red';
    }
  };

  /**
   * Add position information for each square
   * @param data
   */
  const initDataForMiniHeatmap = (data: Array<any>) => {
    let x: number = 1;
    let y: number = 1;
    if (Array.isArray(data) && data.length) {
      data.forEach(d => {
        d.x = x++;
        d.y = y;
        if (x > ColumnSize) {
          // new line
          x = 1;
          y++;
        }
        initTextsForHeatmap(d);
        initColorsForHeatmap(d);
      });

      calculatePercentage(data);
    }
  };

  const calculatePercentage = (data: Array<any>) => {
    let total = data.filter(d => d.valueVariation !== undefined).length;
    let above = data.filter(d => d.valueVariation >= 0).length;
    let below = data.filter(d => d.valueVariation < 0).length;

    const abovePercentage = total != 0 ? d3.format(".1f")(above * 100 / total) : undefined;
    const belowPercentage = total != 0 ? d3.format(".1f")(below * 100 / total) : undefined;

    if (abovePercentage && belowPercentage) {
      updateAbovePercentage(abovePercentage + '%');
      updateBelowPercentage(belowPercentage + '%');
    }
    else {
      updateAbovePercentage('');
      updateBelowPercentage('');
    }
  };

  const selectTitleFromPeriodAndComparaison = () => {
    if(props.period === PERIOD_TYPE.WTD){
      setVariationPeriod(' Weekly Variations');
    } else if (props.period === PERIOD_TYPE.MTD){
      setVariationPeriod(' Monthly Variations');

    }
    else if (props.period === PERIOD_TYPE.QTD){
      setVariationPeriod(' Quarter Variations');

    }
    else if (props.period === PERIOD_TYPE.YTD){
      setVariationPeriod(' Yearly Variations');

    }
    if (props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.BUDGET) {
      setComparaison(intl.formatMessage({id: 'budget'}) + props.year );
    }
    else if (props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.SAME_PERIOD) {
      props.year ? setComparaison((props.year -1 ).toString()) : setComparaison("");

    }
    else if (props.comparisonType === MINI_HEATMAP_COMPARISON_TYPE.PREVIOUS_PERIOD){
      setComparaison(props.year + " ("  + props.period.toUpperCase() + " - 1)");
      setVariationPeriod(" Variations")
    }
  };

  useEffect(() => {
      draw(containerWidth);
      selectTitleFromPeriodAndComparaison();
  }, [width, props.data, props.period, props.comparisonType, props.year]);

  return (
    <div className="mini-heatmap-svg" ref={ref}>
        <h5>{comparaison} {variation}</h5>
        <div className="pt-3">
            <span className="above font-weight-bolder text-success"><FormattedMessage
                id="mini-heatmap.above"/>{abovePercentage}</span>
            <span className="below font-weight-bolder ms-3 text-danger"><FormattedMessage
                id="mini-heatmap.below"/>{belowPercentage}</span>
        </div>
        <div id="content" className="content pt-3 overflow-auto fw-semibold"/>
    </div>
  );
};