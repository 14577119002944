import React, {ReactNode, useEffect, useState} from "react";
import {Accordion} from "../accordion/Accordion";
import {FormattedMessage} from "react-intl";
import {Form, FormGroup} from "reactstrap";
import {FetchNewsDetails} from "../../../services/SynthesisService";
import {NewsDetails} from "./NewsDetails";
import {useRouteContext} from "../../../context/RouteContext/RouteContext";
import {TitleService} from "../../../services/event/TitleService";
import {NewsNotFound} from "../NewsNotFound";
import {Loader} from "../Loader";
import {ConnexionError} from "../ConnexionError";
import {GetRegionValueByCode} from "../../../services/region/RegionService";
import UtilsService from "../../../services/utils/UtilsService";
import {useUser} from "../../../context/UserContext/UserContext";
import {useViewport} from "../../../context/ViewportContext";
import './News.css';


export const News: React.FC<any> = ({params}) =>  {
    const {isAdmin, isAdminWorldWide, isAdminAsia} = useUser();
    const [showEditNews, setShowEditNews] = useState(false);
    const [displayCkeditor, setDisplayCkEditor] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isConnexionFailed, setIsConnexionFailed] = useState(false);
    const [showNewsWidget, setShowNewsWidget] = useState(false);
    const [isDtdPositive, setisDtdPositive] = useState(true);
    const [topLevelNewsDetailsUpdated, setTopLevelNewsDetailsUpdated] = useState(false);
    const [title, setTitle] = useState("");
    const [topLevelBuNews, setTopLevelBuNews] = useState("");
    const {isProformaAvailable} = useRouteContext();
    const {isExtraSmallDevice} = useViewport();

    const [scopesName, setNewsScopesNames] = useState([{
        reportingUnitName: 'No Data Available',
        dtd :1,
        newsDetails: '',
        newsTitle: '',
        aboveDailyBudget : false,
        fakeBu:false,
    }]);
    const [selectedScope, setSelectedScope] = useState({});
    const [topLevelScope, setTopLevelScope] = useState({
        reportingUnitName: 'No Data Available',
        dtd: '',
        newsDetails: '',
        newsTitle: '',
        aboveDailyBudget :'',
        fakeBu:''
    });
    const topLevelScope2 = {
        reportingUnitName: 'No Data Available',
        dtd: '',
        newsDetails: '',
        newsTitle: '',
        aboveDailyBudget :'',
        fakeBu:'false'
    };
    const [newsDetail, setNewsDetail] = useState('No News Data Available for this Scope');
    const [topLevelScopeDtd, setTopLevelScopeDtd] = useState(1);
    const [topLevelId, setTopId] = useState(-1);

    function handleChange(newValue: any, id:any, scopeUpdated : any) {
        const updateItem:any = scopesName?.find((e : any)=> {
           return  e.reportingUnitName === scopeUpdated.title
        });
        if(updateItem){
            updateItem.newsDetails = newValue;
            updateItem.id = id;
            let index = scopesName.indexOf(updateItem);
            scopesName[index] = updateItem;
        }
        setNewsDetail(newValue);
        if(id === topLevelId){
            setTopLevelNewsDetailsUpdated(true);
            setTopLevelBuNews(newValue);
        }
        setDisplayCkEditor(false);
        setSelectedScope(updateItem);
    }
    const shouldDisplayCkeditor = () => {
        return displayCkeditor;
    };

    function viewCkeditor() {
        setDisplayCkEditor(true);
    }

    function viewNewsDetails(scope: any, indice: any) {
        if (scope) {
            let elem = document.getElementById(scope.reportingUnitName);
            if (elem) {
                elem.className = 'sub-scopes-title nav-link ps-1 ps-xs-1 ps-sm-1 ps-md-3 ps-lg-3 ps-xl-3 active ';
            }
            for (let i = 0; i < scopesName.length; i++) {
                if (scopesName[i].reportingUnitName === scope.reportingUnitName) {
                        setNewsDetail(scope.newsDetails);
                        setSelectedScope(scope);

                } else if (scopesName[i].fakeBu){
                    let elem = document.getElementById(scopesName[i].reportingUnitName);
                    if (elem) {
                        elem.className = 'ps-1 ps-xs-1 ps-sm-1 ps-md-3 ps-lg-3 ps-xl-3 nav-link fake-bu-color';
                    }
                }
                else {
                    let elem = document.getElementById(scopesName[i].reportingUnitName);
                    if (elem) {
                        elem.className = 'ps-1 ps-xs-1 ps-sm-1 ps-md-3 ps-lg-3 ps-xl-3 nav-link inactive sub-scopes-title';
                    }
                }
            }
        }
    }

    function viewNewsDetailsTopLevel(scope: any) {
        setSelectedScope(scope);
        if (scopesName[0] === scope) {
                if(topLevelNewsDetailsUpdated){
                    setNewsDetail(topLevelBuNews);
                }else{
                    setNewsDetail(scope.newsDetails);
                }
        }
        for (let i = 1; i < scopesName.length; i++) {
            let elem = document.getElementById(scopesName[i].reportingUnitName);
            if (elem) {
             if (scopesName[i].fakeBu){
                    let elem = document.getElementById(scopesName[i].reportingUnitName);
                    if (elem) {
                        elem.className = 'ps-1 ps-xs-1 ps-sm-1 ps-md-3 ps-lg-3 ps-xl-3 nav-link fake-bu-color';
                    }
                }
               else{
                 elem.className = 'ps-1 ps-xs-1 ps-sm-1 ps-md-3 ps-lg-3 ps-xl-3 nav-link inactive sub-scopes-title';
                 if(scope.dtd < 0 ){
                     setisDtdPositive(false);
                 }
             }
            }
        }
    }

    const constructNewsData = (data: any) => {
        if (data && data.length > 0 ) {
            setNewsScopesNames(data);
            if (data[0] !== undefined ) {
                setTopLevelScope(data[0]);
                setSelectedScope(data[0]);
                setTopId(data[0].id);
                setNewsDetail(data[0].newsDetails);
            }
            if (data[0].dtd) {
                setTopLevelScopeDtd(data[0].dtd);
                if (data[0].dtd > 0) {
                    setisDtdPositive(true);
                } else{
                    setisDtdPositive(false);
                }
            }
        } else{
            setTopLevelScope(topLevelScope2);
            setTopLevelScopeDtd(0);
            setNewsScopesNames([]);
        }
    };

    const handleClose = () => {
        if (displayCkeditor) {
            setDisplayCkEditor(false);
        }
    };

    const LocalLoader = () => {
        return <Loader/>;
    };

    const Error = () => {
        return <ConnexionError />;
    };

    const showEdit = () => {
        if (isExtraSmallDevice) {
            setShowEditNews(false);
        } else if ((isAdminWorldWide && UtilsService.isWorldWideByCode(params.userLocation) && params.region === 'ww')
            || (isAdminAsia && UtilsService.isAsiaByCode(params.userLocation))) {
            setShowEditNews(true);

        }
    }

    const canDisplayNewsWidget = () => {
       return UtilsService.isWorldWideByCode(params.region ? params.region : '') || UtilsService.isAsiaByCode(params.userLocation);
    }

    useEffect(() => {
        showEdit();
    });


    useEffect(() => {
        const subscription = TitleService.observable().subscribe(({title}) => {
            setTitle(title);
        });
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        const date = params.date;
        const reportingUnitId = parseInt(params.scope);
        const importType: string = isProformaAvailable ? "STABILIZED" : "PUBLISHED";
        const regionCode: string | undefined = params.region;
        const regionValue = GetRegionValueByCode(regionCode);
        const subReportingUnitId = params.subScope ? params.subScope : reportingUnitId;
        setIsLoading(true);
        const subscription = FetchNewsDetails(date, importType, reportingUnitId, regionValue, subReportingUnitId).subscribe(
            data => {
                if(data){
                    constructNewsData(data)
                }
                setIsLoading(false);},
            () => {
                setIsLoading(false);
                setIsConnexionFailed(true);
            });
        return () => subscription.unsubscribe();
    }, [params, isProformaAvailable]);

    useEffect(() => {
        setShowNewsWidget(canDisplayNewsWidget());
    });

    const formattedNumber = (num: number): ReactNode => {
        if (!isNaN(num)) {
            return UtilsService.formatNumberWithOneDigit(num)
        }
        return '-';
    };

    if (showNewsWidget) {
        return (
            <Accordion title={<FormattedMessage id="home.news" values={{title: `${title}`}}/>} filter={
                <Form className="d-lg-flex d-none">
                    {
                        showEditNews ?

                            <FormGroup className="d-flex align-items-center mb-0">
                                <button type="button" className="btn btn-dark d-none d-sm-block"
                                        onClick={viewCkeditor}>Edit
                    </button>
                </FormGroup>
                    : ""
                }
            </Form>
        }>
            {(() => {
                    if (isLoading) {
                        return <LocalLoader/>;
                    } else if (isConnexionFailed) {
                        return <Error/>;
                    }
                    else {
                        return (
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4 col-lg-4 col-sm-12 px-0">
                                        <div
                                            className="container-fluid news-detail ps-0"
                                            style={{padding: "0 !important"}}>
                                            <ul className="nav nav-xl nav-underline nav-vertical mt-2">
                                                <li className="" style={{cursor: "pointer"}}>
                                                    <div className="nav-link active"
                                                         style={{padding: "0 !important"}}
                                                         onClick={() => viewNewsDetailsTopLevel(topLevelScope)}>

                                                        <span className="text-truncate">
                                                         <a className="title"
                                                         >{topLevelScope.reportingUnitName} </a>
                                                        </span>
                                                        <label
                                                            className={"border-radius-24 badge rounded-pill badge-light badge-md top-level-corner-numbers float-end me-2" + (isDtdPositive ? ' dtd-positive' : ' budget-above-dtd')}>
                                                            <span>{UtilsService.formatNumberWithOneDigit(topLevelScopeDtd)} Mln€</span>
                                                        </label>
                                                    </div>
                                                    <ul className=" nav nav-xl nav-underline nav-hover nav-vertical mt-2">
                                                        {scopesName.map((scope, i) => (
                                                            <li className="" style={{cursor: "pointer"}}
                                                                key={i}>
                                                                {i > 0 ?
                                                                    <div className="row"
                                                                         onClick={() => viewNewsDetails(scope, i)}
                                                                    >
                                                                        <div className="col text-truncate">
                                                                             <span>
                                                                                <a id={scope["reportingUnitName"]}
                                                                                   className={"sub-scopes-title nav-link ps-xl-3 py-2 " + (scope["fakeBu"] ? 'fake-bu-color' : '')}>
                                                                                    {scope["reportingUnitName"]}
                                                                                </a>
                                                                              </span>
                                                                        </div>
                                                                        {!scope["fakeBu"] ?
                                                                            <div
                                                                                className="flex col-2 top-corner-numbers">
                                                                                <div className="float-end">
                                                                                    <div
                                                                                        id={scope["reportingUnitName"]}>
                                                                                        <label
                                                                                            className={"border-radius-24 badge rounded-pill badge-light badge-md float-end me-2" + (scope["dtd"] > 0 ? ' dtd-positive' : ' budget-above-dtd')}>
                                                                                            <span
                                                                                                className={"margin-content"}> {formattedNumber(scope["dtd"])} Mln€</span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : ''}
                                                                    </div>
                                                                    : ''}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        className="col-md-8 col-lg-8 col-xl-8 col-sm-12 mx-sm-0 pe-0 pe-xs-0 pe-sm-0 pe-md-2 pe-lg-2 pe-xl-2 ">
                                        {shouldDisplayCkeditor() &&
                                            <NewsDetails data={selectedScope} onClose={handleClose}
                                                         onChange={handleChange}/>}
                                        {!shouldDisplayCkeditor() &&
                                            <div>
                                                {newsDetail ?
                                                    <div className="t-align tes">
                                                <span>
                                                    <div className="news-details"
                                                         dangerouslySetInnerHTML={{__html: newsDetail}}/>
                                                </span>
                                                    </div>
                                                    :
                                                    <div className="modal-body ps-300">
                                                        <NewsNotFound/>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                }
            )()}
        </Accordion>
    );
    } else{
        return (<span></span>)
    }
};
