import {createMuiTheme} from "@material-ui/core/styles";

export const MuiTooltipTheme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: "white",
                backgroundColor: "#252c2d",
                maxWidth: 2000,
            }
        }
    }
});
