import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {IRouteParams} from "../route/IRouteParams";
import {useParams} from "react-router-dom";
import {TitleService} from "../../../services/event/TitleService";
import {Loader} from "../Loader";
import UtilsService from "../../../services/utils/UtilsService";
import {ConnexionError} from "../ConnexionError";
import {RiskAVGQuarterData} from "./ytdChartLegend/RiskAVGQuarterData";
import {NbiRiskChart} from "./NbiRiskChart";

interface AverageQuarter {
    quarter: number,
    avgVar: number,
    avgSVar: number,
    current: boolean

}

export const NBIRisk: React.FC<any> = ({data,params}) => {
    const routeParams: IRouteParams = useParams<IRouteParams>();
    const intl = useIntl();
    const [ytdVarData, setYtdVarData] = useState([]);
    const [ytdSVarData, setYtdSVarData] = useState([]);
    const [ytdVarAvg60Data, setYtdVarAvg60Data] = useState([]);
    const [ytdSVarAvg60Data, setYtdSVarAvg60Data] = useState([]);
    const [xAxisPeriod, setXAxisPeriod] = useState([]);
    const [quarterAverageData, setQuarterAverageData] = useState([] as AverageQuarter[]);
    const [isLoading, setIsLoading] = useState(false);
    const [isConnexionFailed, setIsConnexionFailed] = useState(false);
    const [title, setTitle] = useState("");


    const constructQuarterData = (data: any) => {
        if (!data || !data.length) {
            return;
        }

        data?.forEach((d: any) => {
            d.avgVar = getFormattedNumber(d.avgVar);
            d.avgSVar = getFormattedNumber(d.avgSVar);
        });
        setQuarterAverageData(data);
    };

    const constructData = (data: any) => {
        if (!data ) {
            return;
        }
        const varSvarData : any = data.graphData;
        const ytdVarData: any = [];
        const ytdSvarData: any = [];
        const ytdVarAvg60Data: any = [];
        const ytdSVarAvg60Data: any = [];
        const xAxisPeriod: any = [];

        varSvarData?.forEach((d: any) => {
            xAxisPeriod.push(d.date);
            ytdSvarData.push(d.svar? d.svar :null);
            ytdVarData.push(d.var? d.var :null);
            ytdVarAvg60Data.push(d.meanAverage60DaysVar? d.meanAverage60DaysVar :null);
            ytdSVarAvg60Data.push(d.meanAverage60DaysSVar? d.meanAverage60DaysSVar :null);

        });
        setYtdVarData(ytdVarData);
        setYtdSVarData(ytdSvarData);
        setYtdVarAvg60Data(ytdVarAvg60Data);
        setYtdSVarAvg60Data(ytdSVarAvg60Data)
        setXAxisPeriod(xAxisPeriod);
    };

    const getFormattedNumber = (value: number | undefined) => {
        return value !== undefined ? UtilsService.formatNumberWithOneDigit(value, true) : null
    };

    useEffect(() => {
        const subscription = TitleService.observable().subscribe(({title}) => {
            setTitle(title);
        });
        return () => subscription.unsubscribe();
    }, []);

    useEffect(()=> {
        setIsLoading(true);
        if (data.hasOwnProperty('keyFigureQuarterValueList')) {
            constructQuarterData(data.keyFigureQuarterValueList);
            constructData(data);
            setIsLoading(false);
        }
    },[data]);
    const numberSeparator = (number: any) => {
        return UtilsService.numberCommasSeparator(number);
    };


    const LocalLoader = () => {
        return <Loader/>;
    };

    const Error = () => {
        return <ConnexionError />;
    };

    return (
        <div>
            {(()=>{
                if (isLoading) {
                    return <LocalLoader />;
                } else if (isConnexionFailed) {
                    return <Error />;
                } else {
                    return (
                        <div>
                        <div  className="container-fluid">
                                <div className="d-flex">
                                    {quarterAverageData.map((item, index) => (
                                        <div className=" risk-chart d-flex" key={index}>
                                            <RiskAVGQuarterData
                                                legendSymbolStyle={{color: '#155de1'}}
                                                legendSymbolClass="dotted-gradient"
                                                scopeName={item.current ? 'AVG-QTD' + ' VaR' : 'AVG-Q' + item.quarter + ' VaR'}
                                                value={numberSeparator(item.avgVar)}
                                            />

                                            <RiskAVGQuarterData
                                                legendSymbolClass="dotted-gradient"
                                                legendSymbolStyle={{color: '#ff0c00'}}
                                                                scopeName= { item.current ? 'AVG-QTD' + ' SVaR': 'AVG-Q' + item.quarter + ' SVaR'}
                                                                value={numberSeparator(item.avgSVar)}
                                            />
                                        </div>
                                     ))}
                                </div>
                        </div>

                                <NbiRiskChart
                                    params={routeParams}
                                    varData={ytdVarData}
                                    sVarData={ytdSVarData}
                                    varAvg60Data={ytdVarAvg60Data}
                                    sVarAvg60Data={ytdSVarAvg60Data}
                                    xAxisPeriod={xAxisPeriod}
                                />

                    </div>
                    );
                }
            })()}
        </div>
    );
};
