import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {FetchLastAvailableDate} from "../../../services/SynthesisService";
import {FetchScopeList, getCurrentUser} from "../../../services/CommonService";
import {useRouteContext} from "../../../context/RouteContext/RouteContext";
import {first, mergeMap} from 'rxjs/operators';
import {GetRegionCodeByValue} from "../../../services/region/RegionService";
import {first as _first} from 'lodash';

import "./Home.scss";

const URL_SEPARATOR = "/";

export const Home: React.FC = () => {
  const synthesisPageBaseUrl = "/synthesis";
  const [pathname, setPathname] = useState("");
  const {setScopes} = useRouteContext();
  const perimeterList : any[] = [];

  /**
   * Select last available date and first option for SCOPE and REGION
   * "Logic will be changed once "save filters" feature implemented"
   *
   * Synthesis page URL can be one of below two patterns
   * - /synthesis/:region/:scope/:date
   * - /synthesis/:region/:scope/:subScope/:date
   *
   * But this function will only use the first option as sub scope won't
   * be used in this default redirection
   */
  const initData = (lastAvailableDate: string, scopeList: any[]) => {
    if (lastAvailableDate && Array.isArray(scopeList) && scopeList.length) {
      // user first scope as default choice
      const defaultScopeId = _first(scopeList).id;
      const locationList: any[] = scopeList
          .map((perimeter: any): any => perimeter.location)
          .sort((p1: any, p2: any) => p1.order - p2.order);
      const defaultLocationCode = _first(locationList).code.toLowerCase();
      setScopes(scopeList);

      const regionId = perimeterList.filter(p=>defaultScopeId==p.reportingUnitId)[0].regionId;
      const defaultRegionCode = GetRegionCodeByValue(regionId);

      if (defaultScopeId) {
        const pathname = synthesisPageBaseUrl + URL_SEPARATOR + // Base URL
            defaultLocationCode + URL_SEPARATOR + // user location
            defaultRegionCode + URL_SEPARATOR + // region name
            defaultScopeId + URL_SEPARATOR + // scope
            lastAvailableDate; // date
        setPathname(pathname);
      }
    }
  };

  useEffect(() => {
    let lastDate: string;
    const subscription = FetchLastAvailableDate()
      .pipe(mergeMap((lastAvailableDate: string) => {
        lastDate = lastAvailableDate;
        return FetchScopeList(lastAvailableDate);
      }))
      .subscribe((scopeList)=> initData(lastDate, scopeList as any));
    return () => subscription.unsubscribe();
  }, []);


  useEffect(()=>{
    const subscription = getCurrentUser()
        .pipe(first())
        .subscribe((user: any) => {
          user.perimeters.forEach((p:any)=>perimeterList.push(p));
        });

    return () => subscription.unsubscribe();
  },[])



  return (
      <div className="home">
        {pathname &&
        <Redirect to={{
          pathname
        }}>
        </Redirect>
        }
      </div>
  );
};
