import React, {ReactNode, useEffect, useState} from 'react';
import {CARD_TYPE} from '../Constants';
import {FormattedMessage, FormattedNumber} from "react-intl";
import './Card.scss'
import {CardTemplate} from './CardTemplate';

interface ICardProperty {
  type?: string | undefined;
  data?: any | undefined;
  isLoading: boolean;
}

export const Card: React.FC<ICardProperty> = ({type, data, isLoading}) => {

  const [headerColor, setHeaderColor] = useState('bg-green');
  const [bodyColor, setBodyColor] = useState('bg-green');
  const [footerColor, setFooterColor] = useState('bg-footer-green');
  const [variationToBudgetArrow, setVariationToBudgetArrow] = useState('');
  const [variationToPreviousYearArrow, setVariationToPreviousYearArrow] = useState('');

  const hasData = () => {
    if (data && data.value === 0) {
      return true;
    }
    else {
      return data && data.value;
    }
  };

  const isDTD = () => {
    return CARD_TYPE.DTD === type;
  };

  const isWTD =() => {
    return CARD_TYPE.WTD === type;
  }

  const convertToMillion = (param: number): number => {
    if ((Number.isFinite(param))) {
      return param / 1000;
    }
    return NaN;
  };

  const convertToPercentageValue = (param: number): number => {
    if ((Number.isFinite(param))) {
      return param * 100;
    }
    return NaN;
  };

  const formattedNumber = (num: number, digits?: number | 1): ReactNode => {
    if ((Number.isFinite(num))) {
      return <FormattedNumber value={num} maximumFractionDigits={digits}/>;
    }
    return '-';
  };

  const getArrowIcon = (num: number): string => {
    if (Number.isFinite(num)) {
      return num >= 0 ? 'arrow_circle_up' : 'arrow_circle_down';
    }
    return '';
  };

  const getBudgetSign = (num: number): string => {
    if (Number.isFinite(num)) {
      return num >= 0 ? '+' : '';
    }
    return '';
  };

  const getBudgetSignComparison = (num: any, value : number): string => {
    let numValue = +num;
    if (numValue < 0 && value > 0){
      return '+' + Math.abs(num).toString();
    }
    if (numValue < 0 && value < 0){
      return num;
    }
    if ((numValue <= 0 && value <= 0) || value >= 0){
      return '+' + num;
    }
    else if(value <= 0 && numValue > 0 ){
      return '-' + num
    }
    else if (value >= 0 && numValue <= 0){
      return Math.abs(num).toString();
    }
    else return num;
  };

  let cardHeader;
  let cardBody;
  let cardFooter;
  let nbi: number = NaN;
  let variationToBudget: number = NaN;
  let percentageToPreviousYear: number = NaN;
  let variationToPreviousYear: number = NaN;
  const millionEuro = <FormattedMessage id='million-euro'/>;

  if (hasData()) {
    nbi = convertToMillion(data.value);
    const percentageToBudget = formattedNumber(convertToPercentageValue(data.percentageBasedBudgetRealization), 1);
    const percentageToBudgetValue = parseFloat(convertToPercentageValue(data.percentageBasedBudgetRealization).toString()).toFixed(1);
    variationToBudget = convertToMillion(data.budgetRealizationDifferential);

    if (isDTD()) {
      percentageToPreviousYear = convertToPercentageValue(data.percentageBasedyoyDailyAverageVariation);
      variationToPreviousYear = convertToMillion(data.yoyDailyAverageVariation);
    } else {
      percentageToPreviousYear = convertToPercentageValue(data.percentageBasedYearOnYearVariation);
      variationToPreviousYear = convertToMillion(data.valueBasedYearOnYearVariation);
    }

    const formattedNbi = formattedNumber(nbi, 1);
    const formattedVariationToBudget = formattedNumber(variationToBudget, 1);
    const formattedPercentageToPreviousYear = formattedNumber(percentageToPreviousYear, 1);
    const formattedVariationToPreviousYear = formattedNumber(variationToPreviousYear, 1);

    cardHeader =
      <div className="w-100">
        <h5 className="d-flex align-items-center justify-content-between card-title line-height-1 tabular-figure mb-0">
            {type} {data.numberPeriod}
            <span className="small fw-bold start-100 ms-2">
            Day {data.currentDayInPeriod} of {data.totalDaysInPeriod}
          </span>
        </h5>
      </div>
      ;

    cardBody =
      <div className="w-100">
          <h1 className="tabular-figure d-flex align-items-center">
            <div className="flex-grow-0 icon-large-container">
              <i className="icon icon-large me-1">{variationToBudgetArrow}</i>
            </div>
            <span className="flex-grow-1 icon-large-ms-offset"></span>
            <div className="flex-grow-0">
              {formattedNbi}
              <span className="euro ms-1">{millionEuro}</span>
            </div>
              <span className="flex-grow-1"></span>
          </h1>
        {(data.percentageBasedBudgetRealization && data.budgetRealizationDifferential) ?
            <p className="text-regular align-items-center mb-0 d-flex justify-content-center fw-semibold">
                {
                    getBudgetSignComparison(percentageToBudgetValue, variationToBudget)
                }
                % var vs Budget ({getBudgetSign(variationToBudget)}{formattedVariationToBudget} {millionEuro})
            </p>
            : ""}
      </div>
    ;
    cardFooter = (
      !isNaN(percentageToPreviousYear) &&
      <div className="mb-0 w-100">
        <div className="d-flex align-items-center">
          <div className="flex-grow-0 icon-small-container">
            <i className="icon icon-small me-1">
              {variationToPreviousYearArrow}
            </i>
          </div>
            <span className="flex-grow-1 icon-small-ms-offset"></span>
            <div className="flex-grow-0 ms-4">
                {
                    isDTD()
                        ?
                        <span>
                {
                    getBudgetSign(percentageToPreviousYear)
                }
                            {formattedPercentageToPreviousYear}% var vs {type} AVG Y-1 ({getBudgetSign(percentageToPreviousYear)}{formattedVariationToPreviousYear} {millionEuro})
              </span>
                        :
                          isWTD() ?
                              <span>
                {
                    getBudgetSign(percentageToPreviousYear)
                }
                        {formattedPercentageToPreviousYear}% var vs {type} AVG Y-1 ({getBudgetSign(percentageToPreviousYear)}{formattedVariationToPreviousYear} {millionEuro})
              </span>
                      :
                      <span>
                        {
                          getBudgetSign(percentageToPreviousYear)
                        }
                        {formattedPercentageToPreviousYear}% var vs {type} Y-1 ({getBudgetSign(percentageToPreviousYear)}{formattedVariationToPreviousYear} {millionEuro})
                      </span>
            }
          </div>
          <span className="flex-grow-1"></span>
        </div>
      </div>
    )
    ;
  } else {
    cardBody =
      <div className="text-center no-data">
        <h3 className="mb-0">
          <FormattedMessage id='no-data'/>
        </h3>
      </div>
    ;
  }

  useEffect(() => {
    const toBudget = Number(variationToBudget);
    const toPreviousYear = Number(variationToPreviousYear);
    setFooterColor(toPreviousYear < 0 ? "bg-red" : "bg-green");
    if (nbi < 0){
      setHeaderColor("bg-red-warning");
      setBodyColor("bg-red-warning");
    }
    else if (nbi > 0 && toBudget  < 0) {
      setHeaderColor("bg-red");
      setBodyColor("bg-red");
    }
    else {
      setHeaderColor("bg-green");
      setBodyColor("bg-green");
    }
    setVariationToBudgetArrow(getArrowIcon(toBudget));

    setVariationToPreviousYearArrow(getArrowIcon(toPreviousYear));
  }, [variationToBudget, variationToPreviousYear]);

  return (
    <CardTemplate headerColor={headerColor} bodyColor={bodyColor} footerColor={footerColor}
     cardHeader={cardHeader} cardBody={cardBody} cardFooter={cardFooter} isLoading={isLoading}  />
  );
};