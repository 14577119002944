import React, {PropsWithChildren, ReactNode, useState} from "react";
import {Button, CardBody, CardHeader, Collapse} from "reactstrap";

import './Accordion.scss';

interface IAccordion extends PropsWithChildren<any> {
  title: string,
  openByDefault?: boolean,
  filter? : ReactNode
}

export const Accordion: React.FC<any> = (props: IAccordion) => {
  const [isOpen, setIsOpen] = useState(props.openByDefault ?? true);
  const {children, title, filter} = props;
  const toggle = () => setIsOpen(!isOpen);
 
  return (
    <div className="accordion card mt-2 mt-md-4 border">
      <CardHeader className="p-2 p-md-4 d-lg-flex justify-content-lg-between">
        <Button className="p-0" color="link" active={isOpen} onClick={toggle}>
            <h3 className="mb-0 text-left">
                <i className="icon icon-md">{isOpen ? 'expand_more' : 'expand_less'}</i>{title}
            </h3>
        </Button>
        {
            filter
                ?
                <div className={`mt-lg-0 mt-3 ${!isOpen ? 'd-none' : ''}`}>
                    {filter}
                </div>
                :
                <></>
        }
      </CardHeader>
        <Collapse isOpen={isOpen}>
            <CardBody className="card-body ps-2 pe-2 pb-2 pt-0 ps-md-4 pr-md-4 pb-md-4">
                {children}
            </CardBody>
        </Collapse>
    </div>
  );
};
