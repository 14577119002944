import * as d3 from "d3";
import React, {useEffect, useRef, useState} from "react";
import {FormattedMessage} from "react-intl";
import DateService from "../../../services/DateService";
import {FetchHeatMapNews} from "../../../services/DetailService";
import {HeatmapModal} from "./HeatmapModal";
import {DayData} from "./HeatmapModels";
import HeatmapService from "./HeatmapService";
import {first} from 'rxjs/operators';

import './HeatmapSVG.scss';
import {HeatmapExtractModule} from "./HeatmapExtractModule";

export const HeatmapSVG: React.FC<any> = (props) => {
  const svgRef = useRef(null as any);
  const margin = {
    top: 40,
    bottom: 40,
    right: 40,
    left: 40
  };
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const lineCount = months.length;
  const columnCount = 23;
  const days: string[] = d3.range(1, columnCount + 1).map(i => i + '');
  let gridSize = 0;
  const [day, setDay] = useState('');
  const [newsDay, setNewsDay] = useState('');
  const [comparedDay, setComparedDay] = useState('');
  const [show, setShow] = useState(false);
  const [news, setNews] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const reportingUnitId = parseInt(props.params.scope);
  const subReportingUnitId = parseInt(props.params.subScope as any);
  const [datePnl, setDatePnl] = useState<Record<string, number>>({});
  const [dateVariation, setDateVariation] = useState<Record<string, number>>({});
  const hoverColor = '#529aff';

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleResize = () => {
    const size = calculateGridWidth();
    draw(size);
    gridSize = size;
  };

  const calculateGridWidth = (): number => {
    const svgWidth: number = svgRef?.current?.offsetWidth;
    const gridWidth = (svgWidth - margin.left - margin.right) / columnCount;
    return gridWidth < 50 ? 50 : gridWidth;
  };

  const handleMouseover = (d: any) => {
    setDay(DateService.formatDateWithPattern(d.date, "DD MMM YYYY"));
      setComparedDay(HeatmapService.getComparedDay(d, props.data.previousYearDayDataList, props.periodDefaultValue,
          props.comparisonDefaultValue, props.min, props.max, props.min, props.max));

    const linesContainer = d3.select('#content svg .board');

    // draw a horizon line on the top of the card
    linesContainer.append('line')
      .attr('class', `lineX1-${d.x}-${d.y}`)
      .attr('y1', (d.y - 1) * gridSize)
      .attr('x2', d.x * gridSize)
      .attr('y2', (d.y - 1) * gridSize)
      .attr('shape-rendering', 'crispEdges')
      .attr('stroke-width', 2)
      .attr('stroke', hoverColor);

    // draw a horizon line on the bottom of the card
    linesContainer.append('line')
      .attr('class', `lineX2-${d.x}-${d.y}`)
      .attr('x1', 0)
      .attr('y1', d.y * gridSize)
      .attr('x2', d.x * gridSize)
      .attr('y2', d.y * gridSize)
      .attr('shape-rendering', 'crispEdges')
      .attr('stroke-width', 2)
      .attr('stroke', hoverColor);

    // draw a vertical line on the left of the card
    linesContainer.append('line')
      .attr('class', `lineY1-${d.x}-${d.y}`)
      .attr('x1', (d.x - 1) * gridSize)
      .attr('y1', 0)
      .attr('x2', (d.x - 1) * gridSize)
      .attr('y2', d.y * gridSize)
      .attr('shape-rendering', 'crispEdges')
      .attr('stroke-width', 2)
      .attr('stroke', hoverColor);

    // draw a vertical line on the right of the card
    linesContainer.append('line')
      .attr('class', `lineY2-${d.x}-${d.y}`)
      .attr('x1', d.x * gridSize)
      .attr('y1', 0)
      .attr('x2', d.x * gridSize)
      .attr('y2', d.y * gridSize)
      .attr('shape-rendering', 'crispEdges')
      .attr('stroke-width', 2)
      .attr('stroke', hoverColor);
  };

  const handleMouseout = (d: any) => {
    const linesContainer = d3.select('#content svg .board');
    linesContainer.select(`.lineX1-${d.x}-${d.y}`).remove();
    linesContainer.select(`.lineX2-${d.x}-${d.y}`).remove();
    linesContainer.select(`.lineY1-${d.x}-${d.y}`).remove();
    linesContainer.select(`.lineY2-${d.x}-${d.y}`).remove();
  };

  const handleClick = (d: any) => {
      setNewsDay(DateService.formatDateWithPattern(d.date, "DD MMM YYYY"));
      setShow(true);
    getNews(d.date);
  };

  const getNews = (date: string) => {
    setIsLoading(true);
    FetchHeatMapNews(date, reportingUnitId, subReportingUnitId)
        .pipe(first())
        .subscribe(
            data => {
              if (data) {
                setNews(data?.summaryNews);
                setIsLoading(false);
              }
            },
            () => {
              setNews(undefined);
              setIsLoading(false);
            });
  };

  const draw = (gridWidth: number) => {
    const data = props.data.heatmapDayDataList;
    if (data) {
      d3.select('#content svg').remove();

      const svgWidth = gridWidth * columnCount + margin.left + margin.right;
      const svgHeight = gridWidth * lineCount + margin.top + margin.bottom;

      const svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any> = d3.select('#content')
        .append('svg')
        .attr('width', svgWidth)
        .attr('height', svgHeight);

      // Build X scales and axis:
      const x = d3.scaleBand()
        .range([0, gridWidth * columnCount])
        .domain(days)
        .padding(0.1);
      svg.append("g")
          .attr("class", "axis fs-4 text-secondary")
        .attr('transform', 'translate(40, 40)')
        .call(d3.axisTop(x));

      // Build X scales and axis:
      const y = d3.scaleBand()
        .range([0, gridWidth * lineCount])
        .domain(months)
        .padding(0.1);
      svg.append("g")
          .attr("class", "axis fs-4 text-secondary")
        .attr('transform', 'translate(40, 40)')
        .call(d3.axisLeft(y));

      const cards: d3.Selection<d3.BaseType, any, SVGGElement, unknown> = svg.append('g')
        .attr('class', 'board')
        .attr('transform', 'translate(40, 40)')
        .selectAll('.square')
        .data(data);

      const rectGroup: d3.Selection<SVGGElement, any, SVGGElement, unknown> = cards.enter()
        .append('g')
        .attr('class', 'square')
        .on("mouseover", handleMouseover)
        .on("mouseout", handleMouseout)
        .on("click", handleClick);

      appendRect(rectGroup, gridWidth);

      // NBI
      appendPnlValue(rectGroup, gridWidth);

      // Variation
      if (props.methodDefaultValue !== HeatmapService.CONSTANT.HEATMAP.METHOD_EMPTY
        && props.comparisonDefaultValue !== HeatmapService.CONSTANT.HEATMAP.INTERVAL) {
        appendVariationValue(rectGroup, gridWidth);
      }

      cards.exit().remove();
    }
  };

  const appendRect = (rectGroup: d3.Selection<SVGGElement, any, SVGGElement, unknown>, gridWidth: number) => {
    rectGroup.append('rect')
      .attr('x', (d: any) => (d.x - 1) * gridWidth)
      .attr('y', (d: any) => (d.y - 1) * gridWidth)
      .attr('width', gridWidth)
      .attr('height', gridWidth)
      .attr('class', (d: any) => d.color ? d.color : ''); // to fix
  };

  const appendPnlValue = (rectGroup: d3.Selection<SVGGElement, any, SVGGElement, unknown>, gridWidth: number) => {
    rectGroup.append('text')
        .text(pnlValue())
        .attr('class', 'text-large font-weight-medium text-color-dark tabular-figures')
      .style("font-size", "14px")
      .attr("text-anchor", "middle")
      .attr('x', getTextPositionX(gridWidth))
      .attr('y', getTextPositionY(gridWidth, gridWidth / 2));
  };

  const appendVariationValue = (rectGroup: d3.Selection<SVGGElement, any, SVGGElement, unknown>, gridWidth: number) => {
    const marginBottom = props.comparisonDefaultValue === HeatmapService.CONSTANT.HEATMAP.INTERVAL ? 0 : Math.floor(gridWidth * 0.32);
    rectGroup.append('text')
      .text(variationValue())
      .attr('class', 'text-large text-color-dark tabular-figures')
      .style("font-size", "11px")
      .attr("text-anchor", "middle")
      .attr('x', getTextPositionX(gridWidth))
      .attr('y', getTextPositionY(gridWidth, gridWidth / 2 + marginBottom));
  };

  const getTextPositionX = (gridWidth: number) => {
    return (d: any) => {
      return (d.x - 1 / 2) * gridWidth;
    };
  };

  const getTextPositionY = (gridHeight: number, offset: number) => {
    return (d: any) => {
      return (d.y - 1) * gridHeight + offset;
    };
  };

  const pnlValue = () => {
    return (d: DayData) => {
      const value = HeatmapService.getNbiValueInMillion(props.periodDefaultValue, d);
      if (value !== undefined && d?.date !== undefined) {
        setDatePnl(prevObject => ({...prevObject, [d.date as string]: Number(value)}));
      }
      return value != undefined ? value : '';
    }
  };

  const getVariationForExtract = (value: number, d: DayData) => {
    if (!isNaN(value) && d?.date !== undefined) {
      setDateVariation(prevObject => ({...prevObject, [d.date as string]: value}));
    }
  }

  const variationValue = () => {
    return (d: DayData) => {
      let value = NaN;
      let valueWithSymbol: string;

      if (props.comparisonDefaultValue === HeatmapService.CONSTANT.HEATMAP.TARGET && !d.budgetYear && !props.budget
        && props.methodDefaultValue === HeatmapService.CONSTANT.HEATMAP.METHOD_VALUE) {
        // if we don't have budget data, e.g. level 3 BU, and we don't pass a personned budget, we should compare to 0
        value = HeatmapService.getNbiValueInMillion(props.periodDefaultValue, d) ?? NaN;
        valueWithSymbol = value > 0 ? '(+' + value + ')' : '(' + value + ')';
        return Number.isNaN(value) ? '' : valueWithSymbol;
      }

      if (props.methodDefaultValue === HeatmapService.CONSTANT.HEATMAP.METHOD_VALUE) {
        value = HeatmapService.getVariation(props.periodDefaultValue, props.targetDefaultValue,
          props.comparisonDefaultValue, d, props.budget, props.data.previousYearDayDataList, props.min, props.max, props.min, props.max);
        valueWithSymbol = value > 0 ? '(+' + value + ')' : '(' + value + ')';
      } else {
        value = HeatmapService.getPercentageValue(props.periodDefaultValue, props.targetDefaultValue,
            props.comparisonDefaultValue, d, props.budget, props.data.previousYearDayDataList, props.min, props.max, props.min, props.max);
        if (props.periodDefaultValue === HeatmapService.CONSTANT.HEATMAP.TYPE.QUARTERLY ||
            props.periodDefaultValue === HeatmapService.CONSTANT.HEATMAP.TYPE.YEARLY) {
          value = Math.round(value);
        }
        valueWithSymbol = value > 10000 ? '...' : value > 0 ? '(+' + value + '%)' : '(' + value + '%)';
      }
      getVariationForExtract(value, d);
      return Number.isNaN(value) ? '' : valueWithSymbol;
    }
  };

  const hideModal = () => {
    setShow(false);
  }

  const Summary = () => {
    return (
        <div>
          <span className="text-secondary text-large fw-semibold">Results: </span>
          <span className="text-secondary text-large font-weight-extrabold ">{props.result.total} </span>
          <span className="text-dark text-large fw-semibold">	&#124; </span>
          {props.comparisonDefaultValue === HeatmapService.CONSTANT.HEATMAP.INTERVAL ?
              <>
                <span className="text-success text-large fw-semibold"><FormattedMessage id="heatmap.inside"/>: </span>
                <span className="text-success text-large font-weight-extrabold">{props.result.withinInterval} </span>
                <span
                    className="text-success small font-weight-normal"> ({(props.result.withinInterval * 100 / props.result.total).toFixed(1)}%) </span>
                <span className="text-dark text-large fw-semibold">	&#124; </span>

                <span className="text-danger text-large fw-semibold"><FormattedMessage id="heatmap.outside"/>: </span>
                <span className="text-danger text-large font-weight-extrabold">{props.result.outsideInterval} </span>
                <span
                    className="text-danger small font-weight-normal"> ({(props.result.outsideInterval * 100 / props.result.total).toFixed(1)}%) </span>
              </>
              :
              <>
                <span className="text-success text-large fw-semibold"><FormattedMessage id="heatmap.above"/>: </span>
                <span className="text-success text-large font-weight-extrabold">{props.result.aboveTarget} </span>
                <span
                    className="text-success small font-weight-normal"> ({(props.result.aboveTarget * 100 / props.result.total).toFixed(1)}%) </span>
                <span className="text-dark text-large fw-semibold">	&#124; </span>

                <span className="text-danger text-large fw-semibold"><FormattedMessage id="heatmap.below"/>: </span>
                <span className="text-danger text-large font-weight-extrabold">{props.result.belowTarget} </span>
                <span
                    className="text-danger small font-weight-normal"> ({(props.result.belowTarget * 100 / props.result.total).toFixed(1)}%) </span>
              </>
        }
          <span className="text-large fw-semibold blue ms-4">{day}<small>&nbsp;{comparedDay}</small></span>
      </div>
    )
  }

  return (
      <div>
        <div className="d-flex align-items-center justify-content-between value-unit">
          <HeatmapExtractModule dateVariation={dateVariation} datePnl={datePnl} title={props.title}
                                region={props.params.region} periodDefaultValue={props.periodDefaultValue}
                                comparisonDefaultValue={props.comparisonDefaultValue}/>
          <div className="ps-2"><Summary/></div>
          <div className="text-secondary fw-semi-bold">
            <div className="d-flex align-items-center px-0 mx-0 mt-2">
              <span className="me-2"><FormattedMessage id="heatmap.below"/></span>
              <div className="box dark-red-box"></div>
              <div className="box red-box"></div>
              <div className="bar"></div>
              <div className="box green-box"></div>
              <div className="box dark-green-box"></div>
              <span className="ms-2"><FormattedMessage id="heatmap.above"/></span>
            </div>
            <div className="text-center px-0 mx-0">
              <span><FormattedMessage id="heatmap.target.mln"/></span>
            </div>
          </div>
        </div>
        <div id="content" className="content overflow-auto" ref={svgRef}/>
        <div className="mt-1"><Summary/></div>
        <HeatmapModal show={show} handleClose={hideModal} newsDay={newsDay} news={news} isLoading={isLoading}/>
      </div>
  )
}