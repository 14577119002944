import React, {PropsWithRef, useEffect, useState} from "react";

import './TreeSelect.scss';

interface IOption {
    value: string | number;
    name: string;
    children?: IOption[];
    isOpen?: boolean;
}

interface ISelectGroupProps extends PropsWithRef<any> {
    optionList: IOption[];
    onOptionChange?: Function;
    defaultValue?: string;
    hideIcon?: boolean;
}

export const TreeSelect: React.FC<ISelectGroupProps> = (props) => {
    const [showOptionList, setShowOptionList] = useState(false);
    const [optionList, setOptionList] = useState([] as any);
    const [originalOptionList, setOriginalOptionList] = useState([] as any);
    const [selectedOption, setSelectedOption] = useState({} as IOption);
    const [selectedText, setSelectedText] = useState("");

    // This method handles the display of option list
    const handleListDisplay = () => {
        setShowOptionList(prevState => !prevState);
    };

    // This method handles the setting of name in select text area
    // and list display on selection
    const handleOptionClick = (e: any, option: any) => {
        e.stopPropagation();

        props.onOptionChange && props.onOptionChange(option.value, option.name);
        setSelectedOption(option);
        setOptionList(originalOptionList);
        setSelectedText(option.name);
        setShowOptionList(false);
    };

    // This method handles the click that happens outside the
    // select text and list area
    const handleClickOutside = (e: any) => {
        if (
            !e.target.classList.contains("tree-select-option") &&
            !e.target.classList.contains("tree-select-icon") &&
            !e.target.classList.contains("selected-text")) {
            setShowOptionList(false);
        }
    };

    // This method handle the value changing event which will filter options according to input value
    const handleSelectedValueChange = (e: any) => {
        const targetValue = e.target.value;
        const filteredOptionList = filterListWithGivenName(targetValue, originalOptionList);
        setOptionList(filteredOptionList);
        setSelectedText(targetValue);
    };

    const filterListWithGivenName = (name: string, list: IOption[]): IOption[] => {
        const getFilteredOptions = (filteredList: IOption[], option: IOption) => {
            if (option.name.indexOf(name) !== -1) {
                filteredList.push(option);
                return filteredList;
            }
            if (Array.isArray(option.children)) {
                const children = option.children.reduce(getFilteredOptions, []);
                if (children.length) {
                    option.isOpen = true;
                    filteredList.push({...option, children});
                }
            }
            return filteredList;
        };

        return list.reduce(getFilteredOptions, []);
    };

    const openCloseOptionGroup = (target: IOption, optionList?: IOption[]) => {
        optionList?.forEach((option: IOption) => {
            if (Array.isArray(option.children)) {
                if (option.value === target.value) {
                    option.isOpen = !target.isOpen;
                } else {
                    // Close all other groups
                    // This works only for a tree with only two level options
                    option.isOpen = false;
                }
            }
        });
    };

    // Click chevron icon to open/close group options
    const handleIconClick = (e: any, target: IOption) => {
        e.stopPropagation();
        openCloseOptionGroup(target, optionList);
        setOptionList([...optionList]);
    };

    const classForActivatedOption = (option: IOption) => {
        return String(selectedOption.value) === String(option.value) ? 'activated' : ''
    };

    // Recursive method to construct option list with/without children
    const constructTreeStructure = (option: IOption) => {
        let result;
        if (Array.isArray(option.children) && option.children.length) {
            result =
                <li key={option.value}>
                    <div className={`tree-select-option ${classForActivatedOption(option)}`}
                         data-name={option.name} key={option.value}
                         onClick={(e) => handleOptionClick(e, option)}>
                        <i className="icon icon-sm tree-select-icon" onClick={(e) => handleIconClick(e, option)}>
                            {option.isOpen ? 'expand_more' : 'chevron_right'}
                        </i>
                        {option.name}

                    </div>
                    <ul className={`children-options p-0 ${option.isOpen ? 'd-block' : 'd-none'}`}>
                        {option.children.map((option: any) => constructTreeStructure(option))}
                    </ul>
                </li>;
        } else {
            result =
                <li key={option.value}>
                    <div className={`tree-select-option ${classForActivatedOption(option)}`}
                         data-name={option.name} key={option.value}
                         onClick={(e) => handleOptionClick(e, option)}>
                        {option.name}
                    </div>
                </li>;
        }
        return result;
    };

    const constructSelectedTextWithDefaultValue = (optionList: IOption[]) => {
        const option = getOptionWithValue(optionList, props.defaultValue);
        if (option) {
            setSelectedOption(option);
            setSelectedText(option.name);
        } else {
            setSelectedText("");
        }
    };

    const getOptionWithValue = (optionList: IOption[], value: string | undefined) => {
        if (optionList.length && value) {
            const defaultOption = optionList.find(option => String(option.value) === value);
            if (defaultOption) {
                return defaultOption;
            } else {
                let result;
                optionList.some(option => {
                    if (Array.isArray(option.children) && option.children.length) {
                        return result = getOptionWithValue(option.children, value);
                    }
                });
                if (result) {
                    return result;
                }
            }
        }
    };

    const init = () => {
        setOptionList(props.optionList);
        setOriginalOptionList(props.optionList);
        constructSelectedTextWithDefaultValue(props.optionList);
    };

    useEffect(() => constructSelectedTextWithDefaultValue(props.optionList), [props.defaultValue]);

    useEffect(() => {
        // Add Event Listener to handle the click that happens outside
        // the Custom Select Container
        document.addEventListener("mousedown", handleClickOutside);
        init();
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [props.optionList]);

    return (
        <div className="tree-select-container">
            <div className={showOptionList ? "selected-text active" : "selected-text"} onClick={handleListDisplay}>
                <div className="dropdown">
                    {!props.hideIcon &&
                        <div className="input-icon-start">
                            <i className="icon text-large">search</i>
                        </div>
                    }
                    <div className="input-icon-end">
                        <button className="btn btn-flat-primary dropdown-toggle" type="button"></button>
                    </div>
                    <input id={props.inputId} type="text" className="form-control form-control-undefined"
                           value={selectedText}
                           onChange={e => handleSelectedValueChange(e)}/>
                </div>
            </div>
            <ul className={`select-options p-0 mt-1 ${showOptionList ? "d-block" : "d-none"}`}>
                {optionList.map((option: any) => constructTreeStructure(option))}
            </ul>
        </div>
    );
};