import React, {createContext, useContext, useState} from 'react';

interface IRouteParams {
  scopes: any[],
  selectedScope: string;
  setScopes: Function;
  detailPageScope: string;
  detailPageIndicator: string, 
  detailPageParamsChanged: Function;
  isProformaAvailable: any,
  importTypeChanged: Function,
  parentName: any,
}

const defaultContext: IRouteParams = {
  scopes: [],
  selectedScope: "",
  setScopes: () => {
  },
  detailPageScope: "",
  detailPageIndicator: "",
  detailPageParamsChanged: () => {
  },
  isProformaAvailable: false,
  importTypeChanged: () => {
  },
  parentName: ""

};

export const RouteContext = createContext<IRouteParams>(defaultContext);

export const RouteParamsProvider: React.FC = ({children}) => {
  const [scopes, setScopes] = useState([] as any);
  const [selectedScope, setSelectedScope] = useState("");
  const [detailPageScope, setDetailedPageScope] = useState("");
  const [detailPageIndicator, setDetailPageIndicator] = useState("");
  const [isProformaAvailable, setIsProformaAvailable] = useState(false);
  const [parentName, setParentName] = useState("");

  const detailPageParamsChanged = async (scope: string, indicator: string, parentName: string) => {
    setDetailedPageScope(scope);
    setDetailPageIndicator(indicator);
    setParentName(parentName);
  };

  const importTypeChanged = async (isProforma: boolean) => {
    setIsProformaAvailable(isProforma);
  };

  return (
      <RouteContext.Provider value={{scopes, selectedScope, setScopes, detailPageScope, detailPageIndicator, detailPageParamsChanged, isProformaAvailable, importTypeChanged,parentName}}>
        {children}
      </RouteContext.Provider>
  );
};

export const useRouteContext = () => useContext<IRouteParams>(RouteContext);