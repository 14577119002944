import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {combineLatest} from 'rxjs';
import {getLastDate} from '../../../../services/CommonService';
import DatePicker from '../../../common/dateTimePicker/DatePicker';
import {Loader} from '../../../common/Loader';
import './CacheManagement.scss';
import {RequestStatus, Target} from './CacheManagementModels';
import {DataViewType} from "../../../common/DataViewType";
import {clearCacheData, loadCacheData} from "../../../../services/admin/cacheManagementService";
import {timeout} from 'rxjs/operators';

export const CacheManagement: React.FC = () => {
  const LOAD = 'load';
  const CLEAR = 'clear';
  const [mode, setMode] = useState(LOAD);
  const [date, setDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [targets, setTargets] = useState([DataViewType.FPV.valueOf()]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDataType, setShowDataType] = useState(true);
  const [showCheckAll, setShowCheckAll] = useState(true);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.INITIALIZED);
  const [successTargets, setSuccessTargets] = useState([] as string[]);
  const [failureTargets, setFailureTargets] = useState([] as string[]);
  const [timeoutTargets, setTimeoutTargets] = useState([] as string[]);

  useEffect(() => {

    setIsLoading(true);
    const subscription = combineLatest([
      getLastDate()
    ]).subscribe(
        ([date]) => {
          setDate(date);
          setSelectedDate(date);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        }
    );
    return () => {
      subscription.unsubscribe();
    }
  }, []);

  const handleModeChange = (event: any) => {
    const newMode = event.target.value;
    setMode(newMode);
    setSelectedDate(date);
    setRequestStatus(RequestStatus.INITIALIZED);
  };

  const handleDateChange = (date: string) => {
    if (requestStatus !== RequestStatus.IN_PROGRESS) {
      setDate(date);
      setSelectedDate(date);
    }
  };

  const changeTarget = (value: string) => {
    setRequestStatus(RequestStatus.INITIALIZED)
    const newTargets = [...targets];
    const index = newTargets.indexOf(value);
    if (index > -1) {
      newTargets.splice(index, 1);
    } else {
      newTargets.push(value);
    }
    setTargets(newTargets);
    setShowDataType(
        newTargets.includes(DataViewType.FPV.valueOf())
        || newTargets.includes(DataViewType.COMFI.valueOf())
        || newTargets.includes(DataViewType.REGION.valueOf()))
  };

  const changeAllTarget = (isCheck: boolean) => {
    setRequestStatus(RequestStatus.INITIALIZED)
    setShowCheckAll(!isCheck);
    if (isCheck) {
      const newTargets: string[] = [];
      Object.values(Target).forEach(
          value => {
            newTargets.push(value);
          });
      setTargets(newTargets);
    } else {
      setTargets([]);
    }
    setShowDataType(isCheck);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
  };

  const handleAction = (): void => {
    if (requestStatus === RequestStatus.TO_CONFIRM && targets.length > 0) {
      setRequestStatus(RequestStatus.IN_PROGRESS);
      setTimeoutTargets([])
      setSuccessTargets([])
      setFailureTargets([])
      if (mode === LOAD) {
        targets.forEach(value => {
          let request = {
            date: date,
            dataView: value
          };
          loadCache(request);
        })
      } else {
        let request = {
          date: date,
          dataViewOptions: targets
        }
        clearCache(request)
      }
    } else {
      setRequestStatus(RequestStatus.TO_CONFIRM);
    }
  };
  const getAllFinishedLoading = () => {
    return successTargets.length + failureTargets.length + timeoutTargets.length
  }
  useEffect(() => {
    if (getAllFinishedLoading() > 0 && (getAllFinishedLoading() === targets.length)) {
      setRequestStatus(RequestStatus.IS_FINISHED_LOAD)
    }
  }, [successTargets, failureTargets, timeoutTargets])

  const loadCache = (request: any) => {
    const subscription = loadCacheData(request).pipe(timeout(120000))
        .subscribe(
            (response) => {
              setSuccessTargets((prevState) => [...prevState,
                request.dataView
              ]);
            },
            (response) => {
              if (response.name === 'TimeoutError') {
                setTimeoutTargets((prevState) => [...prevState,
                  request.dataView
                ]);
              } else {
                setFailureTargets((prevState) => [...prevState,
                  request.dataView
                ]);
              }
            }
        );
    return () => subscription.unsubscribe();
  };

  const clearCache = (request: any) => {
    const subscription = clearCacheData(request)
        .subscribe(
            () => setRequestStatus(RequestStatus.IS_SUCCESS),
            () => setRequestStatus(RequestStatus.IS_FAILURE)
        );
    return () => subscription.unsubscribe();
  };

  const handleCancel = () => {
    setRequestStatus(RequestStatus.INITIALIZED);
  };

  const handleReset = () => {
    setTargets([DataViewType.FPV.valueOf()])
    setRequestStatus(RequestStatus.INITIALIZED);
  };

  return (
      <div className="container-fluid px-5 wrapper">
        {isLoading ?
            <Loader/>
            :
            <div className="pt-5">
              <h1><FormattedMessage id="admin.cache.head"/></h1>
              <form onSubmit={handleSubmit}>
                <div className="mt-5">
                  <div><h4><FormattedMessage id="admin.mode.title"/></h4></div>
                  <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    <label className={"btn btn-toggle-info me-1" + (mode === LOAD ? ' active' : '')}>
                      <input className="me-2 position-absolute opacity-0"
                             disabled={requestStatus === RequestStatus.IN_PROGRESS}
                             type="checkbox" value={LOAD} checked={mode === LOAD}
                             onChange={handleModeChange}/>
                      <FormattedMessage id="admin.cache.load"/>
                    </label>
                      <label className={"btn btn-toggle-info me-1" + (mode === CLEAR ? ' active' : '')}>
                        <input className="me-2 position-absolute opacity-0"
                               disabled={requestStatus === RequestStatus.IN_PROGRESS}
                               type="checkbox" value={CLEAR} checked={mode === CLEAR}
                               onChange={handleModeChange}/>
                        <FormattedMessage id="admin.cache.clear"/>
                      </label>
                  </div>
                </div>

                <>
                  <div className="mt-4">
                    <h4><FormattedMessage id="admin.cache.date"/></h4>
                    <div className="mb-0 w-100">
                      <DatePicker onDateChanged={handleDateChange} initDate={date}/>
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      <h4><FormattedMessage id="admin.target.title"/></h4>
                      {showCheckAll ?
                          <button disabled={requestStatus === RequestStatus.IN_PROGRESS}
                                  type="button" className="btn btn-sm btn-danger ms-2"
                                  onClick={() => changeAllTarget(true)}>
                            <FormattedMessage id="admin.target.checkAll"/>
                          </button>
                          :
                          <button disabled={requestStatus === RequestStatus.IN_PROGRESS}
                                  type="button" className="btn btn-sm btn-danger ms-2"
                                  onClick={() => changeAllTarget(false)}>
                            <FormattedMessage id="admin.target.uncheckAll"/>
                          </button>
                      }
                    </div>
                    <div className="d-flex my-2">
                      {Object.values(Target).map(
                          value =>
                              <div className="form-check me-4" key={value}>
                                <input
                                    className={'me-2' + (targets.includes(value) ? ' is-valid' : '')}
                                    type="checkbox" value="fpv"
                                    disabled={requestStatus === RequestStatus.IN_PROGRESS}
                                    checked={targets.includes(value)}
                                    onChange={() => changeTarget(value)}/>
                                <label><FormattedMessage
                                    id={"admin.delete.target." + value}/></label>
                              </div>
                      )}
                    </div>
                  </div>

                </>

                <div className="mt-5">
                  {requestStatus === RequestStatus.TO_CONFIRM ?
                      <>
                          <button className="btn btn-danger me-2" onClick={handleAction}>
                              <span><FormattedMessage id="admin.button.confirm"/></span>
                          </button>
                          <button className="btn btn-secondary me-2" onClick={handleCancel}>
                              <span><FormattedMessage id="admin.button.cancel"/></span>
                          </button>
                      </>
                      :
                      <>
                          <button disabled={requestStatus === RequestStatus.IN_PROGRESS}
                                  className="btn btn-primary me-2" onClick={handleAction}>
                              <span><FormattedMessage id={"admin.button.cache." + mode}/></span>
                          </button>
                          <button disabled={requestStatus === RequestStatus.IN_PROGRESS}
                                  className="btn btn-secondary me-2" onClick={handleReset}>
                              <span><FormattedMessage id="admin.button.reset"/></span>
                          </button>
                      </>
                  }
                </div>
              </form>
              {requestStatus === RequestStatus.TO_CONFIRM &&
              <div className="alert alert-outline-warning col-4 mt-5" role="alert">
                <FormattedMessage id="admin.cache.request.toConfirm"
                                  values={{date: `${selectedDate}`, action: `${mode}`}}/>
              </div>
              }
              {(requestStatus === RequestStatus.IN_PROGRESS) &&
              <div className="alert alert-outline-info col-4 mt-5" role="alert">
                  <FormattedMessage id={"admin.cache.request.deleting"}/>
              </div>
              }

              {((successTargets.length > 0 && requestStatus === RequestStatus.IS_FINISHED_LOAD)
                  || requestStatus === RequestStatus.IS_SUCCESS) &&
              <div className="alert alert-outline-success alert-dismissible fade show col-4 mt-5"
                   role="alert">
                <FormattedMessage id="admin.cache.response.success"
                                  values={{
                                    date: `${selectedDate}`,
                                    action: `${mode}`,
                                    targets: `${successTargets.length ? successTargets.toString() : targets.toString()}`
                                  }}/>
              </div>
              }
              {((failureTargets.length > 0 && requestStatus === RequestStatus.IS_FINISHED_LOAD)
                  || requestStatus === RequestStatus.IS_FAILURE) &&
              <div className="alert alert-outline-danger alert-dismissible fade show col-4 mt-5" role="alert">
                <FormattedMessage id="admin.cache.response.failure"
                                  values={{
                                    date: `${selectedDate}`,
                                    action: `${mode}`,
                                    targets: `${failureTargets.length ? failureTargets.toString() : targets.toString()}`
                                  }}/>
              </div>
              }
              {(timeoutTargets.length > 0 && requestStatus === RequestStatus.IS_FINISHED_LOAD) &&
              <div className="alert alert-outline-info col-4 mt-5" role="alert">
                <FormattedMessage id="admin.cache.response.timeout"
                                  values={{
                                    date: `${selectedDate}`,
                                    action: `${mode}`,
                                    targets: `${timeoutTargets.length ? timeoutTargets.toString() : targets.toString()}`
                                  }}/>
              </div>
              }
            </div>
        }
      </div>
  );
}

