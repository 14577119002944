import React from 'react';


export const AuthoritiesCellRenderer : React.FC = (params : any) => {

    const formattedRoles = params.data.roles.join(',');

    if (!params.data.editing) {
        return (
            <div>
                 <p> {formattedRoles }</p>
            </div>
        );
    }
    else {
        return (<div></div>)
    }

}