import {DataViewType} from "../DataViewType";

export const sideBarDef: any = (isSmallDevice: any, dataView: DataViewType) => {
    return (isSmallDevice) ?
        false :
        {
            toolPanels: [
                {
                    id: 'columnCustomization',
                    labelDefault: 'Column Customization',
                    labelKey: 'columnCustomization',
                    iconKey: 'columns',
                    toolPanel: 'columnCustomizationToolPanel',
                },
            ],
            hiddenByDefault: false,
        };
}
export const rangeDateProperty: any = (date: string, startDate: string) => {
    return {
        date: date,
        startDate: startDate
    }
}