import {ajaxPost} from "rxjs/internal/observable/dom/AjaxObservable";
import {catchError, map} from "rxjs/operators";
import {handleError} from "../utils/UtilsService";
import {getEnvVar} from "../../env-vars-utils";
import {getAuthorizationHeader} from "../../helpers";

const API_URL = getEnvVar('react_app_api_endpoint') + '/admin';

export const loadCacheData = (loadRequest: any) => {
    const header = {
        Authorization: getAuthorizationHeader(),
        'Content-Type': 'application/json;charset=UTF-8'
    };
    return ajaxPost(
        API_URL + '/load-cache',
        loadRequest,
        header
    ).pipe(
        map(response => {
            return response;
        }),
        catchError(error => handleError(error))
    );
};

export const clearCacheData = (loadRequest: any) => {
    const header = {
        Authorization: getAuthorizationHeader(),
        'Content-Type': 'application/json;charset=UTF-8'
    };
    return ajaxPost(
        API_URL + '/clear-cache',
        loadRequest,
        header
    ).pipe(
        map(response => {
            return response;
        }),
        catchError(error => handleError(error))
    );
};