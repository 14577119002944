import React from 'react';
import { FormattedMessage } from 'react-intl';

export const ConnexionError: React.FC = () => {
    return (
        <div className="p-2" style={{ height: '400px' }}>
            <h5><FormattedMessage id="error.connexion" /></h5>
        </div>
    );
};
