import React, {createContext, useContext, useEffect, useState} from 'react';
import {getCurrentUser} from "../../services/CommonService";
import {first} from "rxjs/operators";
import {Role} from "../../models/UserInfoModel";

interface IUserContext {
    isAdmin: boolean | undefined;
    isAdminWorldWide: boolean | undefined;
    isAdminAsia: boolean | undefined;
    isEstimatesUser: boolean;
    current: any;
}

const defaultContext: IUserContext = {
    isAdmin: undefined,
    isAdminWorldWide: undefined,
    isAdminAsia: undefined,
    isEstimatesUser: false,
    current: undefined
};

export const UserContext = createContext<IUserContext>(defaultContext);

export const UserProvider: React.FC = ({children}) => {
    const [isAdmin, setIsAdmin] = useState<boolean | undefined>(defaultContext.isAdmin);
    const [isAdminWorldWide, setIsAdminWorldWide] = useState<boolean | undefined>(defaultContext.isAdminWorldWide);
    const [isAdminAsia, setIsAdminAsia] = useState<boolean | undefined>(defaultContext.isAdminAsia);
    const [isEstimatesUser, setIsEstimatesUser] = useState<boolean>(defaultContext.isEstimatesUser);
    const [current, setCurrent] = useState<any>(defaultContext.current);

    useEffect(() => {
        const subscription = getCurrentUser()
            .pipe(first())
            .subscribe((user) => {
                setCurrent(user);
                setIsAdmin(hasAdminRole(user));
                setIsAdminWorldWide(hasAdminWorldWideRole(user));
                setIsAdminAsia(hasAdminAsiaRole(user));
                setIsEstimatesUser(hasEstimatesRole(user));
            });
        return () => subscription.unsubscribe();
    }, []);

    const hasAdminRole = (currentUserInfo: any): boolean => {
        const roles = currentUserInfo?.roles;
        if (roles) {
            return roles.includes(Role.ROLE_ADMIN) || roles.includes(Role.ROLE_ADMIN_ASIA);
        }
        return false;
    };

    const hasAdminWorldWideRole = (currentUserInfo: any): boolean => {
        const roles = currentUserInfo?.roles;
        if (roles) {
            return roles.includes(Role.ROLE_ADMIN);
        }
        return false;
    };

    const hasEstimatesRole = (currentUserInfo: any): boolean => {
        const roles = currentUserInfo?.roles;
        if (roles) {
            return roles.includes(Role.ROLE_ESTIMATES_READER);
        }
        return false;
    };
//check also commenter role
    const hasAdminAsiaRole = (currentUserInfo: any): boolean => {
        const roles = currentUserInfo?.roles;
        if (roles) {
            return roles.includes(Role.ROLE_ADMIN_ASIA);
        }
        return false;
    };

    return (
        <UserContext.Provider value={{isAdmin, current, isAdminWorldWide, isAdminAsia, isEstimatesUser}}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext<IUserContext>(UserContext);