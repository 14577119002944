import React from 'react';
import { FormattedMessage } from 'react-intl';

interface IValidationErrorProps {
    messageId: string;
}


export const FormSuccessMessage: React.FC<IValidationErrorProps> = (props) => {

    if (props.messageId !== '') {
        return (
            < div className="alert alert-outline-success" role="alert" >
                 <FormattedMessage id = {props.messageId}  /><br/>
            </div >
        );
    }
    else
        return (<div></div>);
}