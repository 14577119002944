import {DataStatus} from '../../models/DataStatusModel';
import {sgwtConnect} from '../utils/types';
import {getEnvVar} from "../../env-vars-utils";
import {getAuthorizationHeader} from "../../helpers";

const API_URL = getEnvVar('react_app_api_endpoint');

export const confirmDataStatus = async (date: string, reportingUnitId: number) => {
    return saveOrUpdateDataStatus(date, reportingUnitId, DataStatus.CONFIRMED);
};

export const unvalidateDataStatus = async (date: string, reportingUnitId: number) => {
    return saveOrUpdateDataStatus(date, reportingUnitId, DataStatus.NOT_AVAILABLE);
};

export const saveOrUpdateDataStatus = async (date: string, reportingUnitId: number, dataStatus: string) => {
    const body = {
        date,
        reportingUnitId,
        dataStatus
    };
    const response = await fetch(API_URL + '/synthesis/saveOrUpdateStatusForDate', {
        method: 'POST',
        headers: new Headers({
            Authorization: getAuthorizationHeader() ?? sgwtConnect.getAuthorizationHeader(),
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(body)
    });

    if (!response.ok) {
        return Promise.reject('An error occurred when update data status. Http status code ' + response.status);
    }

    return response.json();
};

export const confirmAllDataStatus = async (date: string, reportingUnitId: number) => {
    const body = {
        date,
        reportingUnitId
    };
    const response = await fetch(API_URL + '/synthesis/confirmAllData', {
        method: 'POST',
        headers: new Headers({
            Authorization: getAuthorizationHeader() ?? sgwtConnect.getAuthorizationHeader(),
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(body)
    });

    if (!response.ok) {
        return Promise.reject('An error occurred when confirm all data status. Http status code ' + response.status);
    }

    return response.json();
};