import Highcharts from 'highcharts/highstock';
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { FetchPerformanceTrendData } from "../../../services/DetailService";
import { GetRegionValueByCode } from '../../../services/region/RegionService';
import UtilsService from "../../../services/utils/UtilsService";
import { ConnexionError } from "../ConnexionError";
import { Loader } from "../Loader";
import {useRouteContext} from "../../../context/RouteContext/RouteContext";
import {useIntl} from "react-intl";

export interface PerformanceTrend {
  'date': number[];
  'dailyPnl': number;
  'dailyBudget': number;
  'meanAverage5Days'?: number;
  'meanAverage20Days'?: number;
  'meanAverage60Days'?: number;
  'meanAverageYtd'?: number;
}

export const PerformanceTrendChart: React.FC<any> = ({ params }) => {
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const [isConnexionFailed, setIsConnexionFailed] = useState(false);
  const [dailyPnlData, setDailyPnlData] = useState([] as any);
  const [dailyBudgetData, setDailyBudgetData] = useState([] as any);
  const [meanAverage5DaysData, setMeanAverage5DaysData] = useState([] as any);
  const [meanAverage20DaysData, setMeanAverage20DaysData] = useState([] as any);
  const [meanAverage60DaysData, setMeanAverage60DaysData] = useState([] as any);
  const [meanAverageYtdData, setMeanAverageYtdData] = useState([] as any);
  const year = UtilsService.getYearFromDate(params.date);
  const {isProformaAvailable} = useRouteContext();

  useEffect(() => {
    const date = params.date;
    const reportingUnitId = parseInt(params.scope);
    const subReportingUnitId = parseInt(params.subScope);
    const dataNatureId = parseInt(params.indicatorId);
    setIsLoading(true);
    const importType: string = isProformaAvailable ? "STABILIZED" : "PUBLISHED";
    const regionId = GetRegionValueByCode(params.region);
    const subscription = FetchPerformanceTrendData(date, reportingUnitId, subReportingUnitId, dataNatureId, importType, regionId)
      .subscribe(response => {
        if (response) {
          buildDataSets(response);
        }
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
        setIsConnexionFailed(true);
      });
    return () => subscription.unsubscribe();
  }, []);

  const buildDataSets = async (rawData: PerformanceTrend[]) => {
    const dailyPnlDataSet: any[] = [];
    const dailyBudgetDataSet: any[] = [];
    const meanAverage5DaysDataSet: any[] = [];
    const meanAverage20DaysDataSet: any[] = [];
    const meanAverage60DaysDataSet: any[] = [];
    const meanAverageYtdDataSet: any[] = [];

    rawData.forEach(
      data => {
        const date = Date.UTC(data.date[0], data.date[1] - 1, data.date[2]);
        dailyPnlDataSet.push([date, UtilsService.formatNumberWithOneDigit(data.dailyPnl)]);
        dailyBudgetDataSet.push([date, UtilsService.formatNumberWithOneDigit(data.dailyBudget)]);
        if (data.meanAverage5Days) {
          meanAverage5DaysDataSet.push([date, UtilsService.formatNumberWithOneDigit(data.meanAverage5Days)]);
        }
        if (data.meanAverage20Days) {
          meanAverage20DaysDataSet.push([date, UtilsService.formatNumberWithOneDigit(data.meanAverage20Days)]);
        }
        if (data.meanAverage60Days) {
          meanAverage60DaysDataSet.push([date, UtilsService.formatNumberWithOneDigit(data.meanAverage60Days)]);
        }
        if (data.meanAverageYtd) {
          meanAverageYtdDataSet.push([date, UtilsService.formatNumberWithOneDigit(data.meanAverageYtd)]);
        }
      }
    );

    setDailyPnlData(dailyPnlDataSet);
    setDailyBudgetData(dailyBudgetDataSet);
    setMeanAverage5DaysData(meanAverage5DaysDataSet);
    setMeanAverage20DaysData(meanAverage20DaysDataSet);
    setMeanAverage60DaysData(meanAverage60DaysDataSet);
    setMeanAverageYtdData(meanAverageYtdDataSet);
  };

  const options = {
    title: {
      text: null
    },
    tooltip: {
      shared: true,
      backgroundColor: 'black',
      borderColor: '#464b4b',
      style: {
        color: 'white',
        background: 'black',
        opacity: '80%',
        fontFamily: 'Inter',
        fontSize: '14px',
      }
    },
    rangeSelector: {
      enabled: true,
      inputEnabled: false,
      verticalAlign: 'top',
      buttonPosition: {
        align: 'right'
      },
      labelStyle: {
        display: 'none'
      },
      buttonTheme: {
        stroke: '#dddddd',
        fill: '#505053',
        style: {
          color: 'white'
        }
      },
      buttons: [{
        type: 'month',
        count: 1,
        text: '1M'
      }, {
        type: 'month',
        count: 3,
        text: '3M'
      }, {
        type: 'month',
        count: 6,
        text: '6M'
      }, {
        type: 'month',
        count: 9,
        text: '9M'
      }, {
        type: 'all',
        text: 'AL'
      }]
    },
    series: [
      {
        name: intl.formatMessage({id: 'budget'}) + year,
        data: dailyBudgetData,
        color: '#D84437',
        marker: {
          enabled: false
        }
      },
      {
        name: 'Daily',
        data: dailyPnlData,
        color: '#5298FF',
        marker: {
          enabled: false
        }
      },
      {
        name: '5D-MA',
        data: meanAverage5DaysData,
        color: '#68EFAD',
        marker: {
          enabled: false
        }
      },
      {
        name: '20D-MA',
        data: meanAverage20DaysData,
        color: '#DB7093',
        marker: {
          enabled: false
        }
      },
      {
        name: '60D-MA',
        data: meanAverage60DaysData,
        color: '#974A5A',
        marker: {
          enabled: false
        }
      },
      {
        name: 'YTD-MA',
        data: meanAverageYtdData,
        color: '#F6A416',
        marker: {
          enabled: false
        }
      }
    ],
    chart: {
      height: 630,
      spacingTop: 0,
      backgroundColor: {
        linearGradient: [0, 0, 500, 500],
        stops: [
          [0, 'rgb(48,51,51)'],
        ]
      },
      zoomType: 'xy',
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%e %b',
      },
      labels: {
        style: {
            color: '#949c9e',//text-secondary
            fontWeight: '600',//fw-semibold
            fontFamily: 'Inter', //body font (inter)
            fontSize: 15
        }
      },
      crosshair:{
        dashStyle: 'Solid',
        snap: true,
        width: 1,
        zIndex: 2
      }
    },
    yAxis: {
      height: '100%',
      resize: {
        enabled: true
      },
      gridLineColor: '#464b4b',
      lineColor: 'white',
      title: {
        text: null
      },
      labels: {
        style: {
            color: '#949c9e',//text-secondary
            fontWeight: '600',//fw-semibold
            fontFamily: 'Inter', //body font (inter)
            fontSize: 15
        }
      },
      startOnTick: false,
      endOnTick: false
    },
    legend: {
      enabled: true,
      align: 'left',
      verticalAlign: 'top',
      itemStyle: {
          fontSize: '15px',
          fontWeight: '600',//fw-semibold
          fontFamily: 'Inter', //body font (inter)
          color: 'white'
      },
    },
    credits: false,
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
            "separator",
            "downloadCSV",
            "downloadXLS",
            "openInCloud"],
          symbolStroke: '#dddddd',
          theme: {
            fill: '#505053'
          },
        }
      }

    },
    scrollbar: {
      enabled: true,
      height: 10,
      rifleColor: 'none',
      barBackgroundColor: '#c4c8ca',
      barBorderWidth: 0,
      buttonBackgroundColor: '#c4c8ca',
      buttonBorderWidth: 0,
      trackBackgroundColor: '#303333',
      trackBorderWidth: 0
    },
  };

  const LocalLoader = () => {
      return <Loader/>;
  };

  const Error = () => {
    return <ConnexionError />;
  };

  const Chart = () => {
    return <div className='chart'><HighchartsReact highcharts={Highcharts} options={options} /></div>
  };

  return (
    <>
    {(() => {
      if (isLoading) {
        return <LocalLoader />;
      } else if (isConnexionFailed) {
        return <Error />;
      } else {
        return <Chart />;
      }
    })()}
    </>
  )
};