import React from 'react';


export const EnableAccountCellRenderer: React.FC = (params: any) => {

    return (
        <div className='form-group ms-2'>
            <div className='custom-control custom-checkbox'>
                <input className='custom-control-input' type='checkbox' checked={params.data.enabled}/>
                <label className='custom-control-label'></label>
            </div>
        </div>
    );
}