import React, {PropsWithRef, useEffect, useMemo, useRef, useState} from "react";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import {
    columnDefsForSmallDevice,
    constructColumnNames,
    getAutoGroupColumnDef,
    getDefaultColDef,
    getGridOptions,
    indicatorColmun,
    updateCurrentYearColsPref,
    viewDetailsColumn,
} from './GridColumnDefs'
import {rangeDateProperty, sideBarDef} from './CustomViewDefs'
import {Column, ColumnApi, GridApi, RowNode} from "ag-grid-community";
import {defaultExcelExportParams, excelStylesValue} from "./ExcelExportParams"
import {GridReadyEvent} from "ag-grid-community/dist/lib/events";
import {
    FetchNbiReportingData,
    GetAllAdminReportingPreference,
    GetUserReportingPreference,
    SaveAdminReportingPreference,
    SaveUserReportingPreference
} from "../../../services/SynthesisService";
import {DataViewType} from "../DataViewType";
import {useViewport} from "../../../context/ViewportContext";
import {ViewDetailsRenderer} from "./ViewDetailsRenderer/ViewDetailsRenderer"
import {ColumnCustomizationToolPanel} from "./ColumnCustomizationToolPanel/ColumnCustomizationToolPanel";
import {ColumnState} from "ag-grid-community/dist/lib/columnController/columnController";
import {FormattedMessage, useIntl} from "react-intl";
import DateService from "../../../services/DateService";
import {useRouteContext} from "../../../context/RouteContext/RouteContext";
import {
    DefaultRegionOption,
    GetRegionCodeByValue,
    GetRegionTitleByValue,
    GetRegionValueByCode
} from "../../../services/region/RegionService";
import {toast} from "react-toastify";

import 'ag-grid-community/dist/styles/ag-grid.css';
import '@sg-bootstrap/ag-grid-theme/dist/23-dark.min.css';
import './NBIReporting.scss'
import {PreferenceType} from "../PreferenceType";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {useUser} from "../../../context/UserContext/UserContext";
import {Loader} from "../Loader";
import {ConnexionError} from "../ConnexionError";

interface INBIReportingParams {
    date: string;
    scope: string;
    subScope?: string;
    region?: string
}

interface INBIReportingBindingParams {
    rangeStartDate?: string;
    disableRegionView: boolean;
    fpvCallbackData?: any[];
    activePreference: PreferenceType;
    title: string
}

interface INBIReportingProps extends PropsWithRef<any> {
    type: DataViewType;
    columnDefsDefault: any;
    params: INBIReportingParams;
    bindingParams: INBIReportingBindingParams;
    disableRegionView: any
}

interface IUserPreference {
    reportStates?: any[];
    hiddenColumns?: any[];
    columnOrders?: any[];
}

interface IPreference {
    preference?: string;
    status?: any;
    name?: any;
}

type ReportData = Record<string, any>;

const PATH_SEPARATOR: string = '.';
const ZOOM_COLUMN_FIELD = 'viewDetailsLink';
const TOOL_PANEL_COLUMN_CUSTOMIZATION = "columnCustomization";
const NONE_PREFERENCE_LABEL = '...';

export const NBIReporting: React.FC<INBIReportingProps> = ({
                                                               type,
                                                               columnDefsDefault,
                                                               params,
                                                               bindingParams,
                                                               activeTab,
                                                               disableRegionView
                                                           }) => {
    const intl = useIntl();
    const {isSmallDevice, isLargeDevice} = useViewport();
    const [rawData, setRawData] = useState(undefined);
    const [rowData, setRowData] = useState(undefined);
    const [columnDefs, setColumnDefs] = useState(undefined);
    const [toolPanelClosed, setToolPanelClosed] = useState(true);
    const [activePreference, setActivePreference] = useState(PreferenceType.NONE);
    const preference = useRef<IUserPreference>();
    const preferenceDefaultAdmin = useRef<IUserPreference>();
    const preferencePrimaryAdmin = useRef<IUserPreference>();
    const preferenceSecondaryAdmin = useRef<IUserPreference>();
    const {isAdmin} = useUser();
    const [activePreferenceLabel, setActivePreferenceLabel] = useState(NONE_PREFERENCE_LABEL);
    const [adminDefaultViewLabel, setAdminDefaultViewLabel] = useState(NONE_PREFERENCE_LABEL);
    const [adminPrimaryViewLabel, setAdminPrimaryViewLabel] = useState(NONE_PREFERENCE_LABEL);
    const [adminSecondaryViewLabel, setAdminSecondaryViewLabel] = useState(NONE_PREFERENCE_LABEL);
    const USER_PREFERENCE_LABEL = 'MyPreference';
    const [hasUserPreference, setHasUserPreference] = useState(false);
    const [endFetchPreferenceAdmin, setEndFetchPreferenceAdmin] = useState(false);
    const [endFetchPreferenceUser, setEndFetchPreferenceUser] = useState(false);
    const gridApi = useRef<GridApi>();
    const [isLoading, setIsLoading] = useState(true);
    const gridColumnApi = useRef<ColumnApi>();
    const dataView: DataViewType = type;
    const scope: string = params.scope;
    let date: string = params.date;
    const {isProformaAvailable} = useRouteContext();
    const reports = document.querySelectorAll('.reporting .ag-root');
    const rangePicker = document.querySelector('.reporting-range-date');
    const reportSideBar = document.querySelectorAll('.reporting .ag-side-bar ');
    const [isConnexionFailed, setIsConnexionFailed] = useState(false);
    const [startDate, setStartDate] = useState(bindingParams.rangeStartDate);

    const autoGroupColumnDef = getAutoGroupColumnDef(dataView, isSmallDevice);
    const defaultColDef = getDefaultColDef(isSmallDevice);
    const sideBar = sideBarDef(isSmallDevice, dataView);
    const gridOptions = getGridOptions(isSmallDevice);

    const getColumnDefs = (preferenceJson: any) => {
        const year = DateService.getFullYearFromDate(date);
        let columnDefs: Array<any>;
        if (isSmallDevice) {
            columnDefs = [...columnDefsForSmallDevice];
        } else {
            const baseColumns: Array<any> = [indicatorColmun];
            if ((dataView === DataViewType.FPV || (dataView === DataViewType.REGION && params.region) || (dataView === DataViewType.RegionsFPV && params.region)) && isLargeDevice) {
                baseColumns.push(viewDetailsColumn);
            }
            columnDefs = baseColumns.concat(preferenceJson);
        }
        // Update column names according to date selected by user in case of year changed
        constructColumnNames(columnDefs, year);
        return columnDefs;
    };

    function flattenReportData(reportData: ReportData): ReportData {
        const flattenedData: ReportData = {};

        for (const [key, value] of Object.entries(reportData)) {
            if (Array.isArray(value)) {
                flattenedData[key] = value;
            } else if (typeof value === "object" && value !== null) {
                Object.assign(flattenedData, flattenReportData(value));
            } else {
                flattenedData[key] = value;
            }
        }
        return flattenedData;
    }

    const constructReportData: any = (reportData: Record<string, any>, previousReportData: any) => {
        reportData = flattenReportData(reportData)
        reportData.path = reportData.sortingMagic;

        // *** In order to differentiate lines with same Reporting Unit but different Indicator, an addition info is ***
        // *** added in sortingMagic value which is used as Path by agGrid ***
        if (previousReportData && previousReportData.sortingMagic === reportData.sortingMagic) {
            reportData.path = reportData.sortingMagic + PATH_SEPARATOR + 'IN' + reportData.indicatorId;
            reportData.cellClass = ['text-secondary'];
            reportData.hideForSmallDevice = true;
        }
        return reportData;
    }

    const constructRowData: any = (data: any, dataView?: DataViewType) => {
        const result: Array<any> = [];
        const prefixPath: string = 'RU';
        const secondPrefixPath: string = 'R';
        let reportDataList: Array<any> = [];
        let previousReportData: any = null;
        switch (dataView) {
            case DataViewType.FPV :
                reportDataList = data?.fpvreportData;
                break;
            case DataViewType.REGION :
                reportDataList = data?.regionsReportData;
                break;
            case DataViewType.COMFI :
                reportDataList = data?.comfiReportData;
                break;
        }
        if (reportDataList.length === 0) {
            return result;
        }

        reportDataList?.forEach(reportData => {
            reportData = constructReportData(reportData, previousReportData);

            if (isSmallDevice && reportData.hideForSmallDevice) {
                // Do nothing here - Filter rows for small device
            } else if (dataView === DataViewType.FPV && params.subScope && params.region === DefaultRegionOption.code) {
                // If subScope presents for FPV view, then row data need to be filtered by subScope
                const subScopePath = prefixPath + params.subScope;
                const subScopeRegexWithoutChildren = new RegExp(`\\w+\\.${subScopePath}$`);
                const subScopeRegexWithChildren = new RegExp(`\\w+\\.${subScopePath}\\.`);
                if (subScopeRegexWithoutChildren.test(reportData.path)) {
                    reportData.path = reportData.path.replace(subScopeRegexWithoutChildren, subScopePath);
                    result.push(reportData);
                } else if (subScopeRegexWithChildren.test(reportData.path)) {
                    reportData.path = reportData.path.replace(subScopeRegexWithChildren, subScopePath + PATH_SEPARATOR);
                    result.push(reportData);
                }
            }
            // SBU x Region(NO Asia)
            else if (params.subScope && params.region && params.region !== DefaultRegionOption.code && reportData.path) {
                if (reportData.path.includes(params.subScope) && params.region === GetRegionCodeByValue(reportData.regionParent)) {
                    const subScopeRegexWithChildren = new RegExp(`\\w+\\.\\w+\\.`);
                    reportData.path = reportData.path.replace(subScopeRegexWithChildren, '');
                    result.push(reportData);
                }
            }
            //SBU X Asia
            else if (params.subScope && reportData.path && !params.region) {
                if (reportData.path.includes(params.subScope)) {
                    const subScopeRegexWithChildren = new RegExp(`\\w+\\.`);
                    reportData.path = reportData.path.replace(subScopeRegexWithChildren, '');
                    result.push(reportData);
                }
            }
            // Bu x Region
            else if (params.region && params.region !== DefaultRegionOption.code && scope) {
                if (params.region === GetRegionCodeByValue(reportData.regionParent)) {
                    const scopePath = prefixPath + scope + secondPrefixPath + reportData.regionParent;
                    const scopeRegexWithoutChildren = new RegExp(`\\w+\\.${scopePath}$`);
                    const scopeRegexWithChildren = new RegExp(`\\w+\\.${scopePath}\\.`);
                    if (scopeRegexWithoutChildren.test(reportData.path)) {
                        reportData.path = reportData.path.replace(scopeRegexWithoutChildren, scopePath);
                        result.push(reportData);
                    } else if (scopeRegexWithChildren.test(reportData.path)) {
                        reportData.path = reportData.path.replace(scopeRegexWithChildren, scopePath + PATH_SEPARATOR);
                        result.push(reportData);
                    }
                }
            } else {
                result.push(reportData);
            }
            previousReportData = {...reportData};
        });
        if (dataView === DataViewType.FPV && params.subScope && params.region === DefaultRegionOption.code) {
            bindingParams.fpvCallbackData = result.filter(data => data.level == 2)
            disableRegionView(false)
        }
        return result;
    };

    const constructRowDataRegionFPV: any = (data: any, dataView?: DataViewType) => {

        const result: Array<any> = [];
        const prefixPath: string = 'RU';
        let reportDataListFPV: any[] | undefined = [];
        let reportDataListRegion: Array<any> = [];
        let previousReportData: any = null;

        if (dataView === DataViewType.RegionsFPV) {
            reportDataListRegion = data?.regionsReportData;
            if (reportDataListRegion.length > 0) {
                reportDataListFPV = bindingParams.fpvCallbackData;
            } else {
                return result;
            }
        }

        let dataReference: any = null;

        reportDataListFPV?.forEach(reportData => {
            reportData = constructReportData(reportData, previousReportData);

            if (isSmallDevice && reportData.hideForSmallDevice) {
                // Do nothing here - Filter rows for small device
            }
            if (params.subScope && params.region === DefaultRegionOption.code) {
                const subScopePath = prefixPath + params.subScope;
                const subScopeRegexWithoutChildren = new RegExp(`\\w+\\.${subScopePath}$`);
                const subScopeRegexWithChildren = new RegExp(`\\w+\\.${subScopePath}\\.`);

                if (subScopeRegexWithoutChildren.test(reportData.path)) {
                    reportData.path = reportData.path.replace(subScopeRegexWithoutChildren, subScopePath);
                    result.push(reportData);
                    dataReference = reportData;
                } else if (subScopeRegexWithChildren.test(reportData.path) && dataReference.title === reportData.title) {
                    reportData.path = reportData.path.replace(subScopeRegexWithChildren, subScopePath + PATH_SEPARATOR);
                    result.push(reportData);
                }
            }
            previousReportData = {...reportData};
        });

        let scopeRegion: any = '';
        reportDataListRegion?.forEach(reportData => {
            reportData = constructReportData(reportData, previousReportData);

            if (isSmallDevice && reportData.hideForSmallDevice) {
                // Do nothing here - Filter rows for small device
            }
            if (reportData.title && (reportData.title === dataReference?.title)) {
                scopeRegion = reportData.unitId;
            }
            if (scopeRegion && reportData.path) {
                if (reportData.path.includes(scopeRegion)) {
                    const subScopeRegexWithChildren = new RegExp(`\\w+\\.\\w+\\.`);
                    reportData.path = reportData.path.replace(subScopeRegexWithChildren, '');
                    const parentPath = dataReference.path + PATH_SEPARATOR;
                    reportData.path = parentPath.concat(reportData.path);
                    if (reportData.level === 3) {
                        reportData.title = GetRegionTitleByValue(reportData.regionParent);
                    }
                    result.push(reportData);
                }
            }
            previousReportData = {...reportData};
        });
        return result;
    };

    const onGridReady = (params: GridReadyEvent) => {
        gridApi.current = params.api;
        gridColumnApi.current = params.columnApi;
    };

    const saveColumnsState = () => {
        setHasUserPreference(true)
        savePreference(false, true, false, undefined);
    };

    const saveAdminColumnsState = (prefType: PreferenceType) => {
        savePreference(false, true, true, prefType);
    };

    const applyUserPref = (pref: IUserPreference | undefined, shouldResetTodefault: boolean) => {
        // apply for rows

        if (!pref) {
            return;
        }
        updateColumnDefs();
        if (shouldResetTodefault) {
            resetToDefault();
        }

        // apply for columns
        const columnOrders: any[] = pref.columnOrders || [];
        const hiddenColumns: any[] = pref.hiddenColumns || [];

        // dataView === DataViewType.REGION && !params.region) ==> Asia user
        if ((dataView === DataViewType.COMFI || (dataView === DataViewType.REGION && !params.region)) && columnOrders.includes(ZOOM_COLUMN_FIELD)) {
            // remove zoom column from columnOrders
            columnOrders.splice(2, 1)
        }

        if ((dataView === DataViewType.FPV || (dataView === DataViewType.REGION && params.region) || dataView === DataViewType.RegionsFPV) && !columnOrders.includes(ZOOM_COLUMN_FIELD)) {
            // if orderColumns not includes zoomColumnField we should add it
            columnOrders.splice(2, 0, ZOOM_COLUMN_FIELD)
        }

        updateCurrentYearColsPref(columnDefsDefault, columnOrders);
        if (columnOrders.length > 0) {
            gridColumnApi.current?.moveColumns(columnOrders, 0);
        }
        hiddenColumns.forEach((hiddenColumn: string) => {
            gridColumnApi.current?.setColumnVisible(hiddenColumn, false);
        });

        gridApi.current?.sizeColumnsToFit();
        setIsLoading(false);
    };
    const getDataPath = (data: any) => {
        return data?.path?.split(PATH_SEPARATOR);
    };

    const setReportingData = (data: any) => {
        const constructedData = (dataView === DataViewType.RegionsFPV) ? constructRowDataRegionFPV(data, dataView) : constructRowData(data, dataView);
        if (constructedData && constructedData.length > 0) {
            setRawData(data);
            setRowData(constructedData);
            gridApi.current?.setRowData(constructedData);
            gridApi.current?.forEachNode((node: RowNode) => {
                node.setExpanded(false);
                if (node.level === 0) {
                    node.setExpanded(true);
                }
            });
        } else {
            gridApi.current?.showNoRowsOverlay();
        }
    };

    const getUserPreference = (prefType: PreferenceType | undefined) => {
        switch (prefType) {
            case PreferenceType.DEFAULT_USER_VIEW:
                return preference.current
            case PreferenceType.DEFAULT_ADMIN_VIEW:
                return preferenceDefaultAdmin.current
            case PreferenceType.PRIMARY_ADMIN_VIEW:
                return preferencePrimaryAdmin.current
            case PreferenceType.SECONDARY_ADMIN_VIEW:
                return preferenceSecondaryAdmin.current
            case PreferenceType.NONE:
                return undefined
        }
    }

    const getPreferenceLabel = (prefType: PreferenceType) => {
        switch (prefType) {
            case PreferenceType.DEFAULT_USER_VIEW:
                return USER_PREFERENCE_LABEL
            case PreferenceType.DEFAULT_ADMIN_VIEW:
                return adminDefaultViewLabel
            case PreferenceType.PRIMARY_ADMIN_VIEW:
                return adminPrimaryViewLabel
            case PreferenceType.SECONDARY_ADMIN_VIEW:
                return adminSecondaryViewLabel
            case PreferenceType.NONE:
                return NONE_PREFERENCE_LABEL
        }
    }

    const fetchAndApplyAdminDefaultPreferences = () => {
        setEndFetchPreferenceAdmin(false)
        setEndFetchPreferenceUser(false)
        const subscription = GetAllAdminReportingPreference()
            .subscribe((pref: IPreference[]) => {
                let userPreference: IPreference | undefined;
                userPreference = pref.find((value) => value.status === PreferenceType.DEFAULT_ADMIN_VIEW) ?? undefined;
                preferenceDefaultAdmin.current = JSON.parse(userPreference?.preference as string)
                setAdminDefaultViewLabel(userPreference?.name)
                userPreference = pref.find((value) => value.status === PreferenceType.PRIMARY_ADMIN_VIEW) ?? undefined;
                preferencePrimaryAdmin.current = JSON.parse(userPreference?.preference as string)
                setAdminPrimaryViewLabel(userPreference?.name)
                userPreference = pref.find((value) => value.status === PreferenceType.SECONDARY_ADMIN_VIEW) ?? undefined;
                preferenceSecondaryAdmin.current = JSON.parse(userPreference?.preference as string)
                setAdminSecondaryViewLabel(userPreference?.name)
                setEndFetchPreferenceAdmin(true)
            });
        let hasPersonalPreference: boolean = false;

        const subscription2 = GetUserReportingPreference()
            .subscribe((pref: IUserPreference) => {
                if (pref) {
                    hasPersonalPreference = true
                    preference.current = pref
                } else {
                    hasPersonalPreference = false
                }
                setHasUserPreference(hasPersonalPreference)
                setEndFetchPreferenceUser(true)
            });
        return () => {
            subscription.unsubscribe();
            subscription2.unsubscribe();
        }
    };

    function updateUserPreference(prefType: PreferenceType | undefined, preferenceJson: IUserPreference) {
        switch (prefType) {
            case PreferenceType.DEFAULT_ADMIN_VIEW:
                preferenceDefaultAdmin.current = preferenceJson
                break
            case PreferenceType.PRIMARY_ADMIN_VIEW:
                preferencePrimaryAdmin.current = preferenceJson
                break
            case PreferenceType.SECONDARY_ADMIN_VIEW:
                preferenceSecondaryAdmin.current = preferenceJson
                break
            case PreferenceType.NONE:
                break
        }
    }

    function getSuccessMessage(toastId: string | number) {
        toast.update(toastId, {
            render: intl.formatMessage({id: 'reporting.savePrefSuccessfully'}),
            type: toast.TYPE.SUCCESS,
            autoClose: 3000
        })
    }

    function getErrorMessage(toastId: string | number) {
        toast.update(toastId, {
            render: intl.formatMessage({id: 'reporting.savePrefFailed'}),
            type: toast.TYPE.ERROR,
            autoClose: 3000
        });
    }

    const savePreference = (saveReportStates: boolean, saveColumnStates: boolean, isAdminPreference: boolean, prefType: PreferenceType | undefined) => {
        const toastId = toast.info(intl.formatMessage({id: 'reporting.savePrefInProgress'}), {autoClose: false});
        const expandedRows: string[] = [];
        gridApi.current?.forEachNode((node: RowNode) => {
            if (node.expanded) {
                expandedRows.push(node.data.path);
            }
        });
        let pref = getUserPreference(prefType)
        const currentPrefInJson = constructPreferenceInJson(pref, expandedRows, scope, saveReportStates, saveColumnStates)
        let preferenceJson: IUserPreference = JSON.parse(currentPrefInJson as string) ?? undefined;
        if (isAdminPreference) {
            const subscription = SaveAdminReportingPreference(currentPrefInJson, prefType)
                .subscribe(
                    () => {
                        updateUserPreference(prefType, preferenceJson)
                        getSuccessMessage(toastId);
                    },
                    () => getErrorMessage(toastId)
                )
            return () => subscription.unsubscribe();
        } else {
            const subscription = SaveUserReportingPreference(currentPrefInJson)
                .subscribe(
                    () => {
                        preference.current = preferenceJson;
                        getSuccessMessage(toastId);
                    },
                    () =>
                        getErrorMessage(toastId)
                )
            return () => subscription.unsubscribe();
        }
    };

    /**
     * @param result
     * @param expandedRows
     * @param scope
     * @param saveReportStats
     * @param saveColumnStates
     */
    const constructPreferenceInJson = (result: any, expandedRows: string[], scope: string, saveReportStats: boolean, saveColumnStates: boolean) => {
        const preferenceJson: IUserPreference = result ?? {};
        const id = parseInt(scope);
        if (saveReportStats) {
            let reportState: any = preferenceJson.reportStates?.find((state: any) => state.id === parseInt(scope) && state.dataView === dataView);
            if (reportState) {
                reportState.path = expandedRows;
            } else {
                reportState = {
                    id,
                    dataView,
                    path: expandedRows
                };
                if (!Array.isArray(preferenceJson.reportStates)) {
                    preferenceJson.reportStates = [];
                }
                preferenceJson.reportStates.push(reportState);
            }
        }
        if (saveColumnStates) {
            const columnState = gridColumnApi.current?.getColumnState() || [];
            const prefColumnOrder: string[] = [];
            const prefHiddenColumns: string[] = [];
            columnState.forEach((column: ColumnState) => {
                if (column.colId) {
                    prefColumnOrder.push(column.colId);
                    if (column.hide) {
                        prefHiddenColumns.push(column.colId);
                    }
                }
            });
            preferenceJson.columnOrders = prefColumnOrder;
            preferenceJson.hiddenColumns = prefHiddenColumns;
        }
        return JSON.stringify(preferenceJson)
    };

    const refreshPreference = (isAdmin: boolean, userPreference: PreferenceType) => {
        resetToDefault()
        if (activePreference === userPreference) {
            applyUserPref(getUserPreference(userPreference), false)
        } else {
            setActivePreference(userPreference)
        }
    };

    const resetToDefault = () => {
        gridApi.current?.setColumnDefs([]);
        gridApi.current?.setColumnDefs(getColumnDefs(columnDefsDefault));
        gridApi.current?.dispatchEvent({type: 'columnVisible'});
        gridApi.current?.forEachNode((node: RowNode) => {
            if (node.level === 0) {
                node.setExpanded(true);
            }
        });
    };

    const handleToolPanelClick = (toolPanelId: string) => {
        const openedToolPanel = gridApi.current?.getOpenedToolPanel();
        if (toolPanelId === openedToolPanel) {
            reports.forEach(value => {
                value?.setAttribute("style", `top: 0 !important`)
            })
            reportSideBar.forEach(value => {
                value?.setAttribute("style", `visibility: hidden !important`)
            })
            rangePicker?.setAttribute("style", `visibility: hidden !important`)

            gridApi.current?.closeToolPanel();
            setToolPanelClosed(true);
        } else {
            reports.forEach(value => {
                value?.setAttribute("style", `top: 6.5em !important`)
            })
            reportSideBar.forEach(value => {
                value?.setAttribute("style", `visibility: visible !important`)
            })
            // rangePicker?.setAttribute("style", `visibility: visible !important`)
            gridApi.current?.openToolPanel(toolPanelId);
            setToolPanelClosed(false);
        }
    };

    useEffect(() => {
        let timerHandler: number;
        if (rawData && columnDefsDefault) {
            updateColumnDefs()
            setRowData((dataView === DataViewType.RegionsFPV) ? constructRowDataRegionFPV(rawData, dataView) : constructRowData(rawData, dataView));
            timerHandler = window.setTimeout(() => {
                gridApi.current?.forEachNode((node: RowNode) => {
                    if (node.level === 0) {
                        node.setExpanded(true);
                    }
                });
            });
        }
        return () => window.clearTimeout(timerHandler);
    }, [isSmallDevice, activeTab]);

    useEffect(() => {
        updateColumnDefs();
    }, [columnDefsDefault, activeTab]);

    const updateColumnDefs = () => {
        setColumnDefs(getColumnDefs(columnDefsDefault) as any);
    }

    /**
     * RegionsFPV data needs to load FPV data first, stored inside bindingParams.fpvCallbackData
     */
    useEffect(() => {
        if (dataView === DataViewType.RegionsFPV && bindingParams.fpvCallbackData && bindingParams.fpvCallbackData.length > 0) {
            return getReportingData();
        }
    }, [bindingParams.fpvCallbackData]);

    useEffect(() => {
        if (dataView !== DataViewType.RegionsFPV && startDate) {
            return getReportingData();
        }
    }, [params, isProformaAvailable, startDate]);

    useEffect(() => {
        setStartDate(bindingParams.rangeStartDate)
        resetToolPanel(); // close tool panel
        gridApi.current?.setSideBar(sideBar);// refresh sideBar
    }, [bindingParams.rangeStartDate]);

    /**
     * FetchNbiReportingData api loads REGION data for RegionsFPV dataView and concat it
     * with FPV data inside constructRowDataRegionFPV function
     */
    const getReportingData = () => {
        let subscription: any;
        gridApi.current?.setRowData([]);
        gridApi.current?.showLoadingOverlay();
        setIsLoading(true);
        if (scope && date && columnDefsDefault) {
            updateColumnDefs();
            if (bindingParams.activePreference !== PreferenceType.NONE) {
                setActivePreference(bindingParams.activePreference)
            }
            const importType: string = isProformaAvailable ? "STABILIZED" : "PUBLISHED";
            const regionCode: string | undefined = params.region;
            const regionId = GetRegionValueByCode(regionCode);
            const dataViewValue: DataViewType = dataView === DataViewType.RegionsFPV ? DataViewType.REGION : dataView;
            const rangeDate = rangeDateProperty(date, startDate);
            subscription = FetchNbiReportingData(rangeDate, importType, scope, dataViewValue, regionId)
                .subscribe(
                    data => {
                        if (data) {
                            setReportingData(data)
                        } else {
                            setRawData(undefined);
                            setRowData(undefined);
                        }
                    },
                    () => {
                        setIsConnexionFailed(true);
                    });
        }
        setIsLoading(false);
        return () => subscription && subscription.unsubscribe();
    }

    function resetToolPanel() {
        reports.forEach(value => {
            value?.setAttribute("style", `top: 0 !important`)
        })
        reportSideBar.forEach(value => {
            value?.setAttribute("style", `visibility: hidden !important`)
        })
        rangePicker?.setAttribute("style", `visibility: hidden !important`)
        setToolPanelClosed(true);
        gridApi.current?.closeToolPanel();
    }

    useEffect(() => {
        if (activeTab === dataView || dataView === DataViewType.RegionsFPV) {
            setIsLoading(true)
            fetchAndApplyAdminDefaultPreferences()
            resetToolPanel();
        }
    }, [activeTab]);

    useEffect(() => {
        if (endFetchPreferenceUser && endFetchPreferenceAdmin) {
            if (bindingParams.activePreference !== PreferenceType.NONE && bindingParams.activePreference !== activePreference) {
                setActivePreference(bindingParams.activePreference)
            } else if (bindingParams.activePreference === activePreference && bindingParams.activePreference !== PreferenceType.NONE) {
                setActivePreferenceLabel(getPreferenceLabel(activePreference))
                applyUserPref(getUserPreference(activePreference), false)
            } else {
                setActivePreference(hasUserPreference ? PreferenceType.DEFAULT_USER_VIEW : PreferenceType.DEFAULT_ADMIN_VIEW)
                setActivePreferenceLabel(getPreferenceLabel(hasUserPreference ? PreferenceType.DEFAULT_USER_VIEW : PreferenceType.DEFAULT_ADMIN_VIEW))
                applyUserPref(getUserPreference(hasUserPreference ? PreferenceType.DEFAULT_USER_VIEW : PreferenceType.DEFAULT_ADMIN_VIEW), false)
            }
        }
    }, [endFetchPreferenceUser, endFetchPreferenceAdmin]);

    useEffect(() => {
        setActivePreferenceLabel(getPreferenceLabel(activePreference))
        applyUserPref(getUserPreference(activePreference), true)
        bindingParams.activePreference = activePreference
    }, [activePreference]);

    const LocalLoader = () => {
        return <Loader/>;
    };
    const Error = () => {
        return < ConnexionError/>;
    };
    const exportDataAsExcel = (): void => {
        const scopeName = bindingParams.title;
        const allColumns = gridColumnApi.current?.getAllDisplayedColumns();
        gridApi.current?.exportDataAsExcel(defaultExcelExportParams(allColumns as Column[], intl, scopeName, date));
    };
    const excelStyles = useMemo(() => {
        return excelStylesValue()
    }, []);

    return (
        <div>
            {(() => {
                if (isConnexionFailed) {
                    return <Error/>;
                } else {
                    return (
                        <div className="reporting w-100">
                            <div
                                className={`${isSmallDevice || isLoading ? 'd-none' : 'd-flex'} align-items-center justify-content-end position-absolute top-0 end-0`}>
                                <button className={`btn btn-flat  ${!toolPanelClosed ? 'active' : ''}`}
                                        id={TOOL_PANEL_COLUMN_CUSTOMIZATION}
                                        onClick={() => handleToolPanelClick(TOOL_PANEL_COLUMN_CUSTOMIZATION)}>
                                    <i className="icon icon-sm me-1">drag_indicator</i>
                                    <FormattedMessage id="reporting.customView"/>
                                </button>
                                <UncontrolledDropdown>
                                    <DropdownToggle nav className="pr-0 p-0">
                                        {activePreference === PreferenceType.NONE ? NONE_PREFERENCE_LABEL :
                                            <button
                                                className="btn btn-flat-primary dropdown-toggle active-favorite-button">
                                                <i className="icon icon-sm me-1">star</i>
                                                {activePreferenceLabel}
                                            </button>
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu right className="box-shadow dropdown-menu">
                                        <DropdownItem
                                            onClick={() => refreshPreference(false, PreferenceType.DEFAULT_USER_VIEW)}
                                            disabled={!hasUserPreference}
                                            className={` favorite-button ${activePreference === PreferenceType.DEFAULT_USER_VIEW ? 'active' : ''}`}>
                                            {USER_PREFERENCE_LABEL}
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => refreshPreference(true, PreferenceType.DEFAULT_ADMIN_VIEW)}
                                            className={` favorite-button ${activePreference === PreferenceType.DEFAULT_ADMIN_VIEW ? 'active' : ''}`}>
                                            {adminDefaultViewLabel}
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => refreshPreference(true, PreferenceType.PRIMARY_ADMIN_VIEW)}
                                            className={`favorite-button ${activePreference === PreferenceType.PRIMARY_ADMIN_VIEW ? 'active' : ''}`}>
                                            {adminPrimaryViewLabel}
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => refreshPreference(true, PreferenceType.SECONDARY_ADMIN_VIEW)}
                                            className={`favorite-button ${activePreference === PreferenceType.SECONDARY_ADMIN_VIEW ? 'active' : ''}`}>
                                            {adminSecondaryViewLabel}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                {(() => {
                                        if (isAdmin) {
                                            return <UncontrolledDropdown>
                                                <DropdownMenu right className="box-shadow dropdown-menu">
                                                    <DropdownItem onClick={saveColumnsState}>
                                                        {USER_PREFERENCE_LABEL}
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => saveAdminColumnsState(PreferenceType.DEFAULT_ADMIN_VIEW)}>
                                                        {adminDefaultViewLabel}
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => saveAdminColumnsState(PreferenceType.PRIMARY_ADMIN_VIEW)}>
                                                        {adminPrimaryViewLabel}
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => saveAdminColumnsState(PreferenceType.SECONDARY_ADMIN_VIEW)}>
                                                        {adminSecondaryViewLabel}
                                                    </DropdownItem>
                                                </DropdownMenu>
                                                <DropdownToggle nav className="pr-0 p-0">
                                                    <button className="btn btn-flat">
                                                        <i className="icon icon-sm me-1">save</i>
                                                        Save as
                                                    </button>
                                                </DropdownToggle>
                                            </UncontrolledDropdown>;
                                        } else {
                                            return (
                                                <button className="btn btn-flat"
                                                        onClick={saveColumnsState}>
                                                    <i className="icon icon-sm me-1">save</i>
                                                    <FormattedMessage id="reporting.saveGridPreferences"/>
                                                </button>
                                            );
                                        }
                                    }
                                )()}
                                <button className="btn btn-flat" onClick={exportDataAsExcel}
                                        style={{padding: '3px 14px'}}>
                                    <i className="icon icon-small">save_alt</i>
                                </button>
                            </div>
                            {isLoading && <LocalLoader/>}
                            <div className={` ${isLoading ? 'd-none' : ''}`}>
                                <div className="w-100 flex-grow-1 ">
                                    <div className={`ag-theme-sg-bootstrap ag-theme-sg-bootstrap-condensed
                                  ${isSmallDevice ? 'small-device' : ''} ${toolPanelClosed ? 'tool-panel-closed' : ''}`}>
                                        <AgGridReact
                                            gridOptions={gridOptions}
                                            columnDefs={columnDefs}
                                            excelStyles={excelStyles}
                                            animateRows={true}
                                            rowData={rowData}
                                            treeData={true}
                                            autoGroupColumnDef={autoGroupColumnDef}
                                            sideBar={sideBar}
                                            getDataPath={getDataPath}
                                            onGridReady={onGridReady}
                                            frameworkComponents={{
                                                viewDetailsRenderer: ViewDetailsRenderer,
                                                columnCustomizationToolPanel: ColumnCustomizationToolPanel
                                            }}
                                            defaultColDef={defaultColDef}
                                        >
                                        </AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            })()}
        </div>
    );
};
