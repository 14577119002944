import {throwError} from 'rxjs';
import {getEnvVar} from "../../env-vars-utils";

interface IScope {
  id: string;
  name: string;
}

class Location {
  static readonly WW = new Location(1, 'ww', 'Worldwide');
  static readonly ASIA = new Location(2, 'asia', 'Asia');

  readonly id: number;
  readonly code: string;
  readonly name: string;

  constructor(id: number, code: string, name: string) {
    this.id = id;
    this.code = code;
    this.name = name;
  }
}

const SCOPE = {
  GBIS_ID: 1,
  MARK_ID: 13,
  GBIS_ASIA_ID: 15,
  MARK_ASIA_ID: 16
};

const MARK_UK: IScope = {
  id: '17',
  name: 'MARK UK'
}

// tslint:disable-next-line: max-classes-per-file
export default class UtilsService {
  static isGbisOrMark(reportingUnitId: number): boolean {
    return reportingUnitId === SCOPE.MARK_ID || reportingUnitId === SCOPE.GBIS_ID ||
        reportingUnitId === SCOPE.MARK_ASIA_ID || reportingUnitId === SCOPE.GBIS_ASIA_ID;
  }

  static isMarkUK(reportingUnit: number | string): boolean {
    return reportingUnit === MARK_UK.name || reportingUnit === (MARK_UK.id)
  }

  static isMarkUKOrChild(reportingUnit: number | string): boolean {
    return reportingUnit.toString().includes(MARK_UK.name)
  }

  static getMarkUK(): IScope {
    return MARK_UK;
  }

  static isGbisOrMarkForWW(reportingUnitId: number): boolean {
    return reportingUnitId === SCOPE.MARK_ID || reportingUnitId === SCOPE.GBIS_ID;
  }

  static isWorldWideByID(locationId: number): boolean {
    return locationId === Location.WW.id;
  }

  static isAsiaByID(locationId: number): boolean {
    return locationId === Location.ASIA.id;
  }

  static isWorldWideByCode(locationCode: string): boolean {
    return locationCode === Location.WW.code;
  }

  static isAsiaByCode(locationCode: string): boolean {
    return locationCode === Location.ASIA.code;
  }

  static getLocationNameByCode(locationCode: string): string {
    if (UtilsService.isWorldWideByCode(locationCode)) {
      return Location.WW.name;
    }
    else if (UtilsService.isAsiaByCode(locationCode)) {
      return Location.ASIA.name;
    }
    return "";
  }

  static formatNumberWithOneDigit(numberToFormat: number, alreadyInMillion ?: boolean): number {
    if (alreadyInMillion) {
      return this.applySpecificFormattingToNumber(numberToFormat, 1,1);
    } else {
      return this.formatNumber(numberToFormat, 1);
    }
  }

  static numberCommasSeparator = (number: any) => {
    if (Number.isFinite(number)) {
      return number.toLocaleString();
    }
    else {
      return '';
    }
  };

  static formatNumberWithTwoDigits(numberToFormat: number): number {
    return this.formatNumber(numberToFormat, 2);
  }

  static formatNumber(numberToFormat: number, digits: number): number {
    return +(numberToFormat / 1000).toFixed(digits);
  }

// use for format in Milllion and decimal
  static applySpecificFormattingToNumber(numberToFormat: number, digits: number, format: number): number{
    return +(numberToFormat*format).toFixed(digits);
  }
  static getYearFromDate(date: string): string {
    return date.substring(0, 4);
  }

  /**
   * Response.json() method doesn't check if response body is empty, it throws un exception in this case.
   * This method check if response is equal to null before transfer to JSON object and return an empty
   * object in case of Null.
   * @param response
   */
  static buildJsonResponse = async (response: Response) => {
    const textBody = await response.text();
    return await (textBody.length ? JSON.parse(textBody) : {});
  };
}

export const handleError = (error: any) => {
  if (error?.status === 401) {
    document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    window.location.href = getEnvVar('react_app_unauthorized_url') as any;
  }
  return throwError(error);
};