import React, {createContext, useContext, useEffect, useState} from 'react';

const SM_MIN_BREAKPOINT: number = 576;
const MD_MIN_BREAKPOINT: number = 768;
const LG_MIN_BREAKPOINT: number = 992;

interface IViewportContext {
  width: number;
  height: number;
  isExtraSmallDevice: boolean;
  isSmallDevice: boolean;
  isMediumDevice: boolean;
  isLargeDevice: boolean;
}

const defaultContext: IViewportContext = {
  width: window.innerWidth,
  height: window.innerHeight,
  isExtraSmallDevice: false,
  isSmallDevice: false,
  isMediumDevice: false,
  isLargeDevice: true
};

export const ViewportContext = createContext<IViewportContext>(defaultContext);

export const GlobalViewportProvider: React.FC = ({children}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  // Extra Small devices
  const extraSmallDeviceDetect = (windowsWidth: number) => {
    return windowsWidth < SM_MIN_BREAKPOINT;
  };

  // Small devices
  const smallDeviceDetect = (windowsWidth: number) => {
    return windowsWidth < MD_MIN_BREAKPOINT;
  };

  // Medium devices (tablets, 768px and up)
  const mediumDeviceDetect = (windowsWidth: number) => {
    return windowsWidth < LG_MIN_BREAKPOINT && windowsWidth >= MD_MIN_BREAKPOINT;
  };

  // Large devices (desktops, 992px and up)
  const largeDeviceDelete = (windowsWidth: number) => {
    return windowsWidth >= LG_MIN_BREAKPOINT;
  };

  const [isExtraSmallDevice, setIsExtraSmallDevice] = useState(extraSmallDeviceDetect(window.innerWidth));
  const [isSmallDevice, setIsSmallDevice] = useState(smallDeviceDetect(window.innerWidth));
  const [isMediumDevice, setIsMediumDevice] = useState(mediumDeviceDetect(window.innerWidth));
  const [isLargeDevice, setIsLargeDevice] = useState(largeDeviceDelete(window.innerWidth));

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    setIsExtraSmallDevice(extraSmallDeviceDetect(window.innerWidth));
    setIsSmallDevice(smallDeviceDetect(window.innerWidth));
    setIsMediumDevice(mediumDeviceDetect(window.innerWidth));
    setIsLargeDevice(largeDeviceDelete(window.innerWidth));
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
      <ViewportContext.Provider value={{width, height, isExtraSmallDevice, isSmallDevice, isMediumDevice, isLargeDevice}}>
        {children}
      </ViewportContext.Provider>
  );
};

export const useViewport = () => useContext<IViewportContext>(ViewportContext);